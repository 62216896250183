.h_bg_wrapper {
  box-sizing: border-box;
  font-family: "Figtree", sans-serif !important;
  background-color: #fff;

  // &::-webkit-scrollbar-track {
  //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  //   background-color: #f5f5f5;
  // }
  
  // &::-webkit-scrollbar {
  //   width: 6px;
  //   background-color: $grey;
  // }
  
  // &::-webkit-scrollbar-thumb {
  //   background-color: $blue;
  // }

  * {
    letter-spacing: normal;
    font-family: "Figtree", sans-serif;
    outline: none !important;
    box-sizing: border-box;
  }

  h6,
  h5,
  h4,
  h3,
  h2,
  h1 {
    line-height: normal !important;
  }

  img {
    user-select: none;
    max-width: 100%;
  }
}
