.card-table table {
    border-collapse: collapse
}

th {
    text-align: inherit
}

label {
    display: inline-block;
    margin-bottom: .5rem
}

button {
    border-radius: 0
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,
input {
    overflow: visible
}

button,
select {
    text-transform: none
}

select {
    word-wrap: normal
}

[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: button
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
    cursor: pointer
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    padding: 0
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: none
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

.small,
small {
    font-size: .8125rem;
    font-weight: 400
}

.figure {
    display: inline-block
}

.table {
    width: 100%;
    margin-bottom: 1.5rem;
    color: #12263f
}

.table td,
.table th {
    padding: 1rem;
    vertical-align: top;
    border-top: 1px solid #edf2f9
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #edf2f9
}

.table tbody+tbody {
    border-top: 2px solid #edf2f9
}

.table-sm td,
.table-sm th {
    padding: 1rem
}

.table-bordered {
    border: 1px solid #edf2f9
}

.table-bordered td,
.table-bordered th {
    border: 1px solid #edf2f9
}

.table-bordered thead td,
.table-bordered thead th {
    border-bottom-width: 2px
}

.table-borderless tbody+tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
    border: 0
}

.table-striped tbody tr:nth-of-type(even) {
    background-color: #f9fbfd
}

.table-primary,
.table-primary>td,
.table-primary>th {
    background-color: #c4daf8
}

.table-primary tbody+tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
    border-color: #91baf1
}

.table-hover .table-primary:hover {
    background-color: #adccf5
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
    background-color: #adccf5
}

.table-secondary,
.table-secondary>td,
.table-secondary>th {
    background-color: #d6dde5
}

.table-secondary tbody+tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
    border-color: #b4bfcf
}

.table-hover .table-secondary:hover {
    background-color: #c6d0db
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
    background-color: #c6d0db
}

.table-success,
.table-success>td,
.table-success>th {
    background-color: #b8f4db
}

.table-success tbody+tbody,
.table-success td,
.table-success th,
.table-success thead th {
    border-color: #7aebbc
}

.table-hover .table-success:hover {
    background-color: #a2f1d0
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
    background-color: #a2f1d0
}

.table-info,
.table-info>td,
.table-info>th {
    background-color: #c8e9f2
}

.table-info tbody+tbody,
.table-info td,
.table-info th,
.table-info thead th {
    border-color: #98d5e7
}

.table-hover .table-info:hover {
    background-color: #b3e1ed
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
    background-color: #b3e1ed
}

.table-warning,
.table-warning>td,
.table-warning>th {
    background-color: #fceeca
}

.table-warning tbody+tbody,
.table-warning td,
.table-warning th,
.table-warning thead th {
    border-color: #fae09d
}

.table-hover .table-warning:hover {
    background-color: #fbe6b2
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
    background-color: #fbe6b2
}

.table-danger,
.table-danger>td,
.table-danger>th {
    background-color: #f8c7d0
}

.table-danger tbody+tbody,
.table-danger td,
.table-danger th,
.table-danger thead th {
    border-color: #f297a8
}

.table-hover .table-danger:hover {
    background-color: #f5b0bd
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
    background-color: #f5b0bd
}

.table-light,
.table-light>td,
.table-light>th {
    background-color: #fafbfd
}

.table-light tbody+tbody,
.table-light td,
.table-light th,
.table-light thead th {
    border-color: #f6f8fc
}

.table-hover .table-light:hover {
    background-color: #e8ecf6
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
    background-color: #e8ecf6
}

.table-dark,
.table-dark>td,
.table-dark>th {
    background-color: #bdc2c9
}

.table-dark tbody+tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
    border-color: #848e9b
}

.table-hover .table-dark:hover {
    background-color: #afb5be
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
    background-color: #afb5be
}

.table-white,
.table-white>td,
.table-white>th {
    background-color: #fff
}

.table-white tbody+tbody,
.table-white td,
.table-white th,
.table-white thead th {
    border-color: #fff
}

.table-hover .table-white:hover {
    background-color: #f2f2f2
}

.table-hover .table-white:hover>td,
.table-hover .table-white:hover>th {
    background-color: #f2f2f2
}

.table-active,
.table-active>td,
.table-active>th {
    background-color: #f9fbfd
}

.table-hover .table-active:hover {
    background-color: #e6eef7
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
    background-color: #e6eef7
}

.table .thead-dark th {
    color: #fff;
    background-color: #3b506c;
    border-color: #496285
}

.table .thead-light th {
    color: #95aac9;
    background-color: #f9fbfd;
    border-color: #edf2f9
}

.table-dark {
    color: #fff;
    background-color: #3b506c
}

.table-dark td,
.table-dark th,
.table-dark thead th {
    border-color: #496285
}

.table-dark.table-bordered {
    border: 0
}

.table-dark.table-striped tbody tr:nth-of-type(even) {
    background-color: rgba(255, 255, 255, .05)
}

.table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, .075)
}

.table-hover tbody tr:hover {
    color: #12263f !important;
    background-color: #f9fbfd !important;
}


@media (max-width:575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-sm>.table-bordered {
        border: 0
    }
}

@media (max-width:767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-md>.table-bordered {
        border: 0
    }
}

@media (max-width:991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-lg>.table-bordered {
        border: 0
    }
}

@media (max-width:1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-xl>.table-bordered {
        border: 0
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}

.table-responsive>.table-bordered {
    border: 0
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 1rem + 2px);
    padding: .5rem .75rem;
    font-size: .9375rem;
    font-weight: 400;
    line-height: 1.5;
    color: #12263f;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d2ddec;
    border-radius: .375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    appearance: auto !important;
}

.form-control-cal {
    height: calc(1.5em + 1rem + 2px);
    padding: .5rem .75rem;
    font-size: .9375rem;
    font-weight: 400;
    line-height: 1.5;
    color: #12263f;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d2ddec;
    border-radius: .375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-control {
        transition: none
    }
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0
}

.form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #12263f
}

.form-control:focus {
    color: #12263f;
    background-color: #fff;
    border-color: #2c7be5;
    outline: 0;
    box-shadow: transparent
}

.form-control::-webkit-input-placeholder {
    color: #b1c2d9;
    opacity: 1
}

.form-control::-moz-placeholder {
    color: #b1c2d9;
    opacity: 1
}

.form-control:-ms-input-placeholder {
    color: #b1c2d9;
    opacity: 1
}

.form-control::-ms-input-placeholder {
    color: #b1c2d9;
    opacity: 1
}

.form-control::placeholder {
    color: #b1c2d9;
    opacity: 1
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #fff;
    opacity: 1
}

select.form-control:focus::-ms-value {
    color: #12263f;
    background-color: #fff
}

.form-control-file,
.form-control-range {
    display: block;
    width: 100%
}

.col-form-label {
    padding-top: calc(.5rem + 1px);
    padding-bottom: calc(.5rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5
}

.col-form-label-lg {
    padding-top: calc(.75rem + 1px);
    padding-bottom: calc(.75rem + 1px);
    font-size: .9375rem;
    line-height: 1.5
}

.col-form-label-sm {
    padding-top: calc(.125rem + 1px);
    padding-bottom: calc(.125rem + 1px);
    font-size: .8125rem;
    line-height: 1.75
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: .5rem 0;
    margin-bottom: 0;
    font-size: .9375rem;
    line-height: 1.5;
    color: #12263f;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0
}

.form-control-sm {
    height: calc(1.75em + .25rem + 2px);
    padding: .125rem .5rem;
    font-size: .8125rem;
    line-height: 1.75;
    border-radius: .25rem
}

.form-control-lg {
    height: calc(1.5em + 1.5rem + 2px);
    padding: .75rem 1.25rem;
    font-size: .9375rem;
    line-height: 1.5;
    border-radius: .5rem
}

select.form-control[multiple],
select.form-control[size] {
    height: auto
}

textarea.form-control {
    height: auto
}


.form-text {
    display: block;
    margin-top: .25rem
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px
}

.form-row>.col,
.form-row>[class*=col-] {
    padding-right: 5px;
    padding-left: 5px
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem
}

.form-check-input {
    position: absolute;
    margin-top: .3rem;
    margin-left: -1.25rem
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    color: #95aac9
}

.form-check-label {
    margin-bottom: 0
}

.form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: .75rem
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: .3125rem;
    margin-left: 0
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: .8125rem;
    color: #00d97e
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .8125rem;
    line-height: 1.5;
    color: #fff;
    background-color: #00d97e;
    border-radius: .375rem
}

.is-valid~.valid-feedback,
.is-valid~.valid-tooltip,
.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip {
    display: block
}

.form-control.is-valid,
.was-validated .form-control:valid {
    border-color: #00d97e
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
    border-color: #00d97e;
    box-shadow: 0 0 0 .15rem rgba(0, 217, 126, .25)
}

.custom-select.is-valid,
.was-validated .custom-select:valid {
    border-color: #00d97e
}

.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
    border-color: #00d97e;
    box-shadow: 0 0 0 .15rem rgba(0, 217, 126, .25)
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
    color: #00d97e
}

.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip,
.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip {
    display: block
}

.custom-control-input.is-valid~.custom-control-label,
.was-validated .custom-control-input:valid~.custom-control-label {
    color: #00d97e
}

.custom-control-input.is-valid~.custom-control-label::before,
.was-validated .custom-control-input:valid~.custom-control-label::before {
    border-color: #00d97e
}

.custom-control-input.is-valid:checked~.custom-control-label::before,
.was-validated .custom-control-input:valid:checked~.custom-control-label::before {
    border-color: #0dff9a;
    background-color: #0dff9a
}

.custom-control-input.is-valid:focus~.custom-control-label::before,
.was-validated .custom-control-input:valid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 .15rem rgba(0, 217, 126, .25)
}

.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before,
.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before {
    border-color: #00d97e
}

.custom-file-input.is-valid~.custom-file-label,
.was-validated .custom-file-input:valid~.custom-file-label {
    border-color: #00d97e
}

.custom-file-input.is-valid:focus~.custom-file-label,
.was-validated .custom-file-input:valid:focus~.custom-file-label {
    border-color: #00d97e;
    box-shadow: 0 0 0 .15rem rgba(0, 217, 126, .25)
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: .8125rem;
    color: #e63757
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .8125rem;
    line-height: 1.5;
    color: #fff;
    background-color: #e63757;
    border-radius: .375rem
}

.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip,
.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip {
    display: block
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    border-color: #e63757
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
    border-color: #e63757;
    box-shadow: 0 0 0 .15rem rgba(230, 55, 87, .25)
}

.custom-select.is-invalid,
.was-validated .custom-select:invalid {
    border-color: #e63757
}

.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
    border-color: #e63757;
    box-shadow: 0 0 0 .15rem rgba(230, 55, 87, .25)
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
    color: #e63757
}

.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip,
.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip {
    display: block
}

.custom-control-input.is-invalid~.custom-control-label,
.was-validated .custom-control-input:invalid~.custom-control-label {
    color: #e63757
}

.custom-control-input.is-invalid~.custom-control-label::before,
.was-validated .custom-control-input:invalid~.custom-control-label::before {
    border-color: #e63757
}

.custom-control-input.is-invalid:checked~.custom-control-label::before,
.was-validated .custom-control-input:invalid:checked~.custom-control-label::before {
    border-color: #ec647d;
    background-color: #ec647d
}

.custom-control-input.is-invalid:focus~.custom-control-label::before,
.was-validated .custom-control-input:invalid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 .15rem rgba(230, 55, 87, .25)
}

.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label::before,
.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label::before {
    border-color: #e63757
}

.custom-file-input.is-invalid~.custom-file-label,
.was-validated .custom-file-input:invalid~.custom-file-label {
    border-color: #e63757
}

.custom-file-input.is-invalid:focus~.custom-file-label,
.was-validated .custom-file-input:invalid:focus~.custom-file-label {
    border-color: #e63757;
    box-shadow: 0 0 0 .15rem rgba(230, 55, 87, .25)
}

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center
}

.form-inline .form-check {
    width: 100%
}

@media (min-width:576px) {
    .form-inline label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0
    }

    .form-inline .form-group {
        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: 0
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle
    }

    .form-inline .form-control-plaintext {
        display: inline-block
    }

    .form-inline .custom-select,
    .form-inline .input-group {
        width: auto
    }

    .form-inline .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-left: 0
    }

    .form-inline .form-check-input {
        position: relative;
        flex-shrink: 0;
        margin-top: 0;
        margin-right: .25rem;
        margin-left: 0
    }

    .form-inline .custom-control {
        align-items: center;
        justify-content: center
    }

    .form-inline .custom-control-label {
        margin-bottom: 0
    }
}

.badge-soft-secondary {
    color: #6e84a3;
    background-color: #e2e6ed
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #12263f;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .5rem .75rem;
    font-size: .9375rem;
    line-height: 1.5;
    border-radius: .375rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .btn {
        transition: none
    }
}

.btn:hover {
    color: #12263f;
    text-decoration: none
}

.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 .15rem rgba(44, 123, 229, .25)
}

.btn.disabled,
.btn:disabled {
    opacity: .65
}

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none
}

.btn-primary {
    color: #fff;
    background-color: #2c7be5;
    border-color: #2c7be5
}

.btn-primary:hover {
    color: #fff;
    background-color: #1a68d1;
    border-color: #1862c6
}

.btn-primary.focus,
.btn-primary:focus {
    color: #fff;
    background-color: #1a68d1;
    border-color: #1862c6;
    box-shadow: 0 0 0 .15rem rgba(76, 143, 233, .5)
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #2c7be5;
    border-color: #2c7be5
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #1862c6;
    border-color: #175dba
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .15rem rgba(76, 143, 233, .5)
}

.btn-secondary {
    color: #fff;
    background-color: #6e84a3;
    border-color: #6e84a3
}

.btn-secondary:hover {
    color: #fff;
    background-color: #5b7190;
    border-color: #566b88
}

.btn-secondary.focus,
.btn-secondary:focus {
    color: #fff;
    background-color: #5b7190;
    border-color: #566b88;
    box-shadow: 0 0 0 .15rem rgba(132, 150, 177, .5)
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #fff;
    background-color: #6e84a3;
    border-color: #6e84a3
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #566b88;
    border-color: #516580
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .15rem rgba(132, 150, 177, .5)
}

.btn-success {
    color: #fff;
    background-color: #00d97e;
    border-color: #00d97e
}

.btn-success:hover {
    color: #fff;
    background-color: #00b368;
    border-color: #00a660
}

.btn-success.focus,
.btn-success:focus {
    color: #fff;
    background-color: #00b368;
    border-color: #00a660;
    box-shadow: 0 0 0 .15rem rgba(38, 223, 145, .5)
}

.btn-success.disabled,
.btn-success:disabled {
    color: #fff;
    background-color: #00d97e;
    border-color: #00d97e
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #00a660;
    border-color: #009959
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 .15rem rgba(38, 223, 145, .5)
}

.btn-info {
    color: #fff;
    background-color: #39afd1;
    border-color: #39afd1
}

.btn-info:hover {
    color: #fff;
    background-color: #2b99b9;
    border-color: #2991ae
}

.btn-info.focus,
.btn-info:focus {
    color: #fff;
    background-color: #2b99b9;
    border-color: #2991ae;
    box-shadow: 0 0 0 .15rem rgba(87, 187, 216, .5)
}

.btn-info.disabled,
.btn-info:disabled {
    color: #fff;
    background-color: #39afd1;
    border-color: #39afd1
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #2991ae;
    border-color: #2688a4
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 .15rem rgba(87, 187, 216, .5)
}

.btn-warning {
    color: #283e59;
    background-color: #f6c343;
    border-color: #f6c343
}

.btn-warning:hover {
    color: #283e59;
    background-color: #f4b71e;
    border-color: #f4b312
}

.btn-warning.focus,
.btn-warning:focus {
    color: #283e59;
    background-color: #f4b71e;
    border-color: #f4b312;
    box-shadow: 0 0 0 .15rem rgba(215, 175, 70, .5)
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #283e59;
    background-color: #f6c343;
    border-color: #f6c343
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show>.btn-warning.dropdown-toggle {
    color: #283e59;
    background-color: #f4b312;
    border-color: #eead0b
}

.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 .15rem rgba(215, 175, 70, .5)
}

.btn-danger {
    color: #fff;
    background-color: #e63757;
    border-color: #e63757
}

.btn-danger:hover {
    color: #fff;
    background-color: #db1b3f;
    border-color: #d01a3b
}

.btn-danger.focus,
.btn-danger:focus {
    color: #fff;
    background-color: #db1b3f;
    border-color: #d01a3b;
    box-shadow: 0 0 0 .15rem rgba(234, 85, 112, .5)
}

.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: #e63757;
    border-color: #e63757
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #d01a3b;
    border-color: #c51938
}

.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 .15rem rgba(234, 85, 112, .5)
}

.btn-light {
    color: #283e59;
    background-color: #edf2f9;
    border-color: #edf2f9
}

.btn-light:hover {
    color: #283e59;
    background-color: #d0ddef;
    border-color: #c7d6ec
}

.btn-light.focus,
.btn-light:focus {
    color: #283e59;
    background-color: #d0ddef;
    border-color: #c7d6ec;
    box-shadow: 0 0 0 .15rem rgba(207, 215, 225, .5)
}

.btn-light.disabled,
.btn-light:disabled {
    color: #283e59;
    background-color: #edf2f9;
    border-color: #edf2f9
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show>.btn-light.dropdown-toggle {
    color: #283e59;
    background-color: #c7d6ec;
    border-color: #bdcfe9
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 .15rem rgba(207, 215, 225, .5)
}

.btn-dark {
    color: #fff;
    background-color: #12263f;
    border-color: #12263f
}

.btn-dark:hover {
    color: #fff;
    background-color: #0a1421;
    border-color: #070e17
}

.btn-dark.focus,
.btn-dark:focus {
    color: #fff;
    background-color: #0a1421;
    border-color: #070e17;
    box-shadow: 0 0 0 .15rem rgba(54, 71, 92, .5)
}

.btn-dark.disabled,
.btn-dark:disabled {
    color: #fff;
    background-color: #12263f;
    border-color: #12263f
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #070e17;
    border-color: #04080d
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 .15rem rgba(54, 71, 92, .5)
}

.btn-white {
    color: #283e59;
    background-color: #f1f1f1;
    border-color: #f1f1f1
}

.btn-white:hover {
    color: #283e59;
    background-color: #ececec;
    border-color: #e6e6e6
}

.btn-white.focus,
.btn-white:focus {
    color: #283e59;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 0 .15rem rgba(223, 226, 230, .5)
}

.btn-white.disabled,
.btn-white:disabled {
    color: #283e59;
    background-color: #fff;
    border-color: #fff
}

.btn-white:not(:disabled):not(.disabled).active,
.btn-white:not(:disabled):not(.disabled):active,
.show>.btn-white.dropdown-toggle {
    color: #283e59;
    background-color: #e6e6e6;
    border-color: #dfdfdf
}

.btn-white:not(:disabled):not(.disabled).active:focus,
.btn-white:not(:disabled):not(.disabled):active:focus,
.show>.btn-white.dropdown-toggle:focus {
    box-shadow: 0 0 0 .15rem rgba(223, 226, 230, .5)
}

.btn-outline-primary {
    color: #2c7be5;
    border-color: #2c7be5
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #2c7be5;
    border-color: #2c7be5
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
    box-shadow: 0 0 0 .15rem rgba(44, 123, 229, .5)
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #2c7be5;
    background-color: transparent
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #2c7be5;
    border-color: #2c7be5
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .15rem rgba(44, 123, 229, .5)
}

.btn-outline-secondary {
    color: #6e84a3;
    border-color: #6e84a3
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #6e84a3;
    border-color: #6e84a3
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
    box-shadow: 0 0 0 .15rem rgba(110, 132, 163, .5)
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #6e84a3;
    background-color: transparent
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show>.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6e84a3;
    border-color: #6e84a3
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .15rem rgba(110, 132, 163, .5)
}

.btn-outline-success {
    color: #00d97e;
    border-color: #00d97e
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #00d97e;
    border-color: #00d97e
}

.btn-outline-success.focus,
.btn-outline-success:focus {
    box-shadow: 0 0 0 .15rem rgba(0, 217, 126, .5)
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #00d97e;
    background-color: transparent
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show>.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #00d97e;
    border-color: #00d97e
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 .15rem rgba(0, 217, 126, .5)
}

.btn-outline-info {
    color: #39afd1;
    border-color: #39afd1
}

.btn-outline-info:hover {
    color: #fff;
    background-color: #39afd1;
    border-color: #39afd1
}

.btn-outline-info.focus,
.btn-outline-info:focus {
    box-shadow: 0 0 0 .15rem rgba(57, 175, 209, .5)
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #39afd1;
    background-color: transparent
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show>.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #39afd1;
    border-color: #39afd1
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 .15rem rgba(57, 175, 209, .5)
}

.btn-outline-warning {
    color: #f6c343;
    border-color: #f6c343
}

.btn-outline-warning:hover {
    color: #283e59;
    background-color: #f6c343;
    border-color: #f6c343
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
    box-shadow: 0 0 0 .15rem rgba(246, 195, 67, .5)
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #f6c343;
    background-color: transparent
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show>.btn-outline-warning.dropdown-toggle {
    color: #283e59;
    background-color: #f6c343;
    border-color: #f6c343
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 .15rem rgba(246, 195, 67, .5)
}

.btn-outline-danger {
    color: #e63757;
    border-color: #e63757
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #e63757;
    border-color: #e63757
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
    box-shadow: 0 0 0 .15rem rgba(230, 55, 87, .5)
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #e63757;
    background-color: transparent
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show>.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #e63757;
    border-color: #e63757
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 .15rem rgba(230, 55, 87, .5)
}

.btn-outline-light {
    color: #edf2f9;
    border-color: #edf2f9
}

.btn-outline-light:hover {
    color: #283e59;
    background-color: #edf2f9;
    border-color: #edf2f9
}

.btn-outline-light.focus,
.btn-outline-light:focus {
    box-shadow: 0 0 0 .15rem rgba(237, 242, 249, .5)
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #edf2f9;
    background-color: transparent
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show>.btn-outline-light.dropdown-toggle {
    color: #283e59;
    background-color: #edf2f9;
    border-color: #edf2f9
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 .15rem rgba(237, 242, 249, .5)
}

.btn-outline-dark {
    color: #12263f;
    border-color: #12263f
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #12263f;
    border-color: #12263f
}

.btn-outline-dark.focus,
.btn-outline-dark:focus {
    box-shadow: 0 0 0 .15rem rgba(18, 38, 63, .5)
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #12263f;
    background-color: transparent
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show>.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #12263f;
    border-color: #12263f
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 .15rem rgba(18, 38, 63, .5)
}

.btn-outline-white {
    color: #fff;
    border-color: #fff
}

.btn-outline-white:hover {
    color: #283e59;
    background-color: #fff;
    border-color: #fff
}

.btn-outline-white.focus,
.btn-outline-white:focus {
    box-shadow: 0 0 0 .15rem rgba(255, 255, 255, .5)
}

.btn-outline-white.disabled,
.btn-outline-white:disabled {
    color: #fff;
    background-color: transparent
}

.btn-outline-white:not(:disabled):not(.disabled).active,
.btn-outline-white:not(:disabled):not(.disabled):active,
.show>.btn-outline-white.dropdown-toggle {
    color: #283e59;
    background-color: #fff;
    border-color: #fff
}

.btn-outline-white:not(:disabled):not(.disabled).active:focus,
.btn-outline-white:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-white.dropdown-toggle:focus {
    box-shadow: 0 0 0 .15rem rgba(255, 255, 255, .5)
}

.btn-link {
    font-weight: 400;
    color: #2c7be5;
    text-decoration: none
}

.btn-link:hover {
    color: #1657af;
    text-decoration: none
}

.btn-link.focus,
.btn-link:focus {
    text-decoration: none;
    box-shadow: none
}

.btn-link.disabled,
.btn-link:disabled {
    color: #b1c2d9;
    pointer-events: none
}

.btn-group-lg>.btn,
.btn-lg {
    padding: .75rem 1.25rem;
    font-size: .9375rem;
    line-height: 1.5;
    border-radius: .5rem
}

.btn-group-sm>.btn,
.btn-sm {
    padding: .125rem .5rem;
    font-size: .8125rem;
    line-height: 1.75;
    border-radius: .25rem
}

.btn-block {
    display: block;
    width: 100%
}

.btn-block+.btn-block {
    margin-top: .5rem
}

input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block {
    width: 100%
}

.fade {
    transition: opacity .15s linear
}

@media (prefers-reduced-motion:reduce) {
    .fade {
        transition: none
    }
}

.fade:not(.show) {
    opacity: 0
}

.collapse:not(.show) {
    display: none
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height .35s ease
}

@media (prefers-reduced-motion:reduce) {
    .collapsing {
        transition: none
    }
}

.dropdown,
.dropleft,
.dropright,
.dropup {
    position: relative
}

.dropdown-toggle {
    white-space: nowrap
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: .2125em;
    vertical-align: .2125em;
    content: "";
    border-top: .25em solid;
    border-right: .25em solid transparent;
    border-bottom: 0;
    border-left: .25em solid transparent
}

.dropdown-toggle:empty::after {
    margin-left: 0
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: .5rem 0;
    margin: 0 0 0;
    font-size: .9375rem;
    color: #12263f;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(18, 38, 63, .1);
    border-radius: .375rem
}

.dropdown-menu-left {
    right: auto;
    left: 0
}

.dropdown-menu-right {
    right: 0;
    left: auto
}

@media (min-width:576px) {
    .dropdown-menu-sm-left {
        right: auto;
        left: 0
    }

    .dropdown-menu-sm-right {
        right: 0;
        left: auto
    }
}

@media (min-width:768px) {
    .dropdown-menu-md-left {
        right: auto;
        left: 0
    }

    .dropdown-menu-md-right {
        right: 0;
        left: auto
    }
}

@media (min-width:992px) {
    .dropdown-menu-lg-left {
        right: auto;
        left: 0
    }

    .dropdown-menu-lg-right {
        right: 0;
        left: auto
    }
}

@media (min-width:1200px) {
    .dropdown-menu-xl-left {
        right: auto;
        left: 0
    }

    .dropdown-menu-xl-right {
        right: 0;
        left: auto
    }
}

.dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: .2125em;
    vertical-align: .2125em;
    content: "";
    border-top: 0;
    border-right: .25em solid transparent;
    border-bottom: .25em solid;
    border-left: .25em solid transparent
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0
}

.dropright .dropdown-toggle::after {
    display: inline-block;
    margin-left: .2125em;
    vertical-align: .2125em;
    content: "";
    border-top: .25em solid transparent;
    border-right: 0;
    border-bottom: .25em solid transparent;
    border-left: .25em solid
}

.dropright .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropright .dropdown-toggle::after {
    vertical-align: 0
}

.dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0
}

.dropleft .dropdown-toggle::after {
    display: inline-block;
    margin-left: .2125em;
    vertical-align: .2125em;
    content: ""
}

.dropleft .dropdown-toggle::after {
    display: none
}

.dropleft .dropdown-toggle::before {
    display: inline-block;
    margin-right: .2125em;
    vertical-align: .2125em;
    content: "";
    border-top: .25em solid transparent;
    border-right: .25em solid;
    border-bottom: .25em solid transparent
}

.dropleft .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropleft .dropdown-toggle::before {
    vertical-align: 0
}

.dropdown-menu[x-placement^=bottom],
.dropdown-menu[x-placement^=left],
.dropdown-menu[x-placement^=right],
.dropdown-menu[x-placement^=top] {
    right: auto;
    bottom: auto
}

.dropdown-divider {
    height: 0;
    margin: .75rem 0;
    overflow: hidden;
    border-top: 1px solid #edf2f9
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .375rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #6e84a3;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #12263f;
    text-decoration: none;
    background-color: transparent
}

.dropdown-item.active,
.dropdown-item:active {
    color: #12263f;
    text-decoration: none;
    background-color: transparent
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #95aac9;
    pointer-events: none;
    background-color: transparent
}

.dropdown-menu.show {
    display: block
}

.dropdown-header {
    display: block;
    padding: .5rem 1.5rem;
    margin-bottom: 0;
    font-size: .8125rem;
    color: inherit;
    white-space: nowrap
}

.dropdown-item-text {
    display: block;
    padding: .375rem 1.5rem;
    color: #6e84a3
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle
}

.btn-group-vertical>.btn,
.btn-group>.btn {
    position: relative;
    flex: 1 1 auto
}

.btn-group-vertical>.btn:hover,
.btn-group>.btn:hover {
    z-index: 1
}

.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus {
    z-index: 1
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

.btn-group>.btn-group:not(:first-child),
.btn-group>.btn:not(:first-child) {
    margin-left: -1px
}

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.dropdown-toggle-split {
    padding-right: .5625rem;
    padding-left: .5625rem
}

.dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
    margin-left: 0
}

.dropleft .dropdown-toggle-split::before {
    margin-right: 0
}

.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split {
    padding-right: .375rem;
    padding-left: .375rem
}

.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split {
    padding-right: .9375rem;
    padding-left: .9375rem
}

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
    width: 100%
}

.btn-group-vertical>.btn-group:not(:first-child),
.btn-group-vertical>.btn:not(:first-child) {
    margin-top: -1px
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.btn-group-vertical>.btn:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
    margin-bottom: 0
}

.btn-group-toggle>.btn input[type=checkbox],
.btn-group-toggle>.btn input[type=radio],
.btn-group-toggle>.btn-group>.btn input[type=checkbox],
.btn-group-toggle>.btn-group>.btn input[type=radio] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%
}

.input-group>.custom-file,
.input-group>.custom-select,
.input-group>.form-control,
.input-group>.form-control-plaintext {
    position: relative;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0
}

.input-group>.custom-file+.custom-file,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.form-control,
.input-group>.custom-select+.custom-file,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.form-control,
.input-group>.form-control+.custom-file,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.form-control,
.input-group>.form-control-plaintext+.custom-file,
.input-group>.form-control-plaintext+.custom-select,
.input-group>.form-control-plaintext+.form-control {
    margin-left: -1px
}

.input-group>.custom-file .custom-file-input:focus~.custom-file-label,
.input-group>.custom-select:focus,
.input-group>.form-control:focus {
    z-index: 3
}

.input-group>.custom-file .custom-file-input:focus {
    z-index: 4
}

.input-group>.custom-select:not(:last-child),
.input-group>.form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.custom-select:not(:first-child),
.input-group>.form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group>.custom-file {
    display: flex;
    align-items: center
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group-append,
.input-group-prepend {
    display: flex
}

.input-group-append .btn,
.input-group-prepend .btn {
    position: relative;
    z-index: 2
}

.input-group-append .btn:focus,
.input-group-prepend .btn:focus {
    z-index: 3
}

.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.btn,
.input-group-append .input-group-text+.input-group-text,
.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-prepend .input-group-text+.input-group-text {
    margin-left: -1px
}

.input-group-prepend {
    margin-right: -1px
}

.input-group-append {
    margin-left: -1px
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: .5rem .75rem;
    margin-bottom: 0;
    font-size: .9375rem;
    font-weight: 400;
    line-height: 1.5;
    color: #95aac9;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #d2ddec;
    border-radius: .375rem
}

.input-group-text input[type=checkbox],
.input-group-text input[type=radio] {
    margin-top: 0
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control:not(textarea) {
    height: calc(1.5em + 1.5rem + 2px)
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control,
.input-group-lg>.input-group-append>.btn,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-prepend>.input-group-text {
    padding: .75rem 1.25rem;
    font-size: .9375rem;
    line-height: 1.5;
    border-radius: .5rem
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control:not(textarea) {
    height: calc(1.75em + .25rem + 2px)
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control,
.input-group-sm>.input-group-append>.btn,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-prepend>.input-group-text {
    padding: .125rem .5rem;
    font-size: .8125rem;
    line-height: 1.75;
    border-radius: .25rem
}

.input-group-lg>.custom-select,
.input-group-sm>.custom-select {
    padding-right: 2rem
}

.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.40625rem;
    padding-left: 1.5rem
}

.custom-control-inline {
    display: inline-flex;
    margin-right: 1rem
}

.custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.20313rem;
    opacity: 0
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #2c7be5;
    background-color: #2c7be5
}

.custom-control-input:disabled~.custom-control-label,
.custom-control-input[disabled]~.custom-control-label {
    color: #95aac9
}

.custom-control-input:disabled~.custom-control-label::before,
.custom-control-input[disabled]~.custom-control-label::before {
    background-color: #fff
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    cursor: pointer
}

.custom-control-label::before {
    position: absolute;
    top: .20313rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #e3ebf6;
    border: #b1c2d9 solid 0
}

.custom-control-label::after {
    position: absolute;
    top: .20313rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50%/50% 50%
}

.custom-checkbox .custom-control-label::before {
    border-radius: .375rem
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23FFFFFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e")
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(44, 123, 229, .5)
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 1rem + 2px);
    padding: .5rem 2rem .5rem .75rem;
    font-size: .9375rem;
    font-weight: 400;
    line-height: 1.5;
    color: #12263f;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml,%3csvg viewBox='0 0 9 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1 1L4.25 4.25L7.5 1' stroke='%2395AAC9' stroke-width='1.08333' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e") no-repeat right .75rem center/.75em .75em;
    border: 1px solid #d2ddec;
    border-radius: .375rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.custom-select-sm {
    height: calc(1.75em + .25rem + 2px);
    padding-top: .125rem;
    padding-bottom: .125rem;
    padding-left: .5rem;
    font-size: .8125rem
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.nav-tabs .nav-link:not(.active) {
    color: #95aac9
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: snow
}

.card a {
    text-decoration: none !important
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-link {
    display: block;
    padding: .5rem 1rem
}

.nav.btn-group .btn-white.active {
    background-color: #2c7be5;
    border-color: #2c7be5;
    color: #fff
}

.nav-tabs {
    border-bottom: 1px solid #e3ebf6
}

.nav-tabs .nav-item {
    margin-bottom: -1px
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #12263f;
    background-color: transparent;
    border-color: transparent transparent #2c7be5
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #edf2f9;
    border-radius: .5rem
}

.card-header {
    padding: 1rem 1.5rem;
    margin-bottom: 0;
    background-color: transparent;
    border-bottom: 1px solid #edf2f9
}

.card-header:first-child {
    border-radius: calc(.5rem - 1px) calc(.5rem - 1px) 0 0
}

.card-footer {
    padding: 1rem 1.5rem;
    background-color: transparent;
    border-top: 1px solid #edf2f9
}

.card-footer:last-child {
    border-radius: 0 0 calc(.5rem - 1px) calc(.5rem - 1px)
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .375rem
}

.page,
.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #12263f;
    background-color: #fff;
    border: 1px solid #e3ebf6
}

.page-item:first-child .page,
.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem
}

.page-item:last-child .page,
.page-item:last-child .page-link {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem
}

.list-group-flush .list-group-item {
    border-right-width: 0;
    border-left-width: 0;
    border-radius: 0
}

.list-group-flush .list-group-item:first-child {
    border-top-width: 0
}

.list-group-flush:last-child .list-group-item:last-child {
    border-bottom-width: 0
}

.dropdown-menu .list-group-flush .form-control {
    height: 30px;
}


.card {
    margin-bottom: 1.5rem;
    border-color: #edf2f9 !important;
    box-shadow: 0 .75rem 1.5rem rgba(18, 38, 63, .03);
}

.card>* {
    flex-shrink: 0
}

.card-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* height: 60px; */
    padding-top: .5rem;
    padding-bottom: .5rem
}

.card-header>:first-child {
    flex: 1
}

.card-header-title {
    margin-bottom: 0
}

.card-table {
    margin-bottom: 0
}

.card-table thead th {
    border-top-width: 0
}

.card-table tbody td:first-child,
.card-table thead th:first-child {
    padding-left: 1.5rem !important
}

.card-table tbody td:last-child,
.card-table thead th:last-child {
    padding-right: 1.5rem !important
}

.card-pagination.pagination-tabs {
    margin-top: -1rem;
    margin-bottom: -1rem;
    border: 0
}

.card-pagination.pagination-tabs .page,
.card-pagination.pagination-tabs .page-link {
    border-top-width: 0 !important
}

.card-pagination.pagination-tabs .active .page,
.card-pagination.pagination-tabs .active .page-link {
    border-width: 0 0 1px 0 !important
}

.custom-select-sm {
    line-height: 1.75
}

.dropdown-menu {
    -webkit-animation: dropdownMenu .15s;
    animation: dropdownMenu .15s
}

@-webkit-keyframes dropdownMenu {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes dropdownMenu {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.dropdown-ellipses {
    font-size: 1.0625rem;
    color: #d2ddec
}

.dropdown-ellipses::after {
    display: none
}

.dropdown-menu-card {
    min-width: 350px;
    padding-top: 0;
    padding-bottom: 0;
    background-color: #fff;
    border-color: rgba(18, 38, 63, .1)
}

.dropdown-menu-card .card-header {
    min-height: 3.125rem
}

.dropdown-menu-card .card-body {
    max-height: 350px;
    overflow-y: auto
}

.form-control-flush {
    padding-left: 0;
    border-color: transparent !important;
    background-color: transparent !important;
    resize: none
}

.form-control:not(.custom-select) {
    padding-right: 0
}

.input-group-flush>.form-control {
    padding-top: 0;
    padding-bottom: 0;
    border-color: transparent !important;
    background-color: transparent !important
}

.input-group-flush>.input-group-append>.input-group-text,
.input-group-flush>.input-group-prepend>.input-group-text {
    padding: 0;
    border-width: 0;
    background-color: transparent
}

.list-group-flush>.list-group-item {
    padding-left: 0;
    padding-right: 0
}

.nav-overflow {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 1px
}

.nav-overflow::-webkit-scrollbar {
    display: none
}

.pagination-tabs {
    border-radius: 0;
    border-top: 1px solid #e3ebf6
}

.pagination-tabs .page,
.pagination-tabs .page-link {
    margin-top: -1px;
    padding: 1.25rem .75rem;
    background-color: transparent;
    border-width: 1px 0 0 0;
    border-radius: 0 !important;
    color: #95aac9
}

.pagination-tabs .active .page,
.pagination-tabs .active .page-link {
    background-color: transparent;
    border-color: #2c7be5;
    color: #12263f
}

[type=search]::-webkit-search-cancel-button {
    -webkit-appearance: none
}

.card-table.table thead th {
    background-color: #f9fbfd;
    text-transform: uppercase;
    font-size: .8125rem;
    font-weight: 600;
    letter-spacing: .08em;
    color: #95aac9;
}

.card-table.table tbody td,
.card-table.table tbody th,
.card-table.table thead th {
    vertical-align: middle
}

.card-table.table-sm {
    font-size: .8125rem
}

.card-table.table-sm thead th {
    font-size: .625rem
}

.table-nowrap td,
.table-nowrap th {
    white-space: nowrap
}

.table [data-sort] {
    white-space: nowrap
}

.table [data-sort]::after {
    content: url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3 0L6 4H0L3 0ZM3 10L0 6H6L3 10Z' fill='%2395AAC9'/></svg>");
    margin-left: .25rem
}

.font-size-base {
    font-size: .9375rem !important
}

.avatar {
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 3rem;
    font-size: 1rem
}

.avatar:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    /* background-image: url(../img/masks/avatar-status.svg), url(../img/masks/avatar-group.svg), url(../img/masks/avatar-group-hover.svg), url(../img/masks/avatar-group-hover-last.svg) */
}

.avatar-img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.avatar-xs {
    width: 1.625rem;
    height: 1.625rem;
    font-size: .54167rem
}

.avatar-lg {
    width: 4rem;
    height: 4rem;
    font-size: 1.33333rem
}

.avatar-lg.avatar-4by3 {
    width: 5.33333rem
}

.avatar-group .avatar-xs+.avatar-xs {
    margin-left: -.40625rem
}

.avatar-group {
    display: inline-flex
}

.header {
    margin-bottom: 2rem
}

.header-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #e3ebf6
}

.header-pretitle {
    text-transform: uppercase;
    letter-spacing: .08em;
    color: #95aac9
}

.header-title {
    margin-bottom: 0
}

.header-tabs {
    margin-bottom: -1.5rem;
    border-bottom-width: 0
}

.header-tabs .nav-link {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
}

.icon {
    display: inline-block
}

.list-alert {
    position: fixed;
    bottom: 1.5rem;
    left: 50%;
    z-index: 1030;
    min-width: 350px;
    margin-bottom: 0;
    transform: translateX(-50%)
}

.list-alert:not(.show) {
    pointer-events: none
}

.list-alert .close {
    top: 50%;
    transform: translateY(-50%)
}

.filterbtn {
    border: #f1f1f1;
    background-color: #f1f1f1;
    padding: 5px
}

.progress-sm {
    height: .25rem
}

.pro-name {
    font-size: .9375rem
}

.pro-name a {
    color: inherit;
    font-family: sans-serif;
    font-weight: 500;
    margin: 0
}
