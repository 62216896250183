/* @import url("https://fonts.googleapis.com/css2?family=Petrona:ital,wght@0,100;0,200;0,300;0,400;0,500;0,531;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,531;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"); */

body {
  color: #000;
  padding: 0;
  margin: 0;
  /* font-size: 24px; */
  /* font-family: "Petrona", serif; */
  font-family: "Noto Sans TC", sans-serif;
  letter-spacing: 0.4px;
  background-color: #f8f8f8;
}
html {
  scroll-behavior: smooth;
}

body,
html {
  overflow-x: hidden;
}

::selection {
  color: while;
  background-color: #f9e8de;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #2a2a2a;
  font-family: "Petrona", serif;
}

a {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-decoration: none !important;
  outline: 0 !important;
}

p {
  line-height: 1.5;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-20 {
  margin-top: 20px;
}

button,
input,
a {
  outline: 0 !important;
  box-shadow: none !important;
}

.btn-outline-light {
  color: #212529;
}

/*dropdown-css*/
.dropdown-menu {
  -webkit-box-shadow: -7px 8px 16px 0 rgba(55, 70, 95, 0.2);
  box-shadow: -7px 8px 16px 0 rgba(55, 70, 95, 0.2);
  background-color: #ffffff;
  border-color: #eeeeee;
}

.dropdown-menu .form-control {
  border-radius: 5px;
  height: 45px;
  appearance: auto;
}

.form-control:focus {
  border-color: #382a1f;
}

/*modal-css*/
.modal p {
  line-height: 1.8;
  color: #727e8c;
}

/*forms*/
.form-check-input {
  margin-top: 0.2rem;
}

.form-control:focus {
  -webkit-box-shadow: unset;
  box-shadow: unset;
  border-color: #e1000a;
}

.section {
  padding: 40px 0;
}

.flb {
  font-weight: 600;
}

/*================================================
Topmenu CSS
=================================================*/
.top-home-navbar {
  width: 100%;
  padding: 10px 0;
  top: 0;
  left: 0;
  z-index: 999;
}

.top-home-navbar .navbar-nav .nav-item:not(:first-child) {
  margin-left: 35px;
}

.top-home-navbar .navbar-nav .nav-item .nav-link,
.mobile_login .nav-link {
  text-transform: capitalize;
  color: #3a3a3a;
  margin: 0;
  padding: 0 2px;
  font-size: 17px;
  position: relative;
  transition: 0.3s;
  font-weight: 500;
}

.top-home-navbar .navbar-nav .nav-item .nav-link::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  border-radius: 0.25rem;
  background-color: #d08a56;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: width 0.3s ease-in forwards;
}

.top-home-navbar .navbar-nav .nav-item .nav-link:hover::before,
.top-home-navbar .navbar-nav .nav-item.active .nav-link::before {
  width: 100%;
  display: none;
}

.top-home-navbar nav.navbar {
  padding-left: 0;
  padding-right: 0;
}

.top-home-navbar .navbar-nav .nav-item .nav-link:hover,
.top-home-navbar .navbar-nav .nav-item.active .nav-link,
.mobile_login .nav-link:hover {
  color: #86af67;
}

.top-home-navbar .navbar-nav .nav-item .nav-link.dropdown-toggle {
  padding-right: 13px;
}

.top-home-navbar .navbar-nav .nav-item .nav-link.dropdown-toggle::after {
  content: "\f078";
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  right: -2px;
  font-family: "FontAwesome";
  font-size: 10px;
  top: 1px;
}

.top-home-navbar .navbar-nav .nav-item .dropdown-item {
  padding: 5px 10px;
  text-transform: capitalize;
  font-size: 15px;
}

.top-home-navbar .navbar-nav .nav-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-home-navbar .navbar-nav .nav-item .nav-link.signup {
  background-color: #b3c6b3;
  color: #fff;
  padding: 8px 10px;
  border-radius: 0.25rem;
  min-width: 96px;
  text-align: center;
}

.top-home-navbar .navbar-nav .nav-item .nav-link.signup:hover {
  background-color: #86af67;
}

.top-home-navbar .navbar-nav {
  font-family: "Noto Sans TC", sans-serif;
}

.mobile_login {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: none;
}

.mobile_login .nav-link:first-child {
  margin-right: 15px;
}

.top-home-navbar.is-sticky {
  position: fixed;
  top: 0;
  left: 0px;
  -webkit-box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.07);
  background: #ffffff !important;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  width: 100%;
}

/*================================================
Header CSS
=================================================*/
.page_header {
  background-color: #000000;
  width: 100%;
  /* min-height: 600px; */
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.page_header #header_slider {
  height: 550px;
  overflow: hidden;
}

.page_header #header_slider .carousel-item::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background: rgba(255, 255, 255, 0.5);
  /* background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.25) 100%
  );
  filter: blur(10px); */
}

.page_header #header_slider .carousel-caption {
  text-align: left;
  color: #2a2a2a;
  top: 50%;
  bottom: auto;
  left: 0;
  right: auto;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.page_header #header_slider .carousel-caption p {
  color: #2a2a2a;
  /* font-size: 1.3rem; */
  background-color: #f9e8de;
  display: initial;
}

.page_header #header_slider .carousel-caption h2,
.page_title_banner .page_titlee h2 {
  color: #2a2a2a;
  font-size: 3.3rem;
  font-weight: 500;
  padding: 5px;
  border-radius: 0.025rem;
  display: initial;
  font-family: "Noto Sans TC", sans-serif;
}

.page_header #header_slider .carousel-indicators li {
  transform: skewX(-13deg);
  width: 40px;
  height: 6px;
  background-color: #666;
  font-family: "Noto Sans TC", sans-serif;
  opacity: 1;
}

.page_header #header_slider .carousel-indicators .active {
  opacity: 0.6;
}

.page_header svg {
  position: absolute;
  bottom: -250px;
  left: 0%;
}

.page_header .chat-btn {
  position: absolute;
  display: block;
  width: 60px;
  height: 60px;
  border: 2px solid #fff;
  background: #222;
  color: #d08a56;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  font-size: 2rem;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  bottom: 40px;
  right: 60px;
  z-index: 999;
}

.page_title_banner {
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.page_title_banner .page_titlee {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
}

.page_title_banner .page_titlee h2 {
  text-transform: uppercase;
}

/* breadcrumb */
.page_title_banner .breadcrumb {
  background-color: transparent;
  border-radius: 0;
  margin: 0;
  padding: 5px;
}

.page_title_banner .breadcrumb .breadcrumb-item {
  color: #2a2a2a;
  margin-right: 18px;
  position: relative;
  font-size: 18px;
  text-transform: capitalize;
  font-family: "Noto Sans TC", sans-serif;
}

.page_title_banner .breadcrumb .breadcrumb-item a {
  display: inline-block;
  color: #382a1f;
  text-transform: capitalize;
}

.page_title_banner .breadcrumb .breadcrumb-item a:hover {
  color: #382a1f;
}

.page_title_banner .breadcrumb .breadcrumb-item a i {
  font-size: 18px;
}

.page_title_banner .breadcrumb .breadcrumb-item::before {
  content: "\f054";
  position: absolute;
  right: -18px;
  top: 4px;
  line-height: 18px;
  color: #606060;
  font-family: "FontAwesome";
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  font-size: 12px;
}

.page_title_banner .breadcrumb .breadcrumb-item :last-child {
  margin-right: 0;
}

.page_title_banner .breadcrumb .breadcrumb-item:last-child::before {
  display: none;
}

/* quote */
.quoteSection {
  padding: 40px 0;
}

.quoteSection .blockquote {
  padding: 0 1rem;
}

.quoteSection .blockquote p {
  position: relative;
  text-align: center;
}

.quoteSection .blockquote p::before {
  content: "\f10d";
  font-family: "FontAwesome";
  position: absolute;
  left: -5px;
  top: -2px;
}

.quoteSection .blockquote p strong {
  text-transform: uppercase;
}

/* title */
.title-box {
  margin: 0 0 2rem;
}

.title-box h1 {
  text-transform: uppercase;
  margin: 0;
  font-size: 35px;
  color: #2a2a2a;
}

.title-box.bl h1 {
  color: #dbab86;
}

.title-box h1 span {
  padding: 10px;
  display: inline-block;
  background-image: url("../img/sm-icon.png");
  background-size: 100% 100%;
  width: 60px;
  height: 60px;
  line-height: 44px;
  background-position: center;
  text-align: center;
}

/* floor plans */
.content-box h1 {
  text-transform: uppercase;
  font-size: 45px;
  margin: 0 0 2rem;
  line-height: 1;
}

.content-box h1 sub,
.content-box h1 sup {
  font-size: 60%;
}

.content-box h1 .pl {
  padding-left: 11rem;
}

.content-box h1 .small,
.content-box h1 small {
  font-size: 80%;
  font-weight: 400;
}

.content-box p,
.quick-headings p {
  padding: 0;
  /* font-weight: 500; */
  /* font-size: 1.2rem; */
  margin: 0 0 2rem;
}

.content-box a,
.quick-headings a,
.plan-card a,
.contact-box form .btn-box .submit-btn,
.payment_btn {
  background-color: #819582;
  text-transform: uppercase;
  color: #fff;
  padding: 8px 20px;
  font-size: 18px;
  transition: all 0.3s;
  font-family: "Roboto", sans-serif;
  border: 2px solid #819582;
}

.content-box a.btn_bg,
.quick-headings a.btn_bg,
.plan-card a.btn_bg {
  background-color: #d08a56;
  border: 2px solid #d08a56;
}

.content-box a:hover,
.quick-headings a:hover,
.plan-card a:hover,
.contact-box form .btn-box .submit-btn:hover,
.payment_btn:hover {
  color: #819582;
  background-color: transparent;
}

.content-box a.btn_bg:hover,
.quick-headings a.btn_bg:hover,
.plan-card a.btn_bg:hover {
  background-color: transparent;
  color: #d08a56;
}

.quick-headings h1 {
  font-size: 2.5rem;
  margin: 0 0 2rem;
  line-height: 1;
}

.quick-headings a {
  padding: 8px 30px;
}

/* sec-2 */
.headings,
.quick-headings {
  text-align: center;
  width: 100%;
  padding: 15px 0;
  margin-bottom: 50px;
}

.headings h1 {
  font-size: 2.5rem;
  color: #2a2a2a;
}

/* card */
.testimonial-card {
  border: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: 0.5rem;
  outline: none !important;
  border: none !important;
}

.testimonial-card .quote_icon,
#rgPgCarousel .blockquote .quote_icon {
  font-size: 2.2rem;
  color: #666;
  margin: 0 0 20px 30px;
}

.testimonial-card .card-body {
  padding: 2rem;
  outline: none;
}

.testimonial .tm {
  padding: 0 15px;
  transform: scaleY(0.9);
  transition: all 0.3s;
  outline: none;
}

.testimonial .tm.slick-current.slick-active + .slick-active {
  transform: scaleY(0.95) translateY(-11px);
}

.testimonial .tm.slick-current.slick-active + .slick-active .testimonial-card {
  background-color: #f7f7f7;
}

.testimonial .slick-prev:before {
  content: "\f104";
}

.testimonial .slick-next:before {
  content: "\f105";
}

.testimonial .slick-prev {
  left: -20p;
}

.testimonial .slick-next {
  right: -20p;
}

.testimonial .slick-prev:before,
.testimonial .slick-next:before {
  font-family: "FontAwesome";
  font-size: 3rem;
  color: #6f6a6a;
  font-weight: 700;
}

.testimonial-card .text,
#rgPgCarousel .blockquote .text {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  color: #727e8c;
  text-align: center;
}

#rgPgCarousel .blockquote {
  padding: 0 2rem;
}

.testimonial-card .article-footer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 30px 0 0;
}

.testimonial-card .article-footer .profile_img,
#rgPgCarousel .blockquote footer .profile_img {
  margin: 0 20px 0 0;
  border-radius: 0.25rem;
  position: relative;
  height: 100px;
  overflow: hidden;
  width: 100px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-mask-image: url(../img/green-icona.png);
  mask-image: url(../img/green-icona.png);
  -webkit-mask-position: center, top left;
  mask-position: center, top left;
  -webkit-mask-repeat: no-repeat, no-repeat;
  mask-repeat: no-repeat, no-repeat;
  -webkit-mask-size: 100%;
}

#rgPgCarousel .blockquote footer .profile_img {
  margin: 20px auto 0;
}

.testimonial-card .article-footer .profile_img img,
#rgPgCarousel .blockquote footer .profile_img img {
  width: 100%;
  height: auto;
}

.register_slider {
  background: #fff;
  height: 100%;
  padding-top: 6rem;
  position: relative;
  z-index: 1;
}

.register_slider::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  width: 300%;
  height: 100%;
  z-index: -1;
  border-top: 2px solid #f9e8de;
  pointer-events: none;
}

.testimonial-card .article-footer .details .name,
#rgPgCarousel .blockquote footer .name {
  text-transform: capitalize;
  font-weight: 600;
  margin: 0 0 5px;
  font-size: 20px;
  padding: 0;
  line-height: 1;
}

#rgPgCarousel .blockquote footer .name {
  text-align: center;
  margin: 10px 0;
}

.testimonial-card .article-footer .details .company,
.testimonial-card .article-footer .details .company_2,
#rgPgCarousel .blockquote footer div {
  margin: 0;
  font-size: 18px;
}

#rgPgCarousel .blockquote footer div {
  text-align: center;
}

#rgPgCarousel .blockquote footer div span {
  margin: 0 5px;
}

#rgPgCarousel .carousel-indicators {
  bottom: -35px;
}

#rgPgCarousel .carousel-indicators li {
  background-color: #f9e8de;
  height: 3px;
}

/* freetril */
.quick-headings {
  margin-bottom: 0;
}

.quick-headings .quick-icon {
  font-size: 4rem;
  color: #d59567;
  display: inline-block;
  padding: 0 15px;
  margin-top: 0;
  border-radius: 0.25rem;
  /* background-image: url('../img/big-icon.png');    background-size: 100%; */
  background-position: center;
  background-repeat: no-repeat;
  width: 120px;
  height: auto;
  margin-bottom: 16px;
}

.quick-headings p {
  padding: 0 7rem;
}

/* price */
.plan-card {
  position: relative;
  width: 350px;
  margin: 0;
  border: none;
  text-align: center;
  -webkit-clip-path: polygon(
    51% 7%,
    100% 20%,
    100% 45%,
    100% 85%,
    50% 99%,
    0% 85%,
    0% 45%,
    0% 20%
  );
  clip-path: polygon(
    51% 7%,
    100% 20%,
    100% 45%,
    100% 85%,
    50% 99%,
    0% 85%,
    0% 45%,
    0% 20%
  );
  height: 450px;
  border-radius: 0.25rem;
}

.plan-card .shape {
  position: absolute;
  top: 30px;
  left: 0;
  width: 100%;
  height: auto;
}

.plan-card .shape.btm-shape {
  top: auto;
  bottom: 5px;
}

.plan-card.plan-2 {
  margin-top: 50px;
}

.plan-card a {
  border-radius: 0.25rem;
  font-size: 16px;
}

.plan-card .card-header {
  background-color: transparent;
  border: none;
  padding-top: 90px;
  height: auto;
  display: block;
}

.plan-card-title {
  border: none;
}

.plan-card-title h1 {
  font-size: 4rem;
  text-transform: uppercase;
}

.plan-card-title h1 small {
  font-size: 75%;
}

.plan-card-title h1 span span {
  font-size: 85%;
}

.plan-card-title p {
  text-align: center;
  padding: 0 20px 10px;
  margin: 0;
}

.plan-card h2 {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 600;
  color: #294f50;
  margin: 0;
  padding: 15px 0;
}

.plan-card h1 {
  font-size: 45px;
  margin: 0;
  font-weight: 600;
  padding: 0;
  color: #294f50;
  text-transform: uppercase;
}

.plan-card h1 span {
  font-size: 14px;
  display: block;
}

.plan-card p {
  color: #294f50;
  font-size: 20px;
}

.plan-card.plan-2 h1,
.plan-card.plan-2 h2 {
  color: #d08a56;
}

.plan-card.plan-2 a {
  background-color: #d08a56;
  border-color: #d08a56;
}

.plan-card.plan-2 a:hover {
  background-color: transparent;
  color: #d08a56;
}

/* Footer */
.footer {
  background-color: #242927;
  padding: 30px 0;
  font-family: "Noto Sans TC", sans-serif;
}

.copyright {
  color: #ffffff;
  margin: 0;
  padding: 20px 0;
  font-size: 16px;
  font-weight: 400;
}

.ft-title {
  font-size: 26px;
  color: #ffffff;
  margin: 0 0 10px;
  padding: 0 0 25px;
  border: none;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 40px;
  position: relative;
}

.footer .ft-title {
  display: flex;
  flex-direction: column;
}

.footer .ft-title > span {
  display: block;
  max-width: 126px;
  height: 5px;
  background-color: #fff;
  margin-top: 15px;
}

.footer .ft-title > span span {
  display: block;
  background-color: #b3c6b3;
  width: 80%;
  height: 100%;
}

/* .ft-title::before {
  content: "";
  position: absolute;
  width: 40%;
  height: 3px;
  background-color: #ffffff;
  left: 0;
  bottom: 15px;
} */

.ft-list,
.ft-cont-list,
.ft-social-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ft-list li,
.ft-cont-list li {
  margin: 0;
  padding: 12px 0;
}

.ft-list li a,
.ft-cont-list li a,
.ft-social-list li a {
  color: #ffffff;
  text-transform: uppercase;
  position: relative;
  font-weight: 300;
  font-size: 16px;
  transition: all 0.3s;
  display: flex;
}

.ft-list li a:hover,
.ft-cont-list li a:hover,
.ft-social-list li :hover {
  color: #d08a56;
}

/* .ft-list li a::before {
  content: "\f105";
  font-family: "FontAwesome";
  position: relative;
  top: 4px;
  left: 0;
  font-size: 12px;
  font-weight: 600;
  margin-right: 20px;
} */
.ft-list li a span {
  margin-right: 20px;
}

.ft-list li a span svg {
  width: 6px;
  height: auto;
  fill: #fff;
}

.footer address {
  color: #333;
  font-size: 1rem;
  font-weight: 500;
}

.footer hr {
  margin: 0;
  padding: 0;
  border-color: #666;
}

.ft-cont-list li a i {
  margin: 5px 8px 0 0;
  font-size: 1rem;
  font-weight: 600;
}

.ft-cont-list li a span {
  margin: 0px 5px 0 0;
}

.ft-cont-list li a span svg {
  width: 15px;
  height: auto;
  fill: #fff;
}

.ft-cont-list li a {
  text-transform: initial;
}

.ft-social-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ft-social-list li:not(:first-of-type) {
  margin-left: 12px;
}

.ft-social-list li a {
  font-size: 0.75rem;
  color: #ffffff;
  text-transform: capitalize;
}

.ft-social-list li a i {
  font-size: 28px;
}

.ft-social-list li a svg {
  width: 25px;
}

.subscribe-box {
  width: 100%;
  padding: 10px;
  margin: 20px 0 0;
  border: 1px solid #d08a56;
  border-radius: 0.25rem;
}

.subscribe-box input {
  width: 100%;
  height: 45px;
  border: 1px solid #d08a56;
  text-transform: capitalize;
  font-weight: 600;
}

.subscribe-box button {
  width: 100%;
  background-color: #d08a56;
  margin: 10px 0 0;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
  font-size: 20px;
  transition: all 0.3s;
  font-family: "Roboto", sans-serif;
  border: 2px solid #d08a56;
}

.subscribe-box button:hover {
  color: #dfa652;
  background-color: transparent;
}

/* contact us */
.contact-box {
  background: #f7f7f7;
  padding: 20px;
  border-radius: 0.25rem;
}

.contact-box form .form-group {
  margin-bottom: 20px;
}

.contact-box form .form-group label {
  color: #6c757d;
  margin-bottom: 8px;
  display: block;
  font-size: 14px;
}

.contact-box form .form-group .form-control {
  padding-left: 15px;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 5px;
  color: #2a2a2a;
  font-size: 14px;
  height: 44px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.contact-box form .form-group .form-control.in-append {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.contact-box form .form-group .input-group .input-group-text {
  background-color: transparent;
  font-size: 16px;
}

.contact-box form .form-group .form-control::-webkit-input-placeholder {
  color: #6c757d;
}

.contact-box form .form-group .form-control:-ms-input-placeholder {
  color: #6c757d;
}

.contact-box form .form-group .form-control::-ms-input-placeholder {
  color: #6c757d;
}

.contact-box form .form-group .form-control::placeholder {
  color: #6c757d;
}

.contact-box form .form-group .form-control:focus {
  border-color: #382a1f;
}

.contact-box form .form-group textarea.form-control {
  height: auto;
  padding-top: 15px;
}

.contact-box form .btn-box {
  margin-top: 10px;
}

.contact-box form .btn-box .submit-btn i {
  font-size: 16px;
  line-height: 1;
  margin-right: 3px;
  position: relative;
}

.contact_card {
  width: 100%;
  margin-bottom: 30px;
  font-family: "Noto Sans TC", sans-serif;
}

.contact_card h1 {
  margin: 0;
  padding: 0 0 15px;
  font-size: 35px;
  font-weight: 700;
  font-family: "Noto Sans TC", sans-serif;
}

.contact_card p,
.contact_card address,
.contact_card li {
  margin: 0;
  padding: 0 0 20px;
  font-size: 16px;
  color: #727e8c;
  font-family: "Noto Sans TC", sans-serif;
}

.contact_card ul,
.contact_card ol {
  margin: 0;
  padding: 0;
  font-family: "Noto Sans TC", sans-serif;
}

.contact_card ul {
  list-style: none;
  font-family: "Noto Sans TC", sans-serif;
}

.contact_card ul li {
  padding: 5px 5px 5px 15px;
  font-family: "Noto Sans TC", sans-serif;
}

.contact_card ul li::before {
  content: "\f054";
  position: absolute;
  top: 12px;
  left: 0;
  font-family: "FontAwesome";
  font-weight: 400;
  font-size: 12px;
}

.contact_card li {
  position: relative;
  padding: 5px;
  font-family: "Noto Sans TC", sans-serif;
}

.contact_card h2,
.contact_card h3,
.contact_card h4,
.contact_card h5 {
  padding: 0 0 10px;
  font-size: 24px;
  font-weight: 600;
  font-family: "Noto Sans TC", sans-serif;
}

.contact_card h2 {
  font-size: 30px;
  font-family: "Noto Sans TC", sans-serif;
}

.contact_card a {
  font-size: 16px;
  display: inline-block;
  color: #727e8c;
  transition: all 0.3s;
  text-decoration: underline !important;
  font-family: "Noto Sans TC", sans-serif;
}

.contact_card a:hover {
  color: #d08a56;
}

/* end contact us */
.fs_card {
  border: 0;
  transition: all 0.3s;
  height: 100%;
}

.fs_card:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.fs_card .fIcon {
  width: 80px;
  height: 80px;
}

/*================================================
FAQ CSS
=================================================*/
.faq-accordion {
  padding: 0;
  margin: 20px 0 0;
}

.faq-accordion .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.faq-accordion .accordion .accordion-item {
  display: block;
  -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  background: #ffffff;
  margin-bottom: 10px;
  padding: 0;
}

.faq-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
}

.faq-accordion .accordion .accordion-title {
  padding: 15px 20px 10px 51px;
  color: #2a2a2a;
  text-decoration: none;
  position: relative;
  display: block;
  font-size: 16px;
  font-weight: 700;
}

.faq-accordion .accordion .accordion-title i {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  text-align: center;
  height: 100%;
  background-color: #382a1f;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}

.faq-accordion .accordion .accordion-title i::before {
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.faq-accordion .accordion .accordion-title.active i::before {
  content: "\f068";
}

.faq-accordion .accordion .accordion-content {
  display: none;
  position: relative;
  color: #727e8c;
  padding: 15px 15px;
  border-top: 1px solid #eeeeee;
}

.faq-accordion .accordion .accordion-content.show {
  display: block;
}

/* animation */
.up_animat {
  animation: float-bob-y infinite 2s linear;
}

@keyframes float-bob-y {
  0% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

/* Login */
.login_section {
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-origin: border-box;
  background-repeat: no-repeat;
  background-size: cover;
}

.login_section .login_card {
  width: 90%;
  margin: 1.5rem auto;
  border: none;
  box-shadow: 0 0 10px rgb(0 0 0 / 27%);
}

.login_section .login_card .card-body {
  padding: 2rem 3rem;
}

.login_section .login_card .login_form {
  border-right: 1px solid #666;
  padding-right: 4rem;
  height: 100%;
}

.login_section .login_card .login_form h2 {
  margin: 0;
  padding: 0 0 10px;
  font-size: 2rem;
}

.login_section .login_card .login_form p {
  margin: 0;
  padding: 0 0 15px;
  font-size: 1.2rem;
}

.login_section .login_card .login_form p span {
  text-transform: uppercase;
  font-weight: 600;
}

.login_form form .form-group {
  position: relative;
  margin-bottom: 25px;
}

.login_form form .form-group .label-title {
  margin-bottom: 0;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  color: #2a2a2a;
  border: 2px solid #c3bbbb;
  border-radius: 0.25rem;
}

.login_form form .form-group .label-title i {
  position: absolute;
  left: 10px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  top: 9px;
  font-size: 1.2rem;
  font-weight: 600;
}

.login_form form .form-group .form-control {
  border-radius: 0;
  border: none;
  border: 2px solid transparent;
  padding: 0 0 0 40px;
  color: #2a2a2a;
  height: 45px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-family: "Petrona", serif;
  font-size: 17px;
  font-weight: 500;
  background-color: #e9ecef;
}

.login_form form .form-group .form-control:focus + .label-title {
  color: #a1a1a1;
  border-color: #a09d9d;
}

.login_section .login_card .login_form button.btn {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.3rem;
  background-color: #819582;
  color: #fff;
  transition: all 0.3s;
  margin-bottom: 2rem;
  border: 2px solid #819582;
}

.login_section .login_card .login_form button.btn:hover {
  color: #819582;
  border-color: #819582;
  background-color: transparent;
}

.login_section .login_card .login_form footer a {
  font-size: 1.2rem;
  text-decoration: underline !important;
  color: #382a1f;
  font-weight: 600;
  transition: all 0.3s;
}

.login_section .login_card .login_form footer a:hover {
  color: #d08a56;
}

.login_section .login_card .login_form footer p {
  margin: 0;
  padding: 0;
}

.login_section .login_card .login_form footer p a {
  text-transform: capitalize;
}

.login_section .login-content {
  padding: 0 1rem;
  margin: 0;
  height: 100%;
  text-align: center;
}

.login_section .login-content .lg_icon {
  margin: 0 auto 15px;
  padding: 20px;
  width: 100px;
  height: 100px;
  background-image: url("../img/green-icon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  background-origin: border-box;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666;
}

.login_section .login-content h4 {
  margin: 0;
  padding: 0 0 15px;
  font-size: 1.3rem;
  font-weight: 600;
}

.login_section .login-content p {
  margin: 0;
  padding: 0;
  font-size: 1.1rem;
}

.login_checkbox label:after {
  content: "";
  display: table;
  clear: both;
}

.login_checkbox label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1rem;
  margin: 0;
}

.login_checkbox label a {
  color: #382a1f;
  text-decoration: underline !important;
  transition: all 0.3s;
  margin: 0 0.2rem;
}

.login_checkbox label a:hover {
  color: #d08a56;
}

.login_checkbox .cr {
  position: relative;
  display: inline-block;
  border: 1px solid #222;
  border-radius: 0.25em;
  width: 1em;
  height: 1em;
  float: left;
  margin-right: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login_checkbox .cr .cr-icon {
  font-size: 0.7em;
  line-height: 0;
}

.login_checkbox label input[type="checkbox"] {
  display: none;
}

.login_checkbox label input[type="checkbox"] + .cr > .cr-icon {
  transform: scale(3) rotateZ(-20deg);
  opacity: 0;
  transition: all 0.3s ease-in;
}

.login_checkbox label input[type="checkbox"]:checked + .cr > .cr-icon {
  transform: scale(1) rotateZ(0deg);
  opacity: 1;
}

.login_checkbox label input[type="checkbox"]:disabled + .cr {
  opacity: 0.5;
}

/* End Login */
/* Payment */
.payment_title {
  font-size: 2rem;
  padding: 0;
  margin: 0;
}

.payment_plan_list li {
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: capitalize;
  font-size: 1.1rem;
  padding: 4px 6px;
  font-weight: 600;
}

.payment_plan.plan-card.payment_plan {
  height: 650px;
  margin: auto;
  -webkit-clip-path: polygon(
    51% 5%,
    100% 14%,
    100% 45%,
    100% 90%,
    51% 99%,
    0% 90%,
    0% 45%,
    0% 14%
  );
  clip-path: polygon(
    51% 5%,
    100% 14%,
    100% 45%,
    100% 90%,
    51% 99%,
    0% 90%,
    0% 45%,
    0% 14%
  );
}

.drp_shadow {
  filter: drop-shadow(0 0 7px rgba(0, 0, 0, 0.3));
  margin: 0;
}

.payment_summary {
  position: relative;
  z-index: 1;
}

.payment_summary::before {
  pointer-events: none;
  content: "";
  position: absolute;
  top: -37px;
  left: -16px;
  width: 300%;
  height: 111%;
  background-color: #fff;
  z-index: -1;
}

.input_field {
  position: relative;
}

.contact-box form .form-group .input_field input.form-control {
  background-color: #f7f7f7;
}

.input_field img {
  position: absolute;
  top: 0.375rem;
  right: 0.75rem;
  background-color: #fff;
  height: 30px;
}

.card_image {
  padding: 2.2rem 0 0;
}

.secure_box {
  margin: 1rem 0 0;
  border: 1px solid #fff;
}

.card_image p {
  font-size: 0.85rem;
  font-weight: 600;
}

.card_img {
  height: 25px;
  position: absolute;
  top: 5px;
  right: -100px;
  background: #fff;
}

.thank_card {
  margin: 0 0 1rem;
  padding: 0 3rem;
  border: none;
  background-color: transparent;
  text-align: center;
}

.thank_card i {
  font-size: 3rem;
  font-weight: 300;
  color: green;
}

.thank_card h2 {
  font-size: 3rem;
}

.thank_card p {
  font-size: 1.3rem;
  font-weight: 500;
  padding: 0;
}

/* End Payment */
.bg_lt_pech {
  background-color: #f9e8de;
}

.bg_lt_grn {
  background-color: #edefec;
}

.bg_pink {
  background-color: #f9e8de;
}

.bg_d_pink {
  background-color: #f5d8c6;
}

/*================================================
Responsive CSS
=================================================*/
@media (max-width: 1250px) {
  .top-home-navbar .navbar-nav .nav-item:not(:first-child) {
    margin-left: 20px;
  }

  .top-home-navbar .navbar-nav .nav-item .nav-link,
  .mobile_login .nav-link {
    font-size: 14px;
  }
}

@media (min-width: 1200px) {
  .container-fluid {
    max-width: 1250px;
  }
}

@media (max-width: 1199px) and (min-width: 980px) {
  .page_header #header_slider .carousel-caption h2,
  .page_title_banner .page_titlee h2 {
    font-size: 3rem;
  }

  .content-box h1 {
    font-size: 35px;
  }

  .title-box h1 {
    font-size: 25px;
  }

  body {
    font-size: 20px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .page_header #header_slider {
    height: auto;
  }

  .title-box h1 {
    font-size: 30px;
  }

  .testimonial-card .article-footer .profile_img {
    width: 90px;
    margin: 0 15px 0 0;
    height: 90px;
  }

  .content-box h1 {
    word-break: break-all;
  }

  .testimonial .tm.slick-current.slick-active + .slick-active,
  .testimonial .tm {
    transform: scaleY(1) translateY(0);
  }

  .testimonial
    .tm.slick-current.slick-active
    + .slick-active
    .testimonial-card {
    background-color: #fff;
  }

  .register_slider {
    padding-top: 3rem;
  }

  #rgPgCarousel .blockquote {
    padding: 0 1rem;
  }
}

@media (max-width: 992px) {
  .mb_hide {
    display: none !important;
  }

  #rgPgCarousel .blockquote footer div span {
    margin: 0 0 5px;
    display: block;
  }

  .page_header #header_slider .carousel-caption h2,
  .page_title_banner .page_titlee h2 {
    font-size: 2rem;
  }

  .quoteSection .blockquote {
    padding: 0 0;
  }

  .plan-card.plan-2 {
    margin: 26px 0;
  }

  .quick-headings p {
    padding: 0;
  }

  .mobile_login {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-family: "Noto Sans TC", sans-serif;
  }

  .top-home-navbar .navbar-collapse {
    padding: 20px 0;
  }

  .top-home-navbar .navbar-nav .nav-item,
  .top-home-navbar .navbar-nav .nav-item .nav-link {
    display: inline-block;
    width: auto;
  }

  .top-home-navbar .navbar-nav .nav-item:not(:first-child) {
    margin-left: 0;
  }

  .top-home-navbar .navbar-brand img {
    width: 120px;
  }

  .mobile_login button.navbar-toggler {
    border: none;
    background: transparent;
    opacity: 1;
    padding: 0;
    margin-left: 10px;
  }

  .title-box {
    margin: 0 0 1.5rem;
  }

  .content-box h1 {
    font-size: 35px;
    margin: 0 0 1.5rem;
    /* word-break: break-all; */
  }

  .content-box h1 .pl {
    padding-left: 6rem;
  }

  .payment_summary::before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .payment_summary {
    padding-top: 2rem;
  }
}

@media (max-width: 767px) {
  .page_header #header_slider {
    height: auto;
  }

  .page_header .chat-btn {
    bottom: 20px;
    right: 20px;
  }

  body,
  html {
    overflow-x: hidden;
  }

  .page_header #header_slider .carousel-caption p {
    color: #2a2a2a;
    font-size: 1.1rem;
  }

  .res-img {
    max-width: 100%;
    margin: 0 !important;
  }

  .page_header {
    height: 100%;
  }

  .page_header .content-box a {
    font-size: 16px;
    padding: 10px 10px;
    margin-right: 15px;
    min-width: 150px;
  }

  .page_header .content-box h2 {
    font-size: 2rem;
  }

  .headings h1,
  .quick-headings h1 {
    font-size: 2rem;
  }

  .page_header .content-box a:last-child {
    margin-right: 0;
  }

  .quick-headings p {
    padding: 0;
  }

  .plan-card-title h1 {
    font-size: 2.5rem;
  }

  .community-card h1 {
    font-size: 3rem;
  }

  .info-content-box h1 {
    font-size: 2rem;
    margin: 0 0 20px;
  }

  .top-home-navbar .navbar-nav .nav-item:not(:first-child) {
    margin-left: 0;
  }

  .title-box h1 {
    font-size: 25px;
  }

  .content-box h1 {
    font-size: 30px;
    word-break: normal;
  }

  body {
    font-size: 18px;
  }

  p {
    line-height: 25px;
  }

  .login_section .login_card {
    width: 100%;
    margin: 0 auto;
  }

  .login_section .login_card .login_form {
    border-right: none;
    padding-right: 0;
    border-bottom: 1px solid #2a2a2a;
    padding-bottom: 20px;
  }

  .login_section .login_card .card-body {
    padding: 1.2rem;
  }

  .login_section .login-content {
    padding: 1rem 0 0;
  }

  .register_slider {
    background: #f9e8de;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-top: 2px solid #382a1f;
  }

  #rgPgCarousel .blockquote {
    padding: 0;
  }

  .login_section .login_card.register .login_form {
    padding-top: 0;
    margin-top: 0;
  }

  #rgPgCarousel .carousel-indicators li {
    background-color: #fff;
  }

  .register_slider::before {
    display: none;
  }

  .payment_plan.plan-card.payment_plan {
    -webkit-clip-path: polygon(
      51% 5%,
      100% 12%,
      100% 45%,
      100% 89%,
      51% 99%,
      0% 90%,
      0% 45%,
      0% 12%
    );
    clip-path: polygon(
      51% 5%,
      100% 12%,
      100% 45%,
      100% 89%,
      51% 99%,
      0% 90%,
      0% 45%,
      0% 12%
    );
    width: 300px;
  }

  .home-page .intro-heading {
    font-size: 30px;
  }
}

@media (max-width: 640px) {
  .home-page .intro-heading {
    font-size: 25px;
  }
}

@media (max-width: 350px) {
  .mobile_login .nav-link:first-child {
    margin-right: 6px;
  }

  .top-home-navbar .navbar-nav .nav-item .nav-link,
  .mobile_login .nav-link {
    font-size: 13px;
  }
}

.page_header #header_slider .carousel-caption h2,
.page_header #header_slider .carousel-caption p {
  background-color: transparent;
}

.faq-accordion .accordion .accordion-title {
  cursor: pointer;
}

.overlay {
  position: relative;
  z-index: 1;
}

.overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

.loader {
  position: relative;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 70px;
  height: 70px;
  left: 50%;
  top: 50%;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

#spinner {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.5;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.text-decoration-none a {
  text-decoration: none !important;
}

.mb_hide.nav-item.dropdown .nav-link::before {
  display: none;
}

.mb_hide.nav-item.dropdown .dropdown-item {
  font-size: 16px;
  font-weight: 600;
  padding: 3px 10px;
  margin: 0;
}

.mb_hide.nav-item.dropdown .dropdown-menu {
  right: 0;
  left: auto;
}
