.form-container {
  padding: 50px 15px 80px;
}

.form-container a {
  color: #b3c6b3;
}

.form-container a:hover,
.form-container a:focus {
  color: #86af67;
}

.form-block {
  max-width: 534px;
  padding: 45px 50px 38px;
  border-radius: 10px;
  box-shadow: 0 0 39px #0000000d;
  font-size: 16px;
}

.form-block .form-block-hd {
  font-family: "Noto Serif", serif;
  margin-bottom: 31px;
}

.form-block p {
  font-family: "Noto Sans TC", sans-serif;
}
.form-block h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
  color: #3a3a3a;
  margin-bottom: 22px;
  font-family: "Noto Sans TC", sans-serif;
}

.form-block h2 span {
  margin-top: 5px;
  font-family: "Noto Sans TC", sans-serif;
}

.form-block h2 .line {
  width: 94px;
  height: 2px;
  background-color: #b3c6b3;
}

.form-block h3 {
  font-family: "Noto Sans TC", sans-serif;
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
}

.free-trial-rw {
  margin-top: 14px;
}

.form-block .free-trial-rw .btn {
  max-width: 373px;
  width: 100%;
  height: 33px;
  padding: 5px 20px;
  font-weight: 400;
}

.form-block-inner,
.free-trial-rw {
  font-family: "Noto Sans TC", sans-serif;
}

.form-inputs .form-group {
  margin-bottom: 20px;
}

.form-inputs .form-group.otp {
  margin-bottom: 12px;
}

.form-inputs .form-group:last-of-type {
  margin-bottom: 7px;
}

.form-inputs .form-control {
  height: 70px;
  padding-left: 22px;
  padding-right: 22px;
  border-radius: 5px;
  background-color: #f7f7f7;
  border-color: transparent;
  font-size: 16px;
}

.form-inputs textarea.form-control {
  height: 164px;
  resize: none;
}

.form-inputs .form-control::placeholder {
  color: #757575;
}

.form-inputs .form-control::-moz-placeholder {
  color: #757575;
}

.form-block .btn {
  font-weight: 500;
  height: 50px;
  border-radius: 5px;
}

.form-block .btn-primary {
  background-color: #b3c6b3;
  border-color: #b3c6b3;
}

.form-block .btn-primary:hover,
.form-block .btn-primary:focus,
.form-block .btn-primary:active {
  background-color: #86af67 !important;
  border-color: #86af67 !important;
}

.forgot-link {
  margin-bottom: 25px;
}

.form-btm {
  margin-top: 33px;
  color: #757575;
}

.form-btm a {
  margin-left: 2px;
}

.checkbox-wrapper {
  cursor: pointer;
  color: #757575;
}

.agree-check {
  margin-bottom: 30px;
}

.checkbox-wrapper input[type="checkbox"] {
  opacity: 0;
}

.checkbox-wrapper .check {
  width: 24px;
  height: 24px;
  border-radius: 5px;
  border: 1px solid #cbcbcb;
  margin-right: 9px;
  font-size: 15px;
  text-decoration: none;
}

.checkbox-wrapper .check i {
  display: none;
}

.checkbox-wrapper input[type="checkbox"]:checked + .check i {
  display: inline-flex;
}

.checkbox-wrapper .txt {
  text-decoration: underline;
}

.checkbox-wrapper:hover .txt,
.checkbox-wrapper:focus .txt {
  color: #86af67;
  text-decoration: none;
}

.otp-msg {
  margin-bottom: 28px;
}

.otp-msg h3,
.otp-msg h4 {
  font-family: "Noto Sans TC", sans-serif;
  font-weight: 400;
}

.otp-msg h3 {
  font-size: 16px;
  margin-bottom: 14px;
}

.otp-msg h3 a {
  color: #3a3a3a;
}

.otp-msg h3 a:hover,
.otp-msg h3 a:focus {
  color: #86af67;
}

.otp-msg h4 {
  font-size: 20px;
}

.otp-msg h4 span {
  font-weight: 700;
  color: #b3c6b3;
}

@media (max-width: 640px) {
  .form-block {
    padding: 35px 20px;
  }
}
