@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Homemade+Apple&family=Pacifico&display=swap');

.home-page h1,
.home-page h2,
.home-page h3,
.home-page h4,
.home-page h5,
.home-page h6,
.home-page p,
.home-page span,
.home-page button,
.home-page input,
.home-page label {
  font-family: "Noto Sans TC", sans-serif;
}

.home-page button,
.home-page input,
.home-page label,
.home-page select,
.home-page textarea {
  resize: none;
}

.home-page .MuiButton-contained {
  box-shadow: none !important;
}

.home-page .MuiButton-root {
  text-transform: none !important;
}

/* *,
 :after,
 :before,
 html,
 body {
   -webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
   box-sizing: border-box;font-family: 'Noto Serif', serif;
   font-family: 'Noto Sans TC', sans-serif;
 } */

.home-page ::-moz-selection {
  background-color: var(--primary-dark-color, #374059);
  color: var(--primary-white-color, #fff);
}

.home-page ::-webkit-selection {
  background-color: var(--primary-dark-color, #374059);
  color: var(--primary-white-color, #fff);
}

.home-page ::selection {
  background-color: var(--primary-dark-color, #374059);
  color: var(--primary-white-color, #fff);
}

.home-page p {
  margin: 0;
}

.home-page ul {
  padding: 0;
  margin: 0;
}

.home-page h1,
.home-page h2,
.home-page h3,
.home-page h4,
.home-page h5,
.home-page h6 {
  margin: 0;
}

.home-page .text-right {
  text-align: right !important;
}

.home-page .text-center {
  text-align: center !important;
}

.home-page .p-t-0 {
  padding-top: 0 !important;
}

.home-page .p-b-0 {
  padding-bottom: 0 !important;
}

.home-page .p-r-0 {
  padding-right: 0 !important;
}

.home-page .p-t-5 {
  padding-top: 5px !important;
}

.home-page .p-b-5 {
  padding-bottom: 5px !important;
}

.home-page .p-t-10 {
  padding-top: 10px !important;
}

.home-page .p-b-10 {
  padding-bottom: 10px !important;
}

.home-page .p-t-15 {
  padding-top: 15px !important;
}

.home-page .p-b-15 {
  padding-bottom: 15px !important;
}

.home-page .p-b-20 {
  padding-bottom: 20px !important;
}

.home-page .p-b-30 {
  padding-bottom: 30px !important;
}

.home-page .m-t-10 {
  margin-top: 10px !important;
}

.home-page .m-t-15 {
  margin-top: 15px !important;
}

.home-page .m-t-30 {
  margin-top: 30px !important;
}

.home-page .m-b-15 {
  margin-bottom: 15px !important;
}

.home-page .m-b-30 {
  margin-bottom: 30px !important;
}

.home-page .form-row {
  margin: 0 -10px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.home-page .form-label {
  letter-spacing: 0px;
  color: #606060;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 22px;
  display: block;
  font-weight: 400;
}

.home-page .w100,
.home-page .w50,
.home-page .w33,
.home-page .w66 {
  padding: 0 10px;
}

.home-page .w100 {
  -webkit-box: 0;
  -moz-box: 0;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

.home-page .w50 {
  -webkit-box: 0;
  -moz-box: 0;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}

.home-page .w33 {
  -webkit-box: 0;
  -moz-box: 0;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33.33%;
  -ms-flex: 0 0 33.33%;
  flex: 0 0 33.33%;
}

.home-page .w66 {
  -webkit-box: 0;
  -moz-box: 0;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 66.66%;
  -ms-flex: 0 0 66.66%;
  flex: 0 0 66.66%;
}

.home-page .form-field {
  margin-bottom: 20px;
}

.home-page .d-flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.home-page html,
.home-page body {
  background-color: var(--primary-bg-color, #f8f8f8);
}

.home-page .container {
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
  padding: 0 15px;
}

.home-page .footer-bottom {
  background: #1a1e1c;
}

.home-page .footer-bottom p {
  letter-spacing: 0px;
  color: #fff;
  font-size: 16px;
  margin: 0;
  text-align: center;
  line-height: 26px;
  padding: 20px 0;
  font-family: "Noto Sans TC", sans-serif;
}

.home-page .site-footer {
  background: #242927;
}

.home-page .footer-heading {
  letter-spacing: 0px;
  color: #fff;
  font-size: 28px;
  line-height: 58px;
  padding-bottom: 15px;
  margin-bottom: 30px;
  position: relative;
}

.home-page .footer-heading:before {
  content: "";
  height: 5px;
  background: #b3c6b3;
  width: 100%;
  max-width: 112px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.home-page .footer-heading:after {
  content: "";
  height: 5px;
  width: 20px;
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 92px;
}

.home-page .footer-links li {
  list-style-type: none;
  padding-bottom: 20px;
}

.home-page .footer-links li a {
  font-size: 16px;
  letter-spacing: 0px;
  color: #fff;
  line-height: 21px;
  position: relative;
  padding-left: 25px;
}

.home-page .footer-links li a:before {
  transform: rotate(-45deg);
  content: "";
  height: 2px;
  width: 12px;
  background: #fff;
  border-radius: 2px;
  position: absolute;
  left: 0px;
  top: 13px;
}

.home-page .footer-links li a:after {
  content: "";
  height: 2px;
  width: 12px;
  background: #fff;
  border-radius: 2px;
  position: absolute;
  left: 0;
  transform: rotate(45deg);
  top: 6px;
}

.home-page .contact-links li {
  list-style-type: none;
  padding-bottom: 20px;
}

.home-page .contact-links li a,
.home-page .contact-links li p {
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0px;
  color: #fff;
  padding-left: 25px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.home-page .c-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  position: absolute;
  top: 6px;
  left: 0;
}

.home-page .c-icon svg path {
  fill: #fff;
}

.home-page .social-links {
  margin-bottom: 15px;
}

.home-page .social-links li {
  list-style-type: none;
  display: inline-flex;
  padding-right: 15px;
  padding-bottom: 15px;
}

.home-page .social-links li a {
  border-radius: 4px;
  width: 27px;
  height: 27px;
}

.home-page .social-links li .social-icon .ab {
  fill: #fff;
}

.home-page .footer-top {
  padding: 30px 0;
}

.home-page .f-row {
  margin: 0 -10px;
  display: flex;
}

.home-page .f-column {
  padding: 0 10px;
  flex: 0 0 25%;
}

.home-page .footer-links li a:hover,
.home-page .contact-links li a:hover {
  color: #b3c6b3;
}

.home-page .btn-primary {
  background: #b3c6b3;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  height: 40px;
}

.home-page .btn-primary.MuiButton-root {
  background: #b3c6b3;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  height: 40px;
}

.home-page .btn-primary:hover,
.home-page .btn-primary:focus,
.home-page .btn-primary.MuiButton-root:hover,
.home-page .btn-primary.MuiButton-root:focus {
  background: #86af67;
}

.home-page .btn-line {
  background: #fff;
  border-radius: 6px;
  color: #b3c6b3;
  font-size: 16px;
  height: 40px;
  border: 1px solid #b3c6b3;
}

.home-page .btn-line.MuiButton-root {
  background: #fff;
  border-radius: 6px;
  color: #b3c6b3;
  font-size: 16px;
  height: 40px;
  border: 1px solid #b3c6b3;
}

.home-page .btn-line:hover,
.home-page .btn-line:focus,
.home-page .btn-line.MuiButton-root:hover,
.home-page .btn-line.MuiButton-root:focus {
  background: #86af67;
  color: #fff;
}

.home-page .menu-links li {
  list-style-type: none;
  display: inline-block;
  vertical-align: middle;
}

.home-page .menu-links li:not(:last-child) {
  padding-right: 30px;
}

.home-page .header-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
}

.home-page .plan-toggle .MuiSwitch-thumb {
  background: #b3c6b3;
  box-shadow: none;
  margin-top: 2px;
}

.home-page .plan-toggle .MuiSwitch-track {
  background: #dcdcdc;
  border-radius: 41px;
}

.home-page .plan-toggle .MuiSwitch-root {
  width: 69px;
  height: 41px;
  border-radius: 41px;
}

.home-page .plan-toggle .MuiSwitch-switchBase.Mui-checked {
  transform: translateX(30px);
}

.home-page .plan-toggle .MuiSwitch-thumb {
  background: #b3c6b3;
  box-shadow: none;
}

.home-page .plan-toggle .MuiSwitch-track {
  background: #dcdcdc;
}

.home-page .plan-toggle .MuiSwitch-root {
  width: 69px;
}

.home-page .plan-toggle .MuiSwitch-switchBase.Mui-checked {
  transform: translateX(28px);
}

.home-page .available-plans {
  padding: 65px 0;
}

.home-page .planbox {
  background: #fff;
  box-shadow: 0px 9px 17px #b3b3b3 29;
  border-radius: 10px;
  padding: 50px;
}

.home-page .plans-list {
  display: flex;
  justify-content: center;
}

.home-page .plans-list > li {
  list-style-type: none;
  flex: 0 0 33.33%;
  transform: scale(1);
  position: relative;
}

.home-page .plans-list > li:nth-child(3n + 2) {
  z-index: 1;
}

.home-page .plans-list > li:nth-child(3n + 2) .planbox {
  border: 1px solid #b3c6b3;
  transform: scale(1.12);
}

.home-page .plans-list > li:first-child .planbox {
  border-radius: 10px 0px 0px 10px;
}

.home-page .plans-list > li:last-child .planbox {
  border-radius: 0px 10px 10px 0px;
  padding-left: 75px;
}

.home-page .plans-list > li:nth-child(3n + 2) .planbox .planbox-top,
.home-page .plans-list > li:nth-child(3n + 2) .planbox .planbox-bottom {
  transform: scale(0.9);
  padding-bottom: 10px;
}

.home-page .planbox-bottom {
  border-top: 1px solid rgba(112, 112, 112, 0.32);
  padding-top: 25px;
}

.home-page .planbox-bottom ul li {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-page .planbox-bottom ul li p {
  font-size: 16px;
  line-height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  letter-spacing: 0px;
  color: #757575;
}

.home-page .planbox-bottom ul li .plan-info {
  display: inline-flex;
  align-items: center;
  justify-self: center;
  background-image: url("../images/info-icon.png");
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%;
  background-size: 15px;
}

.home-page .plan-name {
  font-size: 30px;
  line-height: 46px;
  letter-spacing: 0px;
  color: #3a3a3a;
  font-weight: 700;
  padding-bottom: 10px;
}

.home-page .plan-cost {
  font-size: 40px;
  line-height: 46px;
  margin: 0;
  letter-spacing: 0px;
  color: #b3c6b3;
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}

.home-page .plan-cost span {
  color: #3a3a3a;
  font-size: 18px;
  line-height: 20px;
  display: inline-block;
  vertical-align: middle;
  padding-left: 5px;
}

.home-page .members-limit {
  font-size: 20px;
  color: #3a3a3a;
  font-weight: 700;
  line-height: 35px;
  padding-bottom: 25px;
}

.home-page .planbox-top {
  padding-bottom: 25px;
}

.home-page .planbox-top .plan-subdesc {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0px;
  color: #757575;
}

.home-page .planbox-top .buy-plan {
  margin-bottom: 20px;
}

.home-page .plan-benifits {
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0px;
  color: #3a3a3a;
  padding-bottom: 10px;
}

.home-page .available-plans {
  padding: 65px 0;
}

.home-page .section-heading {
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: 0px;
  color: #3a3a3a;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.home-page .section-heading:before {
  content: "";
  height: 2px;
  width: 98px;
  background: #b3c6b3;
  position: absolute;
  bottom: 0;
  left: 0;
}

.home-page .section-heading.center:before {
  left: 0;
  right: 0;
  margin: 0 auto;
}

.home-page .section-heading.center {
  text-align: center;
}

.home-page .plans-section .section-desc {
  text-align: center;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0px;
  color: #757575;
  font-weight: 400;
  margin-bottom: 20px;
  padding-top: 5px;
}

.home-page .plan-switch {
  display: inline-flex;
  align-items: center;
}

.home-page .plan-switch h5 {
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0px;
  font-weight: 400;
  color: #3a3a3a;
}

.home-page .plan-switch h5 span {
  color: #b3c6b3;
}

.home-page .section-desc {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0px;
  color: #757575;
  font-weight: 400;
  margin-bottom: 20px;
}

.home-page .plans-section .section-heading {
  margin-bottom: 0;
}

.home-page .section-subheading {
  font-size: 32px;
  line-height: 46px;
  letter-spacing: 0px;
  color: #3a3a3a;
  font-weight: 400;
  margin-bottom: 15px;
}

.home-page .section-subheading strong {
  font-weight: 700;
}

.home-page .center-heading {
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: 0px;
  color: #3a3a3a;
  text-align: center;
  padding-bottom: 15px;
}

.home-page .center-desc {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0px;
  color: #757575;
  text-align: center;
  margin-bottom: 15px;
}

.home-page .center-text {
  text-align: center;
}

.home-page .experience-section {
  padding: 100px 0;
  background: #f5f5f5;
}

.home-page section {
  padding: 100px 0;
  background: #fff;
}

.home-page .testimonial-section {
  background: #fff;
}

.home-page .plans-section {
  background: #f8f8f8;
}

.home-page .task-section {
  overflow: hidden;
  position: relative;
  background: #fff;
}

.home-page .task-section .section-inner {
  min-height: 470px;
}

.home-page .task-section .section-right img {
  position: absolute;
  top: 50%;
  max-width: 50%;
  right: -100px;
  transform: translateY(-50%);
}

.home-page .into-section {
  background: #f8f8f8;
}

.home-page .design-section {
  background: #f8f8f8;
}

.home-page .vendor-section {
  background: #f8f8f8;
}

.home-page .intro-heading {
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: 0px;
  color: #3a3a3a;
  padding-bottom: 15px;
  font-family: "Noto Sans TC", sans-serif;
}

.home-page .email-box {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 420px;
}

.home-page .email-box input {
  border: 1px solid #b3c6b3;
  border-radius: 5px;
  width: 100%;
  padding: 4px;
  height: 50px;
  padding-right: 165px;
  padding-left: 15px;
  outline: none;
  font-size: 16px;
}

.home-page .email-box button {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 152px;
}

.home-page section.into-section .section-desc {
  margin-bottom: 30px;
}

.home-page .quote-desc {
  position: relative;
  font-size: 25px;
  line-height: 50px;
  letter-spacing: 0px;
  color: #757575;
  text-align: center;
  max-width: 820px;
  margin: 0 auto;
  padding: 15px 60px;
  font-weight: 300;
  margin: 40px auto 0px;
}

.home-page .quote-desc:before {
  content: "";
  height: 50px;
  width: 50px;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../images/quote-pre.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.home-page .quote-desc:after {
  content: "";
  height: 50px;
  width: 50px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-image: url("../images/quote-post.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.quote-line {
  width: 175px;
  height: 5px;
  background: #b3c6b3;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.home-page .section-inner {
  display: flex;
  margin: 0 -15px;
  align-items: center;
}

.home-page .section-inner.simple-plan {
  margin: 0;
}

.home-page .section-left,
.home-page .section-right {
  flex: 0 0 50%;
  padding: 0 15px;
}

.home-page .into-section .section-right {
  position: relative;
}

.home-page .t-right {
  text-align: right;
}

.home-page .odd-section .section-right {
  order: 1;
}

.home-page .odd-section .section-left {
  order: 2;
}

.home-page .into-section {
  position: relative;
  height: 500px;
  padding: 0 0 50px 0;
  margin-top: 0;
}

.home-page .into-section .container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.home-page .into-section .section-right img {
  position: absolute;
  top: 50%;
  max-width: 608px;
  width: 100%;
  right: -26%;
  transform: translateY(-50%);
}

.home-page .into-section .section-inner {
  padding-top: 0;
  height: 100%;
}

.home-page .social-section {
  /* background-image: url(/assets/images/communication-bg.png); */
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

.home-page .social-section .section-inner {
  align-items: flex-end;
}

.home-page .social-section .section-inner .section-right {
  margin-bottom: -100px;
}

.home-page .social-section .section-inner .section-right img {
  display: flex;
}

.home-page .btn-toggle {
  height: 35px;
  width: 40px;
  border: 0;
  border-radius: 8px;
  background: #b3c6b3;
  padding: 0 5px;
  display: none;
}

.home-page .btn-toggle span {
  display: block;
  height: 1px;
  width: 100%;
  background: #fff;
  margin: 7px 0;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
}

.home-page .testimonial-carousel {
  padding: 0 40px;
  margin-top: 40px;
}

.home-page .testimonial-carousel .author {
  background: #f3f3f3;
  border-radius: 5px;
  padding: 15px 15px 15px 115px;
  position: relative;
  min-height: 118px;
  display: flex;
  align-items: center;
}

.home-page .testimonial-carousel .author-img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 87px;
  width: 87px;
  position: absolute;
  top: 15px;
  left: 15px;
}

.home-page .testimonial-carousel .author-img img {
  width: auto;
}

.home-page .testimonial-carousel .author-details h3 {
  font-size: 22px;
  line-height: 38px;
  color: #3a3a3a;
  margin-bottom: 5px;
}

.home-page .testimonial-carousel .author-details h5 {
  font-size: 20px;
  color: #757575;
}

.home-page .testimonial-carousel .owl-carousel .owl-nav [class*="owl-"] {
  background: #f0f0f0 !important;
  padding: 10px !important;
  border-radius: 0;
  font-size: 30px;
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
}

.home-page .testimonial-carousel .owl-carousel .owl-nav .owl-prev {
  left: -40px;
}

.home-page .testimonial-carousel .owl-carousel .owl-nav .owl-next {
  right: -40px;
}

.home-page .testimonial-carousel .owl-carousel .owl-item.active.center .author {
  background: #b3c6b3;
}

.home-page
  .testimonial-carousel
  .owl-carousel
  .active.center
  .author-details
  h3,
.home-page
  .testimonial-carousel
  .owl-carousel
  .active.center
  .author-details
  h5 {
  color: #fff;
}

.testimonial-carousel .owl-nav button:hover span,
.testimonial-carousel .owl-nav button:focus span {
  color: #d08a56;
}

.home-page .f_button,
.home-page .email-box button,
.home-page .btn-line {
  border: none;
  padding: 5px 10px;
  min-width: 127px;
}

@media only screen and (max-width: 1025px) {
  .home-page .container {
    max-width: 100%;
  }

  .home-page .plans-list > li:last-child .planbox {
    padding-left: 50px;
  }
}

@media only screen and (max-width: 991px) {
  .home-page .planbox {
    padding: 20px;
  }

  .home-page .plans-list > li:last-child .planbox {
    padding-left: 40px;
  }

  .home-page .plans-list > li:nth-child(2) .planbox .planbox-top,
  .home-page .plans-list > li:nth-child(2) .planbox .planbox-bottom {
    padding-bottom: 4px;
  }

  .home-page .plan-cost {
    font-size: 35px;
  }

  .home-page .plan-cost span {
    font-size: 13px;
    line-height: 16px;
  }

  .home-page .members-limit {
    font-size: 15px;
    line-height: 25px;
  }

  .home-page .planbox-top .plan-subdesc {
    font-size: 14px;
    line-height: 22px;
    min-height: 80px;
  }

  .home-page .planbox-bottom ul li p {
    font-size: 14px;
    line-height: 28px;
  }

  .home-page .plan-name {
    font-size: 26px;
    line-height: 40px;
  }

  .home-page .f-row {
    flex-wrap: wrap;
  }

  .home-page .f-column {
    flex: 0 0 50%;
  }

  .home-page .quote-desc {
    font-size: 22px;
    line-height: 44px;
  }

  .home-page .section-heading,
  .home-page .center-heading {
    font-size: 32px;
    line-height: 42px;
  }

  .home-page .section-subheading {
    font-size: 27px;
    line-height: 37px;
  }

  .home-page .into-section .section-right img,
  .home-page .task-section .section-right img {
    position: static;
    transform: none;
    max-width: 100%;
  }

  .home-page .btn-toggle {
    display: inline-block;
  }

  .home-page .header-menu {
    position: fixed;
    bottom: 0;
    top: 0;
    left: -105%;
    background: #fff;
    z-index: 99;
    max-width: 270px;
    width: 100%;
    overflow: auto;
    overflow-x: hidden;
    transition: all 0.3s ease;
  }

  .home-page .menu-links li {
    display: block;
    padding: 10px 15px;
  }

  .home-page .menu-links li:not(:last-child) {
    padding-right: 0;
  }

  .home-page .active-menu .header-menu {
    left: 0;
  }

  .home-page .active-menu .btn-toggle span:first-child {
    transform: rotate(-45deg);
    transform-origin: 21px 2px;
  }

  .home-page .active-menu .btn-toggle span:nth-child(2) {
    display: none;
  }

  .home-page .active-menu .btn-toggle span:last-child {
    transform: rotate(45deg);
    transform-origin: 19px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .home-page .section-left,
  .home-page .section-right {
    flex: 0 0 100%;
    text-align: center;
  }

  .home-page .section-heading:before {
    left: 0;
    right: 0;
    margin: auto;
  }

  .home-page .section-inner {
    flex-wrap: wrap;
  }

  .home-page .section-left {
    padding-bottom: 40px;
  }

  .home-page .odd-section .section-left {
    order: 1;
  }

  .home-page .odd-section .section-right {
    order: 2;
  }

  .home-page .plans-list {
    flex-wrap: wrap;
  }

  .home-page .plans-list > li {
    flex: 0 0 100%;
    padding: 15px;
  }

  .home-page .plans-list > li:nth-child(2) .planbox {
    transform: none;
  }

  .home-page .plans-list > li:nth-child(2) .planbox .planbox-top,
  .home-page .plans-list > li:nth-child(2) .planbox .planbox-bottom {
    transform: none;
  }

  .home-page .plans-list > li:first-child .planbox,
  .home-page .plans-list > li:last-child .planbox {
    border-radius: 10px;
  }

  .home-page section {
    padding: 70px 0;
  }

  .home-page .into-section {
    height: auto;
    padding: 70px 0;
  }

  .home-page .available-plans {
    padding: 0;
  }

  .home-page .f-column {
    flex: 0 0 100%;
  }

  .home-page .quote-desc {
    padding: 15px 50px;
    font-size: 18px;
    line-height: 38px;
  }

  .home-page .quote-desc:before,
  .home-page .quote-desc:after {
    height: 40px;
    width: 40px;
  }

  .home-page .planbox-top .plan-subdesc {
    min-height: 0;
  }

  .home-page .plans-list > li:last-child .planbox {
    padding-left: 20px;
  }
}

.contact_card ul, .contact_card ol {
  padding: 20px !important;
}

.contact_card ul li:before {
  content: none !important;
}

.pac-container {
  z-index: 999999 !important;
}