/*================================================
Default CSS
=================================================*/
@import url("https://fonts.googleapis.com/css2?family=Petrona:ital,wght@0,100;0,200;0,300;0,400;0,500;0,531;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,531;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.symbol.symbol-50.symbol-lg-120 {
  width: 73px;
}

.ck-content .table {
  width: auto;
}

.sigCanvas {
  border: 1px solid #262626;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
}

.signature {
  border: 1px solid #262626;
  background-color: #f8f8f8;
}

:root {
  --ck-z-default: 100;
  --ck-z-modal: calc(var(--ck-z-default) + 999);
}

span.select2.select2-container.select2-container--default {
  width: 100% !important;
}

body {
  color: #2a2a2a;
  background-color: #f1f1f1;
  padding: 0;
  margin: 0;
  font-size: 15px;
  font-family: "Petrona", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: 'Noto Sans TC', sans-serif; */
}

body.modal-open {
  padding-right: 0 !important;
}

img {
  max-width: 100%;
  height: auto;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #2a2a2a;
  font-family: "Petrona", serif;
}

a {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: 0 !important;
}

p {
  line-height: 1.5;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-20 {
  margin-top: 20px;
}

button,
input {
  outline: 0 !important;
}

.btn-outline-light {
  color: #212529;
}

/*dropdown-css*/
.dropdown-menu {
  -webkit-box-shadow: -7px 8px 16px 0 rgba(55, 70, 95, 0.2);
  box-shadow: -7px 8px 16px 0 rgba(55, 70, 95, 0.2);
  background-color: #ffffff;
  border-color: #eeeeee;
}

.dropdown-menu .form-control {
  border-radius: 5px;
  height: 45px;
}

/*progress-css*/
.progress + .progress {
  margin-top: 1rem;
}

/*modal-css*/
.modal p {
  line-height: 1.8;
  color: #727e8c;
}

/*forms*/
.form-check-input {
  margin-top: 0.2rem;
}

.form-control:focus {
  -webkit-box-shadow: unset;
  box-shadow: unset;
  border-color: #382a1f;
}

/* / mega mnu / */
.mega-menu .dropdown-menu {
  left: 0 !important;
  right: auto !important;
  width: 600px;
  max-width: 600px !important;
}

.mega-menu .dropdown-menu::before {
  left: auto !important;
  right: 15px !important;
}

.top-navbar.navbar .nav-item.dropdown .dropdown-menu .dropdown-item {
  display: flex !important;
  align-items: center !important;
}

.top-navbar.navbar .nav-item.dropdown .dropdown-menu .dropdown-item i {
  font-size: 1.4rem !important;
}

.mega-menu .dropdown-menu.hi::before {
  left: auto !important;
  right: 210px !important;
}

.mega-menu .dropdown-menu.drop-admin {
  width: 196px !important;
  left: -152px !important;
}

/* / mega mnu / */
.setting {
  background-color: #f1f1f1 !important;
  box-shadow: none !important;
}

.setting form {
  border: 1px solid #ddd;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
}

/*================================================
Sidemenu CSS
=================================================*/
.sidemenu-area {
  position: relative;
  height: 100%;
  /* z-index: 9999; */
  -webkit-transition: 0.5s;
  transition: 0.5s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sidemenu-area .sidemenu-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 30px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.sidemenu-area .sidemenu-header .navbar-brand {
  padding: 0;
  /* filter: invert(1); */
}

.sidemenu-area .sidemenu-header .navbar-brand span {
  position: relative;
  top: 3px;
  color: #2a2a2a !important;
  margin-left: 10px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 25px;
  font-weight: 700;
}

.sidemenu-area .sidemenu-header .burger-menu {
  cursor: pointer;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.sidemenu-area .sidemenu-header .burger-menu span {
  height: 1px;
  width: 25px;
  background: #2a2a2a;
  display: block;
  margin: 6px 0;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.sidemenu-area .sidemenu-header .burger-menu.active span.top-bar {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 10% 10%;
  transform-origin: 10% 10%;
}

.sidemenu-area .sidemenu-header .burger-menu.active span.middle-bar {
  opacity: 0;
}

.sidemenu-area .sidemenu-header .burger-menu.active span.bottom-bar {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 10% 90%;
  transform-origin: 10% 90%;
  margin-top: 5px;
}

.sidemenu-area .sidemenu-header .responsive-burger-menu {
  cursor: pointer;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.sidemenu-area .sidemenu-header .responsive-burger-menu span {
  height: 1px;
  width: 25px;
  background: #2a2a2a;
  display: block;
  margin: 6px 0;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.sidemenu-area .sidemenu-header .responsive-burger-menu.active span.top-bar {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 10% 10%;
  transform-origin: 10% 10%;
}

.sidemenu-area .sidemenu-header .responsive-burger-menu.active span.middle-bar {
  opacity: 0;
}

.sidemenu-area .sidemenu-header .responsive-burger-menu.active span.bottom-bar {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 10% 90%;
  transform-origin: 10% 90%;
  margin-top: 5px;
}

.sidemenu-area .sidemenu-body {
  /* max-height: calc(100% - 80px); */
  position: relative;
  height: 100%;
  /* -webkit-box-shadow: 0 8px 10px 0 rgba(183, 192, 206, 0.1); */
  /* box-shadow: 0 8px 10px 0 rgba(183, 192, 206, 0.1); */
  /* background: #ffffff; */
  /* overflow: hidden; */
  width: 100%;
  background-color: transparent;
}

.sidemenu-area .sidemenu-body .sidemenu-nav {
  /* padding: 20px 0; */
  list-style-type: none;
  margin-bottom: 0;
  background-color: transparent;
}

.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item-title,
.mega-menu .dropdown-menu .nav-item-title {
  color: #686868;
  display: block;
  text-transform: uppercase;
  margin-bottom: 7px;
  font-size: 13px;
  font-weight: 600;
  padding-left: 20px;
  padding-right: 20px;
}

.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item-title:not(:first-child) {
  margin-top: 20px;
}

.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link {
  color: #7e7e7e;
  padding: 10px 20px;
  position: relative;
  z-index: 1;
  font-size: 15px;
  font-weight: 500;
}

.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link .icon {
  color: #2a2a2a;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  display: inline-block;
  margin-right: 5px;
  font-size: 18px;
  position: relative;
  top: 2px;
}

.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link .badge {
  background-color: #43d39e;
  color: #ffffff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-left: 4px;
  position: relative;
  top: -1px;
  padding: 4px 7px 4px 5px;
}

.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link.disabled {
  color: #a3a3a3;
}

.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link.disabled i {
  color: #a3a3a3;
}

.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .nav-link.collapsed-nav-link {
  position: relative;
}

.topbar-nav .metismenu a:focus {
  background: transparent;
}

.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .nav-link.collapsed-nav-link::after {
  content: "\e9f9";
  position: absolute;
  right: 2px;
  top: 8px;
  color: #7e7e7e;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-family: "boxicons" !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  font-size: 18px;
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link:hover .icon {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  color: #382a1f;
}

.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item.mm-active .nav-link {
  /* background-color: #f9ecef; */
  /* color: #382a1f; */
  color: #000;
}

.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item.mm-active
  .nav-link::before {
  width: 100%;
  display: none;
}

.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item.mm-active
  .nav-link
  .icon {
  /* color: #382a1f; */
  color: #000;
}

.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item.mm-active
  .nav-link.collapsed-nav-link::after {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level {
  list-style-type: none;
  padding: 0 15px;
  min-width: 200px;
  margin: 0;
  background-color: #fff;
}

.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item {
  margin: 0;
}

.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .nav-link {
  color: #7e7e7e;
  background-color: transparent !important;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 5px;
}

.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .nav-link
  .icon {
  color: #2a2a2a;
}

.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .nav-link::before {
  display: none;
}

.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .nav-link.collapsed-nav-link::after {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .nav-link:hover {
  color: #382a1f;
}

.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .nav-link:hover
  .icon {
  color: #382a1f;
}

.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item.mm-active
  .nav-link {
  color: #382a1f;
}

.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item.mm-active
  .nav-link
  .icon {
  color: #382a1f;
}

.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item.mm-active
  .nav-link.collapsed-nav-link::after {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .sidemenu-nav-third-level {
  list-style-type: none;
  padding-left: 15px;
  margin-bottom: 0;
}

.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .sidemenu-nav-third-level
  .nav-item
  .nav-link {
  color: #7e7e7e;
  background-color: transparent !important;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 5px;
}

.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .sidemenu-nav-third-level
  .nav-item
  .nav-link
  .icon {
  color: #2a2a2a;
}

.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .sidemenu-nav-third-level
  .nav-item
  .nav-link::before {
  display: none;
}

.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .sidemenu-nav-third-level
  .nav-item
  .nav-link.collapsed-nav-link::after {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .sidemenu-nav-third-level
  .nav-item
  .nav-link:hover {
  color: #382a1f;
}

.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .sidemenu-nav-third-level
  .nav-item
  .nav-link:hover
  .icon {
  color: #382a1f;
}

.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .sidemenu-nav-third-level
  .nav-item.mm-active
  .nav-link {
  color: #382a1f;
}

.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .sidemenu-nav-third-level
  .nav-item.mm-active
  .nav-link
  .icon {
  color: #382a1f;
}

.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .sidemenu-nav-third-level
  .nav-item.mm-active
  .nav-link.collapsed-nav-link::after {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.sidemenu-area.toggle-sidemenu-area {
  width: 55px;
}

.sidemenu-area.toggle-sidemenu-area .sidemenu-header {
  padding: 0 10px;
}

.sidemenu-area.toggle-sidemenu-area .sidemenu-header .navbar-brand {
  display: block !important;
}

.sidemenu-area.toggle-sidemenu-area .sidemenu-header .navbar-brand span {
  opacity: 0;
  visibility: hidden;
}

.sidemenu-area.toggle-sidemenu-area .sidemenu-header .burger-menu {
  opacity: 0;
  visibility: hidden;
}

.sidemenu-area.toggle-sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item-title {
  display: none;
}

.sidemenu-area.toggle-sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .nav-link
  .menu-title {
  display: none;
}

.sidemenu-area.toggle-sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .nav-link::after {
  display: none;
}

.sidemenu-area.toggle-sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .nav-link
  .badge {
  display: none;
}

.sidemenu-area.toggle-sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level {
  padding-left: 20px;
}

.sidemenu-area.toggle-sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .sidemenu-nav-third-level {
  padding-left: 0;
}

.sidemenu-area:hover.toggle-sidemenu-area {
  width: 250px;
}

.sidemenu-area:hover.toggle-sidemenu-area .sidemenu-header {
  padding: 0 20px;
}

.sidemenu-area:hover.toggle-sidemenu-area .sidemenu-header .navbar-brand {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.sidemenu-area:hover.toggle-sidemenu-area .sidemenu-header .navbar-brand span {
  opacity: 1;
  visibility: visible;
}

.sidemenu-area:hover.toggle-sidemenu-area .sidemenu-header .burger-menu {
  opacity: 1;
  visibility: visible;
}

.sidemenu-area:hover.toggle-sidemenu-area .sidemenu-nav .nav-item-title {
  display: block;
}

.sidemenu-area:hover.toggle-sidemenu-area
  .sidemenu-nav
  .nav-item
  .nav-link
  .menu-title {
  display: inline-block;
}

.sidemenu-area:hover.toggle-sidemenu-area
  .sidemenu-nav
  .nav-item
  .nav-link::after {
  display: block;
}

.sidemenu-area:hover.toggle-sidemenu-area
  .sidemenu-nav
  .nav-item
  .nav-link
  .badge {
  display: inline-block;
}

.sidemenu-area:hover.toggle-sidemenu-area
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level {
  padding-left: 30px;
}

.sidemenu-area:hover.toggle-sidemenu-area
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .sidemenu-nav-third-level {
  padding-left: 15px;
}

/*================================================
Top Navbar CSS
=================================================*/
.top-navbar.navbar {
  padding-left: 0;
  padding-right: 0;
  padding-top: 15px;
}

.top-navbar.navbar .responsive-burger-menu {
  cursor: pointer;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.top-navbar.navbar .responsive-burger-menu span {
  height: 1px;
  width: 25px;
  background: #2a2a2a;
  display: block;
  margin: 6px 0;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.top-navbar.navbar .responsive-burger-menu.active span.top-bar {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 10% 10%;
  transform-origin: 10% 10%;
}

.top-navbar.navbar .responsive-burger-menu.active span.middle-bar {
  opacity: 0;
}

.top-navbar.navbar .responsive-burger-menu.active span.bottom-bar {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 10% 90%;
  transform-origin: 10% 90%;
  margin-top: 5px;
}

.top-navbar.navbar .nav-search-form {
  position: relative;
  width: 300px;
}

.top-navbar.navbar .nav-search-form label {
  display: block;
  margin-bottom: 0;
  position: absolute;
  left: 15px;
  top: 11.3px;
  font-size: 18px;
  color: #a8a8a8;
}

.top-navbar.navbar .nav-search-form .form-control {
  height: 45px;
  border-radius: 30px;
  background-color: #eef5f9;
  border: none;
  color: #2a2a2a;
  padding: 0 15px 0 40px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  font-size: 14px;
  font-weight: 400;
}

.top-navbar.navbar .nav-search-form .form-control:focus {
  -webkit-box-shadow: unset;
  box-shadow: unset;
  background-color: #eef5f9;
}

.top-navbar.navbar
  .nav-search-form
  .form-control:focus::-webkit-input-placeholder {
  color: transparent !important;
}

.top-navbar.navbar .nav-search-form .form-control:focus:-ms-input-placeholder {
  color: transparent !important;
}

.top-navbar.navbar .nav-search-form .form-control:focus::-ms-input-placeholder {
  color: transparent !important;
}

.top-navbar.navbar .nav-search-form .form-control:focus::placeholder {
  color: transparent !important;
}

.top-navbar.navbar .nav-search-form .form-control::-webkit-input-placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.top-navbar.navbar .nav-search-form .form-control:-ms-input-placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.top-navbar.navbar .nav-search-form .form-control::-ms-input-placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.top-navbar.navbar .nav-search-form .form-control::placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.top-navbar.navbar .nav-item {
  margin-left: 15px;
}

.top-navbar.navbar .nav-item .nav-link {
  padding: 0;
  color: #525f80;
  font-weight: 500;
}

.top-navbar.navbar .nav-item .nav-link.dropdown-toggle::after {
  display: none;
}

.top-navbar.navbar .nav-item .nav-link .bx-chevron-down {
  font-size: 18px;
  position: relative;
  display: inline-block;
  top: 3px;
  margin-left: -4px;
  color: #525f80;
}

.top-navbar.navbar .nav-item .nav-link img {
  width: 30px;
  margin-right: 3px;
}

.top-navbar.navbar .nav-item .nav-link .badge {
  position: absolute;
  top: -2px;
  right: -7px;
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  font-size: 9px;
  line-height: 12px;
}

.top-navbar.navbar .nav-item .nav-link .badge.badge-secondary {
  background: #382a1f;
  -webkit-box-shadow: 0 0 0 rgba(3, 3, 3, 0.9);
  box-shadow: 0 0 0 rgba(56, 42, 31, 0.9);
}

.top-navbar.navbar .nav-item .notification-btn.animate .badge.badge-secondary{
  -webkit-animation: pulse-secondary 2s infinite;
  animation: pulse-secondary 2s infinite;
}

.top-navbar.navbar .nav-item .nav-link .badge.badge-primary {
  background: #2962ff;
  -webkit-animation: pulse-primary 2s infinite;
  animation: pulse-primary 2s infinite;
  -webkit-box-shadow: 0 0 0 rgba(41, 98, 255, 0.9);
  box-shadow: 0 0 0 rgba(41, 98, 255, 0.9);
}

.top-navbar.navbar .nav-item .nav-link.bx-fullscreen-btn {
  font-size: 22px;
  position: relative;
  cursor: pointer;
  top: 3px;
  margin-right: 5px;
}

.top-navbar.navbar .nav-item .nav-link.bx-fullscreen-btn:hover {
  color: #382a1f;
}

.top-navbar.navbar .nav-item .nav-link.bx-fullscreen-btn.active i::before {
  content: "\ea5d";
}

.top-navbar.navbar .nav-item.dropdown .dropdown-menu {
  max-width: 280px;
  position: absolute;
  right: 0;
  left: auto;
  border-radius: 2px;
  margin-top: 20px;
  border: none;
  display: block;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
}

.top-navbar.navbar .nav-item.dropdown .dropdown-menu.show {
  margin-top: 10px;
  opacity: 1;
  visibility: visible;
}

.top-navbar.navbar .nav-item.dropdown .dropdown-menu .dropdown-item {
  color: #2a2a2a;
  padding: 5px 15px;
  position: relative;
  font-size: 15px;
}

.top-navbar.navbar .nav-item.dropdown .dropdown-menu .dropdown-item.active,
.top-navbar.navbar .nav-item.dropdown .dropdown-menu .dropdown-item:active {
  color: #2a2a2a;
  background-color: transparent;
  background-color: #f8f9fa;
}

.top-navbar.navbar .nav-item:first-child {
  margin-left: 0;
}

.top-navbar.navbar .nav-item.language-switch-nav-item .nav-link img {
  width: 25px;
  margin-right: 3px;
  position: relative;
  top: -2px;
  border-radius: 2px;
}

.top-navbar.navbar .nav-item.language-switch-nav-item.dropdown .dropdown-menu {
  left: 50%;
  right: auto;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.top-navbar.navbar
  .nav-item.language-switch-nav-item.dropdown
  .dropdown-menu
  .dropdown-item {
  padding: 5px 20px;
  font-size: 14.5px;
}

.top-navbar.navbar
  .nav-item.language-switch-nav-item.dropdown
  .dropdown-menu
  .dropdown-item
  img {
  width: 27px;
  border-radius: 2px;
}

.top-navbar.navbar .nav-item.notification-box .dropdown-menu {
  width: 350px;
  max-width: 350px;
  border-radius: 5px 5px 0 0;
  padding: 0;
  z-index: 99999;
}

.top-navbar.navbar .nav-item.notification-box .nav-link.dropdown-toggle {
  color: #382a1f;
}

.top-navbar.navbar
  .nav-item.notification-box
  .nav-link.dropdown-toggle
  .badge-secondary {
  background: #382a1f;
}

.top-navbar.navbar .nav-item.notification-box .dropdown-menu .dropdown-header {
  border-radius: 5px 5px 0 0;
  background-color: #d08a56;
  color: #ffffff;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 9px;
  padding-bottom: 7px;
}

.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-header
  span {
  font-size: 13px;
  font-weight: 600;
}

.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-header
  span.mark-all-btn {
  cursor: pointer;
}

.top-navbar.navbar .nav-item.notification-box .dropdown-menu .dropdown-body {
  padding-top: 10px;
  padding-bottom: 10px;
}

.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item {
  padding: 7px 15px;
}

.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: relative;
  background: #f7f7f7;
  text-align: center;
  color: #382a1f;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .icon
  i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .content {
  padding-left: 15px;
}

.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .content
  span {
  color: #2a2a2a;
  margin-bottom: 1px;
  font-size: 14.5px;
  font-weight: 600;
}

.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .content
  p {
  font-size: 12px;
  color: #686868;
}

.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:hover
  .icon {
  background-color: #382a1f;
  color: #ffffff;
}

.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(2)
  .icon {
  background: rgba(114, 124, 245, 0.1);
  color: #727cf5;
}

.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(2):hover
  .icon {
  background-color: #727cf5;
  color: #ffffff;
}

.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(3)
  .icon {
  background: rgba(16, 183, 89, 0.1);
  color: #10b759;
}

.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(3):hover
  .icon {
  background-color: #10b759;
  color: #ffffff;
}

.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(4)
  .icon {
  background: rgba(255, 51, 102, 0.1);
  color: #f36;
}

.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(4):hover
  .icon {
  background-color: #f36;
  color: #ffffff;
}

.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(5)
  .icon {
  background: rgba(251, 188, 6, 0.1);
  color: #fbbc06;
}

.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(5):hover
  .icon {
  background-color: #fbbc06;
  color: #ffffff;
}

.top-navbar.navbar .nav-item.notification-box .dropdown-menu .dropdown-footer {
  text-align: center;
  padding: 10px 15px;
  border-top: 1px solid #eeeeee;
}

.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-footer
  .dropdown-item {
  color: #382a1f !important;
  padding: 0;
  position: relative;
  display: inline-block;
  width: auto;
  background-color: transparent !important;
  font-size: 14px;
  font-weight: 600;
}

.top-navbar.navbar .nav-item .notification-btn {
  font-size: 20px;
  position: relative;
  margin-right: 7px;
}

.top-navbar.navbar .nav-item .notification-btn i {
  position: relative;
  top: 3px;
}

.top-navbar.navbar .nav-item .notification-btn.animate i{
  -webkit-animation: ring 4s 0.7s ease-in-out infinite;
  animation: ring 4s 0.7s ease-in-out infinite;
}

.top-navbar.navbar .nav-item.message-box .dropdown-menu {
  width: 350px;
  max-width: 350px;
  border-radius: 5px 5px 0 0;
  padding: 0;
}

.top-navbar.navbar .nav-item.message-box .dropdown-menu .dropdown-header {
  border-radius: 5px 5px 0 0;
  background-color: #2962ff;
  color: #ffffff;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 9px;
  padding-bottom: 7px;
}

.top-navbar.navbar .nav-item.message-box .dropdown-menu .dropdown-header span {
  font-size: 13px;
  font-weight: 600;
}

.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-header
  span.clear-all-btn {
  cursor: pointer;
}

.top-navbar.navbar .nav-item.message-box .dropdown-menu .dropdown-body {
  padding-top: 10px;
  padding-bottom: 10px;
}

.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item {
  padding: 7px 15px;
}

.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .figure
  img {
  width: 35px;
  height: 35px;
}

.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .content {
  width: 285px;
  padding-left: 12px;
}

.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .content
  span {
  color: #2a2a2a;
  margin-bottom: 2px;
  font-size: 14.5px;
  font-weight: 600;
}

.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .content
  .time-text {
  font-size: 13px;
  color: #686868;
}

.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .content
  .sub-text {
  font-size: 12.5px;
  color: #686868;
}

.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:hover
  .icon {
  background-color: #382a1f;
  color: #ffffff;
}

.top-navbar.navbar .nav-item.message-box .dropdown-menu .dropdown-footer {
  text-align: center;
  padding: 10px 15px;
  border-top: 1px solid #eeeeee;
}

.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-footer
  .dropdown-item {
  color: #2962ff !important;
  padding: 0;
  position: relative;
  display: inline-block;
  width: auto;
  background-color: transparent !important;
  font-size: 14px;
  font-weight: 600;
}

.top-navbar.navbar .nav-item .message-btn {
  font-size: 20px;
  position: relative;
  margin-right: 7px;
}

.top-navbar.navbar .nav-item .message-btn i {
  position: relative;
  top: 3px;
}

.top-navbar.navbar .nav-item.apps-box .dropdown-menu {
  width: 350px;
  max-width: 350px;
  border-radius: 5px 5px 0 0;
  padding: 0;
  left: 0;
  right: auto;
}

.top-navbar.navbar .nav-item.apps-box .dropdown-menu .dropdown-header {
  border-radius: 5px 5px 0 0;
  background-color: #2962ff;
  color: #ffffff;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 9px;
  padding-bottom: 7px;
}

.top-navbar.navbar .nav-item.apps-box .dropdown-menu .dropdown-header span {
  font-size: 13px;
  font-weight: 600;
}

.top-navbar.navbar
  .nav-item.apps-box
  .dropdown-menu
  .dropdown-header
  span.edit-btn {
  cursor: pointer;
}

.top-navbar.navbar .nav-item.apps-box .dropdown-menu .dropdown-body {
  padding: 10px 5px;
}

.top-navbar.navbar
  .nav-item.apps-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item {
  -ms-flex: 0 0 25%;
  -webkit-box-flex: 0;
  flex: 0 0 25%;
  max-width: 25%;
  text-align: center;
  padding: 8px 10px;
}

.top-navbar.navbar
  .nav-item.apps-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  span {
  margin-top: 8px;
  font-weight: 600;
}

.top-navbar.navbar .nav-item.apps-box .dropdown-menu .dropdown-footer {
  text-align: center;
  padding: 10px 15px;
  border-top: 1px solid #eeeeee;
}

.top-navbar.navbar
  .nav-item.apps-box
  .dropdown-menu
  .dropdown-footer
  .dropdown-item {
  color: #2962ff !important;
  padding: 0;
  position: relative;
  display: inline-block;
  width: auto;
  background-color: transparent !important;
  font-size: 14px;
  font-weight: 600;
}

.top-navbar.navbar .left-nav {
  position: relative;
  top: 4px;
}

.top-navbar.navbar .left-nav .nav-item .nav-link {
  font-size: 22px;
}

.top-navbar.navbar .left-nav .nav-item .nav-link:hover {
  color: #382a1f;
}

.top-navbar.navbar .right-nav {
  margin-left: 20px;
}

.top-navbar.navbar .profile-nav-item .menu-profile .name {
  color: #382a1f;
  display: inline-block;
  position: relative;
  top: 2px;
  font-weight: 600;
}

.top-navbar.navbar .profile-nav-item .menu-profile img {
  width: 35px;
  height: 35px;
  display: inline-block;
  margin-right: 0;
  margin-left: 5px;
}

.top-navbar.navbar .profile-nav-item.dropdown .dropdown-menu {
  padding: 15px 15px 10px;
  min-width: 230px;
}

.top-navbar.navbar .profile-nav-item.dropdown .dropdown-menu .dropdown-header {
  padding: 0;
  border-bottom: 1px solid #f2f4f9;
}

.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-header
  .figure {
  position: relative;
}

.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-header
  .figure
  img {
  width: 80px;
  height: 80px;
}

.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-header
  .name {
  display: block;
  color: #2a2a2a;
  margin-bottom: 2px;
  font-size: 17px;
  font-weight: 700;
}

.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-header
  .email {
  color: #686868;
}

.top-navbar.navbar .profile-nav-item.dropdown .dropdown-menu .dropdown-body ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-body
  .nav-item {
  margin-left: 0;
}

.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-body
  .nav-item
  .nav-link {
  color: #2a2a2a;
  padding: 5px 15px 5px 38px;
  position: relative;
  font-size: 14px;
  font-weight: 600;
}

.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-body
  .nav-item
  .nav-link
  i {
  color: #686868;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: absolute;
  left: 15px;
  top: 7px;
  font-size: 16px;
}

.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-body
  .nav-item
  .nav-link.active,
.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-body
  .nav-item
  .nav-link:active {
  color: #2a2a2a;
  background-color: transparent;
  background-color: #f8f9fa;
}

.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-body
  .nav-item
  .nav-link:hover
  i {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.top-navbar.navbar .profile-nav-item.dropdown .dropdown-menu .dropdown-footer {
  margin: 10px -15px 0;
  padding: 10px 15px 0;
  border-top: 1px solid #eeeeee;
}

.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-footer
  ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-footer
  .nav-item
  .nav-link {
  color: red;
  padding: 5px 15px 5px 38px;
  position: relative;
  font-size: 14px;
  font-weight: 600;
}

.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-footer
  .nav-item
  .nav-link
  i {
  color: red;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: absolute;
  left: 15px;
  top: 7px;
  font-size: 16px;
}

.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-footer
  .nav-item
  .nav-link.active,
.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-footer
  .nav-item
  .nav-link:active {
  color: #2a2a2a;
  background-color: transparent;
  background-color: #f8f9fa;
}

.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-footer
  .nav-item
  .nav-link:hover
  i {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.top-navbar.navbar.is-sticky {
  position: fixed;
  top: 0;
  left: 0px;
  z-index: 999;
  -webkit-box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.07);
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 17px;
  padding-bottom: 17px;
  background-color: #382a1f !important;
}

.top-navbar.navbar.is-sticky.toggle-navbar-area {
  left: 55px;
  width: calc(100% - 55px);
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
  }

  1% {
    -webkit-transform: rotateZ(30deg);
    transform: rotateZ(30deg);
  }

  3% {
    -webkit-transform: rotateZ(-28deg);
    transform: rotateZ(-28deg);
  }

  5% {
    -webkit-transform: rotateZ(34deg);
    transform: rotateZ(34deg);
  }

  7% {
    -webkit-transform: rotateZ(-32deg);
    transform: rotateZ(-32deg);
  }

  9% {
    -webkit-transform: rotateZ(30deg);
    transform: rotateZ(30deg);
  }

  11% {
    -webkit-transform: rotateZ(-28deg);
    transform: rotateZ(-28deg);
  }

  13% {
    -webkit-transform: rotateZ(26deg);
    transform: rotateZ(26deg);
  }

  15% {
    -webkit-transform: rotateZ(-24deg);
    transform: rotateZ(-24deg);
  }

  17% {
    -webkit-transform: rotateZ(22deg);
    transform: rotateZ(22deg);
  }

  19% {
    -webkit-transform: rotateZ(-20deg);
    transform: rotateZ(-20deg);
  }

  21% {
    -webkit-transform: rotateZ(18deg);
    transform: rotateZ(18deg);
  }

  23% {
    -webkit-transform: rotateZ(-16deg);
    transform: rotateZ(-16deg);
  }

  25% {
    -webkit-transform: rotateZ(14deg);
    transform: rotateZ(14deg);
  }

  27% {
    -webkit-transform: rotateZ(-12deg);
    transform: rotateZ(-12deg);
  }

  29% {
    -webkit-transform: rotateZ(10deg);
    transform: rotateZ(10deg);
  }

  31% {
    -webkit-transform: rotateZ(-8deg);
    transform: rotateZ(-8deg);
  }

  33% {
    -webkit-transform: rotateZ(6deg);
    transform: rotateZ(6deg);
  }

  35% {
    -webkit-transform: rotateZ(-4deg);
    transform: rotateZ(-4deg);
  }

  37% {
    -webkit-transform: rotateZ(2deg);
    transform: rotateZ(2deg);
  }

  39% {
    -webkit-transform: rotateZ(-1deg);
    transform: rotateZ(-1deg);
  }

  41% {
    -webkit-transform: rotateZ(1deg);
    transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
  }

  100% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
  }
}

@keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
  }

  1% {
    -webkit-transform: rotateZ(30deg);
    transform: rotateZ(30deg);
  }

  3% {
    -webkit-transform: rotateZ(-28deg);
    transform: rotateZ(-28deg);
  }

  5% {
    -webkit-transform: rotateZ(34deg);
    transform: rotateZ(34deg);
  }

  7% {
    -webkit-transform: rotateZ(-32deg);
    transform: rotateZ(-32deg);
  }

  9% {
    -webkit-transform: rotateZ(30deg);
    transform: rotateZ(30deg);
  }

  11% {
    -webkit-transform: rotateZ(-28deg);
    transform: rotateZ(-28deg);
  }

  13% {
    -webkit-transform: rotateZ(26deg);
    transform: rotateZ(26deg);
  }

  15% {
    -webkit-transform: rotateZ(-24deg);
    transform: rotateZ(-24deg);
  }

  17% {
    -webkit-transform: rotateZ(22deg);
    transform: rotateZ(22deg);
  }

  19% {
    -webkit-transform: rotateZ(-20deg);
    transform: rotateZ(-20deg);
  }

  21% {
    -webkit-transform: rotateZ(18deg);
    transform: rotateZ(18deg);
  }

  23% {
    -webkit-transform: rotateZ(-16deg);
    transform: rotateZ(-16deg);
  }

  25% {
    -webkit-transform: rotateZ(14deg);
    transform: rotateZ(14deg);
  }

  27% {
    -webkit-transform: rotateZ(-12deg);
    transform: rotateZ(-12deg);
  }

  29% {
    -webkit-transform: rotateZ(10deg);
    transform: rotateZ(10deg);
  }

  31% {
    -webkit-transform: rotateZ(-8deg);
    transform: rotateZ(-8deg);
  }

  33% {
    -webkit-transform: rotateZ(6deg);
    transform: rotateZ(6deg);
  }

  35% {
    -webkit-transform: rotateZ(-4deg);
    transform: rotateZ(-4deg);
  }

  37% {
    -webkit-transform: rotateZ(2deg);
    transform: rotateZ(2deg);
  }

  39% {
    -webkit-transform: rotateZ(-1deg);
    transform: rotateZ(-1deg);
  }

  41% {
    -webkit-transform: rotateZ(1deg);
    transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
  }

  100% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
  }
}

@-webkit-keyframes pulse-secondary {
  0% {
    -webkit-box-shadow: 0 0 0 rgba(56, 42, 31, 0.6);
    box-shadow: 0 0 0 rgba(56, 42, 31, 0.6);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(56, 42, 31, 0);
    box-shadow: 0 0 0 10px rgba(56, 42, 31, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(56, 42, 31, 0);
    box-shadow: 0 0 0 0 rgba(56, 42, 31, 0);
  }
}

@keyframes pulse-secondary {
  0% {
    -webkit-box-shadow: 0 0 0 rgba(56, 42, 31, 0.6);
    box-shadow: 0 0 0 rgba(56, 42, 31, 0.6);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(56, 42, 31, 0);
    box-shadow: 0 0 0 10px rgba(56, 42, 31, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(56, 42, 31, 0);
    box-shadow: 0 0 0 0 rgba(56, 42, 31, 0);
  }
}

@-webkit-keyframes pulse-primary {
  0% {
    -webkit-box-shadow: 0 0 0 rgba(41, 98, 255, 0.6);
    box-shadow: 0 0 0 rgba(41, 98, 255, 0.6);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(41, 98, 255, 0);
    box-shadow: 0 0 0 10px rgba(41, 98, 255, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(41, 98, 255, 0);
    box-shadow: 0 0 0 0 rgba(41, 98, 255, 0);
  }
}

@keyframes pulse-primary {
  0% {
    -webkit-box-shadow: 0 0 0 rgba(41, 98, 255, 0.6);
    box-shadow: 0 0 0 rgba(41, 98, 255, 0.6);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(41, 98, 255, 0);
    box-shadow: 0 0 0 10px rgba(41, 98, 255, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(41, 98, 255, 0);
    box-shadow: 0 0 0 0 rgba(41, 98, 255, 0);
  }
}

.top-navbar.navbar .topMenu .nav-item .nav-link {
  font-weight: 600;
  color: #382a1f;
  font-size: 16px;
  text-transform: capitalize;
  padding: 5px 10px;
}

.top-navbar.navbar .topMenu .nav-item .nav-link:hover,
.top-navbar.navbar .nav-item .nav-link:hover,
.top-navbar.navbar .topMenu .nav-item.mm-active .nav-link {
  /* background-color: #f9ecef; */
  color: #d08a56;
}

.navBarN.main-content {
  min-height: 100%;
  background-color: #f1f1f1;
  overflow: visible;
  min-height: 70px;
}

.navBarN.main-content .sidemenu-area {
  opacity: 1;
  visibility: visible;
  left: 0;
}

.modal-backdrop.fade.show {
  z-index: 10;
}

.table-Dropdown {
  padding: 0;
  border: none;
  background-color: transparent;
  color: #727e8c;
  font-size: 22px;
  line-height: 15px;
}

.table-Dropdown::after {
  display: none;
}

.list-search-form {
  position: relative;
  width: 300px;
}

.list-search-form {
  width: 100%;
}

.list-search-form label {
  display: block;
  margin-bottom: 0;
  position: absolute;
  left: 15px;
  top: 11.3px;
  font-size: 18px;
  color: #a8a8a8;
}

.list-search-form .form-control {
  height: 45px;
  border-radius: 30px;
  background-color: #eef5f9;
  border: none;
  color: #2a2a2a;
  padding: 0 15px 0 40px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  font-size: 14px;
  font-weight: 400;
}

.list-search-form .form-control {
  border-radius: 0.25rem;
}

.list-search-form .searcBtn {
  background-color: #eef5f9;
  color: #2a2a2a;
  font-size: 14px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.list-search-form .searcBtn:hover {
  background-color: #2a2a2a;
  color: #eef5f9;
}

/*================================================
Main Content CSS
=================================================*/
.main-content {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  overflow: hidden;
  min-height: 100vh;
  padding-right: 30px;
  padding-left: 30px;
  /* min-height: auto; */
}

/* .footer-area {
  margin-top: auto;
  align-self: center;
  justify-self: flex-end;
} */

/* div#root {
  display: flex;
  flex-flow: column;
  height: 100vh;
} */

.breadcrumb-area {
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  background-color: #ffffff;
  padding: 20px 25px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.breadcrumb-area h1,
.project-title {
  margin-bottom: 0;
  position: relative;
  color: #2a2a2a;
  padding-right: 12px;
  font-size: 20px;
  font-weight: 700;
}

.project-title {
  font-size: 26px;
}

.project-title.p-title {
  position: absolute;
  bottom: 0;
  left: 20px;
  color: #fff;
}

.breadcrumb-area h1::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 16px;
  width: 1px;
  background: #cecece;
}

.breadcrumb-area .breadcrumb {
  background-color: transparent;
  border-radius: 0;
  margin-bottom: 0;
  padding-left: 12px;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
}

.breadcrumb-area .breadcrumb .item {
  color: #606060;
  margin-right: 25px;
  position: relative;
  font-size: 15px;
  font-family: "Petrona", serif;
}

.breadcrumb-area .breadcrumb .item a {
  display: inline-block;
  color: #382a1f;
}

.breadcrumb-area .breadcrumb .item a:hover {
  color: #382a1f;
}

.breadcrumb-area .breadcrumb .item a i {
  font-size: 18px;
}

.breadcrumb-area .breadcrumb .item::before {
  content: "\e9fa";
  position: absolute;
  right: -21px;
  top: 2px;
  line-height: 18px;
  color: #606060;
  font-family: "boxicons" !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  font-size: 18px;
}

.breadcrumb-area .breadcrumb .item:last-child {
  margin-right: 0;
}

.breadcrumb-area .breadcrumb .item:last-child::before {
  display: none;
}

/*start-stats-box-css*/
.stats-card-box {
  margin-bottom: 30px;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  background-color: #ffffff;
  padding: 25px 25px 25px 115px;
  border-radius: 5px;
  position: relative;
}

.stats-card-box .icon-box {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #382a1f;
  position: absolute;
  left: 25px;
  top: 20px;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-box-shadow: 1px 5px 24px 0 rgba(56, 42, 31, 0.15);
  box-shadow: 1px 5px 24px 0 rgba(56, 42, 31, 0.15);
  text-align: center;
  font-size: 38px;
  color: #ffffff;
}

.stats-card-box .icon-box i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.stats-card-box .icon-box i.bx-paper-plane {
  top: 45%;
  -webkit-transform: translateY(-45%);
  transform: translateY(-45%);
  left: -3px;
}

.stats-card-box .sub-title {
  color: #a3a3a3;
  display: block;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 400;
}

.stats-card-box h3 {
  margin-bottom: 0;
  font-size: 25px;
  font-weight: 700;
}

.stats-card-box h3 .badge {
  line-height: initial;
  border-radius: 0;
  color: #13bb37;
  padding: 0;
  font-size: 13px;
  font-weight: 400;
}

.stats-card-box h3 .badge.badge-red {
  color: #ff4b00;
}

.stats-card-box .progress-list {
  margin-top: 8px;
}

.stats-card-box .progress-list .bar-inner {
  position: relative;
  width: 100%;
  border-bottom: 3px solid #d1d1d1;
}

.stats-card-box .progress-list .bar-inner .bar {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 3px;
  background: #382a1f;
  -webkit-transition: all 2000ms ease;
  transition: all 2000ms ease;
}

.stats-card-box .progress-list p {
  color: #a3a3a3;
  margin-top: 6px;
  margin-bottom: 0;
  font-size: 13px;
}

.col-lg-3:nth-child(2) .stats-card-box .icon-box {
  background-color: #13bb37;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(19, 187, 55, 0.15);
  box-shadow: 1px 5px 24px 0 rgba(19, 187, 55, 0.15);
}

.col-lg-3:nth-child(2) .stats-card-box .progress-list .bar-inner .bar {
  background: #13bb37;
}

.col-lg-3:nth-child(3) .stats-card-box .icon-box {
  background-color: #ff4b00;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(255, 75, 0, 0.15);
  box-shadow: 1px 5px 24px 0 rgba(255, 75, 0, 0.15);
}

.col-lg-3:nth-child(3) .stats-card-box .progress-list .bar-inner .bar {
  background: #ff4b00;
}

.col-lg-3:nth-child(4) .stats-card-box .icon-box {
  background-color: #4788ff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(71, 136, 255, 0.15);
  box-shadow: 1px 5px 24px 0 rgba(71, 136, 255, 0.15);
}

.col-lg-3:nth-child(4) .stats-card-box .progress-list .bar-inner .bar {
  background: #4788ff;
}

.ecommerce-stats-area {
  margin-bottom: 30px;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  background-color: #ffffff;
  border-radius: 5px;
  padding: 30px 25px;
}

.ecommerce-stats-area .row {
  margin-left: 0;
  margin-right: 0;
}

.ecommerce-stats-area .row .col-lg-3 {
  padding-left: 0;
  padding-right: 0;
}

.ecommerce-stats-area .row .col-lg-3:nth-child(2) .single-stats-card-box .icon {
  background-color: #13bb37;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(19, 187, 55, 0.15);
  box-shadow: 1px 5px 24px 0 rgba(19, 187, 55, 0.15);
}

.ecommerce-stats-area .row .col-lg-3:nth-child(3) .single-stats-card-box .icon {
  background-color: #ff4b00;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(255, 75, 0, 0.15);
  box-shadow: 1px 5px 24px 0 rgba(255, 75, 0, 0.15);
}

.ecommerce-stats-area .row .col-lg-3:nth-child(4) .single-stats-card-box {
  border-right: none;
}

.ecommerce-stats-area .row .col-lg-3:nth-child(4) .single-stats-card-box .icon {
  background-color: #4788ff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(71, 136, 255, 0.15);
  box-shadow: 1px 5px 24px 0 rgba(71, 136, 255, 0.15);
}

.single-stats-card-box {
  padding-left: 90px;
  position: relative;
}

.single-stats-card-box .icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #382a1f;
  position: absolute;
  left: 0;
  top: 46%;
  -webkit-transform: translateY(-46%);
  transform: translateY(-46%);
  -webkit-box-shadow: 1px 5px 24px 0 rgba(56, 42, 31, 0.15);
  box-shadow: 1px 5px 24px 0 rgba(56, 42, 31, 0.15);
  text-align: center;
  font-size: 38px;
  color: #ffffff;
}

.single-stats-card-box .icon i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.single-stats-card-box .icon i.bx-paper-plane {
  top: 45%;
  -webkit-transform: translateY(-45%);
  transform: translateY(-45%);
  left: -3px;
}

.single-stats-card-box .sub-title {
  color: #a3a3a3;
  display: block;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 400;
}

.single-stats-card-box h3 {
  margin-bottom: 0;
  color: #382a1f;
  position: relative;
  font-size: 30px;
  font-weight: 700;
}

.single-stats-card-box h3 .badge {
  line-height: initial;
  border-radius: 0;
  color: #13bb37;
  background-color: rgba(49, 203, 114, 0.18);
  padding: 2px 4px 1px;
  border-radius: 5px;
  position: relative;
  bottom: 3px;
  font-size: 11px;
  font-weight: 400;
}

.single-stats-card-box h3 .badge.badge-red {
  background-color: rgba(255, 91, 91, 0.18);
  color: #ff4b00;
}

.single-stats-card-box h3 .badge i {
  display: inline-block;
  margin-right: -2px;
}

/*end-stats-box-css*/
/*start-card-box-css*/
.card {
  background-color: #ffffff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  border: none;
  border-radius: 5px;
  padding: 25px;
}

.card .card-header {
  background-color: #ffffff;
  border-radius: 0;
  margin-bottom: 25px;
  padding: 0;
  border: none;
}

.card .card-header h3 {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
}

.card .card-header .dropdown .dropdown-toggle {
  padding: 0;
  border: none;
  background-color: transparent;
  color: #727e8c;
  font-size: 22px;
  line-height: 15px;
}

.card .card-header .dropdown .dropdown-toggle::after {
  display: none;
}

.card .card-header .dropdown .dropdown-menu {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  border: none;
  border-radius: 0;
  margin-top: 35px;
  display: block;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: unset !important;
  transform: unset !important;
  left: auto !important;
  right: 0;
}

.card .card-header .dropdown .dropdown-menu.show {
  margin-top: 25px;
  opacity: 1;
  visibility: visible;
}

.card .card-header .dropdown .dropdown-menu .dropdown-item,
.tbdrp a.dropdown-item {
  color: #2a2a2a;
  padding: 5px 15px 5px 36px;
  position: relative;
  font-size: 14px;
}

.card .card-header .dropdown .dropdown-menu .dropdown-item i,
.tbdrp a.dropdown-item i {
  color: #686868;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: absolute;
  left: 15px;
  top: 7px;
  font-size: 16px;
}

.card .card-header .dropdown .dropdown-menu .dropdown-item.active,
.card .card-header .dropdown .dropdown-menu .dropdown-item:active {
  color: #2a2a2a;
  background-color: transparent;
  background-color: #f8f9fa;
}

.card .card-header .dropdown .dropdown-menu .dropdown-item:hover i {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.card .card-body {
  padding: 0;
}

.card .card-body .youtube-video iframe {
  width: 100%;
  border: none;
  height: 435px;
}

.card .card-body.border-primary {
  border-width: 1px;
  border-style: solid;
}

.card .card-body.border-secondary {
  border-width: 1px;
  border-style: solid;
}

.card .card-body.border-success {
  border-width: 1px;
  border-style: solid;
}

.card .card-body.border-danger {
  border-width: 1px;
  border-style: solid;
}

.card .card-body.border-warning {
  border-width: 1px;
  border-style: solid;
}

.card .card-body.border-info {
  border-width: 1px;
  border-style: solid;
}

.card .card-body.border-light {
  border-width: 1px;
  border-style: solid;
}

.card .card-body.border-dark {
  border-width: 1px;
  border-style: solid;
}

.activity-card-box {
  margin-top: -20px;
}

.activity-card-box .progress-list {
  margin-top: 20px;
}

.activity-card-box .progress-list span {
  display: inline-block;
  color: #919191;
  font-size: 14px;
  font-weight: 400;
}

.activity-card-box .progress-list .bar-inner {
  margin-top: 8px;
  position: relative;
  width: 100%;
  border-radius: 0 5px 5px 0;
  border-bottom: 5px solid #f2f4f4;
}

.activity-card-box .progress-list .bar-inner .bar {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 5px;
  background: #382a1f;
  border-radius: 0 5px 5px 0;
  -webkit-transition: all 2000ms ease;
  transition: all 2000ms ease;
  -webkit-box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
  box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
}

.activity-card-box .row .col-lg-6:nth-child(2) .progress-list .bar-inner .bar {
  background-color: #33a73b;
  -webkit-box-shadow: 0 2px 6px 0 rgba(57, 218, 138, 0.6);
  box-shadow: 0 2px 6px 0 rgba(57, 218, 138, 0.6);
}

.activity-card-box .row .col-lg-6:nth-child(3) .progress-list .bar-inner .bar {
  background-color: #5f7be7;
  -webkit-box-shadow: 0 2px 6px 0 rgba(95, 123, 231, 0.6);
  box-shadow: 0 2px 6px 0 rgba(95, 123, 231, 0.6);
}

.activity-card-box .row .col-lg-6:nth-child(4) .progress-list .bar-inner .bar {
  background-color: #ff5b5c;
  -webkit-box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
  box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
}

.activity-timeline-content ul {
  padding-left: 0;
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 0;
  margin-top: -20px;
}

.activity-timeline-content ul li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  margin-top: 20px;
  font-size: 15px;
  color: #2a2a2a;
  padding-left: 55px;
  padding-right: 10px;
}

.activity-timeline-content ul li::before {
  position: absolute;
  left: 25px;
  content: "";
  bottom: 0;
  width: 1px;
  height: 150%;
  background-color: #eeeeee;
}

.activity-timeline-content ul li i {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-color: #382a1f;
  border-radius: 50%;
  color: #ffffff;
  text-align: center;
  font-size: 22px;
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.activity-timeline-content ul li span {
  display: block;
  font-size: 14px;
  color: #a3a3a3;
  margin-bottom: 2px;
}

.activity-timeline-content ul li:nth-child(1)::before {
  height: 100%;
}

.activity-timeline-content ul li:nth-child(2)::before {
  height: 100%;
}

.activity-timeline-content ul li:nth-child(2) i {
  background-color: #13bb37;
}

.activity-timeline-content ul li:nth-child(3) i {
  background-color: #4788ff;
}

.activity-timeline-content ul li:nth-child(4) i {
  background-color: #ffc107;
}

.best-sales-box ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.best-sales-box ul li {
  color: #9d9d9d;
  font-size: 14px;
  margin-bottom: 4px;
}

.best-sales-box ul li:last-child {
  margin-bottom: 0;
}

.best-sales-box ul li span {
  display: inline-block;
  min-width: 20px;
  margin-right: 3px;
}

.best-sales-box ul li .bar-inner {
  position: relative;
  width: 100%;
  border-radius: 0 5px 5px 0;
  border-bottom: 5px solid #f2f4f4;
}

.best-sales-box ul li .bar-inner .bar {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 5px;
  background: #382a1f;
  border-radius: 0 5px 5px 0;
  -webkit-transition: all 2000ms ease;
  transition: all 2000ms ease;
  -webkit-box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
  box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
}

.browser-used-box {
  margin-left: -25px;
  margin-right: -25px;
}

.browser-used-box table {
  margin-bottom: 0;
}

.browser-used-box table thead th {
  vertical-align: middle;
  white-space: nowrap;
  border-bottom: 1px solid #eeeeee;
  border-top: 1px solid #eeeeee;
  color: #2a2a2a;
  font-size: 15px;
  font-weight: 500;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.browser-used-box table thead th:first-child {
  padding-left: 25px;
}

.browser-used-box table thead th:last-child {
  padding-right: 25px;
}

.browser-used-box table tbody td {
  vertical-align: bottom;
  border: none;
  white-space: nowrap;
  color: #2a2a2a;
  font-size: 14px;
  font-weight: 400;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 12px;
  padding-bottom: 0;
}

.browser-used-box table tbody td:first-child {
  padding-left: 25px;
}

.browser-used-box table tbody td:last-child {
  padding-right: 25px;
}

.browser-used-box table tbody td i {
  color: #13bb37;
  display: inline-block;
  margin-right: -2px;
}

.browser-used-box table tbody td i.red {
  color: #ff4b00;
}

.browser-statistics-box .box {
  padding-bottom: 15px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 15px;
}

.browser-statistics-box .box:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.browser-statistics-box .box:first-child {
  margin-top: 7px;
}

.browser-statistics-box .box .img {
  background-color: #f3f3f3;
  border-radius: 50%;
  text-align: center;
  width: 45px;
  height: 40px;
  line-height: 37px;
}

.browser-statistics-box .box .content {
  margin-left: 15px;
  width: 100%;
}

.browser-statistics-box .box .content h5 {
  color: #2a2a2a;
  font-size: 15px;
  font-weight: 600;
}

.browser-statistics-box .box .content .bar-inner {
  position: relative;
  width: 100%;
  border-bottom: 3px solid #d1d1d1;
  border-radius: 5px;
}

.browser-statistics-box .box .content .bar-inner .bar {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 3px;
  background: #382a1f;
  -webkit-transition: all 2000ms ease;
  transition: all 2000ms ease;
  -webkit-box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
  box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
  border-radius: 5px;
}

.browser-statistics-box .box .content .count-box {
  margin-left: 15px;
  color: #2a2a2a;
}

.browser-statistics-box .box:nth-child(2) .content .bar-inner .bar {
  background-color: #33a73b;
  -webkit-box-shadow: 0 2px 6px 0 rgba(57, 218, 138, 0.6);
  box-shadow: 0 2px 6px 0 rgba(57, 218, 138, 0.6);
}

.browser-statistics-box .box:nth-child(3) .content .bar-inner .bar {
  background-color: #5f7be7;
  -webkit-box-shadow: 0 2px 6px 0 rgba(95, 123, 231, 0.6);
  box-shadow: 0 2px 6px 0 rgba(95, 123, 231, 0.6);
}

.browser-statistics-box .box:nth-child(4) .content .bar-inner .bar {
  background-color: #ff5b5c;
  -webkit-box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
  box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
}

.browser-statistics-box .box:nth-child(5) .content .bar-inner .bar {
  background-color: #33a73b;
  -webkit-box-shadow: 0 2px 6px 0 rgba(57, 218, 138, 0.6);
  box-shadow: 0 2px 6px 0 rgba(57, 218, 138, 0.6);
}

.browser-statistics-box .box:nth-child(7) .content .bar-inner .bar {
  background-color: #5f7be7;
  -webkit-box-shadow: 0 2px 6px 0 rgba(95, 123, 231, 0.6);
  box-shadow: 0 2px 6px 0 rgba(95, 123, 231, 0.6);
}

.browser-statistics-box .box:nth-child(8) .content .bar-inner .bar {
  background-color: #ff5b5c;
  -webkit-box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
  box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
}

.new-product-box {
  border-radius: 5px;
  margin-bottom: 25px;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  background-color: #ffffff;
  padding: 21.5px 15px;
  text-align: center;
  color: #828d99;
  font-size: 16px;
  font-weight: 400;
}

.new-product-box .icon {
  width: 45px;
  height: 45px;
  background-color: #fbd8d7;
  color: #382a1f;
  border-radius: 50%;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 22px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.new-product-box .icon i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
}

.new-product-box .sub-text {
  color: #2a2a2a;
  margin-top: 3px;
  font-size: 17px;
}

.new-product-box:hover .icon {
  background-color: #382a1f;
  color: #ffffff;
}

.new-user-box {
  margin-bottom: 25px;
  border-radius: 5px;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  background-color: #ffffff;
  padding: 21.5px 15px;
  text-align: center;
  color: #828d99;
  font-size: 16px;
  font-weight: 400;
}

.new-user-box .icon {
  width: 45px;
  height: 45px;
  background-color: #d0f1d7;
  color: #13bb37;
  border-radius: 50%;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 22px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.new-user-box .icon i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
}

.new-user-box .sub-text {
  color: #2a2a2a;
  margin-top: 3px;
  font-size: 17px;
}

.new-user-box:hover .icon {
  background-color: #13bb37;
  color: #ffffff;
}

.upcoming-product-box {
  border-radius: 5px;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  background-color: #ffffff;
  padding: 21.5px 15px;
  text-align: center;
  color: #828d99;
  font-size: 16px;
  font-weight: 400;
}

.upcoming-product-box .icon {
  width: 45px;
  height: 45px;
  background-color: #dbe5f7;
  color: #4788ff;
  border-radius: 50%;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 22px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.upcoming-product-box .icon i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
}

.upcoming-product-box .sub-text {
  color: #2a2a2a;
  margin-top: 3px;
  font-size: 17px;
}

.upcoming-product-box:hover .icon {
  background-color: #4788ff;
  color: #ffffff;
}

.bd-t {
  border-top: 1px solid #eeeeee !important;
  margin-top: 15px !important;
  padding-top: 18px !important;
}

.widget-todo-list {
  border-top: 1px solid #eeeeee;
  padding-top: 18px !important;
  margin-left: -25px;
  margin-right: -25px;
  margin-bottom: -10px;
  margin-top: -10px;
}

.widget-todo-list ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.widget-todo-list ul li {
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding-left: 60px;
  padding-right: 110px;
  padding-top: 9px;
  padding-bottom: 10px;
}

.widget-todo-list ul li .checkbox {
  position: absolute;
  left: 25px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.widget-todo-list ul li .todo-item-title {
  position: relative;
  padding-left: 53px;
}

.widget-todo-list ul li .todo-item-title img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.widget-todo-list ul li .todo-item-title h3 {
  margin-bottom: 2px;
  font-size: 16px;
  font-weight: 600;
}

.widget-todo-list ul li .todo-item-title p {
  color: #919191;
  font-size: 15px;
  margin-bottom: 0;
}

.widget-todo-list ul li .todo-item-action {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 25px;
}

.widget-todo-list ul li .todo-item-action a {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 32px;
  border-radius: 3px;
  background-color: #c6e8cd;
  color: #13bb37;
  text-align: center;
  margin-left: 3px;
}

.widget-todo-list ul li .todo-item-action a:hover {
  background-color: #13bb37;
  color: #ffffff;
}

.widget-todo-list ul li .todo-item-action a.delete {
  background-color: #f9d0d9 !important;
  color: #382a1f !important;
}

.widget-todo-list ul li .todo-item-action a.delete:hover {
  background-color: #382a1f !important;
  color: #ffffff !important;
}

.widget-todo-list ul li:hover {
  background-color: #f2f4f4;
}

.widget-todo-list ul li:nth-child(2) .todo-item-action a {
  background-color: #d1def5;
  color: #4788ff;
}

.widget-todo-list ul li:nth-child(2) .todo-item-action a:hover {
  background-color: #4788ff;
  color: #ffffff;
}

.widget-todo-list ul li:nth-child(3) .todo-item-action a {
  background-color: #f5d1c4;
  color: #fb4a06;
}

.widget-todo-list ul li:nth-child(3) .todo-item-action a:hover {
  background-color: #fb4a06;
  color: #ffffff;
}

.widget-todo-list ul li:nth-child(4) .todo-item-action a {
  background-color: #d1def5;
  color: #4788ff;
}

.widget-todo-list ul li:nth-child(4) .todo-item-action a:hover {
  background-color: #4788ff;
  color: #ffffff;
}

.cbx {
  margin: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.cbx span {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.cbx span:first-child {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  -webkit-transform: scale(1);
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid #dfe3e7;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.cbx span:first-child::before {
  content: "";
  width: 100%;
  height: 100%;
  background: #382a1f;
  display: block;
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
}

.cbx span:first-child:hover span:first-child {
  border-color: #382a1f;
}

.inp-cbx:checked + .cbx span:first-child {
  background: #382a1f;
  border-color: #382a1f;
  -webkit-animation: wave 0.4s ease;
  animation: wave 0.4s ease;
}

.inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}

.inp-cbx:checked + .cbx span:first-child:before {
  -webkit-transform: scale(3.5);
  transform: scale(3.5);
  opacity: 0;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

.inp-cbx + .cbx.active span:first-child {
  background: #382a1f;
  border-color: #382a1f;
  -webkit-animation: wave 0.4s ease;
  animation: wave 0.4s ease;
}

.inp-cbx + .cbx.active span:first-child svg {
  stroke-dashoffset: 0;
}

.inp-cbx + .cbx.active span:first-child:before {
  -webkit-transform: scale(3.5);
  transform: scale(3.5);
  opacity: 0;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

@-webkit-keyframes wave {
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes wave {
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

.recent-orders-box table {
  margin-bottom: 0;
}

.recent-orders-box table thead th {
  text-align: center;
  white-space: nowrap;
  padding: 0 15px 15px;
  border-bottom: 2px solid #f6f6f7;
  border-top: none;
  font-size: 15px;
}

.recent-orders-box table thead th:first-child {
  text-align: left;
  padding-left: 0;
}

.recent-orders-box table tbody tr {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.recent-orders-box table tbody tr td {
  text-align: center;
  padding: 15px;
  vertical-align: middle;
  white-space: nowrap;
  border-bottom: 1px solid #f6f6f7;
  border-top: none;
  font-size: 14.4px;
}

.recent-orders-box table tbody tr td:first-child {
  text-align: left;
  padding-left: 0;
}

/* .recent-orders-box table tbody tr td .badge,
.card-table tbody tr td .badge {
  padding: 0;
  background-color: transparent !important;
  color: #2a2a2a;
  font-weight: 600;
  font-size: 14.4px;
} */
.recent-orders-box table tbody tr td .badge.badge-danger,
.card-table tbody tr td .badge.badge-danger {
  background-color: #8d8787;
}

.recent-orders-box table tbody tr td .badge.badge-success,
.card-table tbody tr td .badge.badge-success {
  background-color: #382a1f;
}

/* .recent-orders-box table tbody tr td .badge.badge-primary,
.card-table tbody tr td .badge.badge-primary {
  color: #13bb37;
}





.recent-orders-box table tbody tr td .badge.badge-info,
.card-table tbody tr td .badge.badge-info {
  color: #294f50;
}

.recent-orders-box table tbody tr td .badge.badge-warning,
.card-table tbody tr td .badge.badge-warning {
  color: orangered;
} */
.editIcon {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.card-table tr:hover .editIcon {
  opacity: 1;
  visibility: visible;
}

.recent-orders-box table tbody tr td img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 5px;
}

.recent-orders-box table tbody tr td.name {
  font-weight: 700;
}

.recent-orders-box table tbody tr:last-child td {
  padding-bottom: 0;
  border-bottom: none;
}

.new-customer-box table {
  margin-bottom: 0;
}

.new-customer-box table thead th {
  text-align: center;
  border-bottom: 2px solid #f6f6f7;
  border-top: none;
  padding: 0 15px 14px;
  font-size: 15px;
}

.new-customer-box table thead th:first-child {
  text-align: left;
  padding-left: 0;
}

.new-customer-box table tbody tr {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.new-customer-box table tbody tr td {
  text-align: center;
  padding: 13px 15px;
  vertical-align: middle;
  border-bottom: 1px solid #f6f6f7;
  border-top: none;
  white-space: nowrap;
  font-size: 14.4px;
}

.new-customer-box table tbody tr td:first-child {
  text-align: left;
  padding-left: 0;
}

.new-customer-box table tbody tr td img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 5px;
}

.new-customer-box table tbody tr td .text-success {
  font-size: 16px;
}

.new-customer-box table tbody tr td .text-danger {
  font-size: 16px;
}

.new-customer-box table tbody tr td .text-warning {
  font-size: 17px;
}

.new-customer-box table tbody tr td.name {
  font-weight: 700;
}

.new-customer-box table tbody tr:last-child td {
  padding-bottom: 0;
  border-bottom: none;
}

.revenue-summary-content p {
  font-size: 14px;
}

.revenue-summary-content h5 {
  font-size: 20px;
}

.revenue-summary-content h5 .primary-text {
  color: #13bb37;
  display: inline-block;
  margin-left: 1px;
  position: relative;
  bottom: 1px;
  font-size: 12px;
}

.revenue-summary-content h5 .danger-text {
  color: #ff4b00;
  display: inline-block;
  margin-left: 1px;
  position: relative;
  bottom: 1px;
  font-size: 12px;
}

.greetings-card-box .image {
  margin-right: 20px;
  margin-top: 60px;
}

.greetings-card-box .content {
  margin-left: 10px;
  text-align: center;
}

.greetings-card-box .content .icon {
  text-align: center;
  width: 65px;
  height: 65px;
  position: relative;
  border-radius: 50%;
  background-color: #fcdedf;
  color: #382a1f;
  font-size: 35px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-left: auto;
  margin-right: auto;
}

.greetings-card-box .content .icon i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
}

.greetings-card-box .content h3 {
  margin-bottom: 10px;
  font-size: 25px;
  font-weight: 700;
}

.greetings-card-box .content p {
  color: #727e8c;
}

.greetings-card-box:hover .content .icon {
  background-color: #382a1f;
  color: #fcdedf;
}

.social-marketing-box .list {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 12.5px;
  padding-bottom: 12.5px;
  padding-left: 50px;
}

.social-marketing-box .list:last-child {
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0;
}

.social-marketing-box .list .icon {
  width: 38px;
  height: 40px;
  position: absolute;
  border-radius: 3px;
  left: 0;
  top: 1px;
  font-size: 23px;
  text-align: center;
  color: #ffffff;
}

.social-marketing-box .list .icon i {
  position: absolute;
  left: 0;
  right: 0;
  top: 45%;
  -webkit-transform: translateY(-45%);
  transform: translateY(-45%);
}

.social-marketing-box .list .icon.facebook {
  background-color: #4267b2;
}

.social-marketing-box .list .icon.twitter {
  background-color: #1da1f2;
}

.social-marketing-box .list .icon.instagram {
  background-color: #a1755c;
}

.social-marketing-box .list .icon.linkedin {
  background-color: #0077b5;
}

.social-marketing-box .list .icon.youtube {
  background-color: #ff0000;
}

.social-marketing-box .list .icon.github {
  background-color: #2962ff;
}

.social-marketing-box .list h4 {
  font-size: 15px;
  font-weight: 600;
}

.social-marketing-box .list h4 a {
  color: #2a2a2a;
  text-decoration: none;
}

.social-marketing-box .list h4 a:hover {
  color: #382a1f;
}

.social-marketing-box .list p {
  color: #828d99;
}

.social-marketing-box .list .stats {
  position: absolute;
  right: 0;
  top: 14px;
  font-size: 14px;
}

.top-rated-product-box ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.top-rated-product-box ul .single-product {
  position: relative;
  border-bottom: 1px solid #f6f6f7;
  margin-bottom: 15px;
  padding-left: 70px;
  padding-bottom: 15px;
  padding-right: 45px;
}

.top-rated-product-box ul .single-product .image {
  position: absolute;
  left: 0;
  top: 0;
}

.top-rated-product-box ul .single-product .image img {
  border: 1px solid #f3f3f3;
  width: 55px;
  height: 55px;
}

.top-rated-product-box ul .single-product h4 {
  font-size: 15px;
  font-weight: 700;
}

.top-rated-product-box ul .single-product h4 a {
  color: #2a2a2a;
  text-decoration: none;
}

.top-rated-product-box ul .single-product h4 a:hover {
  color: #382a1f;
}

.top-rated-product-box ul .single-product p {
  color: #686c71;
}

.top-rated-product-box ul .single-product .price {
  color: #382a1f;
}

.top-rated-product-box ul .single-product .rating i {
  color: #ffce00;
  font-size: 14px;
}

.top-rated-product-box ul .single-product .view-link {
  position: absolute;
  top: 20px;
  right: 0;
  border: 1px solid #382a1f;
  color: #382a1f;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 31px;
  border-radius: 50%;
  font-size: 15px;
}

.top-rated-product-box ul .single-product .view-link:hover {
  background-color: #382a1f;
  color: #ffffff;
}

.top-rated-product-box ul .single-product:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.alert .alert-link:hover {
  text-decoration: underline;
}

.demo-code-preview {
  border: 1px solid #ebebeb;
  padding: 20px 25px 15px;
  margin-top: 2rem;
  position: relative;
  background-color: #ffffff;
  border-radius: 5px;
}

.demo-code-preview::before {
  content: attr(data-label);
  display: block;
  position: absolute;
  top: -9px;
  left: 20px;
  letter-spacing: 1px;
  background-color: #ffffff;
  font-size: 11px;
  padding: 0 5px;
  color: #382a1f;
}

.demo-code-preview .highlight .btn.btn-clipboard {
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 0;
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
}

.demo-code-preview .highlight pre {
  margin-bottom: 0;
  font-family: "Petrona", serif;
}

.demo-code-preview .highlight pre .c {
  color: #999;
}

.demo-code-preview .highlight pre .nt {
  color: #905;
}

.demo-code-preview .highlight pre .na {
  color: #690;
}

.demo-code-preview .highlight pre .s {
  color: #07a;
}

.badge-card-box .card-header {
  margin-bottom: 17px;
}

.button-card-box .card-header {
  margin-bottom: 17px;
}

.button-group-card-box .card-header {
  margin-bottom: 17px;
}

.dropdowns-card-box .card-header {
  margin-bottom: 17px;
}

.popovers-card-box .card-header {
  margin-bottom: 17px;
}

.tooltips-card-box .card-header {
  margin-bottom: 17px;
}

.spinners-card-box .card-header {
  margin-bottom: 17px;
}

.collapse-card-box .card-header {
  margin-bottom: 17px;
}

.accordion-box .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.accordion-box .accordion .accordion-item {
  display: block;
  background: #f5f5ff;
  margin-bottom: 10px;
}

.accordion-box .accordion .accordion-item:last-child {
  margin-bottom: 0;
}

.accordion-box .accordion .accordion-title,
.accordionTitle {
  padding: 12px 40px 10px 20px;
  color: #2a2a2a;
  position: relative;
  text-decoration: none;
  display: block;
  font-size: 15px;
  font-weight: 600;
}

.support_section .card .card-header {
  position: relative;
  border-bottom: 1px solid #f1f1f1;
  padding: 8px 0;
}

.accordionTitle {
  padding: 0px 0px 0px 50px;
  position: static;
}

.accordion-title {
  cursor: pointer;
}

.accordion-box .accordion .accordion-title i,
.accordionTitle i {
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 20px;
}

.accordionTitle i {
  right: auto;
  left: 0;
  width: 40px;
  height: 100%;
  background-color: #382a1f;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accordion-box .accordion .accordion-title.active {
  background-color: #382a1f;
  color: #ffffff;
}

.accordion-box .accordion .accordion-title.active i::before {
  content: "\eaf8";
}

.accordionTitle[aria-expanded="true"] i::before {
  content: "\f068";
}

.supportAction {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.accordionHead:hover .supportAction,
.accordionTitle[aria-expanded="true"] ~ .supportAction {
  opacity: 1;
  visibility: visible;
}

.accordion-box .accordion .accordion-title:hover {
  background-color: #382a1f;
  color: #ffffff;
}

.accordion-box .accordion .accordion-content {
  display: none;
  position: relative;
  padding: 15px;
  font-size: 15px;
  color: #1f1b1b;
  line-height: 1.8;
  background-color: #fafafa;
}

.accordion-box .accordion .accordion-content.show {
  display: block;
}

.boxicons-list .icon-box {
  margin-bottom: 30px;
  -ms-flex: 0 0 25%;
  -webkit-box-flex: 0;
  flex: 0 0 25%;
  max-width: 25%;
  outline: 0;
  padding-left: 15px;
  padding-right: 15px;
}

.boxicons-list .icon-box .icon-box-inner {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 20px 25px;
  outline: 0;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
}

.boxicons-list .icon-box .icon-box-inner .icon-base {
  display: inline-block;
  margin-right: 5px;
  font-size: 25px;
  position: relative;
  top: 5px;
  color: #2a2a2a;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.boxicons-list .icon-box .icon-box-inner .icon-box-name {
  color: #6084a4;
  display: inline-block;
}

.boxicons-list .icon-box .icon-box-inner:hover .icon-base {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.feather-icons-list .icon-box {
  margin-bottom: 30px;
  -ms-flex: 0 0 25%;
  -webkit-box-flex: 0;
  flex: 0 0 25%;
  max-width: 25%;
  outline: 0;
  padding-left: 15px;
  padding-right: 15px;
}

.feather-icons-list .icon-box .icon-box-inner {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 20px 25px;
  outline: 0;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
}

.feather-icons-list .icon-box .icon-box-inner .icon-base {
  display: inline-block;
  margin-right: 5px;
  color: #2a2a2a;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.feather-icons-list .icon-box .icon-box-inner .icon-box-name {
  color: #6084a4;
  display: inline-block;
  position: relative;
  top: 1.5px;
}

.feather-icons-list .icon-box .icon-box-inner:hover .icon-base {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.grid-bg-example .row {
  margin-left: 0;
  margin-right: 0;
}

.grid-bg-example .row + .row {
  margin-top: 1rem;
}

.grid-bg-example .row > .col,
.grid-bg-example .row > [class^="col-"] {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.2);
}

.bd-example-row-flex-cols .row {
  min-height: 10rem;
  background-color: rgba(255, 0, 0, 0.1);
  margin-top: 1rem;
}

.bd-example-row-flex-cols .row:first-child {
  margin-top: 0;
}

.bd-example-border-utils [class^="border"] {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  margin: 0.25rem;
  background-color: #f5f5f5;
}

.bd-example-border-utils.bd-example-border-utils-0 [class^="border"] {
  border: 1px solid #dee2e6;
}

#map {
  height: 500px;
  width: 100%;
}

/*end-card-box-css*/

/*start-all-chart-style-css*/
#website-analytics-chart.extra-margin {
  margin: -20px 0 -15px -20px;
}

#website-analytics-chart .apexcharts-toolbar {
  display: none;
}

#website-analytics-chart .apexcharts-legend {
  bottom: 0 !important;
}

#emailSend-chart.extra-margin {
  margin: -15px 0 -15px 0;
}

#activity-timeline-chart .apexcharts-legend-series {
  margin-bottom: 6px !important;
}

#activity-timeline-chart .apexcharts-legend-series:last-child {
  margin-bottom: 0 !important;
}

#activity-timeline-chart .apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 5px;
}

#traffic-source-chart.extra-margin {
  margin: -20px 0 -25px -20px;
}

#revenue-growth-chart.extra-margin {
  margin: -20px 0 -25px -20px;
}

#revenue-growth-chart.extra-margin .apexcharts-toolbar {
  display: none;
}

#revenue-summary-chart.extra-margin {
  margin: 0 0 -15px -20px;
}

#revenue-summary-chart.extra-margin .apexcharts-toolbar {
  display: none;
}

#revenue-summary-chart .apexcharts-legend {
  bottom: 0 !important;
}

#client-recollection-chart.extra-margin {
  margin: -30px 0 -15px -20px;
}

#client-recollection-chart .apexcharts-toolbar {
  display: none;
}

#client-recollection-chart .apexcharts-legend {
  bottom: 0 !important;
}

#apex-chart-datetime-x-axis .toolbar {
  margin-left: 20px;
}

#apex-chart-datetime-x-axis button {
  background: #ffffff;
  color: #222222;
  border: 1px solid #e7e7e7;
  border-bottom: 2px solid #dddddd;
  border-radius: 2px;
  padding: 4px 17px;
}

#apex-chart-datetime-x-axis button.active {
  color: #ffffff;
  background: #008ffb;
  border: 1px solid blue;
  border-bottom: 2px solid blue;
}

.chartjs-colors .bg-purple {
  background-color: #c679e3 !important;
}

.chartjs-colors .bg-secondary {
  background-color: #f48665 !important;
}

.chartjs-colors .bg-pink {
  background-color: #ff1494 !important;
}

.chartjs-colors .bg-success-light {
  background-color: rgba(80, 196, 85, 0.3) !important;
}

.chartjs-colors .bg-primary-light {
  background-color: rgba(63, 81, 181, 0.3) !important;
}

/*end-all-chart-style-css*/
/*start-all-maps-style-css*/
#world-map-markers {
  height: 398px;
}

/*end-all-maps-style-css*/

/*================================================
Profile CSS
=================================================*/
.bg-grey {
  /* background-color: red; */
  padding: 100px 0 0 0;
}

.profile-header {
  margin-top: 20px;
  border-radius: 5px;
  position: relative;
}

.profile-header .user-profile-images {
  position: relative;
  bottom: -55px;
  left: 20px;
  background-color: #d4d1d1;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.profile-header .user-profile-images.projectbanner {
  top: 0;
  left: 0;
  bottom: 0;
}

.profile-header .user-profile-images .cover-image {
  border-radius: 5px;
}

.profile-header .user-profile-images .profile-image img {
  border-radius: 50%;
  width: 170px;
  height: 170px;
  border: 5px solid #ffffff;
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.41);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.41);
}

.profile-header .user-profile-images .profile-image .upload-profile-photo {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 20px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 1;
}

.profile-header .user-profile-images .profile-image .upload-profile-photo a {
  background-color: transparent;
  color: #ffffff;
  text-decoration: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-align: center;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
}

.profile-header .user-profile-images .profile-image .upload-profile-photo a i {
  font-size: 25px;
  color: #ffffff;
  margin-bottom: 2px;
  display: block;
}

.profile-header
  .user-profile-images
  .profile-image:hover
  .upload-profile-photo {
  opacity: 1;
  visibility: visible;
}

.profile-header .user-profile-images .upload-cover-photo {
  display: inline-block;
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 1;
}

.profile-header .user-profile-images .upload-cover-photo a {
  background-color: transparent;
  color: #ffffff;
  text-decoration: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 15px;
  font-weight: 600;
}

.profile-header .user-profile-images .upload-cover-photo a i {
  font-size: 25px;
  color: #ffffff;
  position: relative;
  top: 5px;
  margin-right: 2px;
}

.profile-header .user-profile-images .upload-cover-photo a span {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.profile-header .user-profile-images .user-profile-text {
  position: absolute;
  left: 200px;
  z-index: 1;
  top: 30px;
}

.profile-header .user-profile-images .user-profile-text h3 {
  margin-bottom: 3px;
  color: #2a2a2a;
  font-size: 25px;
  font-weight: 700;
}

.profile-header .user-profile-images .user-profile-text span {
  color: #2a2a2a;
  opacity: 0.9;
  font-size: 15px;
  font-weight: 600;
}

.profile-header .user-profile-images:hover .upload-cover-photo a span {
  opacity: 1;
  visibility: visible;
}

.profile-header .user-profile-nav {
  padding: 25px 25px 25px 220px;
  background-color: #ffffff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
}

.profile-header .user-profile-nav .nav {
  padding-left: 0;
  border: none !important;
  margin-bottom: 0;
  list-style-type: none;
  border-bottom: 1px solid #eee !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.profile-header .user-profile-nav .nav .nav-item {
  margin-right: 10px;
}

.profile-header .user-profile-nav .nav .nav-item .nav-link {
  color: #666;
  border-radius: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-decoration: none;
  /* background-color: #ffffff; */
  /* background-color: #f7f7f7; */
  border: none !important;
  font-size: 16px;
  font-weight: 700;
  /* padding-left: 25px; */
  /* padding-right: 25px; */
  /* padding-top: 12px; */
  /* padding-bottom: 12px; */
  padding: 5px 10px;
  border-bottom: 2px solid transparent !important;
}

/* 
.profile-header .user-profile-nav .nav .nav-item .nav-link:hover {
  border: none !important;
} */

.profile-header .user-profile-nav .nav .nav-item .nav-link.active {
  /* color: #ffffff; */
  /* background-color: #382a1f; */
  /* border: none !important; */
  color: #382a1f;
  border-bottom-color: #382a1f !important;
}

.user-intro-box .card-body {
  text-align: center;
}

.user-intro-box .card-body button {
  display: block;
  width: 100%;
  border-radius: 5px;
  background-color: #f5f6f7;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border: 1px solid #ccd0d5;
  color: #4b4f56;
  margin-top: 10px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}

.user-intro-box .card-body button:hover {
  background-color: #382a1f;
  color: #ffffff;
  border-color: #382a1f;
}

.user-info-box .card-body ul li {
  color: #1d2129;
  margin-bottom: 10px;
}

.user-info-box .card-body ul li:last-child {
  margin-bottom: 0;
}

.user-info-box .card-body ul li span {
  line-height: 1.6;
}

.user-info-box .card-body ul li i {
  font-size: 22px;
  color: #616770;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.user-info-box .card-body ul li a {
  color: #385898;
  text-decoration: none;
}

.user-info-box .card-body ul li:hover i {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  color: #382a1f;
}

.user-info-box .card-body button {
  display: block;
  width: 100%;
  border-radius: 5px;
  background-color: #f5f6f7;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border: 1px solid #ccd0d5;
  color: #4b4f56;
  margin-top: 15px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}

.user-info-box .card-body button:hover {
  background-color: #382a1f;
  color: #ffffff;
  border-color: #382a1f;
}

.user-photos-box .card-header .photo-upload-btn {
  display: inline-block;
  border: 1px solid #afb8bd;
  color: #2a2a2a;
  border-radius: 5px;
  background-color: transparent;
  text-decoration: none;
  padding: 3px 12px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 14px;
  font-weight: 600;
}

.user-photos-box .card-header .photo-upload-btn:hover {
  background-color: #382a1f;
  color: #ffffff;
  border-color: #382a1f;
}

.user-photos-box .card-body ul {
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -10px;
}

.user-photos-box .card-body ul li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
}

.user-photos-box .card-body ul li a {
  display: block;
}

.user-skills-box .card-header .create-btn {
  display: inline-block;
  border: none;
  color: #385898;
  background-color: transparent;
  text-decoration: none;
  padding: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 14px;
  font-weight: 600;
}

.user-skills-box .card-header .create-btn:hover {
  text-decoration: underline;
}

.user-skills-box .card-body {
  margin-bottom: -3px;
}

.user-skills-box .card-body .badge {
  background-color: #fde8e8;
  padding: 5px 8px 4px;
  color: #382a1f;
  cursor: pointer;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-bottom: 3px;
  margin-right: 1px;
}

.user-skills-box .card-body .badge:hover {
  color: #ffffff;
  background-color: #382a1f;
}

.user-social-box .card-header .add-btn {
  display: inline-block;
  border: none;
  color: #385898;
  background-color: transparent;
  text-decoration: none;
  padding: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 14px;
  font-weight: 600;
}

.user-social-box .card-header .add-btn:hover {
  text-decoration: underline;
}

.user-social-box .card-body {
  margin-bottom: -4px;
}

.user-social-box .card-body a {
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  line-height: 40px;
  background-color: #382a1f;
  color: #ffffff;
  font-size: 20px;
  margin-right: 1px;
  margin-bottom: 4px;
  text-align: center;
  text-decoration: none;
}

.user-social-box .card-body a.facebook {
  background-color: #3b5998;
  color: #ffffff;
}

.user-social-box .card-body a.twitter {
  background-color: #1da1f2;
  color: #ffffff;
}

.user-social-box .card-body a.instagram {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  color: #ffffff;
}

.user-social-box .card-body a.linkedin {
  background-color: #0077b5;
  color: #ffffff;
}

.user-social-box .card-body a.pinterest {
  background-color: #bd081c;
  color: #ffffff;
}

.user-social-box .card-body a.stack-overflow {
  background-color: #f48024;
  color: #ffffff;
}

.timeline-story-content .timeline-story-header .info h6 {
  color: #616770;
  margin-bottom: 3px;
}

.timeline-story-content .timeline-story-header .info h6 a {
  display: inline-block;
  color: #2a2a2a;
  font-weight: 700;
}

.timeline-story-content .timeline-story-header .info span {
  font-size: 14px;
  color: #616770;
}

.timeline-story-content .card-body p {
  color: #727e8c;
}

.timeline-story-content .card-body iframe {
  width: 100%;
  height: 400px;
  border: none;
}

.timeline-story-content .feedback-summary a {
  text-decoration: none;
  color: #686c71;
  margin-right: 15px;
  padding-right: 10px;
  border-right: 1px solid #eeeeee;
}

.timeline-story-content .feedback-summary a i {
  font-size: 20px;
  position: relative;
  top: 3px;
  margin-right: 3px;
}

.timeline-story-content .feedback-summary a:hover {
  color: #382a1f;
}

.timeline-story-content .feedback-summary a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

.user-events-box .card-body ul {
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -10px;
}

.user-events-box .card-body ul li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
}

.user-events-box .card-body ul li a {
  color: #616770;
  display: block;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
  padding: 25px 10px;
  text-align: center;
  text-decoration: none;
}

.user-events-box .card-body ul li a i {
  margin-bottom: 10px;
  background: #382a1f;
  display: inline-block;
  color: #ffffff;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  line-height: 35px;
  font-size: 20px;
}

.user-events-box .card-body ul li a span {
  display: block;
  color: #2a2a2a;
  margin-bottom: 2px;
  font-size: 15px;
  font-weight: 700;
}

.user-events-box .card-body ul li a:hover {
  border-color: #382a1f;
}

.user-friend-request-box .card-body ul li {
  margin-bottom: 20px;
}

.user-friend-request-box .card-body ul li:last-child {
  margin-bottom: 0;
}

.user-friend-request-box .card-body ul li h6 {
  margin-bottom: 2px;
  font-size: 15px;
  font-weight: 700;
}

.user-friend-request-box .card-body ul li h6 a {
  color: #2a2a2a;
}

.user-friend-request-box .card-body ul li span {
  color: #616770;
  font-size: 14px;
}

.user-friend-request-box .card-body ul li span a {
  color: #616770;
}

.user-friend-request-box .card-body ul li .btn-box {
  margin-top: 5px;
}

.user-friend-request-box .card-body ul li .btn-box a {
  text-decoration: none;
  border: 1px solid #eeeeee;
  color: #616770;
  font-size: 18px;
  display: inline-block;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  line-height: 30px;
  text-align: center;
}

.user-friend-request-box .card-body ul li .btn-box a:hover {
  color: #ffffff;
  border-color: #382a1f;
  background-color: #382a1f;
}

.user-trends-box .card-body ul li a {
  text-decoration: none;
  color: #616770;
  font-size: 14px;
  display: block;
  border-bottom: 1px solid #eeeeee;
  padding-top: 10px;
  padding-bottom: 10px;
}

.user-trends-box .card-body ul li a span {
  display: block;
  color: #2a2a2a;
  font-size: 16px;
  font-weight: 700;
  margin-top: 2px;
  margin-bottom: 2px;
}

.user-trends-box .card-body ul li:first-child a {
  border-top: 1px solid #eeeeee;
}

.post-share-content {
  background-color: #ffffff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  border-radius: 5px;
  padding: 25px;
}

.post-share-content .post-share-field {
  position: relative;
}

.post-share-content .post-share-field label {
  display: block;
  margin-bottom: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.post-share-content .post-share-field label img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.post-share-content .post-share-field .form-control {
  border: none;
  border-radius: 0;
  padding: 20px 0 0 75px;
}

.post-share-content .post-share-footer {
  border-top: 1px solid #eeeeee;
}

.post-share-content .post-share-footer .post-share-info a {
  font-size: 25px;
  color: #828d99;
  margin-right: 5px;
  line-height: 1;
  text-decoration: none;
}

.post-share-content .post-share-footer .post-share-info a:last-child {
  margin-right: 0;
}

.post-share-content .post-share-footer .post-share-info a:hover {
  color: #382a1f;
}

.post-share-content .post-share-footer .post-btn a {
  color: #2a2a2a;
  text-decoration: none;
  border-radius: 5px;
  padding: 11px 35px 9px;
  background-color: #e2ecff;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
}

.post-share-content .post-share-footer .post-btn a:hover {
  color: #ffffff;
  background-color: #382a1f;
}

.user-about-me-box p {
  color: #727e8c;
  margin-bottom: 10px;
}

.user-biography-box p {
  color: #727e8c;
  margin-bottom: 10px;
}

.user-friends-box {
  padding-bottom: 10px;
}

.memberlist.user-friends-box {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
}

.user-friends-box .single-friends-box {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 15px;
  margin-bottom: 15px;
  width: 100%;
}

.memberlist.user-friends-box .list-inline-item:not(:last-of-type) {
  margin-right: 10px;
}

.memberlist.user-friends-box .single-friends-box {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.user-friends-box .single-friends-box img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.memberlist.user-friends-box .single-friends-box a {
  text-decoration: none !important;
}

.memberlist.user-friends-box .single-friends-box img,
.memberlist.user-friends-box .single-friends-box .u-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  background-color: #f7f7f7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row;
  flex-flow: row;
  font-size: 18px;
}

.memberlist.user-friends-box .single-friends-box .u-icon i {
  font-size: 20px;
}

.visible-t {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
}

.user-friends-box .single-friends-box .info h5 {
  margin-bottom: 2px;
  font-size: 15px;
  font-weight: 700;
}

.user-friends-box .single-friends-box .info h5 a {
  color: #2a2a2a;
}

.user-friends-box .single-friends-box .info span {
  color: #616770;
  font-size: 14px;
}

.user-friends-box .single-friends-box .info span a {
  color: #616770;
}

.user-settings-box h3,
.list_title {
  text-transform: uppercase;
  margin-bottom: 25px;
  font-size: 15px;
  font-weight: 700;
}

.user-settings-box h3 i,
.list_title i {
  width: 35px;
  height: 35px;
  line-height: 36px;
  border-radius: 50%;
  text-align: center;
  background-color: #e2ecff;
  font-size: 20px;
  margin-right: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  top: 2px;
}

.user-settings-box h3:hover i,
.list_title:hover i {
  color: #ffffff;
  background-color: #382a1f;
}

.user-settings-box h3:not(:first-child) {
  margin-top: 10px;
}

.user-settings-box form .form-group {
  margin-bottom: 20px;
}

.user-settings-box form .form-group label {
  color: #6c757d;
  margin-bottom: 8px;
  display: block;
  font-size: 14px;
}

.user-settings-box form .form-group .form-control {
  padding-left: 15px;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 5px;
  color: #2a2a2a;
  font-size: 14px;
  height: 44px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.user-settings-box form .form-group .form-control.error {
  border-color: #e63757;
}

.user-settings-box form .form-group .form-control.in-append {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.user-settings-box form .form-group .input-group .input-group-text {
  background-color: transparent;
  font-size: 16px;
}

.user-settings-box form .form-group .form-control::-webkit-input-placeholder {
  color: #6c757d;
}

.user-settings-box form .form-group .form-control:-ms-input-placeholder {
  color: #6c757d;
}

.user-settings-box form .form-group .form-control::-ms-input-placeholder {
  color: #6c757d;
}

.user-settings-box form .form-group .form-control::placeholder {
  color: #6c757d;
}

.user-settings-box form .form-group .form-control:focus {
  border-color: #b1bbc4;
}

.user-settings-box form .form-group .form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef !important;
}

.user-settings-box form .form-group textarea.form-control {
  height: auto;
  padding-top: 15px;
}

.user-settings-box form .btn-box {
  margin-top: 10px;
}

.user-settings-box form .btn-box .submit-btn,
.user-settings-box form .btn-box .save-btn {
  border-radius: 5px;
  background-color: #382a1f;
  color: #ffffff;
  text-decoration: none;
  padding: 10px 30px;
  border: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 15px;
  font-weight: 700;
}

.user-settings-box form .btn-box .save-btn {
  background-color: #3578e5;
}

.user-settings-box form .btn-box .submit-btn i,
.user-settings-box form .btn-box .save-btn i {
  font-size: 20px;
  line-height: 1;
  margin-right: 2px;
  position: relative;
  top: 3px;
}

.user-settings-box form .btn-box .submit-btn:hover,
.user-settings-box form .btn-box .save-btn:hover {
  opacity: 0.8;
}

/*================================================
Maintenance CSS
=================================================*/
.maintenance-area {
  height: calc(100vh - 150px);
  position: relative;
  z-index: 1;
  background: #f6f7fb;
}

.maintenance-area .d-table {
  width: 100%;
  height: 100%;
}

.maintenance-area .d-table-cell {
  vertical-align: middle;
}

.maintenance-area .maintenance-content {
  max-width: 850px;
  background: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  padding: 50px 60px 50px 30px;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  margin-left: auto;
  margin-right: auto;
}

.maintenance-area .maintenance-content .logo {
  display: inline-block;
}

.maintenance-area .maintenance-content h2 {
  background: url(../img/oops-bg.jpg) no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
  text-transform: uppercase;
  font-size: 80px;
  font-weight: 900;
  margin-top: 30px;
  margin-bottom: 5px;
}

.maintenance-area .maintenance-content p {
  color: #6084a4;
  line-height: 1.7;
  font-size: 17px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  margin-top: 0;
}

.maintenance-area .maintenance-content a {
  text-decoration: none;
}

/*================================================
Login CSS
=================================================*/
.login-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #f6f7fb;
}

.login-area .d-table {
  width: 100%;
  height: 100%;
}

.login-area .d-table-cell {
  vertical-align: middle;
}

.login-area .login-form {
  max-width: 480px;
  background: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  padding: 50px;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  margin-left: auto;
  margin-right: auto;
}

.login-area .login-form h2 {
  font-size: 30px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 0;
}

.login-area .login-form form {
  margin-top: 25px;
}

.login-area .login-form form .form-group {
  margin-bottom: 30px;
  width: 100%;
  position: relative;
}

.login-area .login-form form .form-group .label-title {
  margin-bottom: 0;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  color: #2a2a2a;
}

.login-area .login-form form .form-group .label-title i {
  position: absolute;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  top: 9px;
  font-size: 22px;
}

.login-area .login-form form .form-group .label-title::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background: #382a1f;
}

.login-area .login-form form .form-group .form-control {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #adadad;
  padding: 0 0 0 32px;
  color: #2a2a2a;
  height: 45px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-family: "Petrona", serif;
  font-size: 17px;
  font-weight: 500;
}

.login-area
  .login-form
  form
  .form-group
  .form-control::-webkit-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.login-area .login-form form .form-group .form-control:-ms-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.login-area .login-form form .form-group .form-control::-ms-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.login-area .login-form form .form-group .form-control::placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.login-area .login-form form .form-group .form-control:focus {
  padding-left: 5px;
}

.login-area
  .login-form
  form
  .form-group
  .form-control:focus::-webkit-input-placeholder {
  color: transparent;
}

.login-area
  .login-form
  form
  .form-group
  .form-control:focus:-ms-input-placeholder {
  color: transparent;
}

.login-area
  .login-form
  form
  .form-group
  .form-control:focus::-ms-input-placeholder {
  color: transparent;
}

.login-area .login-form form .form-group .form-control:focus::placeholder {
  color: transparent;
}

.login-area
  .login-form
  form
  .form-group
  .form-control:focus
  + .label-title::before {
  width: 100%;
}

.login-area .login-form form .form-group .form-control:focus + .label-title {
  color: #a1a1a1;
}

.login-area .login-form form .form-group .form-control:focus + .label-title i {
  top: -22px;
}

.login-area .login-form form .form-group .remember-forgot {
  text-align: left;
  position: relative;
  overflow: hidden;
}

.login-area .login-form form .form-group .remember-forgot .forgot-password,
.btlogin {
  float: right;
  color: #d08a56;
  text-decoration: none !important;
}

.login-area .login-form form .form-group .checkbox-box {
  margin-bottom: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 25px;
  color: #6c757d;
}

.login-area .login-form form .form-group .checkbox-box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.login-area
  .login-form
  form
  .form-group
  .checkbox-box
  input:checked
  ~ .checkmark {
  background-color: #382a1f;
}

.login-area
  .login-form
  form
  .form-group
  .checkbox-box
  input:checked
  ~ .checkmark:after {
  display: block;
}

.login-area .login-form form .form-group .checkbox-box .checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 18px;
  width: 18px;
  overflow: hidden;
  background-color: #eeeeee;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.login-area .login-form form .form-group .checkbox-box .checkmark:after {
  content: "\e9f0";
  position: absolute;
  display: none;
  left: 0;
  top: -4.5px;
  color: #ffffff;
  font-family: "boxicons" !important;
  font-weight: normal;
  font-style: normal;
  font-size: 17px;
  font-variant: normal;
}

.login-area .login-form form .form-group .checkbox-box a {
  text-decoration: none;
  color: #2a2a2a;
}

.login-area .login-form form .login-btn {
  padding: 12px 35px 11px;
  border-radius: 30px;
  border: none;
  text-transform: uppercase;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #382a1f;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
}

.login-area .login-form form a.login-btn {
  padding: 15px 35px;
}

.login-area .login-form form .login-btn:hover,
.login-area .login-form form .login-btn:focus {
  background-color: #2a2a2a;
  color: #ffffff;
}

.login-area .login-form form p {
  margin-top: 20px;
  color: #6c757d;
}

.login-area .login-form form p a {
  text-decoration: none !important;
  color: #d08a56;
}

/*================================================
Register CSS
=================================================*/
.register-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #f6f7fb;
}

.register-area .d-table {
  width: 100%;
  height: 100%;
}

.register-area .d-table-cell {
  vertical-align: middle;
}

.register-area .register-form {
  max-width: 480px;
  background: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  padding: 50px;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  margin-left: auto;
  margin-right: auto;
}

.register-area .register-form h2 {
  font-size: 30px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 0;
}

.register-area .register-form form {
  margin-top: 25px;
}

.register-area .register-form form .form-group {
  margin-bottom: 30px;
  width: 100%;
  position: relative;
}

.register-area .register-form form .form-group .label-title {
  margin-bottom: 0;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  color: #2a2a2a;
}

.register-area .register-form form .form-group .label-title i {
  position: absolute;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  top: 9px;
  font-size: 22px;
}

.register-area .register-form form .form-group .label-title::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background: #382a1f;
}

.register-area .register-form form .form-group .form-control {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #adadad;
  padding: 0 0 0 32px;
  color: #2a2a2a;
  height: 45px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-family: "Petrona", serif;
  font-size: 17px;
  font-weight: 500;
}

.register-area
  .register-form
  form
  .form-group
  .form-control::-webkit-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.register-area
  .register-form
  form
  .form-group
  .form-control:-ms-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.register-area
  .register-form
  form
  .form-group
  .form-control::-ms-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.register-area .register-form form .form-group .form-control::placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.register-area .register-form form .form-group .form-control:focus {
  padding-left: 5px;
}

.register-area
  .register-form
  form
  .form-group
  .form-control:focus::-webkit-input-placeholder {
  color: transparent;
}

.register-area
  .register-form
  form
  .form-group
  .form-control:focus:-ms-input-placeholder {
  color: transparent;
}

.register-area
  .register-form
  form
  .form-group
  .form-control:focus::-ms-input-placeholder {
  color: transparent;
}

.register-area
  .register-form
  form
  .form-group
  .form-control:focus::placeholder {
  color: transparent;
}

.register-area
  .register-form
  form
  .form-group
  .form-control:focus
  + .label-title::before {
  width: 100%;
}

.register-area
  .register-form
  form
  .form-group
  .form-control:focus
  + .label-title {
  color: #a1a1a1;
}

.register-area
  .register-form
  form
  .form-group
  .form-control:focus
  + .label-title
  i {
  top: -22px;
}

.register-area .register-form form .form-group .terms-conditions {
  text-align: left;
  position: relative;
  overflow: hidden;
}

.register-area .register-form form .form-group .checkbox-box {
  margin-bottom: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 25px;
  color: #6c757d;
}

.register-area .register-form form .form-group .checkbox-box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.register-area
  .register-form
  form
  .form-group
  .checkbox-box
  input:checked
  ~ .checkmark {
  background-color: #382a1f;
}

.register-area
  .register-form
  form
  .form-group
  .checkbox-box
  input:checked
  ~ .checkmark:after {
  display: block;
}

.register-area .register-form form .form-group .checkbox-box .checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 18px;
  width: 18px;
  overflow: hidden;
  background-color: #eeeeee;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.register-area .register-form form .form-group .checkbox-box .checkmark:after {
  content: "\e9f0";
  position: absolute;
  display: none;
  left: 0;
  top: -4.5px;
  color: #ffffff;
  font-family: "boxicons" !important;
  font-weight: normal;
  font-style: normal;
  font-size: 17px;
  font-variant: normal;
}

.register-area .register-form form .form-group .checkbox-box a {
  text-decoration: none;
  color: #2a2a2a;
}

.register-area .register-form form .register-btn {
  padding: 12px 35px 11px;
  border-radius: 30px;
  border: none;
  text-transform: uppercase;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #382a1f;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
}

.register-area .register-form form .register-btn:hover,
.register-area .register-form form .register-btn:focus {
  background-color: #2a2a2a;
  color: #ffffff;
}

.register-area .register-form form p {
  margin-top: 20px;
  color: #6c757d;
}

.register-area .register-form form p a {
  text-decoration: none !important;
  color: #d08a56;
}

/*================================================
Reset Password CSS
=================================================*/
.reset-password-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #f6f7fb;
}

.reset-password-area .d-table {
  width: 100%;
  height: 100%;
}

.reset-password-area .d-table-cell {
  vertical-align: middle;
}

.reset-password-area .reset-password-content {
  max-width: 900px;
  background: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  padding: 50px 60px 50px 30px;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  margin-left: auto;
  margin-right: auto;
}

.reset-password-area .reset-password-content .image {
  text-align: center;
}

.reset-password-area .reset-password-content .reset-password-form {
  padding-left: 30px;
}

.reset-password-area .reset-password-content .reset-password-form h2 {
  font-size: 30px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 0;
}

.reset-password-area .reset-password-content .reset-password-form form {
  margin-top: 25px;
}

.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group {
  margin-bottom: 30px;
  width: 100%;
  position: relative;
}

.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .label-title {
  margin-bottom: 0;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  color: #2a2a2a;
}

.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .label-title
  i {
  position: absolute;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  top: 9px;
  font-size: 22px;
}

.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .label-title::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background: #382a1f;
}

.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .form-control {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #adadad;
  padding: 0 0 0 32px;
  color: #2a2a2a;
  height: 45px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-family: "Petrona", serif;
  font-size: 17px;
  font-weight: 500;
}

.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .form-control::-webkit-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .form-control:-ms-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .form-control::-ms-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .form-control::placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .form-control:focus {
  padding-left: 5px;
}

.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .form-control:focus::-webkit-input-placeholder {
  color: transparent;
}

.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .form-control:focus:-ms-input-placeholder {
  color: transparent;
}

.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .form-control:focus::-ms-input-placeholder {
  color: transparent;
}

.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .form-control:focus::placeholder {
  color: transparent;
}

.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .form-control:focus
  + .label-title::before {
  width: 100%;
}

.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .form-control:focus
  + .label-title {
  color: #a1a1a1;
}

.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .form-control:focus
  + .label-title
  i {
  top: -22px;
}

.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .reset-password-btn {
  padding: 12px 35px 11px;
  border-radius: 30px;
  border: none;
  text-transform: uppercase;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #382a1f;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
}

.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .reset-password-btn:hover,
.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .reset-password-btn:focus {
  background-color: #2a2a2a;
  color: #ffffff;
}

/*================================================
Forgot Password CSS
=================================================*/
.forgot-password-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #f6f7fb;
}

.forgot-password-area .d-table {
  width: 100%;
  height: 100%;
}

.forgot-password-area .d-table-cell {
  vertical-align: middle;
}

.forgot-password-area .forgot-password-content {
  max-width: 900px;
  background: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  padding: 50px 60px 50px 30px;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  margin-left: auto;
  margin-right: auto;
}

.forgot-password-area .forgot-password-content .image {
  text-align: center;
}

.forgot-password-area .forgot-password-content .forgot-password-form {
  padding-left: 30px;
}

.forgot-password-area .forgot-password-content .forgot-password-form h2 {
  font-size: 30px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 0;
}

.forgot-password-area .forgot-password-content .forgot-password-form p {
  color: #6c757d;
  margin-top: 7px;
}

.forgot-password-area .forgot-password-content .forgot-password-form form {
  margin-top: 25px;
}

.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group {
  margin-bottom: 30px;
  width: 100%;
  position: relative;
}

.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .label-title {
  margin-bottom: 0;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  color: #2a2a2a;
}

.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .label-title
  i {
  position: absolute;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  top: 9px;
  font-size: 22px;
}

.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .label-title::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background: #382a1f;
}

.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .form-control {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #adadad;
  padding: 0 0 0 32px;
  color: #2a2a2a;
  height: 45px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-family: "Petrona", serif;
  font-size: 17px;
  font-weight: 500;
}

.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .form-control::-webkit-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .form-control:-ms-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .form-control::-ms-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .form-control::placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .form-control:focus {
  padding-left: 5px;
}

.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .form-control:focus::-webkit-input-placeholder {
  color: transparent;
}

.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .form-control:focus:-ms-input-placeholder {
  color: transparent;
}

.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .form-control:focus::-ms-input-placeholder {
  color: transparent;
}

.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .form-control:focus::placeholder {
  color: transparent;
}

.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .form-control:focus
  + .label-title::before {
  width: 100%;
}

.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .form-control:focus
  + .label-title {
  color: #a1a1a1;
}

.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .form-control:focus
  + .label-title
  i {
  top: -22px;
}

.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .forgot-password-btn {
  padding: 12px 35px 11px;
  border-radius: 30px;
  border: none;
  text-transform: uppercase;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #382a1f;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
}

.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .forgot-password-btn:hover,
.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .forgot-password-btn:focus {
  background-color: #2a2a2a;
  color: #ffffff;
}

/*================================================
Lock Screen CSS
=================================================*/
.lock-screen-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #f6f7fb;
}

.lock-screen-area .d-table {
  width: 100%;
  height: 100%;
}

.lock-screen-area .d-table-cell {
  vertical-align: middle;
}

.lock-screen-area .lock-screen-content {
  max-width: 900px;
  background: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  padding: 50px 60px 50px 30px;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  margin-left: auto;
  margin-right: auto;
}

.lock-screen-area .lock-screen-content .image {
  text-align: center;
}

.lock-screen-area .lock-screen-content .lock-screen-form {
  padding-left: 30px;
}

.lock-screen-area .lock-screen-content .lock-screen-form h2 {
  font-size: 30px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 0;
}

.lock-screen-area .lock-screen-content .lock-screen-form form {
  margin-top: 25px;
}

.lock-screen-area .lock-screen-content .lock-screen-form form .form-group {
  margin-bottom: 30px;
  width: 100%;
  position: relative;
}

.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .label-title {
  margin-bottom: 0;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  color: #2a2a2a;
}

.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .label-title
  i {
  position: absolute;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  top: 9px;
  font-size: 22px;
}

.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .label-title::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background: #382a1f;
}

.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .form-control {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #adadad;
  padding: 0 0 0 32px;
  color: #2a2a2a;
  height: 45px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-family: "Petrona", serif;
  font-size: 17px;
  font-weight: 500;
}

.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .form-control::-webkit-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .form-control:-ms-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .form-control::-ms-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .form-control::placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .form-control:focus {
  padding-left: 5px;
}

.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .form-control:focus::-webkit-input-placeholder {
  color: transparent;
}

.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .form-control:focus:-ms-input-placeholder {
  color: transparent;
}

.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .form-control:focus::-ms-input-placeholder {
  color: transparent;
}

.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .form-control:focus::placeholder {
  color: transparent;
}

.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .form-control:focus
  + .label-title::before {
  width: 100%;
}

.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .form-control:focus
  + .label-title {
  color: #a1a1a1;
}

.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .form-control:focus
  + .label-title
  i {
  top: -22px;
}

.lock-screen-area .lock-screen-content .lock-screen-form form .lock-screen-btn {
  padding: 12px 35px 11px;
  border-radius: 30px;
  border: none;
  text-transform: uppercase;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #382a1f;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
}

.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .lock-screen-btn:hover,
.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .lock-screen-btn:focus {
  background-color: #2a2a2a;
  color: #ffffff;
}

/*================================================
Error 404 CSS
=================================================*/
.error-404-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #f6f7fb;
}

.error-404-area .d-table {
  width: 100%;
  height: 100%;
}

.error-404-area .d-table-cell {
  vertical-align: middle;
}

.error-404-area .notfound {
  position: relative;
  max-width: 520px;
  z-index: 1;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.error-404-area .notfound .notfound-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: -1;
}

.error-404-area .notfound .notfound-bg div {
  width: 100%;
  background: #ffffff;
  border-radius: 90px;
  height: 125px;
}

.error-404-area .notfound .notfound-bg div:nth-child(1) {
  -webkit-box-shadow: 5px 5px 0 0 #f3f3f3;
  box-shadow: 5px 5px 0 0 #f3f3f3;
}

.error-404-area .notfound .notfound-bg div:nth-child(2) {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
  -webkit-box-shadow: 5px 5px 0 0 #f3f3f3;
  box-shadow: 5px 5px 0 0 #f3f3f3;
  position: relative;
  z-index: 10;
}

.error-404-area .notfound .notfound-bg div:nth-child(3) {
  -webkit-box-shadow: 5px 5px 0 0 #f3f3f3;
  box-shadow: 5px 5px 0 0 #f3f3f3;
  position: relative;
  z-index: 90;
}

.error-404-area .notfound h1 {
  text-transform: uppercase;
  font-size: 100px;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 5px;
  /* background: url(../img/oops-bg.jpg) no-repeat; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
}

.error-404-area .notfound h3 {
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 25px;
}

.error-404-area .notfound p {
  color: #6084a4;
  margin-bottom: 20px;
  line-height: 1.7;
}

.error-404-area .notfound .default-btn {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-decoration: none;
  padding: 13px 35px 12px;
  border-radius: 30px;
  text-transform: uppercase;
  display: inline-block;
  transition: 0.5s;
  background-color: #382a1f;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
}

.error-404-area .notfound .default-btn:hover,
.error-404-area .notfound .default-btn:focus {
  background-color: #2a2a2a;
  color: #ffffff;
}

/*================================================
App Todo CSS
=================================================*/
.todo-content-area {
  margin-bottom: 30px;
}

.todo-content-area .sidebar-left {
  float: left;
}

.todo-content-area .sidebar-left .sidebar .sidebar-content {
  background-color: #ffffff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  padding: 25px;
  width: 260px;
  height: calc(100vh - 201px);
}

.todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .btn {
  border: none;
  padding: 12px 20px 11px;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 15px;
  font-weight: 600;
}

.todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .btn i {
  font-size: 20px;
  position: absolute;
  margin-left: -23px;
}

.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .btn-primary {
  color: #ffffff;
  background-color: #382a1f;
}

.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .btn-primary:not(:disabled):not(.disabled).active,
.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .btn-primary:not(:disabled):not(.disabled):active,
.show
  > .todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .btn-primary.dropdown-toggle {
  background-color: #382a1f;
  color: #ffffff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(56, 42, 31, 0.2);
  box-shadow: 1px 5px 24px 0 rgba(56, 42, 31, 0.2);
}

.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .btn-primary:not(:disabled):not(.disabled).active:focus,
.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .btn-primary:not(:disabled):not(.disabled):active:focus,
.show
  > .todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 1px 5px 24px 0 rgba(56, 42, 31, 0.2);
  box-shadow: 1px 5px 24px 0 rgba(56, 42, 31, 0.2);
}

.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .btn-primary:hover,
.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .btn-primary:focus {
  background-color: #382a1f;
  color: #ffffff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(56, 42, 31, 0.2);
  box-shadow: 1px 5px 24px 0 rgba(56, 42, 31, 0.2);
}

.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-messages
  .list-group-item {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 29px;
  text-decoration: none;
  margin-bottom: 16px;
  background-color: #ffffff;
  border: none !important;
  color: #2a2a2a;
  border-radius: 0 !important;
  font-size: 16px;
  font-weight: 600;
}

.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-messages
  .list-group-item
  i {
  position: absolute;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  top: 0;
  font-size: 22px;
}

.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-messages
  .list-group-item:last-child {
  margin-bottom: 0;
}

.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-messages
  .list-group-item.active {
  background-color: #ffffff;
  color: #382a1f;
}

.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-messages
  .list-group-item
  .badge {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-left: 4px;
  position: relative;
  top: -1px;
  padding: 4px 6px 4px 6px;
}

.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-messages
  .list-group-item:hover {
  color: #382a1f;
}

.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-messages
  .list-group-item:hover
  i {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  color: #382a1f;
}

.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-label {
  letter-spacing: 1px;
  color: #828d99;
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 600;
}

.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-labels
  .list-group-item {
  text-decoration: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 18px;
  margin-bottom: 12px;
  background-color: #ffffff;
  border: none !important;
  color: #2a2a2a;
  border-radius: 0 !important;
  font-size: 16px;
  font-weight: 600;
}

.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-labels
  .list-group-item::before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  border: 2px solid #382a1f;
}

.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-labels
  .list-group-item:last-child {
  margin-bottom: 0;
}

.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-labels
  .list-group-item.active {
  background-color: #ffffff;
  color: #382a1f;
}

.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-labels
  .list-group-item:hover {
  color: #382a1f;
}

.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-labels
  .list-group-item:nth-child(2)::before {
  border-color: #f79921;
}

.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-labels
  .list-group-item:nth-child(3)::before {
  border-color: #1ebe40;
}

.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-labels
  .list-group-item:nth-child(4)::before {
  border-color: #40fefe;
}

.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-labels
  .list-group-item:nth-child(5)::before {
  border-color: #707070;
}

.todo-content-area .content-right {
  float: right;
  width: calc(100% - 290px);
}

.todo-content-area .content-right .todo-list-wrapper .todo-list {
  background-color: #ffffff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header {
  padding: 15px 20px;
  border-bottom: 1px solid #e8ebf1;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-left {
  position: relative;
  top: 3px;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-left
  .checkbox {
  position: relative;
  top: -2px;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-left
  .btn-icon {
  width: 34px;
  height: 34px;
  background-color: #f3f3f3;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  border: none;
  padding: 0;
  font-size: 18px;
  border-radius: 3px;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-left
  .btn-icon
  i {
  position: absolute;
  left: 0;
  right: 0;
  top: 47%;
  -webkit-transform: translateY(-47%);
  transform: translateY(-47%);
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-left
  .btn-icon:hover {
  color: #ffffff;
  background-color: #382a1f;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-left
  .btn-icon::after {
  display: none;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-left
  .dropdown
  .dropdown-menu {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  border: none;
  border-radius: 0;
  margin-top: 35px;
  display: block;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: unset !important;
  transform: unset !important;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-left
  .dropdown
  .dropdown-menu.show {
  margin-top: 45px;
  opacity: 1;
  visibility: visible;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-left
  .dropdown
  .dropdown-menu
  .dropdown-item {
  color: #2a2a2a;
  padding: 5px 15px 5px 36px;
  position: relative;
  font-size: 14px;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-left
  .dropdown
  .dropdown-menu
  .dropdown-item
  i {
  color: #686868;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: absolute;
  left: 15px;
  top: 7px;
  font-size: 16px;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-left
  .dropdown
  .dropdown-menu
  .dropdown-item.active,
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-left
  .dropdown
  .dropdown-menu
  .dropdown-item:active {
  color: #2a2a2a;
  background-color: transparent;
  background-color: #f8f9fa;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-left
  .dropdown
  .dropdown-menu
  .dropdown-item:hover
  i {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-right {
  position: relative;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-right
  label {
  position: absolute;
  left: 15px;
  color: #71738d;
  margin-bottom: 0;
  top: 14px;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-right
  .form-control {
  height: 45px;
  border-radius: 30px;
  background-color: #eef5f9;
  border: none;
  color: #2a2a2a;
  padding: 2px 15px 0 39px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  font-size: 14px;
  font-weight: 400;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-right
  .form-control:focus {
  -webkit-box-shadow: unset;
  box-shadow: unset;
  background-color: #eef5f9;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-right
  .form-control:focus::-webkit-input-placeholder {
  color: transparent;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-right
  .form-control:focus:-ms-input-placeholder {
  color: transparent;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-right
  .form-control:focus::-ms-input-placeholder {
  color: transparent;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-right
  .form-control:focus::placeholder {
  color: transparent;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-right
  .form-control::-webkit-input-placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-right
  .form-control:-ms-input-placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-right
  .form-control::-ms-input-placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-right
  .form-control::placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper {
  height: calc(100vh - 277px);
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item {
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-bottom: 1px solid #e8ebf1;
  padding-left: 60px;
  padding-right: 110px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item
  .checkbox {
  position: absolute;
  left: 25px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item
  .todo-item-title {
  position: relative;
  padding-left: 53px;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item
  .todo-item-title
  img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item
  .todo-item-title
  h3 {
  margin-bottom: 2px;
  font-size: 16px;
  font-weight: 600;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item
  .todo-item-title
  p {
  color: #919191;
  font-size: 15px;
  margin-bottom: 0;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item
  .todo-item-action {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 25px;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item
  .todo-item-action
  a {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 32px;
  border-radius: 3px;
  background-color: #c6e8cd;
  color: #13bb37;
  text-align: center;
  margin-left: 3px;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item
  .todo-item-action
  a:hover {
  background-color: #13bb37;
  color: #ffffff;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item
  .todo-item-action
  a.delete {
  background-color: #f9d0d9 !important;
  color: #382a1f !important;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item
  .todo-item-action
  a.delete:hover {
  background-color: #382a1f !important;
  color: #ffffff !important;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:hover {
  -webkit-box-shadow: 0 0 10px 0 rgba(58, 70, 93, 0.25);
  box-shadow: 0 0 10px 0 rgba(58, 70, 93, 0.25);
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(2)
  .todo-item-action
  a,
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(6)
  .todo-item-action
  a,
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(10)
  .todo-item-action
  a {
  background-color: #d1def5;
  color: #4788ff;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(2)
  .todo-item-action
  a:hover,
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(6)
  .todo-item-action
  a:hover,
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(10)
  .todo-item-action
  a:hover {
  background-color: #4788ff;
  color: #ffffff;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(3)
  .todo-item-action
  a,
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(7)
  .todo-item-action
  a,
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(11)
  .todo-item-action
  a {
  background-color: #f5d1c4;
  color: #fb4a06;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(3)
  .todo-item-action
  a:hover,
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(7)
  .todo-item-action
  a:hover,
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(11)
  .todo-item-action
  a:hover {
  background-color: #fb4a06;
  color: #ffffff;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(4)
  .todo-item-action
  a,
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(8)
  .todo-item-action
  a,
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(12)
  .todo-item-action
  a {
  background-color: #d1def5;
  color: #4788ff;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(4)
  .todo-item-action
  a:hover,
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(8)
  .todo-item-action
  a:hover,
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(12)
  .todo-item-action
  a:hover {
  background-color: #4788ff;
  color: #ffffff;
}

.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:last-child {
  border-bottom: none;
}

/*================================================
App Email CSS
=================================================*/
.email-content-area {
  margin-bottom: 30px;
}

.email-content-area .sidebar-left {
  float: left;
}

.email-content-area .sidebar-left .sidebar .sidebar-content {
  background-color: #ffffff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  padding: 25px;
  width: 260px;
  height: calc(100vh - 201px);
}

.email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .btn {
  border: none;
  padding: 12px 20px 11px;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 15px;
  font-weight: 600;
}

.email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .btn i {
  font-size: 20px;
  position: absolute;
  margin-left: -23px;
}

.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .btn-primary {
  color: #ffffff;
  background-color: #382a1f;
}

.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .btn-primary:not(:disabled):not(.disabled).active,
.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .btn-primary:not(:disabled):not(.disabled):active,
.show
  > .email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .btn-primary.dropdown-toggle {
  background-color: #382a1f;
  color: #ffffff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(56, 42, 31, 0.2);
  box-shadow: 1px 5px 24px 0 rgba(56, 42, 31, 0.2);
}

.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .btn-primary:not(:disabled):not(.disabled).active:focus,
.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .btn-primary:not(:disabled):not(.disabled):active:focus,
.show
  > .email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 1px 5px 24px 0 rgba(56, 42, 31, 0.2);
  box-shadow: 1px 5px 24px 0 rgba(56, 42, 31, 0.2);
}

.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .btn-primary:hover,
.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .btn-primary:focus {
  background-color: #382a1f;
  color: #ffffff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(56, 42, 31, 0.2);
  box-shadow: 1px 5px 24px 0 rgba(56, 42, 31, 0.2);
}

.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-messages
  .list-group-item {
  text-decoration: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 29px;
  margin-bottom: 16px;
  background-color: #ffffff;
  border: none !important;
  color: #2a2a2a;
  border-radius: 0 !important;
  font-size: 16px;
  font-weight: 600;
}

.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-messages
  .list-group-item
  i {
  position: absolute;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  top: 0;
  font-size: 22px;
}

.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-messages
  .list-group-item:last-child {
  margin-bottom: 0;
}

.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-messages
  .list-group-item.active {
  background-color: #ffffff;
  color: #382a1f;
}

.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-messages
  .list-group-item
  .badge {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-left: 4px;
  position: relative;
  top: -1px;
  padding: 4px 6px 4px 6px;
}

.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-messages
  .list-group-item:hover {
  color: #382a1f;
}

.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-messages
  .list-group-item:hover
  i {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  color: #382a1f;
}

.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-label {
  letter-spacing: 1px;
  color: #828d99;
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 600;
}

.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-labels
  .list-group-item {
  text-decoration: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 18px;
  margin-bottom: 12px;
  background-color: #ffffff;
  border: none !important;
  color: #2a2a2a;
  border-radius: 0 !important;
  font-size: 16px;
  font-weight: 600;
}

.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-labels
  .list-group-item::before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  border: 2px solid #382a1f;
}

.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-labels
  .list-group-item:last-child {
  margin-bottom: 0;
}

.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-labels
  .list-group-item.active {
  background-color: #ffffff;
  color: #382a1f;
}

.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-labels
  .list-group-item:hover {
  color: #382a1f;
}

.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-labels
  .list-group-item:nth-child(2)::before {
  border-color: #f79921;
}

.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-labels
  .list-group-item:nth-child(3)::before {
  border-color: #1ebe40;
}

.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-labels
  .list-group-item:nth-child(4)::before {
  border-color: #40fefe;
}

.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-labels
  .list-group-item:nth-child(5)::before {
  border-color: #707070;
}

.email-content-area .content-right {
  float: right;
  width: calc(100% - 290px);
}

.email-content-area .content-right .email-list-wrapper .email-list {
  background-color: #ffffff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header {
  padding: 15px 20px;
  border-bottom: 1px solid #e8ebf1;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-left {
  position: relative;
  top: 3px;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-left
  .checkbox {
  position: relative;
  top: -2px;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-left
  .btn-icon {
  width: 34px;
  height: 34px;
  background-color: #f3f3f3;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  border: none;
  padding: 0;
  font-size: 18px;
  border-radius: 3px;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-left
  .btn-icon
  i {
  position: absolute;
  left: 0;
  right: 0;
  top: 47%;
  -webkit-transform: translateY(-47%);
  transform: translateY(-47%);
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-left
  .btn-icon:hover {
  color: #ffffff;
  background-color: #382a1f;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-left
  .btn-icon::after {
  display: none;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-left
  .dropdown
  .dropdown-menu {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  border: none;
  border-radius: 0;
  margin-top: 35px;
  display: block;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: unset !important;
  transform: unset !important;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-left
  .dropdown
  .dropdown-menu.show {
  margin-top: 45px;
  opacity: 1;
  visibility: visible;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-left
  .dropdown
  .dropdown-menu
  .dropdown-item {
  color: #2a2a2a;
  padding: 5px 15px 5px 36px;
  position: relative;
  font-size: 14px;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-left
  .dropdown
  .dropdown-menu
  .dropdown-item
  i {
  color: #686868;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: absolute;
  left: 15px;
  top: 7px;
  font-size: 16px;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-left
  .dropdown
  .dropdown-menu
  .dropdown-item.active,
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-left
  .dropdown
  .dropdown-menu
  .dropdown-item:active {
  color: #2a2a2a;
  background-color: transparent;
  background-color: #f8f9fa;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-left
  .dropdown
  .dropdown-menu
  .dropdown-item:hover
  i {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-right {
  position: relative;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-right
  label {
  position: absolute;
  left: 15px;
  color: #71738d;
  margin-bottom: 0;
  top: 14px;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-right
  .form-control {
  height: 45px;
  border-radius: 30px;
  background-color: #eef5f9;
  border: none;
  color: #2a2a2a;
  padding: 2px 15px 0 39px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  font-size: 14px;
  font-weight: 400;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-right
  .form-control:focus {
  -webkit-box-shadow: unset;
  box-shadow: unset;
  background-color: #eef5f9;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-right
  .form-control:focus::-webkit-input-placeholder {
  color: transparent;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-right
  .form-control:focus:-ms-input-placeholder {
  color: transparent;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-right
  .form-control:focus::-ms-input-placeholder {
  color: transparent;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-right
  .form-control:focus::placeholder {
  color: transparent;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-right
  .form-control::-webkit-input-placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-right
  .form-control:-ms-input-placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-right
  .form-control::-ms-input-placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-right
  .form-control::placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper {
  height: calc(100vh - 343px);
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #e8ebf1;
  padding: 16.1px 20px;
  width: 100%;
  position: relative;
  background-color: #ffffff;
  font-size: 15px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item:last-child {
  border-bottom: none;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item
  .email-list-actions
  .checkbox {
  position: relative;
  top: 2px;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item
  .email-list-actions
  .favorite {
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  color: #c7cfd6;
  margin-top: 8px;
  margin-bottom: -5px;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item
  .email-list-actions
  .favorite.active {
  color: #fdac41;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item
  .email-list-actions
  .favorite.active
  i::before {
  content: "\ed99";
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item
  .email-list-detail {
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item
  .email-list-detail
  div {
  position: relative;
  padding-left: 65px;
  margin-top: -2px;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item
  .email-list-detail
  img {
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item
  .email-list-detail
  .from {
  color: #2a2a2a;
  font-size: 16px;
  font-weight: 700;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item
  .email-list-detail
  .msg {
  color: #71738d;
  font-size: 15px;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item
  .email-list-detail
  .date {
  color: #2a2a2a;
  font-weight: 600;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item.read {
  background-color: #f7f7f7;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item.read
  .email-list-detail
  .from {
  color: #616161;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item.read
  .email-list-detail
  .msg {
  color: #71738d;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item.read
  .email-list-detail
  .date {
  color: #616161;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item:hover {
  -webkit-box-shadow: 0 0 10px 0 rgba(58, 70, 93, 0.25);
  box-shadow: 0 0 10px 0 rgba(58, 70, 93, 0.25);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 1;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-footer {
  border-top: 1px solid #e8ebf1;
  padding: 15px 20px;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-footer
  p {
  color: #71738d;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-footer
  .email-pagination-btn {
  margin-bottom: -5px;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-footer
  .email-pagination-btn
  button {
  width: 34px;
  height: 34px;
  background-color: #f3f3f3;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  border: none;
  padding: 0;
  font-size: 18px;
  border-radius: 5px;
  margin-left: 5px;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-footer
  .email-pagination-btn
  button
  i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-footer
  .email-pagination-btn
  button:hover {
  color: #ffffff;
  background-color: #382a1f;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-footer
  .email-pagination-btn
  button.email-pagination-prev {
  border-radius: 5px 0 0 5px;
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-footer
  .email-pagination-btn
  button.email-pagination-next {
  border-radius: 0 5px 5px 0;
}

/*================================================
App Calendar CSS
=================================================*/
.calendar-container {
  overflow: hidden;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  border-radius: 5px;
  background: #ffffff;
}

.calendar-container .calendar {
  display: -ms-grid;
  display: grid;
  width: 100%;
  overflow: auto;
  -ms-grid-columns: (minmax(120px, 1fr));
  grid-template-columns: repeat(7, minmax(120px, 1fr));
  -ms-grid-rows: 50px;
  grid-template-rows: 50px;
  grid-auto-rows: 120px;
}

.calendar-container .calendar-header {
  position: relative;
  padding: 25px 20px;
  border-bottom: 1px solid rgba(166, 168, 179, 0.12);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calendar-container .calendar-header h3 {
  font-size: 18px;
  font-weight: 700;
}

.calendar-container .calendar-header button {
  border: none;
  background-color: #382a1f;
  color: #ffffff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 30px;
  padding: 10px 30px;
  font-size: 15px;
  font-weight: 700;
}

.calendar-container .calendar-header button:hover {
  background-color: #2a2a2a;
  color: #ffffff;
}

.calendar-container .day {
  border-bottom: 1px solid rgba(166, 168, 179, 0.12);
  border-right: 1px solid rgba(166, 168, 179, 0.12);
  text-align: right;
  padding: 14px 20px;
  letter-spacing: 1px;
  font-size: 12px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #898989;
  position: relative;
  pointer-events: none;
  z-index: 1;
}

.calendar-container .day:nth-of-type(7n + 7) {
  border-right: 0;
}

.calendar-container .day:nth-of-type(n + 1):nth-of-type(-n + 7) {
  -ms-grid-row: 2;
  grid-row: 2;
}

.calendar-container .day:nth-of-type(n + 8):nth-of-type(-n + 14) {
  -ms-grid-row: 3;
  grid-row: 3;
}

.calendar-container .day:nth-of-type(n + 15):nth-of-type(-n + 21) {
  -ms-grid-row: 4;
  grid-row: 4;
}

.calendar-container .day:nth-of-type(n + 22):nth-of-type(-n + 28) {
  -ms-grid-row: 5;
  grid-row: 5;
}

.calendar-container .day:nth-of-type(n + 29):nth-of-type(-n + 35) {
  -ms-grid-row: 6;
  grid-row: 6;
}

.calendar-container .day:nth-of-type(7n + 1) {
  -ms-grid-column: 1;
  grid-column: 1/1;
}

.calendar-container .day:nth-of-type(7n + 2) {
  -ms-grid-column: 2;
  grid-column: 2/2;
}

.calendar-container .day:nth-of-type(7n + 3) {
  -ms-grid-column: 3;
  grid-column: 3/3;
}

.calendar-container .day:nth-of-type(7n + 4) {
  -ms-grid-column: 4;
  grid-column: 4/4;
}

.calendar-container .day:nth-of-type(7n + 5) {
  -ms-grid-column: 5;
  grid-column: 5/5;
}

.calendar-container .day:nth-of-type(7n + 6) {
  -ms-grid-column: 6;
  grid-column: 6/6;
}

.calendar-container .day:nth-of-type(7n + 7) {
  -ms-grid-column: 7;
  grid-column: 7/7;
}

.calendar-container .day-name {
  color: #2a2a2a;
  border-bottom: 1px solid rgba(166, 168, 179, 0.12);
  line-height: 50px;
  text-transform: uppercase;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
}

.calendar-container .day--disabled {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23f9f9fa' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
  cursor: not-allowed;
}

.calendar-container .task {
  border-left-width: 3px;
  padding: 8px 12px;
  margin: 10px;
  border-left-style: solid;
  font-size: 14px;
  position: relative;
}

.calendar-container .task.task--warning {
  border-left-color: #fdb44d;
  -ms-grid-column: 4;
  -ms-grid-column-span: 3;
  grid-column: 4 / span 3;
  -ms-grid-row: 3;
  grid-row: 3;
  background: #fef0db;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  color: #fc9b10;
  margin-top: -5px;
}

.calendar-container .task.task--danger {
  border-left-color: #fa607e;
  -ms-grid-column: 2;
  -ms-grid-column-span: 3;
  grid-column: 2 / span 3;
  -ms-grid-row: 3;
  grid-row: 3;
  margin-top: 15px;
  background: rgba(253, 197, 208, 0.7);
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
  color: #f8254e;
}

.calendar-container .task.task--info {
  border-left-color: #4786ff;
  -ms-grid-column: 6;
  -ms-grid-column-span: 2;
  grid-column: 6 / span 2;
  -ms-grid-row: 5;
  grid-row: 5;
  margin-top: 15px;
  background: rgba(218, 231, 255, 0.7);
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
  color: #0a5eff;
}

.calendar-container .task.task--primary {
  background: #4786ff;
  border: 0;
  border-radius: 4px;
  -ms-grid-column: 3;
  -ms-grid-column-span: 3;
  grid-column: 3 / span 3;
  -ms-grid-row: 4;
  grid-row: 4;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
  color: #fff;
  -webkit-box-shadow: 0 10px 14px rgba(71, 134, 255, 0.4);
  box-shadow: 0 10px 14px rgba(71, 134, 255, 0.4);
}

.calendar-container .task .task__detail {
  position: absolute;
  left: 0;
  top: calc(100% + 5px);
  background: #ffffff;
  border: 1px solid rgba(166, 168, 179, 0.2);
  color: #000000;
  padding: 20px;
  border-radius: 4px;
  -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, 0.08);
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.08);
  z-index: 2;
  display: none;
  opacity: 0;
}

.calendar-container .task .task__detail::after,
.calendar-container .task .task__detail::before {
  bottom: 100%;
  left: 30%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.calendar-container .task .task__detail::before {
  margin-left: -8px;
  border-bottom-color: rgba(166, 168, 179, 0.2);
  border-width: 8px;
}

.calendar-container .task .task__detail:after {
  margin-left: -6px;
  border-bottom-color: #ffffff;
  border-width: 6px;
}

.calendar-container .task .task__detail h4 {
  font-size: 15px;
  margin: 0;
  color: #51565d;
}

.calendar-container .task .task__detail p {
  margin-top: 4px;
  margin-bottom: 0;
  color: rgba(81, 86, 93, 0.7);
  font-weight: 500;
  font-size: 12px;
}

.calendar-container .task:hover .task__detail {
  display: block;
  opacity: 1;
}

/*================================================
App Chat CSS
=================================================*/
.chat-content-area {
  margin-bottom: 30px;
}

.chat-content-area .sidebar-left {
  float: left;
}

.chat-content-area .sidebar-left .sidebar {
  background-color: #ffffff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  padding: 25px;
  width: 300px;
}

.chat-content-area .sidebar-left .sidebar .chat-sidebar-header {
  padding-bottom: 25px;
}

.chat-content-area .sidebar-left .sidebar .chat-sidebar-header form label {
  display: block;
  margin-bottom: 0;
  position: absolute;
  left: 15px;
  top: 11.3px;
  font-size: 18px;
  color: #a8a8a8;
}

.chat-content-area
  .sidebar-left
  .sidebar
  .chat-sidebar-header
  form
  .form-control {
  height: 45px;
  border-radius: 30px;
  background-color: #eef5f9;
  border: none;
  color: #2a2a2a;
  padding: 0 15px 0 40px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  font-size: 14px;
  font-weight: 400;
}

.chat-content-area
  .sidebar-left
  .sidebar
  .chat-sidebar-header
  form
  .form-control:focus {
  -webkit-box-shadow: unset;
  box-shadow: unset;
  background-color: #eef5f9;
}

.chat-content-area
  .sidebar-left
  .sidebar
  .chat-sidebar-header
  form
  .form-control:focus::-webkit-input-placeholder {
  color: transparent !important;
}

.chat-content-area
  .sidebar-left
  .sidebar
  .chat-sidebar-header
  form
  .form-control:focus:-ms-input-placeholder {
  color: transparent !important;
}

.chat-content-area
  .sidebar-left
  .sidebar
  .chat-sidebar-header
  form
  .form-control:focus::-ms-input-placeholder {
  color: transparent !important;
}

.chat-content-area
  .sidebar-left
  .sidebar
  .chat-sidebar-header
  form
  .form-control:focus::placeholder {
  color: transparent !important;
}

.chat-content-area
  .sidebar-left
  .sidebar
  .chat-sidebar-header
  form
  .form-control::-webkit-input-placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.chat-content-area
  .sidebar-left
  .sidebar
  .chat-sidebar-header
  form
  .form-control:-ms-input-placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.chat-content-area
  .sidebar-left
  .sidebar
  .chat-sidebar-header
  form
  .form-control::-ms-input-placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.chat-content-area
  .sidebar-left
  .sidebar
  .chat-sidebar-header
  form
  .form-control::placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.chat-content-area .sidebar-left .sidebar .sidebar-content {
  height: calc(100vh - 321px);
}

.chat-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .chat-menu
  .list-group-user
  li {
  cursor: pointer;
  margin-bottom: 20px;
}

.chat-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .chat-menu
  .list-group-user
  li:last-child {
  margin-bottom: 0;
}

.chat-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .chat-menu
  .list-group-user
  li
  .avatar {
  position: relative;
}

.chat-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .chat-menu
  .list-group-user
  li
  .avatar
  .status-busy {
  background-color: #ff5b5c;
}

.chat-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .chat-menu
  .list-group-user
  li
  .avatar
  .status-online {
  background-color: #39da8a;
}

.chat-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .chat-menu
  .list-group-user
  li
  .avatar
  .status-away {
  background-color: #fdac41;
}

.chat-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .chat-menu
  .list-group-user
  li
  .avatar
  .status-offline {
  background-color: #475f7b;
}

.chat-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .chat-menu
  .list-group-user
  li
  .avatar
  [class*="status-"] {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: absolute;
  left: 1px;
  top: 1px;
}

.chat-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .chat-menu
  .list-group-user
  li
  h6 {
  margin-bottom: 3px;
  font-size: 16px;
}

.chat-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .chat-menu
  .list-group-user
  li
  span {
  color: #71738d;
  font-size: 14.5px;
}

.chat-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .chat-menu
  .list-group-label {
  color: #2a2a2a;
  margin-top: 25px;
  margin-bottom: 20px;
  padding-bottom: 5px;
  position: relative;
  border-bottom: 1px solid #eeeeee;
  font-size: 18px;
  font-weight: 700;
}

.chat-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .chat-menu
  .list-group-label::before {
  width: 70px;
  height: 1px;
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  background-color: #382a1f;
}

.chat-content-area .content-right {
  float: right;
  width: calc(100% - 330px);
}

.chat-content-area .content-right .chat-list-wrapper .chat-list {
  background-color: #ffffff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .simplebar-scrollbar::before {
  background: #aaaaaa;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header {
  padding: 15px 20px;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-left
  h6 {
  white-space: nowrap;
  font-size: 16px;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-left
  .avatar {
  position: relative;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-left
  .avatar
  .status-busy {
  background-color: #ff5b5c;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-left
  .avatar
  .status-online {
  background-color: #39da8a;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-left
  .avatar
  .status-away {
  background-color: #fdac41;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-left
  .avatar
  .status-offline {
  background-color: #475f7b;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-left
  .avatar
  [class*="status-"] {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: absolute;
  left: 1px;
  top: 1px;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-right {
  position: relative;
  top: 1px;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-right
  ul
  li {
  display: inline-block;
  margin-left: 1px;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-right
  ul
  li:first-child {
  margin-left: 0;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-right
  ul
  li
  .favorite {
  font-size: 20px;
  cursor: pointer;
  color: #c7cfd6;
  position: relative;
  top: -1px;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-right
  ul
  li
  .favorite.active {
  color: #fdac41;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-right
  ul
  li
  .favorite.active
  i::before {
  content: "\ed99";
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-right
  ul
  li
  .dropdown
  .dropdown-toggle {
  padding: 0;
  border: none;
  background-color: transparent;
  color: #727e8c;
  font-size: 22px;
  line-height: 15px;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-right
  ul
  li
  .dropdown
  .dropdown-toggle::after {
  display: none;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-right
  ul
  li
  .dropdown
  .dropdown-menu {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  border: none;
  border-radius: 0;
  margin-top: 35px;
  display: block;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: unset !important;
  transform: unset !important;
  left: auto !important;
  right: 0;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-right
  ul
  li
  .dropdown
  .dropdown-menu.show {
  margin-top: 25px;
  opacity: 1;
  visibility: visible;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-right
  ul
  li
  .dropdown
  .dropdown-menu
  .dropdown-item {
  color: #2a2a2a;
  padding: 5px 15px 5px 36px;
  position: relative;
  font-size: 14px;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-right
  ul
  li
  .dropdown
  .dropdown-menu
  .dropdown-item
  i {
  color: #686868;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: absolute;
  left: 15px;
  top: 7px;
  font-size: 16px;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-right
  ul
  li
  .dropdown
  .dropdown-menu
  .dropdown-item.active,
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-right
  ul
  li
  .dropdown
  .dropdown-menu
  .dropdown-item:active {
  color: #2a2a2a;
  background-color: transparent;
  background-color: #f8f9fa;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-right
  ul
  li
  .dropdown
  .dropdown-menu
  .dropdown-item:hover
  i {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container {
  height: calc(100vh - 338px);
  background-color: #e3e6f1;
  padding: 25px 20px;
  /* background-image: url(../img/chat-bg.png); */
  background-position: center center;
  background-size: contain;
  background-repeat: repeat;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container
  .chat-content {
  text-align: center;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container
  .chat-content
  .chat
  .chat-avatar {
  float: right;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container
  .chat-content
  .chat
  .chat-body {
  overflow: hidden;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container
  .chat-content
  .chat
  .chat-body
  .chat-message {
  position: relative;
  float: right;
  max-width: 420px;
  text-align: left;
  padding: 12px 16px;
  margin: 10px 21.28px 21.28px 0;
  clear: both;
  word-break: break-word;
  color: #ffffff;
  background-color: #3578e5;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container
  .chat-content
  .chat
  .chat-body
  .chat-message
  p {
  color: #ffffff;
  margin-bottom: 0;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container
  .chat-content
  .chat
  .chat-body
  .chat-message
  p
  a {
  color: #ffffff !important;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container
  .chat-content
  .chat
  .chat-body
  .chat-message
  .time {
  position: absolute;
  bottom: -25px;
  right: 0;
  color: #6b7886;
  font-size: 0.8rem;
  white-space: nowrap;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container
  .chat-content
  .chat
  .chat-body
  .chat-message:first-child {
  margin-top: 0 !important;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container
  .chat-content
  .chat.chat-left
  .chat-avatar {
  float: left;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container
  .chat-content
  .chat.chat-left
  .chat-body
  .chat-message {
  text-align: left;
  float: left;
  margin: 10px 0 21.28px 21.28px;
  color: #727e8c;
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container
  .chat-content
  .chat.chat-left
  .chat-body
  .chat-message
  p {
  color: #727e8c;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container
  .chat-content
  .chat.chat-left
  .chat-body
  .chat-message
  p
  a {
  color: #727e8c !important;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container
  .chat-content
  .badge-light {
  padding: 9px 20px 7px;
  text-transform: uppercase;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-footer {
  background-color: #fafafa;
  padding: 15px 20px;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-footer
  .emoji-btn {
  padding: 0;
  border: none;
  background-color: #f9d0d9;
  border-radius: 50%;
  width: 35px;
  font-size: 19px;
  line-height: 40px;
  height: 35px;
  color: #382a1f;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-footer
  .emoji-btn:hover,
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-footer
  .emoji-btn:focus {
  background-color: #382a1f;
  color: #ffffff;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-footer
  .file-attachment-btn {
  padding: 0;
  border: none;
  background-color: #f9d0d9;
  border-radius: 50%;
  width: 35px;
  font-size: 19px;
  line-height: 40px;
  height: 35px;
  color: #382a1f;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-footer
  .file-attachment-btn:hover,
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-footer
  .file-attachment-btn:focus {
  background-color: #382a1f;
  color: #ffffff;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-footer
  form {
  position: relative;
  padding-right: 128px;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-footer
  form
  .form-control {
  background-color: #ffffff;
  height: 45px;
  border-radius: 30px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding-left: 15px;
  font-size: 14.5px;
  border: 1px solid #dfe3e7;
  color: #2a2a2a;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-footer
  form
  .form-control:focus {
  border-color: #382a1f;
  -webkit-box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-footer
  form
  .send-btn {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border: none;
  background-color: #382a1f;
  color: #ffffff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 30px;
  height: 45px;
  padding: 0 30px;
  -webkit-box-shadow: 0 4px 12px 0 rgba(56, 42, 31, 0.2);
  box-shadow: 0 4px 12px 0 rgba(56, 42, 31, 0.2);
  font-size: 15px;
  font-weight: 700;
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-footer
  form
  .send-btn:hover {
  -webkit-box-shadow: 0 4px 12px 0 rgba(56, 42, 31, 0.6);
  box-shadow: 0 4px 12px 0 rgba(56, 42, 31, 0.6);
}

/*================================================
App Email Read CSS
=================================================*/
.email-read-content-area {
  margin-bottom: 30px;
}

.email-read-content-area .sidebar-left {
  float: left;
}

.email-read-content-area .sidebar-left .sidebar .sidebar-content {
  background-color: #ffffff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  padding: 25px;
  width: 260px;
  height: calc(100vh - 201px);
}

.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .btn {
  border: none;
  padding: 12px 20px 11px;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 15px;
  font-weight: 600;
}

.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .btn
  i {
  font-size: 20px;
  position: absolute;
  margin-left: -23px;
}

.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .btn-primary {
  color: #ffffff;
  background-color: #382a1f;
}

.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .btn-primary:not(:disabled):not(.disabled).active,
.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .btn-primary:not(:disabled):not(.disabled):active,
.show
  > .email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .btn-primary.dropdown-toggle {
  background-color: #382a1f;
  color: #ffffff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(56, 42, 31, 0.2);
  box-shadow: 1px 5px 24px 0 rgba(56, 42, 31, 0.2);
}

.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .btn-primary:not(:disabled):not(.disabled).active:focus,
.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .btn-primary:not(:disabled):not(.disabled):active:focus,
.show
  > .email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 1px 5px 24px 0 rgba(56, 42, 31, 0.2);
  box-shadow: 1px 5px 24px 0 rgba(56, 42, 31, 0.2);
}

.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .btn-primary:hover,
.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .btn-primary:focus {
  background-color: #382a1f;
  color: #ffffff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(56, 42, 31, 0.2);
  box-shadow: 1px 5px 24px 0 rgba(56, 42, 31, 0.2);
}

.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-messages
  .list-group-item {
  text-decoration: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 29px;
  margin-bottom: 16px;
  background-color: #ffffff;
  border: none !important;
  color: #2a2a2a;
  border-radius: 0 !important;
  font-size: 16px;
  font-weight: 600;
}

.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-messages
  .list-group-item
  i {
  position: absolute;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  top: 0;
  font-size: 22px;
}

.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-messages
  .list-group-item:last-child {
  margin-bottom: 0;
}

.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-messages
  .list-group-item.active {
  background-color: #ffffff;
  color: #382a1f;
}

.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-messages
  .list-group-item
  .badge {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-left: 4px;
  position: relative;
  top: -1px;
  padding: 4px 6px 4px 6px;
}

.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-messages
  .list-group-item:hover {
  color: #382a1f;
}

.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-messages
  .list-group-item:hover
  i {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  color: #382a1f;
}

.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-label {
  letter-spacing: 1px;
  color: #828d99;
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 600;
}

.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-labels
  .list-group-item {
  text-decoration: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 18px;
  margin-bottom: 12px;
  background-color: #ffffff;
  border: none !important;
  color: #2a2a2a;
  border-radius: 0 !important;
  font-size: 16px;
  font-weight: 600;
}

.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-labels
  .list-group-item::before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  border: 2px solid #382a1f;
}

.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-labels
  .list-group-item:last-child {
  margin-bottom: 0;
}

.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-labels
  .list-group-item.active {
  background-color: #ffffff;
  color: #382a1f;
}

.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-labels
  .list-group-item:hover {
  color: #382a1f;
}

.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-labels
  .list-group-item:nth-child(2)::before {
  border-color: #f79921;
}

.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-labels
  .list-group-item:nth-child(3)::before {
  border-color: #1ebe40;
}

.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-labels
  .list-group-item:nth-child(4)::before {
  border-color: #40fefe;
}

.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-labels
  .list-group-item:nth-child(5)::before {
  border-color: #707070;
}

.email-read-content-area .content-right {
  float: right;
  width: calc(100% - 290px);
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list {
  background-color: #ffffff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header {
  padding: 15px 20px;
  border-bottom: 1px solid #e8ebf1;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-left {
  position: relative;
  top: 1px;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-left
  .go-back {
  color: #727e8c;
  font-size: 25px;
  line-height: 0;
  position: relative;
  top: -1px;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-left
  .go-back:hover {
  color: #382a1f;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-left
  h3 {
  font-size: 18px;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-left
  h3
  .badge {
  color: #ffffff;
  border-radius: 30px;
  padding: 5px 15px;
  font-weight: 500;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-right {
  position: relative;
  top: 3px;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-right
  .btn-icon {
  width: 34px;
  height: 34px;
  background-color: #f3f3f3;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  border: none;
  padding: 0;
  font-size: 18px;
  border-radius: 3px;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-right
  .btn-icon
  i {
  position: absolute;
  left: 0;
  right: 0;
  top: 47%;
  -webkit-transform: translateY(-47%);
  transform: translateY(-47%);
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-right
  .btn-icon:hover {
  color: #ffffff;
  background-color: #382a1f;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-right
  .btn-icon::after {
  display: none;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-right
  .dropdown
  .dropdown-menu {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  border: none;
  border-radius: 0;
  margin-top: 35px;
  display: block;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: unset !important;
  transform: unset !important;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-right
  .dropdown
  .dropdown-menu.show {
  margin-top: 45px;
  opacity: 1;
  visibility: visible;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-right
  .dropdown
  .dropdown-menu
  .dropdown-item {
  color: #2a2a2a;
  padding: 5px 15px 5px 36px;
  position: relative;
  font-size: 14px;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-right
  .dropdown
  .dropdown-menu
  .dropdown-item
  i {
  color: #686868;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: absolute;
  left: 15px;
  top: 7px;
  font-size: 16px;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-right
  .dropdown
  .dropdown-menu
  .dropdown-item.active,
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-right
  .dropdown
  .dropdown-menu
  .dropdown-item:active {
  color: #2a2a2a;
  background-color: transparent;
  background-color: #f8f9fa;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-right
  .dropdown
  .dropdown-menu
  .dropdown-item:hover
  i {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-scroll-area {
  height: calc(100vh - 338px);
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper {
  padding: 20px;
  margin-bottom: 0;
  list-style-type: none;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item {
  border-radius: 5px;
  border: 1px solid #ffffff;
  padding: 15px 20px;
  width: 100%;
  position: relative;
  font-size: 15px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-bottom: 15px;
  -webkit-box-shadow: 0 0 18px rgba(25, 42, 70, 0.13);
  box-shadow: 0 0 18px rgba(25, 42, 70, 0.13);
  background-color: #ffffff;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item:last-child {
  margin-bottom: 0;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail {
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  cursor: pointer;
  position: relative;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .user-information {
  position: relative;
  padding-left: 65px;
  margin-top: -2px;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  img {
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .name {
  color: #2a2a2a;
  font-size: 16px;
  font-weight: 700;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .name
  .email {
  color: #727e8c;
  font-weight: normal;
  font-size: 15px;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .to {
  color: #71738d;
  font-size: 13px;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .email-information
  .date {
  color: #727e8c;
  font-weight: 500;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .email-information
  .favorite {
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  color: #c7cfd6;
  margin-left: 12px;
  margin-right: 8px;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .email-information
  .favorite.active {
  color: #fdac41;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .email-information
  .favorite.active
  i::before {
  content: "\ed99";
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .email-information
  .dropdown
  .dropdown-toggle {
  padding: 0;
  border: none;
  background-color: transparent;
  color: #727e8c;
  font-size: 22px;
  line-height: 15px;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .email-information
  .dropdown
  .dropdown-toggle::after {
  display: none;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .email-information
  .dropdown
  .dropdown-menu {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  border: none;
  border-radius: 0;
  margin-top: 35px;
  display: block;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: unset !important;
  transform: unset !important;
  left: auto !important;
  right: 0;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .email-information
  .dropdown
  .dropdown-menu.show {
  margin-top: 25px;
  opacity: 1;
  visibility: visible;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .email-information
  .dropdown
  .dropdown-menu
  .dropdown-item {
  color: #2a2a2a;
  padding: 5px 15px 5px 36px;
  position: relative;
  font-size: 14px;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .email-information
  .dropdown
  .dropdown-menu
  .dropdown-item
  i {
  color: #686868;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: absolute;
  left: 15px;
  top: 7px;
  font-size: 16px;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .email-information
  .dropdown
  .dropdown-menu
  .dropdown-item.active,
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .email-information
  .dropdown
  .dropdown-menu
  .dropdown-item:active {
  color: #2a2a2a;
  background-color: transparent;
  background-color: #f8f9fa;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .email-information
  .dropdown
  .dropdown-menu
  .dropdown-item:hover
  i {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-content {
  margin-top: 25px;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-content
  p {
  margin-bottom: 10px;
  color: #727e8c;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-content
  .email-read-list-footer {
  border-top: 1px solid #e8ebf1;
  padding-top: 18px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: -20px;
  margin-top: 20px;
  margin-right: -20px;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-content
  .email-read-list-footer
  label {
  font-size: 14px;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-content
  .email-read-list-footer
  ul
  li {
  margin-bottom: 5px;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-content
  .email-read-list-footer
  ul
  li
  a {
  color: #727e8c;
  text-decoration: none;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-reply-list-item {
  margin-top: 30px;
  margin-bottom: 8px;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-reply-list-item
  a {
  -webkit-box-shadow: 0 0 18px rgba(25, 42, 70, 0.13);
  box-shadow: 0 0 18px rgba(25, 42, 70, 0.13);
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  padding: 10px 30px;
  border-radius: 30px;
  margin-right: 10px;
  text-decoration: none;
  color: #2a2a2a;
  font-size: 15px;
  font-weight: 700;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-reply-list-item
  a:last-child {
  margin-right: 0;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-reply-list-item
  a
  i {
  font-size: 20px;
  position: relative;
  top: 3px;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-reply-list-item
  a:hover {
  color: #ffffff;
  border-color: #382a1f;
  background-color: #382a1f;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-list-footer {
  border-top: 1px solid #e8ebf1;
  padding: 15px 20px;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-list-footer
  p {
  color: #71738d;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-list-footer
  .email-pagination-btn {
  margin-bottom: -5px;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-list-footer
  .email-pagination-btn
  button {
  width: 34px;
  height: 34px;
  background-color: #f3f3f3;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  border: none;
  padding: 0;
  font-size: 18px;
  border-radius: 5px;
  margin-left: 5px;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-list-footer
  .email-pagination-btn
  button
  i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-list-footer
  .email-pagination-btn
  button:hover {
  color: #ffffff;
  background-color: #382a1f;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-list-footer
  .email-pagination-btn
  button.email-pagination-prev {
  border-radius: 5px 0 0 5px;
}

.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-list-footer
  .email-pagination-btn
  button.email-pagination-next {
  border-radius: 0 5px 5px 0;
}

/*================================================
App Email Compose CSS
=================================================*/
.email-compose-content-area {
  margin-bottom: 30px;
}

.email-compose-content-area .sidebar-left {
  float: left;
}

.email-compose-content-area .sidebar-left .sidebar .sidebar-content {
  background-color: #ffffff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  padding: 25px;
  width: 260px;
}

.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-messages
  .list-group-item {
  text-decoration: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 29px;
  margin-bottom: 16px;
  background-color: #ffffff;
  border: none !important;
  color: #2a2a2a;
  border-radius: 0 !important;
  font-size: 16px;
  font-weight: 600;
}

.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-messages
  .list-group-item
  i {
  position: absolute;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  top: 0;
  font-size: 22px;
}

.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-messages
  .list-group-item:last-child {
  margin-bottom: 0;
}

.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-messages
  .list-group-item.active {
  background-color: #ffffff;
  color: #382a1f;
}

.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-messages
  .list-group-item
  .badge {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-left: 4px;
  position: relative;
  top: -1px;
  padding: 4px 6px 4px 6px;
}

.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-messages
  .list-group-item:hover {
  color: #382a1f;
}

.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-messages
  .list-group-item:hover
  i {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  color: #382a1f;
}

.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-label {
  letter-spacing: 1px;
  color: #828d99;
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 600;
}

.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-labels
  .list-group-item {
  text-decoration: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 18px;
  margin-bottom: 12px;
  background-color: #ffffff;
  border: none !important;
  color: #2a2a2a;
  border-radius: 0 !important;
  font-size: 16px;
  font-weight: 600;
}

.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-labels
  .list-group-item::before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  border: 2px solid #382a1f;
}

.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-labels
  .list-group-item:last-child {
  margin-bottom: 0;
}

.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-labels
  .list-group-item.active {
  background-color: #ffffff;
  color: #382a1f;
}

.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-labels
  .list-group-item:hover {
  color: #382a1f;
}

.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-labels
  .list-group-item:nth-child(2)::before {
  border-color: #f79921;
}

.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-labels
  .list-group-item:nth-child(3)::before {
  border-color: #1ebe40;
}

.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-labels
  .list-group-item:nth-child(4)::before {
  border-color: #40fefe;
}

.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-labels
  .list-group-item:nth-child(5)::before {
  border-color: #707070;
}

.email-compose-content-area .content-right {
  float: right;
  width: calc(100% - 290px);
}

.email-compose-content-area .content-right .email-compose-list-wrapper {
  background-color: #ffffff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  border: none;
  border-radius: 5px;
  padding: 25px;
}

.email-compose-content-area .content-right .email-compose-list-wrapper h3 {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 700;
}

.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .card {
  background-color: transparent;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  border-radius: 5px;
  padding: 0;
}

.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .card
  .card-header {
  background-color: transparent;
  margin-bottom: 0;
}

.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .form-control {
  height: 42px;
}

.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .form-control:focus {
  border-color: #b1bbc4;
}

.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .note-editor.note-airframe,
.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .note-editor.note-frame {
  border-color: #ced4da;
}

.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .note-popover
  .popover-content
  > .note-btn-group,
.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .note-toolbar
  > .note-btn-group {
  margin-top: -1px;
}

.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .form-group
  .btn {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 2px;
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
  padding: 10px 25px 9.5px;
  font-size: 15px;
  font-weight: 700;
}

.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .form-group
  .btn
  i {
  position: relative;
  top: 1px;
}

.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .form-group
  .btn.btn-success {
  background-color: #3578e5;
  color: #ffffff;
  border-color: #3578e5;
}

.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .form-group
  .btn.btn-success:hover,
.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .form-group
  .btn.btn-success:focus {
  background-color: #3578e5;
  color: #ffffff;
  border-color: #3578e5;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(102, 88, 221, 0.25) !important;
  box-shadow: 1px 5px 24px 0 rgba(102, 88, 221, 0.25) !important;
}

.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .form-group
  .btn.btn-primary {
  background-color: #382a1f;
  color: #ffffff;
  border-color: #382a1f;
}

.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .form-group
  .btn.btn-primary:hover,
.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .form-group
  .btn.btn-primary:focus {
  background-color: #e33e45;
  color: #ffffff;
  border-color: #e33e45;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(56, 42, 31, 0.25) !important;
  box-shadow: 1px 5px 24px 0 rgba(56, 42, 31, 0.25) !important;
}

/*================================================
FAQ CSS
=================================================*/
.faq-search {
  text-align: center;
  background-color: #ffffff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  border-radius: 5px;
  padding: 35px 25px;
}

.faq-search h2 {
  margin-bottom: 0;
  font-size: 30px;
  font-weight: 700;
}

.faq-search form {
  max-width: 600px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 20px;
}

.faq-search form .form-control {
  background-color: #ffffff;
  color: #2a2a2a;
  border: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 30px;
  -webkit-box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
  box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
  height: 55px;
  font-size: 16px;
  padding-left: 20px;
}

.faq-search form .form-control::-webkit-input-placeholder {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.faq-search form .form-control:-ms-input-placeholder {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.faq-search form .form-control::-ms-input-placeholder {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.faq-search form .form-control::placeholder {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.faq-search form .form-control:focus::-webkit-input-placeholder {
  color: transparent;
}

.faq-search form .form-control:focus:-ms-input-placeholder {
  color: transparent;
}

.faq-search form .form-control:focus::-ms-input-placeholder {
  color: transparent;
}

.faq-search form .form-control:focus::placeholder {
  color: transparent;
}

.faq-search form button {
  position: absolute;
  right: 5px;
  top: 5px;
  border: none;
  text-transform: uppercase;
  border-radius: 30px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding: 12px 30px 11px;
  background-color: #382a1f;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
}

.faq-search form button:hover {
  opacity: 0.5;
}

.faq-search p {
  margin-bottom: 0;
  color: #6084a4;
}

.faq-area {
  background-color: #ffffff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  border-radius: 5px;
  padding: 35px 25px;
}

.faq-area .title {
  margin-bottom: 50px;
  text-align: center;
}

.faq-area .title h2 {
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 700;
}

.faq-area .title p {
  color: #6084a4;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}

.faq-accordion-tab .tabs {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 50px;
  text-align: center;
}

.faq-accordion-tab .tabs li {
  margin-left: 10px;
  margin-right: 10px;
}

.faq-accordion-tab .tabs li a {
  color: #2a2a2a;
  text-decoration: none;
  border-radius: 5px;
  padding: 20px;
  display: block;
  -webkit-box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
  box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
  font-size: 15px;
  font-weight: 700;
}

.faq-accordion-tab .tabs li a i {
  font-size: 30px;
}

.faq-accordion-tab .tabs li a span {
  display: block;
  margin-top: 5px;
}

.faq-accordion-tab .tabs li a:hover {
  background-color: #382a1f;
  color: #ffffff;
}

.faq-accordion-tab .tabs li.current a {
  background-color: #382a1f;
  color: #ffffff;
}

.faq-accordion {
  padding-left: 20px;
  padding-right: 20px;
}

.faq-accordion .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.faq-accordion .accordion .accordion-item {
  display: block;
  -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  background: #ffffff;
  margin-bottom: 10px;
}

.faq-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
}

.faq-accordion .accordion .accordion-title {
  padding: 15px 20px 10px 51px;
  color: #2a2a2a;
  text-decoration: none;
  position: relative;
  display: block;
  font-size: 15px;
  font-weight: 700;
}

.faq-accordion .accordion .accordion-title i {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  text-align: center;
  height: 100%;
  background: #382a1f;
  color: #ffffff;
  font-size: 22px;
}

.faq-accordion .accordion .accordion-title i::before {
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.faq-accordion .accordion .accordion-title.active i::before {
  content: "\eaf8";
}

.faq-accordion .accordion .accordion-content {
  display: none;
  position: relative;
  color: #727e8c;
  padding: 15px 15px;
  border-top: 1px solid #eeeeee;
}

.faq-accordion .accordion .accordion-content.show {
  display: block;
}

.tab_content .tabs_item {
  display: none;
}

.tab_content .tabs_item:first-child {
  display: block;
}

/*================================================
Invoice CSS
=================================================*/
.invoice-area {
  background-color: #ffffff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  border-radius: 5px;
  padding: 25px;
}

.invoice-header h3 {
  font-size: 25px;
  font-weight: 700;
}

.invoice-header p {
  font-weight: 600;
  font-size: 15px;
}

.invoice-middle h4 {
  font-size: 18px;
  font-weight: 700;
}

.invoice-middle h5 {
  margin-bottom: 12px;
  font-size: 15px;
  font-weight: 700;
}

.invoice-middle h5 sub {
  margin-left: 50px;
  bottom: 0;
  width: 90px;
  display: inline-block;
  color: #6084a4;
  font-weight: 500;
  font-size: 15px;
}

.invoice-middle span {
  font-weight: 600;
  font-size: 15px;
}

.invoice-table table {
  margin-bottom: 0;
}

.invoice-table table thead th {
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid #dee2e6;
  font-size: 15px;
  font-weight: 700;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.invoice-table table tbody td {
  vertical-align: middle;
  color: #6084a4;
  border-width: 1px;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.invoice-table table tbody td.total,
.invoice-table table tbody td.total-price {
  color: #2a2a2a;
  font-size: 17px;
}

.invoice-btn-box .default-btn {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-decoration: none;
  padding: 13px 35px 12px;
  border-radius: 30px;
  text-transform: uppercase;
  display: inline-block;
  transition: 0.5s;
  background-color: #382a1f;
  color: #ffffff;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 700;
}

.invoice-btn-box .default-btn:hover,
.invoice-btn-box .default-btn:focus {
  background-color: #2a2a2a;
  color: #ffffff;
}

.invoice-btn-box .optional-btn {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-decoration: none;
  padding: 13px 35px 12px;
  border-radius: 30px;
  text-transform: uppercase;
  display: inline-block;
  transition: 0.5s;
  background-color: #3578e5;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
}

.invoice-btn-box .optional-btn:hover,
.invoice-btn-box .optional-btn:focus {
  background-color: #2a2a2a;
  color: #ffffff;
}

/*================================================
Pricing CSS
=================================================*/
.single-pricing-box {
  margin-bottom: 30px;
  text-align: center;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.08);
  padding: 40px 30px 28px 30px;
  border-radius: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-pricing-box .pricing-header {
  position: relative;
  z-index: 1;
  background-color: #382a1f;
  margin: -40px -30px 30px -30px;
  border-radius: 5px 5px 0 0;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 25px;
}

.single-pricing-box .pricing-header h3 {
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
}

.single-pricing-box .price {
  color: #2a2a2a;
  line-height: 50px;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  position: relative;
  z-index: 1;
  font-size: 40px;
  font-weight: 700;
}

.single-pricing-box .price sub {
  line-height: 16px;
  color: #6084a4;
  margin: 0 -8px 0 -9px;
  position: relative;
  top: -2px;
  display: inline-block;
  font-weight: 600;
  font-size: 16px;
}

.single-pricing-box .price-features-list {
  text-align: left;
  padding: 30px 30px;
  margin: 25px -30px 30px -30px;
  list-style-type: none;
  border-bottom: 1px solid #eeeeee;
  border-top: 1px solid #eeeeee;
}

.single-pricing-box .price-features-list li {
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 25px;
  color: #6084a4;
  font-weight: 500;
  font-size: 16px;
}

.single-pricing-box .price-features-list li i {
  font-size: 20px;
  color: #382a1f;
  position: absolute;
  right: 0;
  top: 10px;
}

.single-pricing-box .price-features-list li i.bx-check-double {
  color: #6ac04f;
}

.single-pricing-box .price-features-list li i.bx-x {
  color: #dd5252;
}

.single-pricing-box .get-started-btn {
  display: inline-block;
  color: #382a1f;
  position: relative;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
}

.single-pricing-box .get-started-btn::before {
  width: 0;
  height: 1px;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #382a1f;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-pricing-box .get-started-btn:hover::before {
  width: 100%;
}

.single-pricing-box.red .pricing-header {
  background-color: #fc6765;
}

.single-pricing-box.red .get-started-btn {
  color: #fc6765;
}

.single-pricing-box.red .get-started-btn::before {
  background-color: #fc6765;
}

.single-pricing-box.orange .pricing-header {
  background-color: #ffa143;
}

.single-pricing-box.orange .get-started-btn {
  color: #ffa143;
}

.single-pricing-box.orange .get-started-btn::before {
  background-color: #ffa143;
}

.single-pricing-box:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

/*================================================
Timeline CSS
=================================================*/
.timeline {
  position: relative;
}

.timeline::before {
  position: absolute;
  content: "";
  width: 4px;
  height: 100%;
  background-color: #382a1f;
  left: 14px;
  top: 5px;
  border-radius: 4px;
}

.timeline .timeline-month {
  position: relative;
  padding: 4px 15px 4px 35px;
  background-color: #382a1f;
  display: inline-block;
  border-radius: 30px;
  margin-bottom: 30px;
  color: #ffffff;
  font-weight: 700;
}

.timeline .timeline-month::before {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  border-radius: 100%;
  left: 5px;
  top: 5px;
}

.timeline .timeline-section {
  padding-left: 35px;
  display: block;
  position: relative;
  margin-bottom: 30px;
}

.timeline .timeline-section .timeline-date {
  margin-bottom: 15px;
  padding: 3px 15px;
  background-color: #294f50;
  position: relative;
  display: inline-block;
  border-radius: 20px;
  color: #ffffff;
  font-weight: 600;
}

.timeline .timeline-section::before {
  content: "";
  position: absolute;
  width: 30px;
  height: 1px;
  background-color: #294f50;
  top: 12px;
  left: 20px;
}

.timeline .timeline-section::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #294f50;
  top: 7px;
  left: 11px;
  border-radius: 50%;
}

.timeline .timeline-section .col-lg-4 {
  margin-bottom: 15px;
}

.timeline .timeline-section .timeline-box {
  position: relative;
  background-color: #f7f7f7;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  overflow: hidden;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  border-radius: 15px;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
}

.timeline .timeline-section .timeline-box .box-title {
  padding: 10px 15px;
  font-weight: 600;
}

.timeline .timeline-section .timeline-box .box-title i {
  margin-right: 2px;
  font-size: 18px;
  position: relative;
  top: 2px;
}

.timeline .timeline-section .timeline-box .box-content {
  padding: 15px;
  background-color: #ffffff;
  border-top: 1px solid #eeeeee;
}

.timeline .timeline-section .timeline-box .box-content .box-item {
  margin-bottom: 10px;
  color: #606060;
  font-size: 14px;
}

.timeline .timeline-section .timeline-box .box-content .box-item-invalid {
  margin-bottom: 10px;
  color: #FF0000;
  font-size: 14px;
}   

.timeline .timeline-section .timeline-box .box-content .box-item-paused {
  margin-bottom: 10px;
  color: #ebc034;
  font-size: 14px;
}

.timeline .timeline-section .timeline-box .box-content .box-item:last-child {
  margin-bottom: 0;
}

.timeline .timeline-section .timeline-box .box-content .box-item strong {
  color: #2a2a2a;
  font-style: italic;
}

.timeline .timeline-section .timeline-box .box-footer {
  padding: 10px 15px;
  background-color: #2a2a2a;
  text-align: right;
  font-style: italic;
  color: #ffffff;
}

/*================================================
Footer CSS
=================================================*/
.footer-area {
  padding-bottom: 25px;
  border-top: 1px solid #eeeeee;
  padding-top: 25px;
}

.footer-area p {
  font-size: 15px;
  margin-bottom: 0;
  color: #686868;
}

.footer-area p a {
  display: inline-block;
  color: #686868;
}

.footer-area p a:hover {
  color: #382a1f;
  text-decoration: none;
}

.pagination {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
}

.pagination li {
  display: block;
  float: left;
  padding: 5px;
}

.pagination li:first-child {
  border: none;
}

.pagination button,
.pagination span {
  background: none;
  border: none;
  border-radius: 50%;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.6);
  display: block;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  min-width: 40px;
  padding: 0;
}

.pagination button {
  outline: none;
  position: relative;
  -webkit-transition: all 170ms linear;
  transition: all 170ms linear;
}

.pagination button:before {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  content: "";
  cursor: pointer;
  height: 0;
  left: 50%;
  opacity: 0;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 170ms linear;
  transition: all 170ms linear;
  top: 50%;
  width: 0;
}

.pagination button:hover:not(.active) {
  color: black;
}

.pagination button:hover:not(.active):before {
  -webkit-animation: hoverAnimation 510ms linear forwards;
  animation: hoverAnimation 510ms linear forwards;
  height: 40px;
  width: 40px;
}

.pagination button.active {
  background: rgba(0, 0, 0, 0.1);
  color: black;
}

.pagination .prev,
.pagination .next {
  font-size: 14px;
}

/* toast messages */
.custom-toast {
  text-align: left;
  padding: 20px 0;
  background-color: #fff;
  border-radius: 4px;
  max-width: 500px;
  top: 0px;
  position: relative;
  box-shadow: 1px 7px 14px -5px rgba(0, 0, 0, 0.2);
  opacity: 1;
  margin-bottom: 15px;
}

.custom-toast:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.custom-toast .icon {
  position: absolute;
  top: 20px;
  left: 22px;
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: 20px;
  color: #fff;
  font-size: 1.2rem;
}

.custom-toast .type {
  color: #3e3e3e;
  font-weight: 700;
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
}

.custom-toast .message {
  font-size: 14px;
  margin: 0;
  padding: 0;
  color: #878787;
}

.custom-toast .content {
  padding-left: 70px;
  padding-right: 60px;
}

.custom-toast-close {
  position: absolute;
  right: 22px;
  top: 50%;
  width: 14px;
  cursor: pointer;
  height: 14px;
  fill: #878787;
  transform: translateY(-50%);
  display: inline-block;
  width: auto;
  height: auto;
}

.toast--green .icon {
  background-color: #2bde3f;
}

.toast--green:before {
  background-color: #2bde3f;
}

.toast--blue .icon {
  background-color: #1d72f3;
}

.toast--blue:before {
  background-color: #1d72f3;
}

.toast--yellow .icon {
  background-color: #ffc007;
}

.toast--yellow:before {
  background-color: #ffc007;
}

/* error box */

#error-container {
  position: relative;
  margin: auto;
  overflow: hidden;
  width: 700px;
  height: 250px;
}

#error-container h1 {
  font-size: 0.9em;
  font-weight: 100;
  letter-spacing: 3px;
  padding-top: 5px;
  color: #fcfcfc;
  padding-bottom: 5px;
  text-transform: uppercase;
  margin: 0;
}

#error-container .green {
  color: #4ec07d;
}

#error-container .red {
  color: #e96075;
}

#error-container .alert {
  font-weight: 700;
  letter-spacing: 5px;
}

#error-container p {
  margin-top: -5px;
  font-size: 0.5em;
  font-weight: 100;
  color: #5e5e5e;
  letter-spacing: 1px;
}

#error-container button,
.dot {
  cursor: pointer;
}

#error-container #success-box {
  position: absolute;
  width: 35%;
  height: 100%;
  left: 12%;
  background: linear-gradient(to bottom right, #b0db7d 40%, #99dbb4 100%);
  border-radius: 20px;
  box-shadow: 5px 5px 20px #cbcdd3;
  perspective: 40px;
}

#error-container #error-box {
  position: absolute;
  width: 35%;
  height: 100%;
  right: 12%;
  background: linear-gradient(to bottom left, #ef8d9c 40%, #ffc39e 100%);
  border-radius: 20px;
  box-shadow: 5px 5px 20px #cbcdd3;
}

#error-container .dot {
  width: 8px;
  height: 8px;
  background: #fcfcfc;
  border-radius: 50%;
  position: absolute;
  top: 4%;
  right: 6%;
}

#error-container .dot:hover {
  background: #c9c9c9;
}

#error-container .two {
  right: 12%;
  opacity: 0.5;
}

#error-container .face {
  position: absolute;
  width: 22%;
  height: 22%;
  background: #fcfcfc;
  border-radius: 50%;
  border: 1px solid #777777;
  top: 21%;
  left: 37.5%;
  z-index: 2;
  animation: bounce 1s ease-in infinite;
}

#error-container .face2 {
  position: absolute;
  width: 22%;
  height: 22%;
  background: #fcfcfc;
  border-radius: 50%;
  border: 1px solid #777777;
  top: 21%;
  left: 37.5%;
  z-index: 2;
  animation: roll 3s ease-in-out infinite;
}

#error-container .eye {
  position: absolute;
  width: 5px;
  height: 5px;
  background: #777777;
  border-radius: 50%;
  top: 40%;
  left: 20%;
}

#error-container .right {
  left: 68%;
}

#error-container .mouth {
  position: absolute;
  top: 43%;
  left: 41%;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

#error-container .happy {
  border: 2px solid;
  border-color: transparent #777777 #777777 transparent;
  transform: rotate(45deg);
}

#error-container .sad {
  top: 49%;
  border: 2px solid;
  border-color: #777777 transparent transparent #777777;
  transform: rotate(45deg);
}

#error-container .shadow {
  position: absolute;
  width: 21%;
  height: 3%;
  opacity: 0.5;
  background: #777777;
  left: 40%;
  top: 43%;
  border-radius: 50%;
  z-index: 1;
}

#error-container .scale {
  animation: scale 1s ease-in infinite;
}

#error-container .move {
  animation: move 3s ease-in-out infinite;
}

#error-container .message {
  position: absolute;
  width: 100%;
  text-align: center;
  height: 40%;
  top: 47%;
}

#error-container .button-box {
  position: absolute;
  background: #fcfcfc;
  width: 50%;
  height: 15%;
  border-radius: 20px;
  top: 73%;
  left: 25%;
  outline: 0;
  border: none;
  box-shadow: 2px 2px 10px rgba(119, 119, 119, 0.5);
  transition: all 0.5s ease-in-out;
}

#error-container .button-box:hover {
  background: #efefef;
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
}

@keyframes bounce {
  50% {
    transform: translateY(-10px);
  }
}

@keyframes scale {
  50% {
    transform: scale(0.9);
  }
}

@keyframes roll {
  0% {
    transform: rotate(0deg);
    left: 25%;
  }

  50% {
    left: 60%;
    transform: rotate(168deg);
  }

  100% {
    transform: rotate(0deg);
    left: 25%;
  }
}

@keyframes move {
  0% {
    left: 25%;
  }

  50% {
    left: 60%;
  }

  100% {
    left: 25%;
  }
}

/* notification */
#notification-screen {
  width: 100%;
  height: auto;
  padding: 15px 0;
  position: relative;
}

#notification-screen .notification {
  height: 40px;
  width: 250px;
  background-color: #1d72f3;
  transform-origin: right;
  padding: 5px 0;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 5px 0 0 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-animation: notify 3s ease-in-out infinite;
  animation: notify 3s ease-in-out infinite;
}

@-webkit-keyframes notify {
  0% {
    transform: scaleX(0);
  }

  10% {
    transform: scaleX(1);
  }

  13% {
    transform: scale(1.1);
  }

  16% {
    transform: scale(1);
  }

  55% {
    transform: scaleX(1);
    border-radius: 0%;
  }

  65% {
    transform: scaleX(0.16);
    border-radius: 50%;
  }

  95% {
    transform: scaleX(0.16);
    border-radius: 50%;
  }

  100% {
    transform: scaleX(0);
  }
}

@keyframes notify {
  0% {
    transform: scaleX(0);
  }

  10% {
    transform: scaleX(1);
  }

  13% {
    transform: scale(1.1);
  }

  16% {
    transform: scale(1);
  }

  55% {
    transform: scaleX(1);
    border-radius: 0%;
  }

  65% {
    transform: scaleX(0.16);
    border-radius: 50%;
  }

  95% {
    transform: scaleX(0.16);
    border-radius: 50%;
  }

  100% {
    transform: scaleX(0);
  }
}

#notification-screen .identifier {
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #0748ab;
  top: 10px;
  left: 10px;
  -webkit-animation: exit 3s ease-in-out infinite;
  animation: exit 3s ease-in-out infinite;
}

#notification-screen .text {
  margin-left: 40px;
  color: #fff;
  margin-bottom: 0;
  line-height: 1.2;
  -webkit-animation: exit 3s ease-in-out infinite;
  animation: exit 3s ease-in-out infinite;
}

@-webkit-keyframes exit {
  0% {
    opacity: 1;
  }

  55% {
    opacity: 1;
  }

  65% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes exit {
  0% {
    opacity: 1;
  }

  55% {
    opacity: 1;
  }

  65% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

#notification-screen .number {
  position: absolute;
  font-size: 14px;
  color: #fff;
  margin: 0;
  font-size: 1.1rem;
  top: 4px;
  right: 15px;
  -webkit-animation: enter 3s ease-in-out infinite;
  animation: enter 3s ease-in-out infinite;
}

#notification-screen .number p {
  margin: 0;
}

@-webkit-keyframes enter {
  0% {
    opacity: 0;
  }

  55% {
    opacity: 0;
  }

  65% {
    opacity: 1;
    transform: scaleX(1);
  }

  95% {
    opacity: 1;
    transform: scaleX(1);
  }

  100% {
    opacity: 0;
    transform: scaleX(1);
  }
}

@keyframes enter {
  0% {
    opacity: 0;
  }

  55% {
    opacity: 0;
  }

  65% {
    opacity: 1;
    transform: scaleX(1);
  }

  95% {
    opacity: 1;
    transform: scaleX(1);
  }

  100% {
    opacity: 0;
    transform: scaleX(1);
  }
}

/* sidenav footer */

/*  */
.form-control-flush {
  padding-left: 10px;
  height: 45px;
  box-shadow: none !important;
  border: 1px solid #eee !important;
}

.filterbtn {
  box-shadow: none !important;
  padding: 10px;
  height: 45px;
  white-space: nowrap;
}

.filterbtn .badge-primary {
  background-color: #382a1f;
}

/*  */

/*================================================
Right Side Form CSS
=================================================*/

/*Right*/
.modal.right {
  z-index: 10000;
}

.modal-dialog {
  pointer-events: all;
}

.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 100%;
  max-width: 650px;;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
  border-radius: 0;
  border: none;
  padding-top: 70px;
  padding-bottom: 70px;
  z-index: 1;
}

.modal.right.fade .modal-dialog {
  right: -650px;
  pointer-events: all;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
  right: 0px;
  pointer-events: all;
}

.modal.right .modal-header,
.modal.right .modal-footer {
  position: absolute;
  width: 100%;
  z-index: 10000000 !important;
  background: #f7f7f7;
  left: 0;
  right: 0;
  top: 0;
  height: 70px;
}

.modal.right .modal-footer {
  top: auto;
  bottom: 0;
  height: auto;
}

.modal.right .modal-header .modal-title {
  font-size: 1.3rem;
  font-weight: 600;
}

/* Profile */
.modal.right.fade.profileModal .modal-dialog {
  width: 400px;
}

.modal.right.profileModal .modal-dialog {
  right: -400px;
}

.modal.right.profileModal .modal-content {
  padding: 0;
}

.profileModal .figure {
  max-width: 80px;
  max-height: 80px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  padding: 3px;
  border: 2px solid #9e9e9e;
  overflow: hidden;
  margin: auto;
  display: block;
}

/* End Profile */

/*full modal*/
.fullWidth.modal.right .modal-dialog.fullWd {
  width: 100%;
  max-width: 100%;
}

.fullWidth.modal.right.fade .modal-dialog.fullWd {
  right: -100%;
}

.fullWidth.modal.right.show .modal-dialog.fullWd {
  right: 0px;
}

/*full modal*/

/* Radio Buton */

.bulgy-radios {
  width: auto;
  background: #fff;
  padding: 0;
  border-radius: 0;
  display: inline-block;
}

.bulgy-radios label {
  display: inline-block !important;
  position: relative;
  min-height: 25px;
  padding-left: 30px;
  margin-bottom: 0 !important;
  cursor: pointer;
  font-size: 1rem !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #555 !important;
  letter-spacing: 1px;
}

.bulgy-radios label:hover input:not(:checked) ~ .radio {
  opacity: 0.8;
}

.bulgy-radios .label {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 0.8rem;
  padding-right: 1.2rem;
}

.bulgy-radios .label span {
  line-height: 1em;
}

.bulgy-radios input {
  position: absolute;
  cursor: pointer;
  height: 0;
  width: 0;
  left: -2000px;
}

.bulgy-radios input:checked ~ .radio {
  background-color: #382a1f;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}

.bulgy-radios input:checked ~ .radio::after {
  opacity: 1;
  /* background-color: #d08a56; */
}

.bulgy-radios input:checked ~ .label {
  color: #382a1f;
}

.bulgy-radios input:checked ~ .label span {
  -webkit-animation: bulge 0.5s forwards;
  animation: bulge 0.5s forwards;
}

.bulgy-radios input:checked ~ .label span:nth-child(1) {
  -webkit-animation-delay: 0.025s;
  animation-delay: 0.025s;
}

.bulgy-radios input:checked ~ .label span:nth-child(2) {
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
}

.bulgy-radios input:checked ~ .label span:nth-child(3) {
  -webkit-animation-delay: 0.075s;
  animation-delay: 0.075s;
}

.bulgy-radios input:checked ~ .label span:nth-child(4) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.bulgy-radios input:checked ~ .label span:nth-child(5) {
  -webkit-animation-delay: 0.125s;
  animation-delay: 0.125s;
}

.bulgy-radios input:checked ~ .label span:nth-child(6) {
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}

.bulgy-radios input:checked ~ .label span:nth-child(7) {
  -webkit-animation-delay: 0.175s;
  animation-delay: 0.175s;
}

.bulgy-radios input:checked ~ .label span:nth-child(8) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.bulgy-radios input:checked ~ .label span:nth-child(9) {
  -webkit-animation-delay: 0.225s;
  animation-delay: 0.225s;
}

.bulgy-radios input:checked ~ .label span:nth-child(10) {
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}

.bulgy-radios input:checked ~ .label span:nth-child(11) {
  -webkit-animation-delay: 0.275s;
  animation-delay: 0.275s;
}

.bulgy-radios input:checked ~ .label span:nth-child(12) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.bulgy-radios input:checked ~ .label span:nth-child(13) {
  -webkit-animation-delay: 0.325s;
  animation-delay: 0.325s;
}

.bulgy-radios input:checked ~ .label span:nth-child(14) {
  -webkit-animation-delay: 0.35s;
  animation-delay: 0.35s;
}

.bulgy-radios input:checked ~ .label span:nth-child(15) {
  -webkit-animation-delay: 0.375s;
  animation-delay: 0.375s;
}

.bulgy-radios input:checked ~ .label span:nth-child(16) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.bulgy-radios input:checked ~ .label span:nth-child(17) {
  -webkit-animation-delay: 0.425s;
  animation-delay: 0.425s;
}

.bulgy-radios input:checked ~ .label span:nth-child(18) {
  -webkit-animation-delay: 0.45s;
  animation-delay: 0.45s;
}

.bulgy-radios input:checked ~ .label span:nth-child(19) {
  -webkit-animation-delay: 0.475s;
  animation-delay: 0.475s;
}

.radio {
  position: absolute;
  top: 0.2rem;
  left: 0;
  height: 20px;
  width: 20px;
  background: #c9ded6;
  border-radius: 50%;
}

.radio::after {
  content: "";
  position: absolute;
  opacity: 0;
  top: 3px;
  left: 3px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #fff;
}

@-webkit-keyframes bulge {
  50% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
    font-size: 1.5em;
    font-weight: bold;
  }

  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    font-size: 1em;
    font-weight: bold;
  }
}

@keyframes bulge {
  50% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
    font-size: 1.5em;
    font-weight: bold;
  }

  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    font-size: 1em;
    font-weight: bold;
  }
}

/* End Radio Buton */

/* custom file */
.box {
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 5px;
  color: #2a2a2a;
  font-size: 14px;
  height: 44px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding: 0;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  max-width: 100%;
  font-size: 1.25rem;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 0.625rem 1.25rem;
  color: #382a1f !important;
}

.inputfile + label svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  margin-top: -0.25em;
  margin-right: 0.25em;
}

/* end custom file */
/* for error msg */
.invalid-feedback {
  display: block;
  text-align: left;
}

/* for error msg */

/* mega dropdown */

/* .mega-menu .dropdown-toggle {
  font-size: 20px !important;
} */

.mega-menu.nm .dropdown-toggle {
  font-size: 16px !important;
}

.mega-menu .dropdown-menu {
  /* border-top: 3px solid #382a1f !important; */
  padding: 15px;
  top: 42px;
  left: 0 !important;
  right: auto !important;
  width: 200px;
}

.mega-menu .dropdown-menu::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: -10px;
  left: 10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
}

.subLevel .dropdown-item {
  position: relative;
}

.subLevel .dropdown-item::before {
  content: "";
  position: relative;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAlUlEQVRIS+3UQQ2AMBBE0V8HOAEJ4ABHIAEnSAAJ4AQJZG/Age5s0oQDPTfzutO0icIrFc7nB7INf6qiEZiAI3vsywbvBBY+ABvQKYgXqIAVqFXEC9jQIUQBQogKyEgUWIAG2IH27dJVwO7BHW7jKoAcrgChcAXogdnT+fOVKxUZYo+tyFehfD+3vcoEIeQHsrUVr+gEKn4iGe4d4fkAAAAASUVORK5CYII=");
  display: inline-block;
  background-size: 100%;
  background-position: center;
  height: 15px;
  width: 15px;
  top: 3px;
}

@media screen and (min-width: 992px) {
  .mega-menu .dropdown-menu .nav-item-title {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 800;
    color: #666;
  }

  .mega-menu .dropdown-menu .nav-item .dropdown-item {
    padding: 3px 0 !important;
    font-size: 16px !important;
    background-color: transparent !important;
    font-weight: 600;
    text-transform: capitalize;
  }

  .mega-menu .dropdown-menu .nav-item .dropdown-item:hover {
    color: #d08a56 !important;
  }

  .mega-menu .dropdown-menu .nav-item {
    margin: 0;
  }

  .mega-menu .dropdown:hover .dropdown-menu,
  .mega-menu .dropdown .dropdown-menu:hover {
    display: block;
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.3s linear;
  }

  .mega-menu .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.15);
    background-color: #fff;
  }
}

/* end mega dropdown */

/* project stage */
.project-stage {
  height: auto;
  padding: 15px 0;
  margin-bottom: 25px !important;
}

.project-stage ul {
  margin: 0;
  padding: 0;
  min-height: 85px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.project-stage ul li {
  width: calc(100% / 10 - 14px);
  margin: 0;
  min-height: 85px;
}

.project-stage ul li:not(:last-child) {
  margin-right: 10px;
}

.project-stage ul li:first-child {
  margin-right: 20px;
}

.project-stage ul li:nth-last-child(2) {
  margin-right: 0;
}

.project-stage ul li:last-child {
  margin-left: 20px;
}

.project-stage ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  text-decoration: none;
  color: #2a2a2a;
  background-color: #f7f7f7;
  width: 100%;
  height: 100%;
  padding: 30px 10px 20px;
  position: relative;
}

.project-stage ul li a.active {
  background-color: #47505e;
  background-color: #382a1f;
  color: #fff;
}

.project-stage ul li a::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
  right: -8px;
  z-index: 5;
  border-left: 8px solid #f7f7f7;
  -webkit-transition: border 0.3s ease;
  -moz-transition: border 0.3s ease;
  -o-transition: border 0.3s ease;
  -ms-transition: border 0.3s ease;
  transition: border 0.3s ease;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
}

.project-stage ul li a.active::before {
  border-left-color: #382a1f;
}

.project-stage ul li:last-child a::before,
.project-stage ul li:first-child a::before,
.project-stage ul li:nth-last-child(2) a::before {
  display: none;
}

.project-stage ul li a span {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}

.project-stage ul li a span.counts {
  font-size: 26px;
}

.project-stage ul li a.active span.counts {
  font-weight: 700;
}

.project-stage ul li a small {
  position: absolute;
  top: 10px;
  left: 10px;
  text-transform: uppercase;
  font-size: 12px;
}

.project-stage ul li a small i {
  margin-right: 5px;
  color: #22af47 !important;
}

.project-stage ul li a small.archived-icon {
  position: static;
  font-size: 16px;
  color: #51565d !important;
}

/* end project stage */

/* custom card */
.card.card-custom {
  -webkit-box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
  border: 0;
}

.card.card-custom > .card-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  min-height: 70px;
  padding-top: 0;
  padding-bottom: 0;
  background-color: transparent;
}

.card.card-custom > .card-header .card-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0.5rem;
  margin-left: 0;
}

.card.card-custom > .card-header .card-title .card-icon {
  margin-right: 0.75rem;
  line-height: 0;
}

.card.card-custom > .card-header .card-title .card-icon i {
  font-size: 1.25rem;
  color: #7e8299;
  line-height: 0;
}

.card.card-custom > .card-header .card-title .card-icon i:after,
.card.card-custom > .card-header .card-title .card-icon i:before {
  line-height: 0;
}

.card.card-custom > .card-header .card-title .card-icon .svg-icon svg {
  height: 24px;
  width: 24px;
}

.card.card-custom > .card-header .card-title .card-icon .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #7e8299;
}

.card.card-custom
  > .card-header
  .card-title
  .card-icon
  .svg-icon
  svg:hover
  g
  [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.card.card-custom > .card-header .card-title,
.card.card-custom > .card-header .card-title .card-label {
  font-weight: 500;
  font-size: 1.275rem;
  color: #181c32;
}

.card.card-custom > .card-header .card-title .card-label {
  margin: 0 0.75rem 0 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.card.card-custom > .card-header .card-title small {
  color: #b5b5c3;
  font-size: 1rem;
}

.card.card-custom > .card-header .card-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0.5rem 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.card.card-custom > .card-header.card-header-tabs-line {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.card.card-custom > .card-header.card-header-tabs-line .card-toolbar {
  margin: 0;
}

.card.card-custom > .card-header.card-header-tabs-line .nav {
  border-bottom-color: transparent;
}

.card.card-custom > .card-header.card-header-tabs-line .nav .nav-item {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.card.card-custom > .card-header.card-header-tabs-line .nav .nav-link {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.card.card-custom > .card-header.card-header-right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.card.card-custom > .card-footer {
  background-color: transparent;
}

.symbol {
  display: inline-block;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
  border-radius: 0.42rem;
}

.symbol > img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 0.42rem;
}

.symbol.symbol-primary .symbol-label {
  background-color: #3699ff;
  color: #ffffff;
}

.font-size-h5 {
  font-size: 1.25rem !important;
}

.symbol-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.symbol-group .symbol {
  position: relative;
  z-index: 0;
  margin-left: -10px;
  border: 2px solid #ffffff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.symbol-group .symbol:first-child {
  margin-left: 0;
}

.symbol-group .symbol:hover {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 1;
}

.symbol-group.symbol-hover .symbol {
  cursor: pointer;
}

.symbol.symbol-circle {
  border-radius: 50%;
}

.symbol .symbol-label {
  width: 50px;
  height: 50px;
}

.symbol .symbol-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 500;
  line-height: 0;
  color: #3f4254;
  background-color: #f3f6f9;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 0.42rem;
}

.symbol.symbol-circle > img {
  border-radius: 50%;
}

.symbol.symbol-circle .symbol-label {
  border-radius: 50%;
}

.symbol.symbol-light-primary .symbol-label {
  background-color: #e1f0ff;
  color: #3699ff;
}

.font-size-h4 {
  font-size: 1.35rem !important;
}

.symbol.symbol-30 > img {
  width: 100%;
  max-width: 30px;
  height: 30px;
}

.symbol.symbol-30 .symbol-label {
  width: 30px;
  height: 30px;
}

.symbol.symbol-30 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}

.symbol.symbol-30 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}

.symbol.symbol-30.symbol-circle .symbol-badge {
  top: -5.85px;
  right: -5.85px;
}

.symbol.symbol-30.symbol-circle .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -5.85px;
}

.symbol.symbol-30.symbol-fixed .symbol-label {
  width: 30px;
  height: 30px;
}

.symbol.symbol-30.symbol-fixed > img {
  width: 30px;
  height: 30px;
  max-width: auto;
}

.symbol.symbol-30.symbol-2by3 .symbol-label {
  height: 30px;
  width: 45px;
}

.symbol.symbol-30.symbol-2by3 > img {
  height: 30px;
  width: 45px;
  max-width: auto;
}

.symbol.symbol-40 > img {
  width: 100%;
  max-width: 40px;
  height: 40px;
}

.symbol.symbol-40 .symbol-label {
  width: 40px;
  height: 40px;
}

.symbol.symbol-40 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}

.symbol.symbol-40 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}

.symbol.symbol-40.symbol-circle .symbol-badge {
  top: -3.8px;
  right: -3.8px;
}

.symbol.symbol-40.symbol-circle .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -3.8px;
}

.symbol.symbol-40.symbol-fixed .symbol-label {
  width: 40px;
  height: 40px;
}

.symbol.symbol-40.symbol-fixed > img {
  width: 40px;
  height: 40px;
  max-width: auto;
}

.symbol.symbol-40.symbol-2by3 .symbol-label {
  height: 40px;
  width: 60px;
}

.symbol.symbol-40.symbol-2by3 > img {
  height: 40px;
  width: 60px;
  max-width: auto;
}

.symbol.symbol-light-warning .symbol-label {
  background-color: #fff4de;
  color: #ffa800;
}

.symbol.symbol-light .symbol-label {
  background-color: #f3f6f9;
  color: #7e8299;
}

.separator {
  height: 0;
}

.separator.separator-solid {
  border-bottom: 1px solid #ebedf3;
}

.text-dark-50 {
  color: #7e8299 !important;
}

.btn.btn-light-primary {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}

.btn.btn-light-danger {
  color: #f64e60;
  background-color: #ffe2e5;
  border-color: transparent;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #ebedf3;
  border-radius: 0.42rem;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #3699ff;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    -webkit-transition: none;
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.w-150px {
  width: 150px !important;
}

@media (min-width: 1200px) {
  .w-xl-300px {
    width: 300px !important;
  }
}

.progress.progress-xs {
  height: 0.5rem;
}

.icon-2x {
  font-size: 2rem !important;
}

.font-size-sm {
  font-size: 0.925rem;
}

.text-dark-75 {
  color: #3f4254 !important;
}

/* btn */
.material-button,
.wizard > .actions .disabled a,
.wizard > .actions a,
.wizard > .actions a:hover,
.wizard > .actions a:active {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  padding: 0 16px;
  min-width: 64px;
  height: 45px;
  vertical-align: middle;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255)) !important;
  /* background-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243)); */
  background-color: #382a1f;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  font-size: 14px;
  font-weight: 500;
  line-height: 3.5;
  overflow: hidden;
  outline: none;
  border-radius: 30px;
  cursor: pointer;
  transition: box-shadow 0.2s;
}

.material-button::-moz-focus-inner {
  border: none;
}

/* Overlay */
.material-button::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #382a1f;
  opacity: 0;
  transition: opacity 0.2s;
}

/* Ripple */
.material-button::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  padding: 50%;
  width: 32px;
  /* Safari */
  height: 32px;
  /* Safari */
  background-color: #382a1f;
  opacity: 0;
  transform: translate(-50%, -50%) scale(1);
  transition: opacity 1s, transform 0.5s;
}

/* Hover, Focus */
/* ,
.profile-header .user-profile-nav .nav.mt-tabs .nav-item .nav-link:hover,
.profile-header .user-profile-nav .nav.mt-tabs .nav-item .nav-link:focus */
.material-button:hover,
.material-button:focus,
.wizard > .actions a:hover,
.wizard > .actions a:active {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.material-button:hover::before,
.profile-header
  .user-profile-nav
  .nav.mt-tabs
  .nav-item
  .nav-link:hover::before {
  opacity: 0.08;
}

.material-button:focus::before,
.profile-header
  .user-profile-nav
  .nav.mt-tabs
  .nav-item
  .nav-link:focus::before {
  opacity: 0.24;
}

.material-button:hover:focus::before,
.profile-header
  .user-profile-nav
  .nav.mt-tabs
  .nav-item
  .nav-link:hover:focus::before {
  opacity: 0.3;
}

/* Active */
/* .profile-header .user-profile-nav .nav.mt-tabs .nav-item .nav-link.active */
.material-button:active {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

/* .profile-header .user-profile-nav .nav.mt-tabs .nav-item .nav-link.active::after  */
.material-button:active::after {
  opacity: 0.32;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0s;
}

/* Disabled */
.material-button:disabled {
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
  background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.12);
  box-shadow: none;
  cursor: initial;
}

.material-button:disabled::before {
  opacity: 0;
}

.material-button:disabled::after {
  opacity: 0;
}

.material-button.bg-danger:hover {
  background-color: #dc3545 !important;
}

.btn-group .material-button + button {
  background-color: #382a1f;
  color: #fff;
  box-shadow: none !important;
}

.btn-group .material-button + button:hover {
  color: #fff;
}

.sv-drop .dropdown-item {
  padding: 8px 15px;
}

.sv-drop .dropdown-item i {
  margin-right: 8px;
  font-weight: 600;
}

/* .profile-header .user-profile-nav .nav.mt-tabs .nav-item .nav-link {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border: none;
  background-color: #382a1f;
  color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255)) !important;
} */

.bg-mt,
.wizard > .actions .disabled a {
  background-color: #e9f5fe;
  color: #2a2a2a !important;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.bg-mt:hover {
  color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255)) !important;
  background-color: #382a1f;
}

.wizard > .actions .disabled a:hover,
.wizard > .actions .disabled a:active {
  border: none;
  opacity: 1;
}

.bg-mt-light {
  background-color: transparent;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  color: #2a2a2a !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.bg-mt-light:hover {
  background-color: #e9f5fe;
}

.wizard .wizard-icon-wrap:before {
  background-color: #382a1f;
}

/* toggle btn */
.toggle-btn {
  position: relative;
  width: 55px;
  height: 25px;
  margin: 0 auto;
  border-radius: 50px;
}

.toggle-btn input[type="checkbox"] {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: 0px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

.toggle-btn span {
  display: block;
  width: 55px;
  height: 25px;
  background-color: #eeeeee;
  border-radius: 20px;
  -webkit-transition: 0.5s ease background-color;
  -o-transition: 0.5s ease background-color;
  transition: 0.5s ease background-color;
}

.toggle-btn span::before {
  content: "";
  position: absolute;
  top: 0;
  width: 25px;
  height: 25px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 2px 6px 25px #ccc;
  -webkit-transform: translate(0px, 0px) scale(0.8);
  -ms-transform: translate(0px, 0px) scale(0.8);
  transform: translate(0px, 0px) scale(0.8);
  -webkit-transition: 0.6s ease transform, 0.6s -webkit-box-shadow;
  transition: 0.6s ease transform, 0.6s -webkit-box-shadow;
  -o-transition: 0.6s ease transform, 0.6s box-shadow;
  transition: 0.6s ease transform, 0.6s box-shadow;
  transition: 0.6s ease transform, 0.6s box-shadow, 0.6s -webkit-box-shadow;
}

.toggle-btn span::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0px;
  left: 11px;
  width: 0px;
  height: 15px;
  margin-top: -8px;
  border: 1px solid #382a1f;
  border-radius: 20px;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  -webkit-transition: 0.4s ease width, 0.4s ease border-radius,
    0.62s ease transform;
  -o-transition: 0.4s ease width, 0.4s ease border-radius, 0.62s ease transform;
  transition: 0.4s ease width, 0.4s ease border-radius, 0.62s ease transform;
}

.toggle-btn input[type="checkbox"]:checked + span:before {
  box-shadow: 0px 0px 10px #eee;
  -webkit-transform: translate(30px, 0px) scale(0.8);
  -ms-transform: translate(30px, 0px) scale(0.8);
  transform: translate(30px, 0px) scale(0.8);
}

.toggle-btn input[type="checkbox"]:checked + span::after {
  width: 15px;
  border-radius: 50%;
  -webkit-transform: translate(24px, 0px);
  -ms-transform: translate(24px, 0px);
  transform: translate(24px, 0px);
}

.toggle-btn input[type="checkbox"]:checked + span {
  background-color: #382a1f;
}

.checkbox-container label:after,
.radio-container label:after {
  content: "";
  display: table;
  clear: both;
}

.checkbox-container .cr,
.radio-container .cr {
  position: relative;
  display: inline-block;
  border: 1px solid #eeeeee;
  background-color: #eeeeee;
  border-radius: 0.25em;
  width: 1.3em;
  height: 1.3em;
  /* float: left; */
  margin-right: 0.5em;
  -webkit-transition: 0.4s ease width, 0.4s ease border-radius,
    0.62s ease transform;
  -o-transition: 0.4s ease width, 0.4s ease border-radius, 0.62s ease transform;
  transition: 0.4s ease width, 0.4s ease border-radius, 0.62s ease transform;
}

.radio-container .cr {
  border-radius: 50%;
}

.checkbox-container .cr .cr-icon,
.radio-container .cr .cr-icon {
  position: absolute;
  font-size: 1.2em;
  line-height: 0;
  top: 50%;
  left: -1px;
  font-weight: 600;
}

.radio-container .cr .cr-icon {
  margin-left: 0.04em;
}

.checkbox-container label input[type="checkbox"],
.radio-container label input[type="radio"] {
  display: none;
}

.checkbox-container label input[type="checkbox"] + .cr > .cr-icon,
.radio-container label input[type="radio"] + .cr > .cr-icon {
  transform: scale(3) rotateZ(-20deg);
  opacity: 0;
  transition: all 0.3s ease-in;
}

.checkbox-container label input[type="checkbox"]:checked + .cr,
.radio-container label input[type="radio"]:checked + .cr {
  border: 1px solid #382a1f;
  background-color: #382a1f;
  color: #fff;
}

.checkbox-container label input[type="checkbox"]:checked + .cr > .cr-icon,
.radio-container label input[type="radio"]:checked + .cr > .cr-icon {
  transform: scale(1) rotateZ(0deg);
  opacity: 1;
}

.checkbox-container label input[type="checkbox"]:disabled + .cr,
.radio-container label input[type="radio"]:disabled + .cr {
  opacity: 0.5;
}

/* toggle btn completed */
.btn.action-btn-icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  height: 35px;
  width: 35px;
  color: #382a1f;
  background-color: #f3f6f9;
  border-color: #f3f6f9;
  font-size: 1.1rem;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  box-shadow: none !important;
  outline: none !important;
}

.btn.action-btn-icon:hover {
  color: #fff;
  background-color: #382a1f;
  border-color: #382a1f;
}

.btn.action-btn-icon:not(:last-child) {
  margin-right: 5px;
}

/* pagination */

.table-pagination .pagination .page-item .page-link {
  font-size: 1rem;
  font-weight: 700;
  box-shadow: none !important;
  outline: none !important;
  border-radius: 0.25rem;
}

.table-pagination .pagination .page-item.active .page-link {
  background-color: #382a1f;
  border-color: #382a1f;
}

/* designo crad */
.design_card {
  list-style: none;
  padding: 0;
  margin: 0;
}

.design_card .booking-card {
  position: relative;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
  text-align: center;
  color: #0a4870;
  transition: 0.3s;
  width: 100%;
  height: 100%;
}

.design_card .booking-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(10, 72, 112, 0);
  transition: 0.3s;
}

.design_card .booking-card .book-container {
  height: 150px;
}

.design_card .booking-card .book-container .content {
  position: relative;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  transform: translateY(-150px);
  transition: 0.3s;
}

.design_card .booking-card .book-container .content .btn {
  border: 3px solid white;
  padding: 10px 15px;
  background: none;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.3em;
  color: white;
  cursor: pointer;
  transition: 0.3s;
}

.design_card .booking-card .book-container .content .btn:hover {
  background: white;
  border: 0px solid white;
  color: #0a4870;
}

.design_card .booking-card .informations-container {
  flex: 1 0 auto;
  padding: 20px;
  background: #f0f0f0;
  transform: translateY(206px);
  transition: 0.3s;
  height: 100%;
}

.design_card .booking-card .informations-container .title {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 1.2em;
}

.design_card .booking-card .informations-container .title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  width: 50px;
  margin: auto;
  background: #0a4870;
}

.design_card .booking-card .informations-container .price {
  display: flex;
  align-items: center;
  justify-content: center;
}

.design_card .booking-card .informations-container .price .icon {
  margin-right: 10px;
}

.design_card .booking-card .informations-container .more-information {
  opacity: 0;
  transition: 0.3s;
}

.design_card
  .booking-card
  .informations-container
  .more-information
  .info-and-date-container {
  display: flex;
}

.design_card
  .booking-card
  .informations-container
  .more-information
  .info-and-date-container
  .box {
  flex: 1 0;
  padding: 15px;
  margin-top: 20px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background: white;
  font-weight: bold;
  font-size: 0.9em;
  height: 100%;
}

.design_card
  .booking-card
  .informations-container
  .more-information
  .info-and-date-container
  .box
  .icon {
  margin-bottom: 5px;
  font-size: 1.4rem;
}

.design_card
  .booking-card
  .informations-container
  .more-information
  .info-and-date-container
  .box.info {
  color: #ec992c;
  margin-right: 10px;
  height: 100%;
}

.design_card
  .booking-card
  .informations-container
  .more-information
  .disclaimer {
  margin-top: 20px;
  font-size: 0.8em;
  color: #7d7d7d;
}

.design_card .booking-card:hover::before {
  background: rgba(10, 72, 112, 0.6);
}

.design_card .booking-card:hover .book-container .content {
  opacity: 1;
  transform: translateY(0px);
}

.design_card .booking-card:hover .informations-container {
  transform: translateY(0px);
}

.design_card .booking-card:hover .informations-container .more-information {
  opacity: 1;
}

@media (max-width: 768px) {
  .design_card .booking-card::before {
    background: rgba(10, 72, 112, 0.6);
  }

  .design_card .booking-card .book-container .content {
    opacity: 1;
    transform: translateY(0px);
  }

  .design_card .booking-card .informations-container {
    transform: translateY(0px);
  }

  .design_card .booking-card .informations-container .more-information {
    opacity: 1;
  }
}

/* end designo crad */

/* dropzone */

.dropzone {
  border: 2px dashed #e0e3e4;
  color: #324148;
}

.dropzone .dz-message {
  margin: 3em 0;
}

.dropzone .dz-preview .dz-details {
  color: #324148;
}

.dropzone .dz-preview .dz-filename span {
  background-color: transparent;
}

.dropzone .dz-preview .dz-image {
  border-radius: 4px;
}

.dropzone .dz-preview.dz-file-preview .dz-image {
  border-radius: 4px;
}

/*Activity*/
.user-activity .media {
  position: relative;
  padding-bottom: 20px;
  margin-left: 20px;
}

.user-activity .media .media-img-wrap {
  position: absolute;
  left: -20px;
}

.user-activity .media:after {
  content: "";
  position: absolute;
  width: 1px;
  height: calc(100% - 77px);
  background: #eaecec;
  top: 60px;
}

.user-activity .media .media-body {
  border-bottom: 1px solid #e0e3e4;
  padding-bottom: 30px;
  margin-left: 40px;
  min-width: 0;
}

.user-activity .media:last-child .media-body {
  border-bottom: none;
}

.user-activity.user-activity-sm .media {
  padding-bottom: 10px;
  margin-left: 16px;
}

.user-activity.user-activity-sm .media .media-img-wrap {
  left: -16px;
}

.user-activity.user-activity-sm .media:after {
  /* height: calc(100% - 47px);
  top: 40px; */
}

.user-activity.user-activity-sm .media .media-body {
  padding-bottom: 10px;
  margin-left: 30px;
}

.w-75p {
  width: 75px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

@media (max-width: 576px) {
  .user-activity .media .media-body {
    margin-left: 35px;
  }
}

.avatar-sm {
  height: 3rem;
  width: 3rem;
}

.avatar-title {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #536de6;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 600;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.refreshBtn {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #e9f5fe;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.refreshBtn img {
  width: 100%;
  -webkit-filter: invert(1);
  filter: invert(1);
}

.refreshBtn:hover {
  background-color: #382a1f;
}

.refreshBtn:hover img {
  -webkit-filter: invert(0);
  filter: invert(0);
}

/* popup */

.cd-popup {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(94, 110, 141, 0.9);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
  z-index: 99999;
}

.cd-popup.is-visible {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0s;
  transition: opacity 0.3s 0s, visibility 0s 0s;
}

.cd-popup-container {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 4em auto;
  background: #fff;
  border-radius: 0.25em 0.25em 0.4em 0.4em;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(-40px);
  -moz-transform: translateY(-40px);
  -ms-transform: translateY(-40px);
  -o-transform: translateY(-40px);
  transform: translateY(-40px);
  /* Force Hardware Acceleration in WebKit */
  -webkit-backface-visibility: hidden;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.cd-popup-container p {
  margin: 0;
  padding: 2em 1em;
  font-size: 1rem;
}

.cd-popup-container .cd-buttons {
  margin: 0;
  padding: 0;
}

.cd-popup-container .cd-buttons:after {
  content: "";
  display: table;
  clear: both;
}

.cd-popup-container .cd-buttons li {
  float: left;
  width: 50%;
  list-style: none;
}

.cd-popup-container .cd-buttons span {
  display: block;
  height: 60px;
  line-height: 60px;
  text-transform: uppercase;
  color: #fff;
  -webkit-transition: background-color 0.2s;
  -moz-transition: background-color 0.2s;
  transition: background-color 0.2s;
  text-decoration: none;
  cursor: pointer;
}

.cd-popup-container .cd-buttons li:first-child span {
  background: #dbab86;
  border-radius: 0 0 0 0.25em;
}

.no-touch .cd-popup-container .cd-buttons li:first-child span:hover {
  background-color: #fc8982;
}

.cd-popup-container .cd-buttons li:last-child span {
  color: #382a1f;
  background: #f9e8de;
  border-radius: 0 0 0.25em 0;
}

.no-touch .cd-popup-container .cd-buttons li:last-child span:hover {
  background-color: #c5ccd8;
}
.cd-popup-container .cd-button {
  margin: 0;
  padding: 0;
}

.cd-popup-container .cd-button:after {
  content: "";
  display: table;
  clear: both;
}

.cd-popup-container .cd-button li {
  float: left;
  width: 100%;
  list-style: none;
}

.cd-popup-container .cd-button span {
  display: block;
  height: 60px;
  line-height: 60px;
  text-transform: uppercase;
  color: #fff;
  -webkit-transition: background-color 0.2s;
  -moz-transition: background-color 0.2s;
  transition: background-color 0.2s;
  text-decoration: none;
  cursor: pointer;
}

.cd-popup-container .cd-button li:first-child span {
  background: #dbab86;
  border-radius: 0 0 0 0.25em;
}

.no-touch .cd-popup-container .cd-button li:first-child span:hover {
  background-color: #fc8982;
}


.cd-popup-container .cd-popup-close {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 30px;
  height: 30px;
}

.img-replace {
  /* replace text with an image */
  display: inline-block;
  overflow: hidden;
  text-indent: 100%;
  color: transparent;
  white-space: nowrap;
}

.cd-popup-container .cd-popup-close::before,
.cd-popup-container .cd-popup-close::after {
  content: "";
  position: absolute;
  top: 12px;
  width: 14px;
  height: 3px;
  background-color: #8f9cb5;
}

.cd-popup-container .cd-popup-close::before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 8px;
}

.cd-popup-container .cd-popup-close::after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  right: 8px;
}

.is-visible .cd-popup-container {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

@media only screen and (min-width: 1170px) {
  .cd-popup-container {
    margin: 8em auto;
  }
}

/* end popup */
/* Select2 */
.select2-container--default
  .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #382a1f;
  color: white;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #382a1f;
  line-height: 45px;
}

.select2-container .select2-selection--single {
  height: 45px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 45px;
}

.form-control.cs_select.error
  + .select2.select2-container
  .select2-selection--single {
  border-color: red;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.accordion-content span.select2.select2-container.select2-container--default {
  width: 100% !important;
}

/* Payment */
.payment_container {
  font-size: 18px;
  width: 70%;
  margin: 30px auto;
  background: #ffe0b1;
  border-radius: 9px;
  overflow: hidden;
}

.payment_container .icing,
.payment_container .dough {
  padding: 20px;
  box-sizing: border-box;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.payment_container .icing {
  position: relative;
  padding-right: 30px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#edefec),
    color-stop(80%, #edefec)
  );
  background: linear-gradient(to right, #edefec 0%, #edefec 80%);
  color: #382a1f;
}

.payment_container .icing:before {
  content: "";
  width: 36px;
  height: 36px;
  display: block;
  position: absolute;
  top: -18px;
  right: -7.2px;
  z-index: 1;
  background: #edefec;
  border-radius: 50%;
  box-shadow: 21.8181818182px 28.8px 0 #ffe0b1, 0 57.6px 0 #edefec,
    21.8181818182px 86.4px 0 #ffe0b1, 0 115.2px 0 #edefec,
    21.8181818182px 144px 0 #ffe0b1, 0 172.8px 0 #edefec,
    21.8181818182px 201.6px 0 #ffe0b1, 0 230.4px 0 #edefec,
    21.8181818182px 259.2px 0 #ffe0b1, 0 288px 0 #edefec,
    21.8181818182px 316.8px 0 #ffe0b1, 0 345.6px 0 #edefec, 0 400px 0 #edefec,
    21.8181818182px 373.8px 0 #ffe0b1, 0 345.6px 0 #edefec,
    21.8181818182px 430.8px 0 #ffe0b1, 0 345.6px 0 #edefec;
}

.payment_container .dough {
  color: #382a1f;
}

.payment_container .order {
  -webkit-box-flex: 1;
  flex-grow: 1;
  padding: 20px 0;
  margin: 20px 0;
  border: 1px solid white;
  border-color: white transparent;
}

.payment_container .order li,
.payment_container .total {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  text-transform: capitalize;
}

.payment_container h2 {
  font-size: 1.2em;
  /* color: #382a1f; */
}

.payment_container .icing h2 {
  color: #382a1f;
}

.payment_container .total {
  font-size: 1.5em;
}

.payment_container .form {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 20px;
  -webkit-box-flex: 1;
  flex-grow: 1;
}

.payment_container .inputs {
  -webkit-box-flex: 1;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.payment_container .buttons {
  width: 100%;
}

.payment_container .label {
  font-size: 0.8em;
  margin-bottom: 0.2em;
}

.payment_container .text-input {
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  font-size: 1em;
  font-family: inherit;
  background: white;
  border: 1px solid rgba(144, 81, 14, 0.4);
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1) inset;
}

.payment_plan_list li {
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: capitalize;
  font-size: 1.1rem;
  padding: 4px 6px;
  font-weight: 600;
}

.thank_card {
  margin: 0 0 1rem;
  padding: 0 3rem;
  border: none;
  background-color: transparent;
  text-align: center;
}

.thank_card i {
  font-size: 3rem;
  font-weight: 300;
  color: green;
}

.thank_card h2 {
  font-size: 3rem;
}

.thank_card p {
  font-size: 1.3rem;
  font-weight: 500;
  padding: 0;
}

/* Select2 */
.datepicker td,
.datepicker th {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 0.85rem;
}

.datepicker-dropdown {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.userInfoDetails li {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  border: none;
  padding: 8px 0;
}

.userInfoDetails li span {
  min-width: 10%;
  text-align: right;
  padding: 0 15px;
  position: relative;
}

.userInfoDetails li span::before {
  content: ":";
  position: absolute;
  top: 0;
  right: 5px;
}

/* profileimae */
.profileImgesLogo {
  width: 100px;
  height: 100px;
  /* border: 1px solid #382a1f; */
  border-radius: 50%;
  position: relative;
  padding: 5px;
  margin: 0;
  /*overflow: hidden;*/
}

.profileImgesLogo .profile-pic {
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
}

.profileImgesLogo .file-upload {
  display: none;
}

.profileImgesLogo .circle {
/*  border-radius: 50%;*/
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.profileImgesLogo .p-image {
  /*border-radius: 50%;*/
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 1.3rem;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}

.profileImgesLogo .p-image:hover {
  opacity: 1;
}

/* profileimae */
.profileImges {
  width: 50px;
  height: 50px;
  /* border: 1px solid #382a1f; */
  border-radius: 50%;
  position: relative;
  padding: 5px;
  margin: 0;
  overflow: hidden;
}

.profileImges .profile-pic {
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
}

.profileImges .file-upload {
  display: none;
}

.profileImges .circle {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.profileImges .p-image {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 1.3rem;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}

.profileImges .p-image:hover {
  opacity: 1;
}

/* enquiry */
.enquiry-accordion {
  padding: 0;
}

.enquiry-accordion .accordion .accordion-item {
  box-shadow: none;
  border: 1px solid #f8f8f8;
  border-radius: 0.25rem;
}

.enquiry-accordion .accordion .accordion-title {
  display: flex;
  justify-content: space-between;
  background-color: #f8f8f8;
  color: #382a1f;
  flex-flow: row-reverse;
  padding: 15px 20px 10px 15px;
  font-size: 1.1rem;
  /* border-radius:0.25rem; */
  transition: all 0.3s;
}

.enquiry-accordion .accordion .accordion-title i {
  position: static;
  display: inline-block;
  color: #382a1f;
  width: auto;
  background-color: transparent;
  transition: all 0.3s;
}

.enquiry-accordion .accordion .accordion-title i::before {
  position: static;
}

.enquiry-accordion .accordion .accordion-title.active {
  background-color: #d08a56;
  transition: all 0.3s;
  color: #ffffff;
}

.enquiry-accordion .accordion .accordion-title.active i {
  color: #fff;
}

/* enquiry */
.h100vh {
  min-height: 65vh;
}

/* loader */
.preLoader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f9e8de;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99999999;
  min-width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.holder {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: #2d2f48;
}

.preloader {
  /* size */
  width: 100px;
  height: 100px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  animation: rotatePreloader 2s infinite ease-in;
}

@keyframes rotatePreloader {
  0% {
    transform: translateX(-50%) translateY(-50%) rotateZ(0deg);
  }

  100% {
    transform: translateX(-50%) translateY(-50%) rotateZ(-360deg);
  }
}

.preloader div {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.preloader div:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0%;
  width: 10%;
  height: 10%;
  background-color: #ffffff;
  transform: translateX(-50%);
  border-radius: 50%;
}

.preloader div:nth-child(1) {
  transform: rotateZ(0deg);
  animation: rotateCircle1 2s infinite linear;
  z-index: 9;
}

@keyframes rotateCircle1 {
  0% {
    opacity: 0;
  }

  0% {
    opacity: 1;
    transform: rotateZ(36deg);
  }

  7% {
    transform: rotateZ(0deg);
  }

  57% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}

.preloader div:nth-child(2) {
  transform: rotateZ(36deg);
  animation: rotateCircle2 2s infinite linear;
  z-index: 8;
}

@keyframes rotateCircle2 {
  5% {
    opacity: 0;
  }

  5.0001% {
    opacity: 1;
    transform: rotateZ(0deg);
  }

  12% {
    transform: rotateZ(-36deg);
  }

  62% {
    transform: rotateZ(-36deg);
  }

  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}

.preloader div:nth-child(3) {
  transform: rotateZ(72deg);
  animation: rotateCircle3 2s infinite linear;
  z-index: 7;
}

@keyframes rotateCircle3 {
  10% {
    opacity: 0;
  }

  10.0002% {
    opacity: 1;
    transform: rotateZ(-36deg);
  }

  17% {
    transform: rotateZ(-72deg);
  }

  67% {
    transform: rotateZ(-72deg);
  }

  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}

.preloader div:nth-child(4) {
  transform: rotateZ(108deg);
  animation: rotateCircle4 2s infinite linear;
  z-index: 6;
}

@keyframes rotateCircle4 {
  15% {
    opacity: 0;
  }

  15.0003% {
    opacity: 1;
    transform: rotateZ(-72deg);
  }

  22% {
    transform: rotateZ(-108deg);
  }

  72% {
    transform: rotateZ(-108deg);
  }

  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}

.preloader div:nth-child(5) {
  transform: rotateZ(144deg);
  animation: rotateCircle5 2s infinite linear;
  z-index: 5;
}

@keyframes rotateCircle5 {
  20% {
    opacity: 0;
  }

  20.0004% {
    opacity: 1;
    transform: rotateZ(-108deg);
  }

  27% {
    transform: rotateZ(-144deg);
  }

  77% {
    transform: rotateZ(-144deg);
  }

  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}

.preloader div:nth-child(6) {
  transform: rotateZ(180deg);
  animation: rotateCircle6 2s infinite linear;
  z-index: 4;
}

@keyframes rotateCircle6 {
  25% {
    opacity: 0;
  }

  25.0005% {
    opacity: 1;
    transform: rotateZ(-144deg);
  }

  32% {
    transform: rotateZ(-180deg);
  }

  82% {
    transform: rotateZ(-180deg);
  }

  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}

.preloader div:nth-child(7) {
  transform: rotateZ(216deg);
  animation: rotateCircle7 2s infinite linear;
  z-index: 3;
}

@keyframes rotateCircle7 {
  30% {
    opacity: 0;
  }

  30.0006% {
    opacity: 1;
    transform: rotateZ(-180deg);
  }

  37% {
    transform: rotateZ(-216deg);
  }

  87% {
    transform: rotateZ(-216deg);
  }

  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}

.preloader div:nth-child(8) {
  transform: rotateZ(252deg);
  animation: rotateCircle8 2s infinite linear;
  z-index: 2;
}

@keyframes rotateCircle8 {
  35% {
    opacity: 0;
  }

  35.0007% {
    opacity: 1;
    transform: rotateZ(-216deg);
  }

  42% {
    transform: rotateZ(-252deg);
  }

  92% {
    transform: rotateZ(-252deg);
  }

  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}

.preloader div:nth-child(9) {
  transform: rotateZ(288deg);
  animation: rotateCircle9 2s infinite linear;
  z-index: 1;
}

@keyframes rotateCircle9 {
  40% {
    opacity: 0;
  }

  40.0008% {
    opacity: 1;
    transform: rotateZ(-252deg);
  }

  47% {
    transform: rotateZ(-288deg);
  }

  97% {
    transform: rotateZ(-288deg);
  }

  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}

.preloader div:nth-child(10) {
  transform: rotateZ(324deg);
  animation: rotateCircle10 2s infinite linear;
  z-index: 0;
}

@keyframes rotateCircle10 {
  45% {
    opacity: 0;
  }

  45.0009% {
    opacity: 1;
    transform: rotateZ(-288deg);
  }

  52% {
    transform: rotateZ(-324deg);
  }

  102% {
    transform: rotateZ(-324deg);
  }

  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}

/* 

.preLoader .loader {
  width: 5em;
  height: 5em;
  font-size: 25px;
  box-sizing: border-box;
  border-top: 0.3em solid #0e153a;
  border-radius: 50%;
  position: relative;
  animation: rotating 2s ease-in-out infinite;
  --direction: 1;
}

.preLoader .loader::before,
.preLoader .loader::after {
  content: '';
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  box-sizing: border-box;
  top: -0.2em;
}

.preLoader .loader::before {
  border-top: 0.3em solid #1f4287;
  transform: rotate(120deg);
}

.preLoader .loader::after {
  border-top: 0.3em solid gold;
  transform: rotate(240deg);
}

.preLoader .loader span {
  position: absolute;
  color: white;
  width: inherit;
  height: inherit;
  text-align: center;
  line-height: 10em;
  font-family: sans-serif;
  animation: rotating 2s linear infinite;
  --direction: -1;
}

@keyframes rotating {
  50% {
    transform: rotate(calc(180deg * var(--direction)));
  }

  100% {
    transform: rotate(calc(360deg * var(--direction)));
  }
} */

/* end loader */

/* project details */
.addUserBtn {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#statusBtn + .dropdown-menu {
  /* left: auto !important; */
  /* right: 0 !important; */
  min-width: 100%;
}

#statusBtn + .dropdown-menu .dropdown-item {
  font-size: 15px;
  color: #382a1f;
  font-weight: 600;
  transition: all 0.3s;
  padding: 5px 15px;
  margin: 0;
  text-align: left;
}

#statusBtn + .dropdown-menu .dropdown-item:hover {
  color: #d08a56;
}

.modal .select2-container {
  width: 100% !important;
}

.view_description {
  padding-left: 40px !important;
}

.add_user_btn {
  width: 25px;
  height: 25px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 25px;
  min-height: 25px;
  max-width: 25px;
  max-height: 25px;
  line-height: 1;
  padding: 0;
}

/* project details */
.user-settings-box form .form-group label.ck.ck-label.ck-voice-label {
  display: none;
}

.dropdownYScroll {
  overflow: hidden;
  overflow-y: auto;
}

.loaderContener {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  z-index: 11000;
}

.Ploader {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;
  border: 4px solid #fff;
  /* top: 50%; */
  animation: loader 2s infinite ease;
}

.Ploader .loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: #fff;
  animation: loader-inner 2s infinite ease-in;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

.restoreBtn {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #382a1f;
  border-radius: 150px;
  padding: 1px 5px;
  font-weight: 400 !important;
  transition: all 0.3s;
}

.restoreBtn:hover {
  color: #fff;
  background: #271c14;
}

.restoreBtn i {
  font-size: 16px;
}

/* scroll bar */
/* Let's get this party started */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 8px;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 8px;
  border-radius: 8px;
  background: #f9e8de;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(249, 232, 222, 0.8);
}

/* scroll bar */
/* eve index */
.shortcutCard {
  height: 100%;
  margin: 0;
}

.shortcutCard .card-header a {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  color: #382a1f;
  transition: all 0.3s;
}

.shortcutCard .list-group .list-group-item {
  border: none;
  padding: 0;
}

.shortcutCard .list-group .list-group-item a {
  color: #382a1f;
  font-weight: 600;
  display: flex;
  padding: 5px 10px;
  font-size: 16px;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.3s;
  position: relative;
  border-bottom: 1px solid #f7f4f4;
}

.shortcutCard .list-group .list-group-item:last-child a {
  border: none;
}

.shortcutCard .list-group .list-group-item a::before {
  content: "\e9fa";
  font-family: "boxicons" !important;
  position: absolute;
  right: 0;
}

.shortcutCard .list-group .list-group-item a:hover,
.shortcutCard .card-header a:hover {
  color: #d08a56;
}

/* mega mnu */
.mega-menu .dropdown-menu {
  left: 0 !important;
  right: auto !important;
  width: 600px;
  max-width: 600px !important;
}

.mega-menu .dropdown-menu.drop-admin::before {
  left: auto !important;
  right: 15px !important;
}

.top-navbar.navbar .nav-item.dropdown .dropdown-menu .dropdown-item {
  display: flex;
  align-items: center;
}

.top-navbar.navbar .nav-item.dropdown .dropdown-menu .dropdown-item i {
  font-size: 1.4rem;
}

.mega-menu .dropdown-menu::before {
  left: auto !important;
  right: 210px !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

/* password indfo */
.passwordInfoContainer {
  position: relative;
}

.passwordInfo {
  width: 40px;
  height: 40px;
  background-color: #382a1f;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

.passwordInfobox {
  position: absolute;
  width: 200px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 10px;
  background-color: #f1f1f1;
  top: calc(100% + 15px);
  left: 0;
  transition: all 0.3s;
  display: none;
  opacity: 0;
}

.passwordInfo:hover ~ .passwordInfobox {
  transition: all 0.3s;
  display: block;
  opacity: 1;
}

.passwordInfo:hover {
  color: #fff;
  background-color: #3b2d22;
}

.passwordInfobox p {
  margin: 0;
  padding: 0;
  font-size: 12px;
  text-align: center;
  line-height: 1.3;
  font-weight: 500;
}

.passwordInfobox::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: -10px;
  left: 10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #f1f1f1;
}

/* password indfo */
.actionBtnsTitle {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.actionBtnsTitle .actionBtnss {
  position: absolute;
  right: -80px;
  top: -15px;
  opacity: 0;
  transition: all 0.3s;
}

.actionBtnsTitle .actionBtnss.notesList {
  right: -50px;
  top: -3px;
}

.actionBtnsTitle:hover .actionBtnss {
  opacity: 1;
}

.actionBtnsTitle .actionBtnss i {
  background-color: transparent !important;
  padding: 0;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #382a1f !important;
}

.taskTitleMediaBox {
  position: relative;
}

.taskActionBtn {
  top: -25px;
  right: -15px;
  opacity: 0;
  transition: all 0.3s;
}

.taskTitleMediaBox:hover .taskActionBtn {
  opacity: 1;
}

/* search */
.dropdownSec .prImg {
  transform: translateY(0) !important;
}

.dropdownSec {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 2px 6px rgb(0 0 0 / 18%);
  border-radius: 0 0 5px 5px;
}

/* search */

.requiredInput {
  border-right: 3px solid #382a1f !important;
}

/* roles  heckboxs */
.react-checkbox-tree label {
  margin-bottom: 0 !important;
}

.rct-collapse.rct-collapse-btn {
  font-size: 22px;
}

.rct-collapse,
.rct-checkbox,
.rct-node-icon {
  font-size: 20px;
}

.rct-title {
  font-weight: 500;
  color: #382a1f;
}

.rct-node-icon {
  color: #dbab86 !important;
  font-size: 16px !important;
}

/* roles  heckboxs */
.ck.ck-editor__editable_inline p,
.ck.ck-editor__editable_inline h1,
.ck.ck-editor__editable_inline h2,
.ck.ck-editor__editable_inline h3,
.ck.ck-editor__editable_inline h4,
.ck.ck-editor__editable_inline h5,
.ck.ck-editor__editable_inline h6 {
  margin: 0 0 5px 0;
}

.actions_button a {
  background-color: #eee;
  color: #382a1f !important;
  padding: 5px;
  border-radius: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}

.actions_button a:hover {
  background-color: #382a1f;
  color: #ffffff !important;
}

/* // */
#settingAccordion .card {
  margin-bottom: 15px;
  border: 0;
  padding: 0;
}

#settingAccordion .card .card-header {
  border: 0;
  -webkit-box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
  box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
  border-radius: 2px;
  padding: 0;
}

#settingAccordion .card .card-header .btn-header-link {
  color: #382a1f;
  display: block;
  text-align: left;
  background: #fff;
  padding: 20px;
  font-size: 20px;
  font-weight: 600;
}

#settingAccordion .card .card-header .btn-header-link:after {
  content: "\f107";
  font-family: "FontAwesome";
  font-weight: 900;
  float: right;
}

#settingAccordion .card .card-header .btn-header-link.collapsed {
  background: #ccc;
  color: #382a1f;
}

#settingAccordion .card .card-header .btn-header-link.collapsed:after {
  content: "\f106";
}

#settingAccordion .card .collapsing {
  background: #fff;
  line-height: 30px;
}

#settingAccordion .card .collapse {
  border: 0;
}

#settingAccordion .card .collapse.show {
  background: #fff;
  line-height: 30px;
  color: #222;
}

.borderColor {
  border-color: #edeff1 !important;
}

/* // */
.react-date-picker__calendar {
  z-index: 3;
}

.datepicer .react-date-picker__wrapper {
  border: none;
}

.react-time-picker__wrapper {
  border: none;
}

.word_space {
  word-break: break-word;
  white-space: break-spaces;
}

.filterDate .react-date-picker__inputGroup {
  height: 100%;
}

.filterDate .react-date-picker__button {
  overflow: hidden;
  padding: 0 7px 0 0;
  height: 26px;
  position: relative;
  top: -7px;
}

.filterDate .react-date-picker__button svg {
  width: 10px;
}

/* New-Screens-Style */
.page-wrapper {
  padding: 50px 0 80px;
}

.inner-page-wrapper {
  background-color: #fff;
  padding-top: 40px;
  padding-bottom: 40px;
}

.inner-page-wrapper.contact {
  padding-bottom: 0;
}

.page-wrapper .container {
  max-width: 90%;
}

.page-wrapper.contact {
  padding-bottom: 0;
}

.page-title {
  margin-bottom: 68px;
}

.page-title h2 {
  font-size: 36px;
  font-weight: 700;
  line-height: normal;
  color: #3a3a3a;
  margin-bottom: 22px;
}

.page-title h2 span {
  margin-top: 1px;
}

.page-title h2 .line {
  width: 94px;
  height: 2px;
  background-color: #14b982;
}

@media only screen and (max-width: 1025px) {
  .page-wrapper .container {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .inner-page-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .page-wrapper .container {
    max-width: none;
  }
}
.ck-editor__editable {
  min-height: 150px;
}

body {
  --ck-z-default: 100;
  --ck-z-modal: calc(var(--ck-z-default) + 999);
}

.accept_btn {
  background-color: #382a1f;
  border: none;
  color: #fff;
  border-radius: 0.25rem;
  padding: 3px 5px;
font-size: 12px;
}

.taskUserDropdownMenu{
  right: auto !important;
bottom: auto !important;
will-change: 0 !important;
top: 100% !important;
left:  auto !important;
transform: translate3d(0, 0,0 ) !important;
}

.chatbadge {
  /* background: #FF0000 !important; */
  /* background: #d08a56 !important; */
  background: #f44336 !important;
  position: absolute !important;
  display: block !important;
  width: 20px !important;
  height: 21px !important;
  border-radius: 50% !important;
  font-size: 13px !important;
  margin-left: 80% !important;
  top: 8px !important;
  text-align: center !important;
}

.faq-accordion img {
  max-height: 800px !important;
  width: auto !important;
}

.gallary_section .gallary{
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  margin: 0 -15px;
}

.gallary_section .gallary .item-wrap {
  width: 20%;
  padding: 15px;
}

.gallary_section .gallary .item {
  box-sizing: border-box;
  background-image: none !important;
  background-color: #fff;
  box-shadow: none;
}

.gallary_section .gallary .item .item__details {
  padding: 10px;
  position: unset;
  height: inherit;
  justify-content: space-between;
  align-items: flex-start;
  /* min-height: 65px; */
}

.gallary_section .gallary .item .item__details span {
  color: #2b90d9;
  word-break: break-word;
}

.gallary_section .gallary .item .item__details .add_btn{
  width: 30px;
  height: 30px;
  border-radius: 4px;
  color: #ea6a6a;
}

.gallary_section .gallary .item .img-wrap {
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallary_section .gallary .item .img-wrap img {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.align-items-center {
  align-items: center;
}

.sign-out-danger-border {
  border: 1px solid red !important;
}

.text-transform-none {
  text-transform: none;
}

.status-dropdown {
  line-height: 24px;
}

.status-dropdown .active {
  color: #d08a56;
}

.status-dropdown .dropdown-menu {
  min-width: 110px;
}

.ml-auto {
  margin-left: auto;
}

.ml-top-proj {
  margin-left: auto;
  margin-top: 10px;
  margin-bottom: 0px;
}


.fz-18 {
  font-size: 18px;
}

.fz-16 {
  font-size: 16px !important;
}

.filter-parent .filter-form {
  top: -100px !important
}

.cke_button__payment, 
.cke_button__packages_label, 
.cke_button__initials_label {
  display: inline !important;
}

/* checklist */

.checklist_card {
  background: #EBEAE6;
  /* height: 100%; */
}

.checklist_section {
  background: #DDCBBD;
  padding: 0.6rem;
  border-radius: 2rem;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  padding-right: 1.5rem;
}

.checklist_section input {
  height: 1.6rem !important;
  background: transparent;
  border: transparent;
}

.checklist_item, .checklist_item .login_checkbox, .checklist_item .login_checkbox, .checklist_remove_btn   {
  margin-bottom: 5px !important;
}

.checklist_item input {
  height: 1.6rem !important;
  background: transparent;
}

.checklist_section .requiredInput {
  border-right: none !important;
}

.checklist_section input::placeholder, .checklist_item input::placeholder {
  color: #000;
}

.assigned_user_select {
  height: 1.6rem !important;
  font-size: 1rem;
  padding: 0px 5px;
}

.popver-component{
right: 0px !important;
bottom: auto !important;
will-change: 0 !important;
top: 100% !important;
left:  auto !important;
transform: translate3d(0, 0,0 ) !important;
}

.checked_checklist_item {
  background-color: #ebebeb;
}

.strikethrough {
  text-decoration: line-through;
}

.checked_checklist_item .assigned_user {
  text-decoration: none;
}

.dynamic_proposal_textarea {
  box-shadow: 0 0 0 0 rgba(50, 50, 50, 0);
    -webkit-box-shadow: 0 0 0 0 rgba(50, 50, 50, 0);
    -moz-box-shadow: 0 0 0 0 rgba(50, 50, 50, 0);
    -ms-box-shadow: 0 0 0 0 rgba(50, 50, 50, 0);
    -o-box-shadow: 0 0 0 0 rgba(50, 50, 50, 0);
    -webkit-appearance: none;
    border: 0;
    transition: none;
    color: #382a1f;
    min-height: 30px !important;
    padding: 5px 10px;
    margin: 0;
    border-radius: 3px;
    caret-color: #18c7c9;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 25px;
    letter-spacing: normal;
    text-align: left;
    border: 0;
    outline: 0;
    word-wrap: break-word;
    resize: none;
    transition: all 0.15s ease;
    overflow: hidden;
    overflow-wrap: break-word;
}

.package_description {
  white-space: break-spaces;
}

.project_status_dropdown {
  min-width: 8rem;
  width: auto;
}

.err_message_max_width {
  max-width: 24rem !important;
}

.min-w-100{
  min-width: 100%;
}

.guest-list-buttons {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 500px) { 
    .guest-list-buttons { 
      flex-direction: column;
    } 

    .guest-list-btn {
      min-width: 100%;
    }
}

.dynamic-textarea {
  overflow-y: hidden;
}

.white-space-break-spaces {
  white-space: break-spaces;
}

.hyperlink {
  border-bottom: 1px solid;
}
.no-after .media::after {
  content: none;
}

.pss .slick-slide > div {
  padding: 0 15px;
}

.pss .slick-list {
  margin: 0 -15px;
} 

.header-buttons {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 500px) { 
    .header-buttons { 
      flex-direction: column;
    } 

    .header-btn {
      min-width: 100%;
      width: 100%;
    }
}

@media screen and (max-width: 500px) { 
  .sidemenu-area .sidemenu-header .navbar-brand {
    width: 120px;
  }
}

.toaster-container {
  z-index: 10005;
}
