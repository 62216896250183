.card.vendor_cards {
  padding: 0;
  transform: translateY(-3px);
  transition: 0.3s all ease-out;
  border-radius: 0.5rem;
  overflow: hidden;
  height: 100%;
  margin: 0;
  box-shadow: 0 0 8px rgb(0 0 0 / 7%);
}



.card.vendor_cards .card-info {
  padding: 20px;
  min-height: 130px;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-end;
}

.cards_link {
  border-radius: 50%;
  width: 70px !important;
  height: 70px !important;
  z-index: 10;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 170px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  margin: 0;
}

.cards_link .image {
  position: inherit;
  border-radius: 50%;
  width: 85%;
  height: 85%;
  display: block;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 0 0 8px rgb(255 255 255);
  background-color: #f1f1f1;
}

.cards__img {
  width: 100%;
  height: 200px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  box-shadow: 0px 3px 7px 0 rgb(0 0 0 / 7%);
  background-color: #ddd;
}

.cards__img>div {
  width: 100%;
  height: 100%;
  display: block;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-origin: content-box;
  transition: 0.3s all ease-out;
  transform: scale(1);
}

.cards__img:hover div,
.vendor_cards:hover .cards__img>div {
  transform: scale(1.3);
}

.cards__info-hover {
  position: absolute;
  width: 100%;
  padding: 0;
  opacity: 0;
  text-align: right;
  z-index: 9;
}

.cards__info-hover .btn {
  margin: 8px 5px;
  padding: 0;
  transition: all 0.3s;
}

.vendor_cards:hover .cards__info-hover {
  opacity: 1;
}

.textLimit {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}