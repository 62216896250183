@import "./variables";
@import "./utility";
@import "./drawer";

* {
  outline: none !important;
  box-sizing: border-box;
}






.text-blue {
  color: $blue;
}

.text-grey {
  color: $grey;
}

.text-black {
  color: $black !important;
}

.text-white {
  color: $white;
}

.font-noto-sans-tc {
  font-family: "Noto Sans TC", sans-serif;
}

// a {
//   text-decoration: none !important;
//   color: $black !important;
// }

// h6,
// h5,
// h4,
// h3,
// h2,
// h1 {
//   margin-top: 0;
//   margin-bottom: 0;
//   font-weight: 400;
//   line-height: normal;
// }

// p {
//   font-size: 20px;
//   margin-top: 0;
//   margin-bottom: 0;
//   line-height: normal;
//   line-height: 30px;
//   color: $grey;
// }

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-bolder {
  font-weight: 700;
}

.italic {
  font-style: italic;
}

.container {
  max-width: 1320px;
  margin: 0 auto;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  // max-width: 184px;
  width: 100%;
  height: 40px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  font-family: "Figtree", sans-serif;
  text-transform: capitalize;
}

.btn-primary {
  background: $blue !important;
  //border: 1px solid $blue !important;
  color: $white !important;
}

.btn-primary-register {
  background: #2b2b46 !important;
  //border: 1px solid #2b2b46 !important;
  color: $white !important;
}

.login-button {
  background: #2b2b46 !important;
  //border: 1px solid #2b2b46 !important;
  color: $white !important;
}

/*.btn-primary:hover,
.btn-primary:focus {
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 20%);
  color: $white !important;
  background: $blue !important;
}*/

.btn-secondary {
  background: $white !important;
  border: 1px solid $blue;
  color: $blue !important;
}

.btn-secondary:hover,
.btn-secondary:focus {
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 20%);
  color: $blue !important;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  border-radius: 100px;
  border: 2px solid $black;
  background: rgba(45, 47, 39, 0.2);
  height: 50px;
  padding-left: 30px;
}

.form-control:focus {
  outline: none;
  box-shadow: unset;
  border-color: $black;
}

// ::placeholder {
//   opacity: 1;
//   color: $black;
//   font-size: 20px;
//   font-family: Noto Sans TC;
// }

:-ms-input-placeholder {
  color: $black;
  font-size: 20px;
  font-family: Noto Sans TC;
}

::-ms-input-placeholder {
  color: $black;
  font-size: 20px;
  font-family: Noto Sans TC;
}

.password-eye {
  position: absolute;
  right: 15px;
  top: 12px;
  cursor: pointer;
}

.checkbox-wrapper {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $black;
  font-size: 18px;
}

.checkbox-wrapper a {
  color: $blue !important;
  font-weight: 700;
}

.checkbox-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-wrapper-new {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $black;
  font-size: 18px;
}

.checkbox-wrapper-new a {
  color: #2b2b46 !important;
  font-weight: 700;
}

.checkbox-wrapper-new input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-wrapper-new:hover input ~ .checkmark {
  background-color: #ccc;
}

.checkbox-wrapper-new input:checked ~ .checkmark {
  background-color: #2b2b46;
  border: 2px solid #2b2b46;
}

.checkbox-wrapper-new input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-wrapper-new .checkmark:after {
  left: 8px;
  top: 2px;
  width: 7px;
  height: 14px;
  border: solid $white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 2px;
  border: 2px solid $black;
  background-color: rgba(45, 47, 39, 0.2);
}

.checkbox-wrapper:hover input ~ .checkmark {
  background-color: #ccc;
}

.checkbox-wrapper input:checked ~ .checkmark {
  background-color: $blue;
  border: 2px solid $blue;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-wrapper input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-wrapper .checkmark:after {
  left: 8px;
  top: 2px;
  width: 7px;
  height: 14px;
  border: solid $white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.link {
  color: #197cb5;
  font-weight: 700;
}

.link-register {
  color: #2b2b46;
  font-weight: 700;
}
/* login page :: START */
.authentication-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.authentication-wrapper .authentication-img {
  position: relative;
  width: 50%;
  //border-radius: 0px 100px 100px 0px;
  background: #e6e8ff;
  padding: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.authentication-wrapper .authentication-img .authentication-logo {
  position: absolute;
  top: 35px;
  left: 35px;
  height: 50px;
}

.authentication-wrapper .authentication-img .authentication {
  max-width: 563px;
  width: 100%;
  height: auto;
}

.authentication-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding: 35px;
  height: 100vh;
}

.authentication-content .form-wrapper {
  max-width: 540px;
  height: auto;
}

.welcome-title {
  color: $black;
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 5px;
}

.welcome-para {
  color: $grey;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 40px !important;
}

.forgot-password {
  color: $black;
  text-align: right;
  font-size: 18px;
}

.form-wrapper .btn {
  max-width: 100%;
  font-weight: 500;
  font-size: 18px;
  height: 50px;
}

.or-wrapper {
  width: 100%;
  border-bottom: 1px solid $black;
  position: relative;
  margin-top: 26px;
  margin-bottom: 37px;
}

.or-wrapper p {
  margin-bottom: 0;
  position: absolute;
  color: #2b2b46;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  background-color: $white;
  width: fit-content;
  left: 50%;
  top: -50%;
  transform: translate(-50%, -50%);
  padding: 0 10px;
}

.do-account-wrapper {
  color: $black;
  text-align: center;
  font-size: 18px;
  display: block;
}

.do-account-wrapper span {
  color: #2b2b46;
  font-weight: 700;
}

.do-account-wrapper:hover span {
  text-decoration: underline;
}

/* login page :: END */

/* register page :: START */
.register-wrapper {
  align-items: flex-start;
}

.register-wrapper .form-wrapper {
  // height: 92vh;
  height: calc(100vh - 40px);
  overflow: auto;
  overflow-x: clip;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; 
}

.register-wrapper .form-wrapper::-webkit-scrollbar {
  display: none;
  width: 0px;
}

.authentication-form {
  width: 100%;
}

/* register page :: END */

/* Header page :: START */
header {
  position: fixed;
  width: 100%;
  background: $white;
  z-index: 3;
}

.header {
  position: fixed;
  width: 100%;
  background: $white;
  z-index: 9;
}

header .navbar {
  padding: 15px 0;
}

.header .navbar {
  padding: 15px 0;
}

.navbar-expand-lg .navbar-nav .nav-link {
  margin: 0 5px;
  color: $black;
  font-size: 18px;
  white-space: nowrap;
}

.header_nav_link {
  margin: 0 5px;
  color: $black;
  font-size: 18px;
}

.header_logo {
  height: 50px;
}

.try-btn {
  margin-left: 24px;
}

.header_try_harpsen {
  width: 147px;
}

.header_schedule_demo {
  width: 177px;
}

// .header_cta_buttons {
//   max-width: 184px;
//   width: 100%;
//   font-size: 18px !important;
// }

// .header_cta_buttons_schedule_demo {
//   max-width: 184px;
//   width: 100%;
//   font-size: 18px !important;
// }

.navbar_nav_margin {
  margin-right: -5px;
}
.navbar_nav_margin .header_nav_link {
  margin-right: 10px;
}

.navbar_shadow {
  box-shadow: 1px 2px 24px #0000004f;
}

.navbar-logged-in { 
  position: relative;
  left: -75px;
}
/* Header page :: END */

/* Hero section :: START */
.hero-wrapper {
  padding-top: 150px;
  padding-bottom: 80px;
}

.hero-main-title {
  color: $black;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 30px;
}

.hero-sub-title {
  color: $black;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 30px;
}

.hero-para {
  color: $black;
  font-size: 20px;
  margin-bottom: 20px;
  line-height: 30px !important;
}
.hero-smaller-para {
  font-size: 20px;
}

.hero-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  margin-top: 30px;
}

.hero-btn-wrapper .btn {
  max-width: 250px;
  height: 45px;
  font-size: 22px;
}

.user-image {
  height: 45px;
}

.header_user-profile {
  .dropdown-toggle .menu-profile {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  .dropdown-toggle::after {
    content: none;
  }
}
.header_loggedIn-btns li a::after {
  display: none;
}

.profile-nav li a {
  white-space: nowrap;
}
.modal-dialog {
  width: auto !important;
}

.mobile-nav-buttons {
  display: flex;
  gap: 1rem;
}
/* Hero section :: END */

/* event section :: START */
.event-wrapper {
  padding-bottom: 112px;
  .slider {
    margin-bottom: -80px;
  }
  .event-bg {
    width: 100%;
    border-radius: 10px;
  }  
} 

.laptop-image {
  background-color: $black;
  padding: 112px 0px ;
}

.event-slider-wrapper {
  max-width: 1090px;
  border-radius: 10px;
  border: 3px solid $blue;
  background: $white;
  backdrop-filter: blur(2px);
  padding: 10px;
  margin: 0 auto;
}

.event-slider-wrapper .title {
  color: $black;
  text-align: center;
  font-size: 25px;
  font-weight: 700;
}

.event-slider {
  margin-top: 30px;
  margin-bottom: 10px;
}

.event-slider .slick-slide img {
  text-align: center;
  margin: 0 auto;
}

/* event section :: END */

/* tab section :: START */
.tab-wrapper {
  padding-top: 80px;
  padding-bottom: 80px;
}

.tabbing-wrapper {
  display: flex;
  align-items: flex-start;
  border-radius: 10px;
  border: 1px solid $lightGrey;
  background: $lightGrey;
  overflow: hidden;
}

.tabbing-wrapper .nav {
  flex-direction: column;
  min-width: 312px;
  border: 0;
}

.tabbing-wrapper .nav li.nav-item {
  background: $black;
  height: 70px;
  margin: 0;
  border-bottom: 1px solid $lightGrey;
}

.tabbing-wrapper .nav li.nav-item a {
  color: $white !important;
  text-align: center;
  font-size: 18px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  font-weight: 400;
  padding: 0;
}

.tabbing-wrapper .nav li.nav-item a.active,
.tabbing-wrapper .nav li.nav-item:hover a {
  background: $lightGrey !important;
  color: $black !important;
  font-weight: 700;
}

.tabbing-wrapper .tab-content {
  padding: 25px;
  width: 100%;
}
.tab-wrapper-one {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tab-title {
  color: $black;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  text-decoration-line: underline;
  margin-bottom: 20px;
}

.tab-para {
  color: #313131;
  text-align: center;
  font-size: 15px;
  line-height: 35px;
  margin-bottom: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.tab-img {
  margin-bottom: 15px;
  flex-direction: column;
  gap: 15px;
  flex: 1;
}

.learn-more {
  font-weight: 500;
  width: fit-content;
  margin: auto auto 0;
  border: none;
  background-color: transparent;
  font-size: 14px;
  padding: 5px 10px;
  height: auto;
  img {
    margin-bottom: -3px;
    filter: brightness(0) invert(1);
  }
}

/* tab section :: END */

/* explore section :: START */
.explore-wrapper {
  background-color: $black;
  // padding-top: 80px;
  padding-top: 93px;
  padding-bottom: 80px;
}

.explore-harpsen {
  background-color: $black;
  padding: 112px 0px;
}

.heading-wrapper .title {
  border-radius: 10px;
  border: 3px solid $blue;
  background: $white;
  backdrop-filter: blur(2px);
  color: $black;
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  padding: 28px;
  max-width: 844px;
  margin: 0 auto;
  margin-bottom: -50px;
  position: relative;
  z-index: 1;
}

.et_nav-tabs {
  border: 0;
  width: fit-content;
  border-radius: 100px;
  border: 2px solid $black;
  background: $lightGrey;
  padding: 4px;
  margin: 0px auto 40px;
}

.et_nav-link {
  border-radius: 0;
  border: 0;
  color: $black !important;
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 100px;
  padding: 5px 20px;

  .active {
    color: $white !important;
    background-color: $black;
  }
}
.et_nav-link_active {
  border-radius: 0;
  border: 0;
  color: $black;
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 100px;
  padding: 5px 20px;

  color: $white !important;
  background-color: $black;
}

.explore-tabbing .nav-tabs {
  border: 0;
  width: fit-content;
  border-radius: 100px;
  border: 2px solid $black;
  background: $lightGrey;
  padding: 4px;
  margin: 1px auto 40px;
} //

.explore-tabbing .nav-tabs .nav-item {
  margin-bottom: 0;
}

.explore-tabbing .nav-tabs .nav-link {
  border-radius: 0;
  border: 0;
  color: $black;
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 100px;
  padding: 5px 20px;
} //

.explore-tabbing .nav-tabs .nav-link.active {
  color: $white !important;
  background-color: $black;
}

.explore-tabbing .nav-tabs .nav-link:focus,
.explore-tabbing .nav-tabs .nav-link:hover {
  border-color: unset;
}

.et_nav-link {
  border-radius: 0;
  border: 0;
  color: $black;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 100px;
  padding: 5px 20px;
} //

.et_nav-link.active {
  color: $white !important;
  background-color: $black;
}

.et_nav-link:focus,
.et_nav-link:hover {
  border-color: unset;
}

.video-wrapper {
  border-radius: 10px;
}

.video-overlay {
  border-radius: 10px;
  background: rgba(222, 219, 217, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.vendor_portal-video {
 width: 100%;
 height: 520px; 
}

.video-play-btn {
  width: 60px;
  height: 60px;
  background-color: $white;
  border-radius: 100%;
  position: absolute;
  z-index: 8;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.video-play-btn:before,
.video-play-btn:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 50%;
  border: 1px solid $white;
  background-color: rgba(255, 255, 255, 0.5);
  left: -20px;
  right: -20px;
  bottom: -20px;
  top: -20px;
  animation: animate 1.5s linear infinite;
  opacity: 0;
  backface-visibility: hidden;
  z-index: -1;
}

.video-play-btn:after {
  animation-delay: 0.5s;
}

@keyframes animate {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

/* explore section :: END */

/* grid section :: START */
.grid-card-wrapper {
  border-radius: 10px;
  background: $lightGrey;
  padding: 30px;
  position: relative;
  margin-top: 145px;
  margin-bottom: 80px;
}

.grid-img-wrapper {
  border-radius: 100%;
  background: $lightGrey;
  width: 130px;
  height: 130px;
  margin: 0 auto;
  border: 15px solid $blue;
  margin-top: -94px;
}

.grid-card-wrapper h4 {
  color: $blue;
  font-size: 20px;
  font-weight: 700;
  line-height: 40px !important;
  margin-top: 30px;
  margin-bottom: 20px;
}

.grid-card-wrapper p {
  color: $black;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 0;
}

/* grid section :: END */

/* Our Story section :: START */
.our-story-wrapper {
  padding-top: 80px;
  padding-bottom: 108px;

  p {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 0;
    line-height: normal;
    line-height: 30px;
    color: $grey;
  }
}

.our-story-wrapper .title {
  color: $black;
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 30px;
}

.our-story-wrapper p {
  margin-bottom: 20px;
}

.our-story-wrapper .story-img-wrapper {
  background-color: $black;
  border-radius: 10px;
  float: right;
}

.our-story-wrapper .story-img-wrapper img {
  position: relative;
  top: 28px;
  right: 28px;
  border-radius: 10px;
}

/* Our Story section :: END */

/* people says section :: START */
.people-says-wrapper {
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: $black;
  overflow: hidden;
}

.people-says-wrapper .title {
  color: $white;
  font-size: 25px;
  font-weight: 700;
  padding-top: 113px;
  padding-left: 120px;
}

.people-card-wrapper {
  border-radius: 10px;
  background: $white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 30px;
  p {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 0;
    line-height: normal;
    line-height: 30px;
    color: $grey;

    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-style: italic;
  }
}

.people-card-wrapper .logo-wrapper {
  margin-bottom: 22px;
}

.people-card-wrapper .logo-wrapper img {
  max-width: 110px;
  width: 100%;
  height: 50px;
  width: 50px;
}

.people-card-wrapper .people-details {
  margin-top: 30px;
  gap: 10px;
}

.people-card-wrapper .people-details img {
  border-radius: 100px;
  border: 2px solid $blue;
  min-width: 50px;
  height: 50px;
}

.people-card-wrapper h6 {
  color: $blue;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px !important;
  margin: 0px;
}

.people-card-wrapper span {
  color: #777;
  font-size: 15px;
  line-height: 25px;
}

.people-say-slider,  .modal-content-wrapper{
  .slick-next:before,
  .slick-prev:before {
    display: none;
  }

  .slick-arrow {
    outline: none;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    z-index: 9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  }

  .slick-prev {
    position: absolute;
    left: -44%;
    top: 60%;

    // width: 50px;
    // height: 50px;
    // border-radius: 100%;
    // background-color: $white;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .slick-next {
    position: absolute;
    right: 130.5%;
    top: 60%;

    // width: 50px;
    // height: 50px;
    // border-radius: 100%;
    // background-color: $white;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
}
// .people-say-slider .slick-slide {
//   margin: 0 15px;
// }

// .people-say-slider .slick-list {
//   margin: 0 -15px;
// }

// .pss .slick-slide {
//   margin: 0 15px;
// }

// .pss .slick-list {
//   margin: 0 -15px;
// }

.people-say-slider .slick-prev:before,
.people-say-slider .slick-next:before {
  display: none;
}

.people-say-slider .slick-arrow {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.people-say-slider .slick-arrow img {
  width: 32px;
}

.people-say-slider .slick-prev {
  left: -44%;
  top: 54%;
}

.people-say-slider .slick-next {
  right: 130.5%;
  top: 54%;
}

.people-says-wrapper > .people-content {
  width: 550px;
}

.people-says-wrapper .people-slider {
  max-width: calc(100% - 550px);
  width: 100%;
}

/* people says section :: END */

/* pricing section :: START */
.pricing-wrapper {
  padding-top: 80px;
  padding-bottom: 80px;
}

.pricing-wrapper .nav-tabs {
  margin-bottom: 30px;
}

.pricing-card-wrapper {
  border-radius: 25px;
  background: $lightGrey;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 40px;
  max-width: 996px;
  margin: 0 auto;

  button, a {
  width: 184px !important;
  white-space: nowrap;
  }
}

.pricing-card-wrapper h3 {
  color: $blue;
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 26px;
}

.pricing-card-wrapper h3 span {
  color: $black;
  font-size: 25px;
  font-weight: 400;
}

.pricing-card-wrapper h4 {
  color: $black;
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 20px;
}

.pricing-card-wrapper p {
  color: #313131;
  font-size: 18px;
  margin-bottom: 24px;
  max-width: 390px;
  line-height: 30px;
}

.pricing-card-wrapper ul {
  list-style-type: none;
}

.pricing-card-wrapper ul li {
  color: #313131;
  font-size: 18px;
  position: relative;
  padding-left: 31px;
}

.pricing-card-wrapper ul li::before {
  content: "";
  width: 24px;
  height: 24px;
  background-image: url(../images/icons/check-circle.svg);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: -3px;
}

.pricing-card-wrapper ul li:not(:last-child) {
  margin-bottom: 11px;
}

.pricing-card-wrapper:before {
  content: "";
  width: 1px;
  height: calc(100% - 62px);
  background-color: $blue;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* pricing section :: END */

/* footer section :: START */
.footer-wrapper {
  background-color: $lightGrey;

  p {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 0;
    line-height: normal;
    line-height: 30px;
    color: $grey;
  }
}

.footer-wrapper h6 {
  color: $black;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 35px;
  margin-top: 50px;
}

.footer-wrapper ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.footer-wrapper ul li a {
  color: $black;
  font-size: 18px;
}

.footer-wrapper ul li:not(:last-child) {
  margin-bottom: 20px;
}

.footer-wrapper ul li img {
  margin-right: 13px;
}

.footer-wrapper .social {
  display: flex;
  gap: 15px;
}

.footer-wrapper .social li img {
  margin: 0;
}

.footer-wrapper .social li a {
  border-radius: 100%;
  background: $blue;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-wrapper .copy-rights {
  border-top: 2px solid $black;
  margin-top: 40px;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  color: $black;
  font-weight: 500;
}

/* footer section :: END */

/* FAQ section :: START */
.other-pages header,
.other-pages .hero-wrapper {
  background-color: $lightGrey;
}

.faq-wrapper {
  padding-top: 80px;
  padding-bottom: 80px;
}

.search-field {
  margin-bottom: 40px;
}

.search-field input {
  padding-left: 45px;
}

.search-field img {
  position: absolute;
  z-index: 2;
  left: 15px;
  top: 13px;
}

.faq-title {
  max-width: 100%;
  color: $black;
  font-size: 20px;
  font-weight: 700;
  border-radius: 0;
  justify-content: flex-start;
  padding: 16px 0;
  position: relative;
  border: 0;
  height: auto;
  text-align: left;
  padding-right: 25px;
}

.faq-title:before {
  content: "";
  width: 24px;
  height: 24px;
  background-image: url(../images/icons/chevron-down.svg);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 0;
}

.faq-title[aria-expanded="true"] {
  color: $blue;
}

.faq-title[aria-expanded="true"]:before {
  transform: rotate(180deg);
  filter: brightness(0) saturate(100%) invert(55%) sepia(97%) saturate(5285%)
    hue-rotate(181deg) brightness(86%) contrast(80%);
}

.faq-title:focus {
  outline: unset;
  box-shadow: unset;
  border-color: unset;
}

.faq-wrapper .card {
  border: 0;
}

.faq-wrapper .card-header {
  padding: 0;
  background-color: transparent;
  border-bottom: 2px solid $lightGrey;
}

.faq-wrapper .collapse.show {
  border-bottom: 2px solid $lightGrey;
}

.faq-wrapper .accordion > .card > .card-header {
  margin: 0;
}

.faq-wrapper .card-body h6 {
  color: $blue;
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 20px;
}

.faq-wrapper .card-body ol li {
  color: $grey;
  font-size: 20px;
}

.faq-wrapper .card-body ol li:not(:last-child) {
  margin-bottom: 15px;
}

.faq-wrapper .card-body ol li img {
  margin-top: 20px;
  margin-bottom: 5px;
  width: 100%;
  border: 1px solid $blue;
  border-radius: 10px;
  max-width: calc(100% - 15px);
  display: block;
}

.faq-wrapper .card-body ol li img:last-child {
  margin-top: 15px;
}

.faq-wrapper .card-body ol li ol li {
  margin-top: 5px;
  margin-bottom: 0 !important;
}

.faq-btn {
  max-width: 540px;
  margin: 0 auto;
  margin-top: 40px;
}

.faq-wrapper .card-body {
  font-size: 16px !important;
  line-height: 1.5 !important;
}

/* FAQ section :: END */

/* Blog section :: START */
.blog-wrapper {
  padding-top: 80px;
  padding-bottom: 80px;
}

.blog-wrapper .blog-img img {
  border-radius: 10px;
  width: 100%;
  margin-bottom: 15px;
}

.blog-wrapper .blog-content .blog-title {
  color: $black;
  text-overflow: ellipsis;
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blog-wrapper .blog-content .blog-para {
  color: $grey;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.blog-wrapper .blog-content .btn {
  max-width: 100%;
}

.blog-wrapper .row {
  row-gap: 40px;
  margin-left: -20px;
  margin-right: -20px;
}

.blog-wrapper .row .col-lg-4.col-md-6 {
  padding-left: 20px;
  padding-right: 20px;
}

/* Blog section :: END */

/* Blog Details section :: START */
.blog-details-wrapper {
  padding-top: 80px;
  padding-bottom: 80px;
}

.blog-details-img img {
  width: 100%;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog-details-title {
  color: $black;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0rem !important;
}

.blog-details-content {
  gap: 15px 75px;
  flex-wrap: wrap;
  margin-bottom: 22px;
}

.blog-author {
  gap: 13px;
}

.author-img {
  border-radius: 5px;
  background: $blue;
  width: 30px;
  height: 30px;
}

.blog-author h6 {
  color: $black;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  margin-bottom: 0px !important;
}

.blog-details-wrapper p {
  color: $grey;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.blog-details-wrapper p:not(:last-child) {
  margin-bottom: 20px;
}

.blog-details-wrapper {
  p {
    &:last-child {
      margin-bottom: 0px;
    }
  } 
} 

.quote {
  padding: 30px 65px;
  border-radius: 10px;
  background-color: $lightGrey;
  margin-top: 25px;
  margin-bottom: 25px;
  display: block;
}

.quote p {
  margin: 0;
  color: $black;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
}

.quote:before,
.quote:after {
  content: "";
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
}

.quote:before {
  background-image: url(../images/icons/quote-up.svg);
  top: 16px;
  left: 16px;
}

.quote:after {
  background-image: url(../images/icons/quote-down.svg);
  bottom: 16px;
  right: 16px;
}

.blog-details-sidebar {
  padding-left: 10px;
}

.blog-details-sidebar .col-md-12:not(:last-child) {
  margin-bottom: 44px;
}

.blog-details-sidebar {
  .col-md-12 {
    &:not(:last-child) {
      margin-bottom: 44px;
    }
  }
} 

.blog-details-sidebar h4 {
  color: $black;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 10px;
  border-bottom: 2px solid $lightGrey;
  margin-bottom: 21px;
}

.blog-details-sidebar ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.blog-details-sidebar ul li a {
  color: $grey;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  padding-right: 30px;
  display: block;
}

.blog-details-sidebar ul li:before {
  content: "";
  width: 24px;
  height: 24px;
  background-image: url(../images/icons/category-list-chevron.svg);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 0;
}

.blog-details-sidebar .category-list li:not(:last-child) {
  margin-bottom: 10px;
}

.blog-details-sidebar .recent-list li:not(:last-child) {
  margin-bottom: 15px;
}

.blog-details-sidebar .recent-list li a {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-right: 0;
}

.blog-details-sidebar .recent-list li:before {
  display: none;
}

.blog-details-sidebar .recent-list li a img {
  width: 125px;
  height: 83px;
  object-fit: cover;
  border-radius: 10px;
}

.blog-details-sidebar .recent-list li a span {
  color: $grey;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Blog Details section :: END */

/* All modal :: START */
.modal-style .modal-xl {
  max-width: 1024px;
  border-radius: 10px;
  background: $white;
  align-items: flex-start;
}
.modal-style .modal-xl .modal-body {
  border: 0;
  padding: 60px 50px;
}
.modal-style .modal-xl .modal-content {
  border: 0;
  border-radius: 10px;
}

.modal-style .close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100% !important;
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25) !important;
  position: absolute;
  right: -15px;
  top: -15px;
  opacity: 1 !important;
}
.modal-style .close img {
  width: 30px;
  height: 30px;
}
.modal-content-wrapper .modal-card-wrapper .title {
  color: $black;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}
.modal-content-wrapper .modal-card-wrapper p {
  color: $grey;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
.modal-content-wrapper .modal-card-wrapper p:not(:last-child) {
  margin-bottom: 15px;
}
.modal-content-wrapper .modal-card-wrapper .modal-img-slider {
  margin-top: 30px;
  text-align: center;
}
.modal-content-wrapper .modal-card-wrapper .modal-img-slider img {
  border-radius: 10px; 
  border: 1px solid $lightGrey;
  margin: 0 auto;
}
// SLICK DOTS : _overriding.scss
.modal-style .modal-img-slider .slick-dots {
  text-align: center;
  bottom: -50px;
}
.modal-style .modal-img-slider .slick-dots li button {
  width: 10px;
  height: 10px;
  border: 1px solid $blue;
  background-color: $white;
  border-radius: 10px;
  margin: 0 auto;
}
.modal-style .modal-img-slider .slick-dots li.slick-active button {
  background-color: $blue;
  width: 20px;
}
.modal-style .modal-img-slider .slick-dots li button:before {
  display: none;
}
.modal-content-wrapper .slick-arrow:before {
  display: none;
}
.modal-content-wrapper .slick-arrow {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $blue;
  border-radius: 100%;
  top: 0;
}
.modal-content-wrapper .slick-arrow.slick-prev {
  left: unset;
  right: 62px;
}
.modal-content-wrapper .slick-arrow.slick-next {
  right: 10px;
}
.modal-content-wrapper .slick-arrow img {
  width: 32px;
  height: 32px;
  filter: brightness(0) saturate(100%) invert(90%) sepia(1%) saturate(0%)
    hue-rotate(172deg) brightness(111%) contrast(101%);
}
.based-budget {
  padding: 20px;
  margin: 20px auto 0;
  max-width: 610px;
  background-color: $lightGrey;
  border: 1px solid $blue;
  border-radius: 10px;
  width: 100%;
}
.based-budget {
  color: $blue !important;
}
/* All modal :: END */

.vertical-tab li a {
  transition: none !important;
}

@import "./responsive";
