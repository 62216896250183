.particle_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: white;
  color: black;
  overflow: hidden;
}

.particle_content {
  position: relative;
  width: 500px;
  max-width: 100%;
  margin: 20px;
  background: white;
  padding: 50px 30px;
  text-align: center;
  /* box-shadow: -10px 10px 67px -12px rgba(0, 0, 0, 0.2); */
  opacity: 0;
  animation: apparition 0.8s 1.2s cubic-bezier(0.39, 0.575, 0.28, 0.995) forwards;
}

.particle_content p {
  font-size: 1.3rem;
  margin-top: 0;
  margin-bottom: 0.6rem;
  letter-spacing: 0.1rem;
  color: #595959;
}

.particle_content p:last-child {
  margin-bottom: 0;
}

.particle {
  position: absolute;
  display: block;
  pointer-events: none;
}

.particle:nth-child(1) {
  top: 71.921182266%;
  left: 70.1581027668%;
  font-size: 12px;
  filter: blur(0.02px);
  animation: 30s floatReverse2 infinite;
}

.particle:nth-child(2) {
  top: 34.7826086957%;
  left: 40.8560311284%;
  font-size: 28px;
  filter: blur(0.04px);
  animation: 34s floatReverse2 infinite;
}

.particle:nth-child(3) {
  top: 6.7796610169%;
  left: 12.6705653021%;
  font-size: 26px;
  filter: blur(0.06px);
  animation: 29s floatReverse infinite;
}

.particle:nth-child(4) {
  top: 43.3734939759%;
  left: 75.7281553398%;
  font-size: 30px;
  filter: blur(0.08px);
  animation: 38s float infinite;
}

.particle:nth-child(5) {
  top: 93.6585365854%;
  left: 42.1568627451%;
  font-size: 20px;
  filter: blur(0.1px);
  animation: 22s float2 infinite;
}

.particle:nth-child(6) {
  top: 35.3808353808%;
  left: 52.2682445759%;
  font-size: 14px;
  filter: blur(0.12px);
  animation: 26s floatReverse2 infinite;
}

.particle:nth-child(7) {
  top: 48.4848484848%;
  left: 63.4146341463%;
  font-size: 25px;
  filter: blur(0.14px);
  animation: 29s float2 infinite;
}

.particle:nth-child(8) {
  top: 60.7099143207%;
  left: 61.9469026549%;
  font-size: 17px;
  filter: blur(0.16px);
  animation: 23s float infinite;
}

.particle:nth-child(9) {
  top: 61.1650485437%;
  left: 27.34375%;
  font-size: 24px;
  filter: blur(0.18px);
  animation: 35s floatReverse2 infinite;
}

.particle:nth-child(10) {
  top: 26.1818181818%;
  left: 47.8048780488%;
  font-size: 25px;
  filter: blur(0.2px);
  animation: 30s float infinite;
}

.particle:nth-child(11) {
  top: 71.8446601942%;
  left: 65.4296875%;
  font-size: 24px;
  filter: blur(0.22px);
  animation: 36s floatReverse infinite;
}

.particle:nth-child(12) {
  top: 60.6356968215%;
  left: 6.8762278978%;
  font-size: 18px;
  filter: blur(0.24px);
  animation: 38s floatReverse2 infinite;
}

.particle:nth-child(13) {
  top: 61.7647058824%;
  left: 28.5433070866%;
  font-size: 16px;
  filter: blur(0.26px);
  animation: 37s floatReverse2 infinite;
}

.particle:nth-child(14) {
  top: 88.7816646562%;
  left: 28.1827016521%;
  font-size: 29px;
  filter: blur(0.28px);
  animation: 37s float2 infinite;
}

.particle:nth-child(15) {
  top: 96.4329643296%;
  left: 74.0375123396%;
  font-size: 13px;
  filter: blur(0.3px);
  animation: 27s float infinite;
}

.particle:nth-child(16) {
  top: 21.4896214896%;
  left: 53.974484789%;
  font-size: 19px;
  filter: blur(0.32px);
  animation: 36s floatReverse2 infinite;
}

.particle:nth-child(17) {
  top: 32%;
  left: 47.8048780488%;
  font-size: 25px;
  filter: blur(0.34px);
  animation: 32s float infinite;
}

.particle:nth-child(18) {
  top: 30.5418719212%;
  left: 1.976284585%;
  font-size: 12px;
  filter: blur(0.36px);
  animation: 39s floatReverse infinite;
}

.particle:nth-child(19) {
  top: 82.2249093108%;
  left: 91.5287244401%;
  font-size: 27px;
  filter: blur(0.38px);
  animation: 23s floatReverse2 infinite;
}

.particle:nth-child(20) {
  top: 53.9759036145%;
  left: 81.5533980583%;
  font-size: 30px;
  filter: blur(0.4px);
  animation: 21s floatReverse infinite;
}

.particle:nth-child(21) {
  top: 37.9562043796%;
  left: 8.8062622309%;
  font-size: 22px;
  filter: blur(0.42px);
  animation: 24s floatReverse infinite;
}

.particle:nth-child(22) {
  top: 70.3614457831%;
  left: 14.5631067961%;
  font-size: 30px;
  filter: blur(0.44px);
  animation: 23s floatReverse infinite;
}

.particle:nth-child(23) {
  top: 81.472392638%;
  left: 77.8325123153%;
  font-size: 15px;
  filter: blur(0.46px);
  animation: 32s float infinite;
}

.particle:nth-child(24) {
  top: 54.9397590361%;
  left: 53.3980582524%;
  font-size: 30px;
  filter: blur(0.48px);
  animation: 33s floatReverse infinite;
}

.particle:nth-child(25) {
  top: 50.1809408926%;
  left: 79.6890184645%;
  font-size: 29px;
  filter: blur(0.5px);
  animation: 24s float2 infinite;
}

.particle:nth-child(26) {
  top: 53.7240537241%;
  left: 45.142296369%;
  font-size: 19px;
  filter: blur(0.52px);
  animation: 28s floatReverse2 infinite;
}

.particle:nth-child(27) {
  top: 55.205811138%;
  left: 52.6315789474%;
  font-size: 26px;
  filter: blur(0.54px);
  animation: 30s float2 infinite;
}

.particle:nth-child(28) {
  top: 48.1572481572%;
  left: 15.7790927022%;
  font-size: 14px;
  filter: blur(0.56px);
  animation: 31s floatReverse2 infinite;
}

.particle:nth-child(29) {
  top: 25.2427184466%;
  left: 83.0078125%;
  font-size: 24px;
  filter: blur(0.58px);
  animation: 29s float2 infinite;
}

.particle:nth-child(30) {
  top: 17.6470588235%;
  left: 25.5905511811%;
  font-size: 16px;
  filter: blur(0.6px);
  animation: 25s float2 infinite;
}

.particle:nth-child(31) {
  top: 93.4809348093%;
  left: 20.7305034551%;
  font-size: 13px;
  filter: blur(0.62px);
  animation: 32s floatReverse2 infinite;
}

.particle:nth-child(32) {
  top: 18.6046511628%;
  left: 39.331366765%;
  font-size: 17px;
  filter: blur(0.64px);
  animation: 23s float2 infinite;
}

.particle:nth-child(33) {
  top: 3.9024390244%;
  left: 11.7647058824%;
  font-size: 20px;
  filter: blur(0.66px);
  animation: 23s float infinite;
}

.particle:nth-child(34) {
  top: 64.3902439024%;
  left: 54.9019607843%;
  font-size: 20px;
  filter: blur(0.68px);
  animation: 24s float infinite;
}

.particle:nth-child(35) {
  top: 42.0537897311%;
  left: 77.6031434185%;
  font-size: 18px;
  filter: blur(0.7px);
  animation: 22s float2 infinite;
}

.particle:nth-child(36) {
  top: 36.6706875754%;
  left: 51.5063168124%;
  font-size: 29px;
  filter: blur(0.72px);
  animation: 34s float2 infinite;
}

.particle:nth-child(37) {
  top: 40.4438964242%;
  left: 70.2274975272%;
  font-size: 11px;
  filter: blur(0.74px);
  animation: 33s floatReverse infinite;
}

.particle:nth-child(38) {
  top: 61.8357487923%;
  left: 86.5758754864%;
  font-size: 28px;
  filter: blur(0.76px);
  animation: 22s float infinite;
}

.particle:nth-child(39) {
  top: 34.5252774353%;
  left: 87.0425321464%;
  font-size: 11px;
  filter: blur(0.78px);
  animation: 30s float infinite;
}

.particle:nth-child(40) {
  top: 2.8915662651%;
  left: 17.4757281553%;
  font-size: 30px;
  filter: blur(0.8px);
  animation: 31s floatReverse2 infinite;
}

.particle:nth-child(41) {
  top: 39.457459926%;
  left: 17.8041543027%;
  font-size: 11px;
  filter: blur(0.82px);
  animation: 32s float2 infinite;
}

.particle:nth-child(42) {
  top: 3.9119804401%;
  left: 56.974459725%;
  font-size: 18px;
  filter: blur(0.84px);
  animation: 36s float2 infinite;
}

.particle:nth-child(43) {
  top: 39.8058252427%;
  left: 19.53125%;
  font-size: 24px;
  filter: blur(0.86px);
  animation: 30s float2 infinite;
}

.particle:nth-child(44) {
  top: 37.1184371184%;
  left: 49.0677134446%;
  font-size: 19px;
  filter: blur(0.88px);
  animation: 36s floatReverse infinite;
}

.particle:nth-child(45) {
  top: 85.4368932039%;
  left: 4.8828125%;
  font-size: 24px;
  filter: blur(0.9px);
  animation: 26s floatReverse2 infinite;
}

.particle:nth-child(46) {
  top: 72.6380368098%;
  left: 22.6600985222%;
  font-size: 15px;
  filter: blur(0.92px);
  animation: 26s floatReverse infinite;
}

.particle:nth-child(47) {
  top: 57.1428571429%;
  left: 16.7984189723%;
  font-size: 12px;
  filter: blur(0.94px);
  animation: 38s float infinite;
}

.particle:nth-child(48) {
  top: 61.0837438424%;
  left: 0.9881422925%;
  font-size: 12px;
  filter: blur(0.96px);
  animation: 26s floatReverse2 infinite;
}

.particle:nth-child(49) {
  top: 77.5757575758%;
  left: 97.5609756098%;
  font-size: 25px;
  filter: blur(0.98px);
  animation: 21s float infinite;
}

.particle:nth-child(50) {
  top: 59.5848595849%;
  left: 14.7203140334%;
  font-size: 19px;
  filter: blur(1px);
  animation: 32s floatReverse infinite;
}

.particle:nth-child(51) {
  top: 25.5214723926%;
  left: 86.6995073892%;
  font-size: 15px;
  filter: blur(1.02px);
  animation: 31s float infinite;
}

.particle:nth-child(52) {
  top: 81.8742293465%;
  left: 14.8367952522%;
  font-size: 11px;
  filter: blur(1.04px);
  animation: 34s float infinite;
}

.particle:nth-child(53) {
  top: 1.9277108434%;
  left: 40.7766990291%;
  font-size: 30px;
  filter: blur(1.06px);
  animation: 35s float infinite;
}

.particle:nth-child(54) {
  top: 67.9802955665%;
  left: 25.6916996047%;
  font-size: 12px;
  filter: blur(1.08px);
  animation: 31s float infinite;
}

.particle:nth-child(55) {
  top: 5.7901085645%;
  left: 2.915451895%;
  font-size: 29px;
  filter: blur(1.1px);
  animation: 26s floatReverse infinite;
}

.particle:nth-child(56) {
  top: 54.9397590361%;
  left: 96.1165048544%;
  font-size: 30px;
  filter: blur(1.12px);
  animation: 24s float infinite;
}

.particle:nth-child(57) {
  top: 12.6213592233%;
  left: 12.6953125%;
  font-size: 24px;
  filter: blur(1.14px);
  animation: 27s floatReverse2 infinite;
}

.particle:nth-child(58) {
  top: 66.6666666667%;
  left: 83.6614173228%;
  font-size: 16px;
  filter: blur(1.16px);
  animation: 25s float2 infinite;
}

.particle:nth-child(59) {
  top: 12.8078817734%;
  left: 0.9881422925%;
  font-size: 12px;
  filter: blur(1.18px);
  animation: 37s float2 infinite;
}

.particle:nth-child(60) {
  top: 45.7420924574%;
  left: 16.6340508806%;
  font-size: 22px;
  filter: blur(1.2px);
  animation: 33s float2 infinite;
}

.particle:nth-child(61) {
  top: 57.7017114914%;
  left: 5.8939096267%;
  font-size: 18px;
  filter: blur(1.22px);
  animation: 40s float2 infinite;
}

.particle:nth-child(62) {
  top: 21.23039807%;
  left: 47.619047619%;
  font-size: 29px;
  filter: blur(1.24px);
  animation: 28s float infinite;
}

.particle:nth-child(63) {
  top: 29.4478527607%;
  left: 11.8226600985%;
  font-size: 15px;
  filter: blur(1.26px);
  animation: 21s floatReverse2 infinite;
}

.particle:nth-child(64) {
  top: 69.864698647%;
  left: 46.3968410661%;
  font-size: 13px;
  filter: blur(1.28px);
  animation: 22s floatReverse infinite;
}

.particle:nth-child(65) {
  top: 72.6380368098%;
  left: 58.1280788177%;
  font-size: 15px;
  filter: blur(1.3px);
  animation: 30s float infinite;
}

.particle:nth-child(66) {
  top: 79.8053527981%;
  left: 89.0410958904%;
  font-size: 22px;
  filter: blur(1.32px);
  animation: 32s float2 infinite;
}

.particle:nth-child(67) {
  top: 13.7423312883%;
  left: 96.5517241379%;
  font-size: 15px;
  filter: blur(1.34px);
  animation: 39s float2 infinite;
}

.particle:nth-child(68) {
  top: 4.8899755501%;
  left: 43.2220039293%;
  font-size: 18px;
  filter: blur(1.36px);
  animation: 38s float2 infinite;
}

.particle:nth-child(69) {
  top: 64.5476772616%;
  left: 3.9292730845%;
  font-size: 18px;
  filter: blur(1.38px);
  animation: 26s floatReverse infinite;
}

.particle:nth-child(70) {
  top: 76.5644171779%;
  left: 42.3645320197%;
  font-size: 15px;
  filter: blur(1.4px);
  animation: 24s float infinite;
}

.particle:nth-child(71) {
  top: 92.9782082324%;
  left: 21.4424951267%;
  font-size: 26px;
  filter: blur(1.42px);
  animation: 31s float infinite;
}

.particle:nth-child(72) {
  top: 42.3124231242%;
  left: 37.5123395854%;
  font-size: 13px;
  filter: blur(1.44px);
  animation: 27s floatReverse infinite;
}

.particle:nth-child(73) {
  top: 25.3968253968%;
  left: 43.1795878312%;
  font-size: 19px;
  filter: blur(1.46px);
  animation: 24s float infinite;
}

.particle:nth-child(74) {
  top: 32.9696969697%;
  left: 23.4146341463%;
  font-size: 25px;
  filter: blur(1.48px);
  animation: 34s floatReverse2 infinite;
}

.particle:nth-child(75) {
  top: 66.9126691267%;
  left: 4.935834156%;
  font-size: 13px;
  filter: blur(1.5px);
  animation: 22s float2 infinite;
}

.particle:nth-child(76) {
  top: 13.4939759036%;
  left: 97.0873786408%;
  font-size: 30px;
  filter: blur(1.52px);
  animation: 24s floatReverse infinite;
}

.particle:nth-child(77) {
  top: 49.3946731235%;
  left: 60.4288499025%;
  font-size: 26px;
  filter: blur(1.54px);
  animation: 29s float infinite;
}

.particle:nth-child(78) {
  top: 93.023255814%;
  left: 86.529006883%;
  font-size: 17px;
  filter: blur(1.56px);
  animation: 23s floatReverse infinite;
}

.particle:nth-child(79) {
  top: 84.2105263158%;
  left: 80.6293018682%;
  font-size: 17px;
  filter: blur(1.58px);
  animation: 36s floatReverse2 infinite;
}

.particle:nth-child(80) {
  top: 39.3603936039%;
  left: 81.9348469891%;
  font-size: 13px;
  filter: blur(1.6px);
  animation: 34s floatReverse2 infinite;
}

@keyframes apparition {
  from {
    opacity: 0;
    transform: translateY(100px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes float {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(180px);
  }
}

@keyframes floatReverse {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-180px);
  }
}

@keyframes float2 {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(28px);
  }
}

@keyframes floatReverse2 {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-28px);
  }
}