// DO NOT INCLUDE / IMPORT THIS FILE INTO ANY OTHER SCSS FILE
// THIS FILE MUST BE USED TO DIRECTLY TARGET THE CLASSES OF PACKAGES
// CREATING THIS FILE BECAUSE CSS MODULES WITH REACT SLICK PACKAGE IS NOT WORKING
@import "./variables";

.modal-style .modal-img-slider .slick-dots {
  text-align: center;
  bottom: -50px;
}
.modal-style .modal-img-slider .slick-dots li button {
  width: 10px;
  height: 10px;
  border: 1px solid $blue;
  background-color: $white;
  border-radius: 10px;
  margin: 0 auto;
}
.modal-style .modal-img-slider .slick-dots li.slick-active button {
  background-color: $blue;
  width: 20px;
}
.modal-style .modal-img-slider .slick-dots li button:before {
  display: none;
}

.modal-backdrop.fade.show {
  z-index: 10;
}

.h_bg_wrapper {
  box-sizing: border-box;
  font-family: "Noto Sans TC", sans-serif;
  background-color: #fff;

  * {
    letter-spacing: normal;
    font-family: "Noto Sans TC", 'boxicons', sans-serif;
    outline: none !important;
    box-sizing: border-box;
    i.bx {
      font-family: 'boxicons' !important;
    }
  }
  
  .bx {
    font-family: 'boxicons' !important;
  }
   

  p {
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 0;
    line-height: normal;
    line-height: 30px;
    color: $grey;
  }

  h6,
  h5,
  h4,
  h3,
  h2,
  h1 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 400;
    line-height: normal !important;
  }
}

.h_bg_wrapper .bx {
  font-family: 'boxicons' !important;
}