#frame {
  width: 100%;
  /* min-width: 100%; */
  /* max-width: 100%; */
  height: 100%;
  /* min-height: 300px; */
  /* max-height: 720px; */
  margin: 0 auto;
}

@media screen and (max-width: 360px) {
  #frame {
    width: 100%;
    height: 100vh;
  }
}

#frame #sidepanel {
  float: left;
  /* min-width: 250px;
  max-width: 320px; */
  width: 40%;
  height: 70vh;
  /* height: 540px; */
  background: #2c3e50;
  color: #f5f5f5;
  overflow: hidden;
  position: relative;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel {
    width: 58px;
    min-width: 58px;
  }
}

#frame #sidepanel #profile {
  /* width: 80%; */
  margin: 8px 12px;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #profile {
    /* width: 100%; */
    margin: 8px 6px;
    padding: 5px 0 0 0;
    background: #32465a;
  }
}

#frame #sidepanel #profile .wrap {
  height: 60px;
  line-height: 60px;
  -moz-transition: 0.3s height ease;
  -o-transition: 0.3s height ease;
  -webkit-transition: 0.3s height ease;
  transition: 0.3s height ease;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap {
    height: 55px;
  }
}

#frame #sidepanel #profile .wrap img {
  width: 50px;
  border-radius: 50%;
  padding: 3px;
  height: auto;
  float: left;
  cursor: pointer;
  -moz-transition: 0.3s border ease;
  -o-transition: 0.3s border ease;
  -webkit-transition: 0.3s border ease;
  transition: 0.3s border ease;
  height: 50px;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap img {
    width: 40px;
    margin-left: 10px;
  }
}

#frame #sidepanel #profile .wrap span {
  position: absolute;
  left: 38px;
  margin: -2px 0 0 -2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #2c3e50;
  background: #95a5a6;
  bottom: -24px;
}

#frame #sidepanel #profile .wrap span.online {
  background: #2ecc71;
}

#frame #sidepanel #profile .wrap span.away {
  background: #f1c40f;
}

#frame #sidepanel #profile .wrap span.busy {
  background: #e74c3c;
}

#frame #sidepanel #profile .wrap span.offline {
  background: #95a5a6;
}

#frame #sidepanel #profile .wrap p {
  float: left;
  margin-left: 4px;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap p {
    /* display: none; */
  }
}

#frame #sidepanel #profile .wrap i.expand-button {
  float: right;
  margin-top: 23px;
  font-size: 0.8em;
  cursor: pointer;
  color: #435f7a;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap i.expand-button {
    display: none;
  }
}

#frame #sidepanel #profile .wrap #status-options {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  width: 150px;
  margin: 70px 0 0 0;
  border-radius: 6px;
  z-index: 99;
  line-height: initial;
  background: #435f7a;
  -moz-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options {
    width: 58px;
    margin-top: 57px;
  }
}

#frame #sidepanel #profile .wrap #status-options.active {
  opacity: 1;
  visibility: visible;
  margin: 75px 0 0 0;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options.active {
    margin-top: 62px;
  }
}

#frame #sidepanel #profile .wrap #status-options:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid #435f7a;
  margin: -8px 0 0 24px;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options:before {
    margin-left: 23px;
  }
}

#frame #sidepanel #profile .wrap #status-options ul {
  overflow: hidden;
  border-radius: 6px;
  list-style: none;
  margin: 0;
}

#frame #sidepanel #profile .wrap #status-options ul li {
  padding: 15px 0 30px 18px;
  display: block;
  cursor: pointer;
  list-style: none;
  margin: 0;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options ul li {
    padding: 15px 0 35px 22px;
  }
}

#frame #sidepanel #profile .wrap #status-options ul li:hover {
  background: #496886;
}

#frame #sidepanel #profile .wrap #status-options ul li span.status-circle {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 5px 0 0 0;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options ul li span.status-circle {
    width: 14px;
    height: 14px;
  }
}

#frame #sidepanel #profile .wrap #status-options ul li span.status-circle:before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  margin: -3px 0 0 -3px;
  background: transparent;
  border-radius: 50%;
  z-index: 0;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options ul li span.status-circle:before {
    height: 18px;
    width: 18px;
  }
}

#frame #sidepanel #profile .wrap #status-options ul li p {
  padding-left: 12px;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options ul li p {
    display: none;
  }
}

#frame #sidepanel #profile .wrap #status-options ul li#status-online span.status-circle {
  background: #2ecc71;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-online.active span.status-circle:before {
  border: 1px solid #2ecc71;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-away span.status-circle {
  background: #f1c40f;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-away.active span.status-circle:before {
  border: 1px solid #f1c40f;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-busy span.status-circle {
  background: #e74c3c;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-busy.active span.status-circle:before {
  border: 1px solid #e74c3c;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-offline span.status-circle {
  background: #95a5a6;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-offline.active span.status-circle:before {
  border: 1px solid #95a5a6;
}

#frame #sidepanel #profile .wrap #expanded {
  padding: 100px 0 0 0;
  display: block;
  line-height: initial !important;
}

#frame #sidepanel #profile .wrap #expanded label {
  float: left;
  clear: both;
  margin: 0 8px 5px 0;
  padding: 5px 0;
}

#frame #sidepanel #profile .wrap #expanded input {
  border: none;
  margin-bottom: 6px;
  background: #32465a;
  border-radius: 3px;
  color: #f5f5f5;
  padding: 7px;
  width: calc(100% - 43px);
}

#frame #sidepanel #profile .wrap #expanded input:focus {
  outline: none;
  background: #435f7a;
}

#frame #sidepanel #search {
  border-top: 1px solid #45586b;
  border-bottom: 1px solid #45586b;
  font-weight: 300;

}

@media screen and (max-width: 735px) {
  #frame #sidepanel #search {
    display: none;
  }
}

#frame #sidepanel #search label {
  position: absolute;
  margin: 10px 0 0 20px;
}

#frame #sidepanel #search input {
  font-family: "proxima-nova", "Source Sans Pro", sans-serif;
  padding: 10px 10px 10px 46px;
  width: 100%;
  border: none;
  background: #45586b;
  color: #f5f5f5;
  font-size: 16px;
  background-color: #45586b;
}

#frame #sidepanel #search input:focus {
  outline: none;
  background: #435f7a;
}

#frame #sidepanel #search input::-webkit-input-placeholder {
  color: #f5f5f5;
}

#frame #sidepanel #search input::-moz-placeholder {
  color: #f5f5f5;
}

#frame #sidepanel #search input:-ms-input-placeholder {
  color: #f5f5f5;
}

#frame #sidepanel #search input:-moz-placeholder {
  color: #f5f5f5;
}

#frame #sidepanel #contacts {
  height: calc(100% - 162px);
  overflow: hidden auto;
  max-height: 800px;
  /* overflow-x: hidden; */
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts {
    height: calc(100% - 136px);
    overflow: hidden auto;
  }

  #frame #sidepanel #contacts::-webkit-scrollbar {
    display: none;
  }
}

#frame #sidepanel #contacts.expanded {
  height: calc(100% - 334px);
}

#frame #sidepanel #contacts::-webkit-scrollbar {
  width: 8px;
  background: #2c3e50;
}

#frame #sidepanel #contacts::-webkit-scrollbar-thumb {
  background-color: #243140;
}

#frame #sidepanel #contacts ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#frame #sidepanel #contacts ul li.contact {
  position: relative;
  padding: 8px 0;
  font-size: 0.9em;
  cursor: pointer;
  border-bottom: 1px solid #45586b;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact {
    padding: 6px 0 6px 8px;
  }
}

#frame #sidepanel #contacts ul li.contact:hover {
  background: #32465a;
}

#frame #sidepanel #contacts ul li.contact.active {
  background: #32465a;
  border-right: 5px solid #435f7a;
}

#frame #sidepanel #contacts ul li.contact.active span.contact-status {
  border: 2px solid #32465a !important;
}

#frame #sidepanel #contacts ul li.contact .wrap {
  /* width: 88%; */
  margin: 0 8px;
  position: relative;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact .wrap {
    /* width: 100%; */
  }
}

#frame #sidepanel #contacts ul li.contact .wrap span {
  position: absolute;
  left: 32px;
  margin: -2px 0 0 -2px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid #2c3e50;
  background: #95a5a6;
  bottom: 0;
}

#frame #sidepanel #contacts ul li.contact .wrap span.online {
  background: #2ecc71;
}

#frame #sidepanel #contacts ul li.contact .wrap span.away {
  background: #f1c40f;
}

#frame #sidepanel #contacts ul li.contact .wrap span.busy {
  background: #e74c3c;
}

#frame #sidepanel #contacts ul li.contact .wrap img {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 50%;
  float: left;
  margin-right: 10px;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact .wrap img {
    /* margin-right: 0px; */
  }
}

/* #frame #sidepanel #contacts ul li.contact .wrap .meta {
  padding: 5px 0 0 0;
} */

@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact .wrap .meta {
    /* display: none; */
  }
}

#frame #sidepanel #contacts ul li.contact .wrap .meta .name {
  font-weight: 600;
  font-size: 16px !important;
}

#frame #sidepanel #contacts ul li.contact .wrap .meta .preview {
  margin: 5px 0 0 0;
  padding: 0 0 1px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-transition: 1s all ease;
  -o-transition: 1s all ease;
  -webkit-transition: 1s all ease;
  transition: 1s all ease;
  font-size: 14px;
}

#frame #sidepanel #contacts ul li.contact .wrap .meta .preview.msgPreview {
  max-width: 160px;
}

#frame #sidepanel #contacts ul li.contact .wrap .meta .preview span {
  position: initial;
  border-radius: initial;
  background: none;
  border: none;
  padding: 0 2px 0 0;
  margin: 0 0 0 1px;
  opacity: 0.5;
}

#frame #sidepanel #bottom-bar {
  position: absolute;
  width: 100%;
  bottom: 0;
}

#frame #sidepanel #bottom-bar button {
  float: left;
  border: none;
  width: 33.33%;
  padding: 10px 0;
  background: #32465a;
  color: #f5f5f5;
  cursor: pointer;
  font-size: 12px;
  font-family: "proxima-nova", "Source Sans Pro", sans-serif;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar button {
    float: none;
    /* width: 100%; */
    padding: 15px 0;
  }
}

#frame #sidepanel #bottom-bar button:focus {
  outline: none;
}

#frame #sidepanel #bottom-bar button:nth-child(1) {
  border-right: 1.5px solid #2c3e50;
}

#frame #sidepanel #bottom-bar button:nth-child(2) {
  border-left: 1.5px solid #2c3e50;
}

#frame #sidepanel #bottom-bar button:nth-child(2) {
  border-right: 1.5px solid #2c3e50;
}

#frame #sidepanel #bottom-bar button:nth-child(3) {
  border-left: 1.5px solid #2c3e50;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar button:nth-child(1) {
    border-right: none;
    border-bottom: 1px solid #2c3e50;
  }
}

#frame #sidepanel #bottom-bar button:hover {
  background: #435f7a;
}

#frame #sidepanel #bottom-bar button i {
  margin-right: 3px;
  font-size: 1em;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar button i {
    font-size: 1.3em;
  }
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar button span {
    display: none;
  }
}

#frame .content {
  float: right;
  width: 60%;
  height: 70vh;
  /* height: 540px; */
  overflow: hidden;
  position: relative;
}

@media screen and (max-width: 735px) {
  #frame .content {
    width: calc(100% - 58px);
    min-width: 300px !important;
  }
}

/* @media screen and (min-width: 900px) {
  #frame .content {
    width: calc(100% - 250px);
  }
} */

#frame .content .contact-profile {
  width: 100%;
  /* line-height: 60px; */
  background: #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: -1px 0px 24px 11px rgb(92 92 94 / 50%);
  padding: 8px;
}

.contact-profile.active-channel {
  justify-content: space-between !important;
}

.active-channel .leaveGroup {
  background: #f64e60;
}

#frame .content .contact-profile img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  float: left;
  margin: 0px 12px 0 0px;
}

#frame .content .contact-profile p {
  float: left;
  font-size: 15px;
}

#frame .content .contact-profile .social-media {
  float: right;
}

#frame .content .contact-profile .social-media i {
  margin-left: 14px;
  cursor: pointer;
}

#frame .content .contact-profile .social-media i:nth-last-child(1) {
  margin-right: 20px;
}

#frame .content .contact-profile .social-media i:hover {
  color: #435f7a;
}

#frame .content .messages {
  height: auto;
  min-height: calc(100% - 100px);
  max-height: calc(100% - 100px);
  overflow-x: hidden;
  /* background: url('../../../../assets/images/chat-bg.jpg') repeat scroll 0 0; */
}

@media screen and (max-width: 735px) {
  #frame .content .messages {
    max-height: calc(100% - 105px);
  }
}

#frame .content .messages::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}

#frame .content .messages::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}

#frame .content .messages ul li {
  display: inline-block;
  clear: both;
  float: left;
  margin: 5px 15px 5px 15px;
  width: calc(100% - 25px);
  /* padding: 0px 50px; */
  font-size: 14px;

}

#frame .content .messages ul li>span {
  padding: 0px 50px;
  display: flex;
  justify-content: center;
}

#frame .content .messages ul li:nth-last-child(1) {
  margin-bottom: 20px;
}

#frame .content .messages ul li.sent img {
  margin: 0 8px 0 0;
}

#frame .content .messages ul li.sent p {
  background: #435f7a;
  color: #f5f5f5;
}

#frame .content li.sent .message_text {
  background: #fff !important;
  /* color: #757575 !important; */
  color: #382a1f !important;
}


#frame .content .messages ul li.replies img {
  float: right;
  margin: 0 0 0 8px;
}

#frame .content .messages ul li.replies p {
  /* background: #f5f5f5; */
  /* background: #d08a569e; */
  background: #435f7a;
  color: #f5f5f5;
  float: right;
  position: relative;
}

.chat_reply_text:before {
  box-sizing: border-box;
  width: 0;
  height: 0;
  margin-top: 10px;
  content: '';
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 12px 18px;
  -ms-transform-origin: 12px 18px;
  transform-origin: 12px 18px;
  border: 7px solid transparent;
  border-bottom-color: #fff;
  border-left-color: #fff;
  box-shadow: -2px 3px 4px -3px rgb(0 0 0 / 40%);
  position: absolute;
  left: -13px;
  top: 3px;
}

.chat_text:before {
  box-sizing: border-box;
  width: 0;
  height: 0;
  margin-top: 10px;
  content: '';
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 3px -5px;
  -ms-transform-origin: 3px -5px;
  transform-origin: 3px -5px;
  border-top-color: #435f7a !important;
  border-right-color: #435f7a !important;
  border: 7px solid transparent;
  box-shadow: 2px -2px 4px -3px rgb(0 0 0 / 40%);
  position: absolute;
  right: -14px;
  top: 3px;
}

#frame .content .messages ul li.replies p,
#frame .content .messages ul li.sent p {
  position: relative;
}

#frame .content .messages ul li.replies,
#frame .content .messages ul li.sent {
  position: relative;
}

#frame .content .messages ul li.replies .time,
#frame .content .messages ul li.sent .time {
  background: transparent;
  float: none;
  position: absolute;
  bottom: -23px;
  left: 30px;
}

#frame .content .messages ul li.replies .time {
  right: 30px;
  left: auto;
}

#frame .content .messages ul li img {
  /* width: 22px; */
  height: 40px;
  width: 40px;
  border-radius: 50%;
  float: left;
}

#frame .content .messages ul li p {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 205px;
  line-height: 130%;
}

@media screen and (min-width: 735px) {
  #frame .content .messages ul li p {
    max-width: 300px;
  }
}

#frame .content .message-input {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 99;
  height: 40px;
  border: 1px solid #f1f1f1;
}

#frame .content .message-input .wrap {
  position: relative;
}

#frame .content .message-input .wrap input {
  float: left;
  width: calc(100% - 50px);
  padding: 11px 32px 10px 8px;
  font-size: 0.8em;
  color: #382a1f;
  border-radius: 0;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

@media screen and (max-width: 735px) {
  #frame .content .message-input .wrap input {
    padding: 15px 32px 16px 8px;
  }
}

#frame .content .message-input .wrap input:focus {
  outline: none;
}

#frame .content .message-input .wrap .attachment {
  position: absolute;
  right: 60px;
  z-index: 4;
  margin-top: 10px;
  font-size: 1.1em;
  color: #435f7a;
  opacity: 0.5;
  cursor: pointer;
}

@media screen and (max-width: 735px) {
  #frame .content .message-input .wrap .attachment {
    margin-top: 17px;
    right: 65px;
  }
}

#frame .content .message-input .wrap .attachment:hover {
  opacity: 1;
}

#frame .content .message-input .wrap button {
  float: right;
  border: none;
  width: 50px;
  padding: 12px 0;
  cursor: pointer;
  background: #382a1f;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 735px) {
  #frame .content .message-input .wrap button {
    padding: 16px 0;
  }
}

#frame .content .message-input .wrap button:hover {
  background: #435f7a;
}

#frame .content .message-input .wrap button:focus {
  outline: none;
}

.image_icons {
  width: 40px;
  height: 40px;
  display: flex;
  background-color: #1e2226;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  margin-right: 7px;
  margin-bottom: 0;
  color: #f5f5f5;
}

.image_icons.small {
  /* width: 30px;
  height: 30px; */
  width: 40px;
  height: 40px;
}

.addUser {
  background-color: #382a1f;
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  border-radius: 0.25rem;
  padding: 5px;
  align-self: center;
  height: auto;
  width: auto;
  line-height: 1.4;
}

.p_name {
  font-weight: 500;
  margin-right: 5px;
  text-transform: capitalize;
  margin-top: 5px;
}

/* chat css */
.chat_container {
  position: fixed;
  bottom: 0px;
  right: 18px;
  z-index: 9999;
  border-radius: 2px;
}

.chat_container .single_side_panel {
  max-width: unset !important;
  width: 100% !important;
}

.chat_container .card {
  padding: 3px !important;
  box-shadow: 0px 0px 24px 3px rgb(92 92 94 / 43%) !important;
}

.chat_modal {
  /* position: absolute; */
  width: 830px;
  max-height: 98vh;
  /* height: 100%; */
}

.chat_modal.no_contact {
  width: 380px !important;
}

.chat_toggle_btn, .chat_close_btn {
  width: 50px;
  height: 50px;
  background-color: #382a1f;
  color: #fff;
  font-size: 1.8rem;
  border-radius: 50%;
  position: fixed;
  bottom: 35px;
  right: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #382a1f;
  z-index: 10000;
}

.btnRight {
  right: 90px;
}

.chat_close_btn {
  position: absolute;
  right: auto;
  bottom: auto;
  right: -18px;
  top: -18px;
  width: 40px;
  height: 40px;
}

.chat_no_contact_div {
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 100px;
}

.chat_unread_count_badge {
  bottom: 70px;
  position: absolute;
  z-index: 100001;
  right: 26px;
  border: 2px solid;
  border-radius: 20px;
  min-width: 26px;
  min-height: 26px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  display: flex;
  /* background: rgb(219, 171, 134); */
  background: #f44336;
}

.chat_unread_count_badge_right {
  right: 62px;
}

.unreadMessage {
  display: flex !important;
  justify-content: center;
  align-items: center;
  min-height: 22px;
  min-width: 22px;
  border-radius: 9px !important;
}

.fz_11 {
  font-size: 11px;
}

.groupList .contact {
  padding: 10px;
  border-bottom: 1px solid #95a5a6;
}

.groupList.boxBorder {
  border: 1px solid #afbbbc;
  border-radius: 12px;
}

.groupList.boxBorder .contact:last-child {
  border-bottom: none;
}

.chat_settings_modal {
  z-index: 9999;
}

.leaveGroupMsg {
  color: #f64e60;
}

/**Responsive CSS**/
@media screen and (max-width: 735px) {
  .chat_modal.no_contact {
    width: 345px !important;
  }

  #frame #sidepanel #contacts ul li.contact .wrap .meta .preview.msgPreview {
    line-height: 16px;
  }

  .chat_modal {
    width: 420px;
  }

  .contact-profile.active-channel button span {
    display: none;
  }
}

/* chat css */