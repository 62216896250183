@media (max-width: 1920.98px) {
  .people-says-wrapper .title {
    padding-top: 60px;
  }

  .people-say-slider .slick-prev {
    left: -32%;
  }

  .people-say-slider .slick-next {
    right: 122%;
  }
}

@media (max-width: 1560.98px) {
  .people-says-wrapper .title {
    padding-top: 100px;
  }

  .people-say-slider .slick-prev {
    left: -43%;
  }
}

@media (max-width: 1536.98px) {
  .people-say-slider .slick-prev {
    left: -44%;
  }

  .people-say-slider .slick-next {
    right: 130.5%;
  }
}

@media (max-width: 1440.98px) {
  .people-say-slider .slick-prev {
    left: -48%;
  }

  .people-say-slider .slick-next {
    right: 133.5%;
  }

  .people-says-wrapper .title {
    padding-left: 120px;
  }
  .navbar-logged-in { 
    left: -60px;
  }
}

@media (max-width: 1366.98px) {
  .people-says-wrapper .title {
    padding-left: 40px;
  }

  .people-say-slider .slick-prev {
    left: -63%;
  }

  .people-say-slider .slick-next {
    right: 147.5%;
  }

  .people-says-wrapper .title {
    padding-top: 125px;
  }
}

@media (max-width: 1280.98px) {
  .people-says-wrapper .title {
    padding-left: 15px;
  }

  .people-say-slider .slick-prev {
    left: -73%;
  }

  .people-say-slider .slick-next {
    right: 156.5%;
  }

  .people-says-wrapper .title {
    padding-top: 145px;
  }
  .people-say-slider .slick-prev {
    left: -82%;
  }

  .people-say-slider .slick-next {
    right: 162.5%;
  }

  .people-says-wrapper .title {
    padding-top: 110px;
  }

  .modal-dialog {
    max-width: calc(100% - 60px) !important;
    margin: 30px auto;
  }
  .navbar-logged-in { 
    left: -50px;
  }
}

@media (max-width: 1024.98px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 15px;
    padding-right: 3px;
    padding-left: 3px;
  }
  .header_try_harpsen {
    width: 184px;
  }

  .header_schedule_demo {
    width: 184px;
  }
  .try-btn {
    margin-left: 15px;
  }

  .hero-main-title {
    font-size: 50px;
    margin-bottom: 30px;
  }

  .hero-sub-title {
    font-size: 40px;
    margin-bottom: 30px;
  }

  .hero-para {
    font-size: 25px;
  }

  .hero-wrapper {
    padding-top: 100px;
    padding-bottom: 50px;
  }

  .tab-title {
    font-size: 25px;
    margin-bottom: 10px;
  }

  .tab-para {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 15px;
  }

  .tab-wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .explore-wrapper {
    padding-bottom: 50px;
  }

  .heading-wrapper .title {
    font-size: 30px;
    padding: 15px;
    margin-bottom: -50px;
  }

  .explore-tabbing .nav-tabs .nav-link {
    font-size: 20px;
  }

  .grid-card-wrapper {
    margin-bottom: 50px;
    margin-top: 115px;
  }

  .our-story-wrapper .title {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .our-story-wrapper {
    padding-top: 50px;
    padding-bottom: 78px;
  }

  .people-say-slider .slick-prev {
    left: -15px;
    top: 50%;
    z-index: 2;
  }

  .people-say-slider .slick-next {
    top: 50%;
    right: -15px;
    z-index: 2;
  }

  .people-says-wrapper .title {
    padding-top: 0;
    font-size: 30px;
    text-align: center;
  }

  .people-says-wrapper {
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .people-says-wrapper .people-content {
    width: 100%;
    padding-left: 0 15px;
  }

  .people-says-wrapper .people-slider {
    max-width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 30px;
  }

  .pricing-wrapper,
  .faq-wrapper,
  .blog-wrapper,
  .blog-details-wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .search-field {
    margin-bottom: 20px;
  }

  .blog-wrapper .row {
    row-gap: 25px;
    margin-left: -15px;
    margin-right: -15px;
  }

  .blog-wrapper .row .col-lg-4.col-md-6 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .blog-details-title {
    font-size: 30px;
  }

  .blog-details-sidebar h4 {
    font-size: 28px;
  }

  .blog-details-sidebar {
    padding-left: 0;
  }
  .vendor_portal-video {
    width: 100%;
    height: 430px;
  }
}

@media (max-width: 991.98px) {
  header a.nav-link.btn.btn-secondary {
    margin-bottom: 15px;
  }
  .header a.nav-link.btn.btn-secondary {
    margin-bottom: 15px;
  }

  .navbar-nav li .btn {
    max-width: 184px;
  }

  .navbar-container input[type="checkbox"],
  .navbar-container .hamburger-lines {
    display: block;
  }

  .navbar-container input[type="checkbox"] {
    position: absolute;
    display: block;
    height: 20px;
    width: 25px;
    top: 4px;
    left: 0;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }

  .navbar-toggler {
    padding: 0;
    margin: 0;
    border: none !important;
    outline: 0 !important;
    box-shadow: none !important;
    position: relative;
  }

  .navbar-container .hamburger-lines {
    display: block;
    height: 20px;
    width: 25px;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .navbar-container .hamburger-lines .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: $black;
  }

  .navbar-container .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.3s ease-in-out;
  }

  .navbar-container .hamburger-lines .line2 {
    transition: transform 0.1s ease-in-out;
  }

  .navbar-container .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.3s ease-in-out;
  }

  .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
    transform: rotate(45deg);
  }

  .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
    transform: scaleY(0);
  }

  .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
    transform: rotate(-45deg);
  }

  .try-btn {
    margin-left: 0px;
  }

  .container {
    padding: 0 20px !important;
  }

  .authentication-wrapper {
    flex-direction: column;
    height: auto;
  }

  .authentication-wrapper .authentication-img {
    height: 100vh;
    width: 100%;
    border-radius: 0px 0px 100px 100px;
    padding: 20px;
  }

  .authentication-wrapper .authentication-content {
    width: 100%;
    padding: 20px;
  }

  .register-wrapper .form-wrapper {
    height: auto;
  }

  .hero-main-title {
    font-size: 35px;
    margin-bottom: 20px;
  }

  .hero-sub-title {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .hero-para {
    font-size: 23px;
  }

  .tabbing-wrapper {
    flex-direction: column;
  }

  .tabbing-wrapper .nav {
    display: flex;
    flex-direction: row;
    overflow: auto;
    flex-wrap: nowrap;
    width: 100%;
  }

  .tabbing-wrapper .nav li.nav-item a {
    white-space: nowrap;
    padding: 0 20px;
  }

  .tabbing-wrapper .nav li.nav-item {
    border-bottom: 0;
    border-right: 1px solid $grey;
  }

  .tabbing-wrapper .tab-content {
    padding: 20px 10px;
    width: 100%;
  }

  .grid-card-wrapper {
    margin-bottom: 0px;
  }

  .grid-wrapper {
    padding-bottom: 50px;
  }

  .our-story-wrapper .story-img-wrapper {
    width: fit-content;
    margin: 15px auto 0;
    float: unset;
  }

  .blog-details-sidebar {
    margin-top: 50px;
  }

  .blog-details-sidebar .col-md-12:not(:last-child) {
    margin-bottom: 50px;
  }

  .modal-style .modal-xl .modal-body {
    padding: 60px 20px 20px 20px;
  }
  .modal-content-wrapper .slick-arrow {
    top: -40px;
  }
  .modal-style .close {
    width: 40px;
    height: 40px;
    right: -20px;
    top: -20px;
    img {
      width: 25px;
      height: 25px;
    }
  }
  .story-img-wrapper {
    max-width: calc(100% - 20px) !important;
    margin: 0 auto;
    // margin-left: 20px !important;
    img {
      top: 20px !important;
      right: 20px !important;
    }
  }
  .authentication-content {
    height: auto;
  }
  .header_loggedIn-btns {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-direction: unset;
    flex-flow: row-reverse;
    justify-content: flex-end;
    align-items: flex-start !important;
  }
  .navbar-logged-in { 
    left: unset;
    position: unset;
  }
}

@media (max-width: 768.98px) {
  .authentication-wrapper .authentication-img {
    height: auto;
    width: 100%;
    border-radius: 0px 0px 50px 50px;
    padding: 120px 20px 30px 20px;
  }

  .event-slider-wrapper {
    padding: 15px;
  }

  .tabbing-wrapper .row {
    gap: 30px;
  }

  .pricing-card-wrapper ul {
    padding-left: 30px;
  }

  .footer-wrapper h6 {
    margin-bottom: 15px;
    margin-top: 30px;
  }

  .footer-wrapper .copy-rights {
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .et_nav-link {
    font-size: 18px;
  }
  .vendor_portal-video {
    width: 100%;
    height: 340px;
  }
  .explore-harpsen, .event-wrapper {
    padding: 50px 0px;
  }
}

@media (max-width: 767.98px) {
  .pricing-card-wrapper {
    padding: 20px;
  }

  .pricing-card-wrapper:before {
    display: none;
  }

  .pricing-card-wrapper h3 {
    font-size: 45px;
    margin-bottom: 15px;
  }

  .pricing-card-wrapper h4 {
    font-size: 25px;
  }

  .pricing-card-wrapper p {
    margin-bottom: 20px;
    max-width: 100%;
  }

  .pricing-card-wrapper ul {
    padding-left: 0;
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid $blue;
  }
}

@media (max-width: 650.98px) {
  .hero-btn-wrapper {
    flex-direction: column;
    margin-top: 15px;
    gap: 15px;
  }

  .hero-btn-wrapper .btn {
    max-width: 100%;
    font-size: 20px;
    height: 50px;
  }
  .quote:before,
  .quote:after {
    content: "";
    width: 25px;
    height: 25px;
  }
  .quote {
    padding: 25px 45px;
  }
  .quote p {
    font-size: 20px;
  }
  .heading-wrapper .title {
    margin-bottom: 30px;
  }
  .event-wrapper .event-bg {
    margin-top: 30px;
  }
}

@media (max-width: 480.98px) {
  .explore-tabbing .nav-tabs,
  .et_nav-tabs {
    display: flex;
    flex-wrap: nowrap;
  }
  .our-story-wrapper {
    .story-img-wrapper {
      width: calc(100% - 30px);
      margin: 15px auto 0;
      float: right;
    }
  }
  .vendor_portal-video {
    width: 100%;
    height: 220px;
  }
}
